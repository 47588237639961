/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {UserNotificationDataRepresentation} from './userNotificationDataRepresentation';

export interface UserNotificationRepresentation {
  id?: string;
  profileId?: string;
  timestamp?: number;
  type?: UserNotificationRepresentation.TypeEnum;
  seen?: boolean;
  data?: UserNotificationDataRepresentation;
}
export namespace UserNotificationRepresentation {
  export type TypeEnum =
    | 'NONE'
    | 'INCOMING_CONNECTION'
    | 'DISCONNECT'
    | 'INCOMING_PROPERTY_REQUESTS'
    | 'INCOMING_GROUP_REQUESTS'
    | 'PROPERTIES_CHANGED'
    | 'GROUP_FOLLOW'
    | 'GROUP_UNFOLLOW'
    | 'GROUP_JOIN'
    | 'GROUP_LEAVE'
    | 'GROUP_ADDED_AS_MEMBER'
    | 'GROUP_REMOVED_AS_MEMBER'
    | 'BIRTHDAY'
    | 'NEW_SUGGESTIONS'
    | 'IMPORT_SUCCEEDED'
    | 'IMPORT_FAILED'
    | 'SOCIAL_VERIFICATION_SUCCEEDED'
    | 'SOCIAL_VERIFICATION_FAILED'
    | 'INVITATION_ANSWERED'
    | 'INVITATION_CLAIMED'
    | 'ORGANISATION_CLAIM_ACCEPTED'
    | 'ORGANISATION_CLAIM_REJECTED'
    | 'SUBSCRIBE_TO_AZTRIX_NEWSLETTER'
    | 'CONFIRM_NON_CONFIRMED_AGREEMENT'
    | 'NON_CONFIRMED_AGREEMENT_BASED_ON_PROFILE'
    | 'NEW_SUBSCRIBER'
    | 'AGREEMENT_BECAME_UNCONFIRMED'
    | 'INCOMING_ROLE_REQUEST'
    | 'UPDATED_TERMS_AND_CONDITIONS'
    | 'UPDATED_PRIVACY_POLICY'
    | 'AGREEMENT_DELETED_BY_OWNER'
    | 'AGREEMENT_UPDATED_BY_SUBSCRIBER'
    | 'UNRECOGNIZED';
  export const TypeEnum = {
    NONE: 'NONE' as TypeEnum,
    INCOMING_CONNECTION: 'INCOMING_CONNECTION' as TypeEnum,
    DISCONNECT: 'DISCONNECT' as TypeEnum,
    INCOMING_PROPERTY_REQUESTS: 'INCOMING_PROPERTY_REQUESTS' as TypeEnum,
    INCOMING_GROUP_REQUESTS: 'INCOMING_GROUP_REQUESTS' as TypeEnum,
    PROPERTIES_CHANGED: 'PROPERTIES_CHANGED' as TypeEnum,
    GROUP_FOLLOW: 'GROUP_FOLLOW' as TypeEnum,
    GROUP_UNFOLLOW: 'GROUP_UNFOLLOW' as TypeEnum,
    GROUP_JOIN: 'GROUP_JOIN' as TypeEnum,
    GROUP_LEAVE: 'GROUP_LEAVE' as TypeEnum,
    GROUP_ADDED_AS_MEMBER: 'GROUP_ADDED_AS_MEMBER' as TypeEnum,
    GROUP_REMOVED_AS_MEMBER: 'GROUP_REMOVED_AS_MEMBER' as TypeEnum,
    BIRTHDAY: 'BIRTHDAY' as TypeEnum,
    NEW_SUGGESTIONS: 'NEW_SUGGESTIONS' as TypeEnum,
    IMPORT_SUCCEEDED: 'IMPORT_SUCCEEDED' as TypeEnum,
    IMPORT_FAILED: 'IMPORT_FAILED' as TypeEnum,
    SOCIAL_VERIFICATION_SUCCEEDED: 'SOCIAL_VERIFICATION_SUCCEEDED' as TypeEnum,
    SOCIAL_VERIFICATION_FAILED: 'SOCIAL_VERIFICATION_FAILED' as TypeEnum,
    INVITATION_ANSWERED: 'INVITATION_ANSWERED' as TypeEnum,
    INVITATION_CLAIMED: 'INVITATION_CLAIMED' as TypeEnum,
    ORGANISATION_CLAIM_ACCEPTED: 'ORGANISATION_CLAIM_ACCEPTED' as TypeEnum,
    ORGANISATION_CLAIM_REJECTED: 'ORGANISATION_CLAIM_REJECTED' as TypeEnum,
    SUBSCRIBE_TO_AZTRIX_NEWSLETTER: 'SUBSCRIBE_TO_AZTRIX_NEWSLETTER' as TypeEnum,
    CONFIRM_NON_CONFIRMED_AGREEMENT: 'CONFIRM_NON_CONFIRMED_AGREEMENT' as TypeEnum,
    NON_CONFIRMED_AGREEMENT_BASED_ON_PROFILE:
      'NON_CONFIRMED_AGREEMENT_BASED_ON_PROFILE' as TypeEnum,
    NEW_SUBSCRIBER: 'NEW_SUBSCRIBER' as TypeEnum,
    AGREEMENT_BECAME_UNCONFIRMED: 'AGREEMENT_BECAME_UNCONFIRMED' as TypeEnum,
    INCOMING_ROLE_REQUEST: 'INCOMING_ROLE_REQUEST' as TypeEnum,
    UPDATED_TERMS_AND_CONDITIONS: 'UPDATED_TERMS_AND_CONDITIONS' as TypeEnum,
    UPDATED_PRIVACY_POLICY: 'UPDATED_PRIVACY_POLICY' as TypeEnum,
    AGREEMENT_DELETED_BY_OWNER: 'AGREEMENT_DELETED_BY_OWNER' as TypeEnum,
    AGREEMENT_UPDATED_BY_SUBSCRIBER: 'AGREEMENT_UPDATED_BY_SUBSCRIBER' as TypeEnum,
    UNRECOGNIZED: 'UNRECOGNIZED' as TypeEnum,
  };
}
