import {Context, ContextType, Profile, Property, PropertyTuple, PropertyType} from '@aztrix/models';
import {ContextRepresentation, ProfileRepresentation, PropertyRepresentation} from '@aztrix/sdk';

import {requiredContextTypes} from '../metadata/context-type-provider';
import {existingFilter, idFilter, isOfType, typeFilter} from '../util/filter.util';
import {not} from '../util/predicate.util';
import {possibleContextsForMissingPropertyType} from './profile-enriching';
import {getContext, getContexts, isPublicProfile} from './profile-functions';
import {
  canBeDeleted as propertyCanBeDeleted,
  getProperties,
  getProperty,
} from './property-functions';

export function getPropertyTuple(
  contexts?: (Context | ContextRepresentation)[] | ReadonlyArray<Context | ContextRepresentation>,
  filter: (property: Property) => boolean = () => true
): PropertyTuple {
  if (Array.isArray(contexts)) {
    for (const c of contexts) {
      const p = getProperties(c).find(filter);
      if (p) {
        return <PropertyTuple>{property: p, context: c};
      }
    }
  }
  return {};
}

export function getPropertyTuples(
  contexts:
    | Context[]
    | ContextRepresentation[]
    | ReadonlyArray<Context>
    | ReadonlyArray<ContextRepresentation>
    | undefined,
  filter: (property: Property) => boolean = () => true
): {property: Property; context: Context}[] {
  if (!contexts) {
    return [];
  }
  return <{property: Property; context: Context}[]>[...contexts]
    .flatMap((c) => {
      if (c) {
        return getProperties(c)
          .filter(filter)
          .map((p: Property) => ({property: p, context: c}));
      } else {
        return undefined;
      }
    })
    .filter((c) => !!c);
}

export function getPropertiesOfType(
  profile?: Profile | ProfileRepresentation,
  propertyType?: PropertyType
): Property[] {
  const contexts = profile ? profile.propertyContexts || [] : [];
  return <Property[]>getPropertyTuples(contexts, typeFilter(propertyType))
    .map((tuple) => tuple.property)
    .filter((p) => !!p);
}

export function getPropertyOfType(
  profile: Profile | ProfileRepresentation,
  propertyType?: PropertyType
): Property | undefined {
  const properties = getPropertiesOfType(profile, propertyType);
  if (!properties?.length) {
    return undefined;
  }
  return properties[0] || undefined;
}

export function getPropertyTupleById(
  contexts: Context[] | ReadonlyArray<Context>,
  id: string
): PropertyTuple {
  if (!contexts) {
    return {};
  }
  for (const c of contexts) {
    const p = getProperty(c, idFilter(id));
    if (p) {
      return <PropertyTuple>{property: p, context: c};
    }
  }
  return {};
}

export function primaryContextOfType(
  profile: Profile | ProfileRepresentation,
  type: ContextType | ContextRepresentation.TypeEnum,
  filter: (context: Context | ContextRepresentation) => boolean = () => true
): Context | ContextRepresentation | undefined {
  return getContext(
    <ProfileRepresentation>profile,
    (context) => isOfType(context, type) && filter(context)
  );
}

export function commonPropertyOfType(
  profile: Profile,
  type: PropertyType | PropertyRepresentation.TypeEnum
): PropertyTuple | undefined {
  const commonContext = primaryContextOfType(profile, ContextType.PROFILE);
  if (commonContext) {
    const tuple =
      getPropertyTuple([commonContext], typeFilter(type)) ||
      <PropertyTuple>{
        context: commonContext,
      };
    return tuple;
  }
  return undefined;
}

export function contextCanBeDeleted(profile: Profile | ProfileRepresentation, context: Context) {
  if (!context.id || isPublicProfile(<ProfileRepresentation>profile)) {
    return false;
  }
  if (requiredContextTypes(profile.type, profile.profileContext).includes(context.type)) {
    return false;
  }
  return !getProperties(context, existingFilter).some(
    (property) => !propertyCanBeDeleted(profile, context, property)
  );
}

export function updateContextInProfileMapper(
  oldProfile: Profile | ProfileRepresentation,
  oldContext: Context | ContextRepresentation,
  newContext: Context | ContextRepresentation
): Profile | ProfileRepresentation {
  return {
    ...oldProfile,
    propertyContexts: <ContextRepresentation[]>(
      getContexts(
        oldProfile,
        !oldContext.id ? (c) => c !== oldContext : not(idFilter(oldContext.id))
      ).concat(newContext || [])
    ),
  };
}

export function canAddPropertyType(
  propertyType: PropertyRepresentation.TypeEnum | undefined,
  profile: Profile | ProfileRepresentation
): boolean {
  return possibleContextsForMissingPropertyType(propertyType, profile).length > 0;
}

export function firstAvailablePropertyOfType(
  profile: Profile,
  ...propertyTypes: PropertyType[]
): Property | undefined {
  return getPropertyTuples(profile?.propertyContexts || [], typeFilter(...propertyTypes)).map(
    (tuple) => tuple.property
  )[0];
}

export function isSingleWebsitePropertyContext(context: Context): boolean {
  return (
    (context.type === ContextType.PERSONAL ||
      context.type === ContextType.GENERAL ||
      context.type === ContextType.WORK) &&
    context.label !== '' &&
    context.properties.length === 1 &&
    context.properties[0].type === PropertyType.WEBSITE
  );
}
