import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {AgreementVerification} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'agreementVerificationStateLabel',
  pure: false,
})
export class AgreementVerificationStateLabelPipe implements PipeTransform {
  private _translatePipe: TranslatePipe;
  constructor(
    translate: TranslateService,
    changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    languageOverride$?: BehaviorSubject<string | undefined>
  ) {
    this._translatePipe = new TranslatePipe(translate, changeDetector, languageOverride$);
  }

  transform(state: AgreementVerification): string {
    return this._translatePipe.transform('agreement.' + state.toLowerCase());
  }
}
