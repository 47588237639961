{
  "": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP"
      }
    ],
    [
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTY"
      }
    ],
    [
      {
        "propertyType": "DISTRICT"
      }
    ],
    [
      {
        "propertyType": "PROVINCE"
      }
    ],
    [
      {
        "propertyType": "STATE"
      }
    ],
    [
      {
        "propertyType": "SUBURB"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "AT": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 6
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "BG": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "BE": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      },
      {
        "propertyType": "SUBURB"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "CY": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "CZ": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "DE": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "DK": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "DISTRICT"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "EE": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "COUNTY"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "ES": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "PROVINCE"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "FI": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "FR": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "COUNTY"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "GR": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "GB": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTY"
      }
    ],
    [
      {
        "propertyType": "ZIP"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "HR": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "COUNTY"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "HU": [
    [
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "IE": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTY"
      }
    ],
    [
      {
        "propertyType": "ZIP"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "IT": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "PROVINCE"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "LT": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "LOCALITY"
      },
      {
        "propertyType": "ZIP",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "LU": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "LOCALITY"
      },
      {
        "propertyType": "ZIP",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "LV": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "LOCALITY"
      },
      {
        "propertyType": "ZIP",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "MT": [
    [
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      },
      {
        "propertyType": "STREET"
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "ZIP"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "NL": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 7
      },
      {
        "propertyType": "LOCALITY"
      },
      {
        "propertyType": "SUBURB"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "PL": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 6
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "PT": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "SUBURB"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 8
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "RO": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 6
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "SE": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 6
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "SI": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ],
  "SK": [
    [
      {
        "propertyType": "STREET"
      },
      {
        "propertyType": "NUMBER",
        "length": 5
      },
      {
        "propertyType": "BOX",
        "length": 5
      }
    ],
    [
      {
        "propertyType": "BUILDING"
      }
    ],
    [
      {
        "propertyType": "ZIP",
        "length": 5
      },
      {
        "propertyType": "LOCALITY"
      }
    ],
    [
      {
        "propertyType": "COUNTRY"
      }
    ]
  ]
}
