import {SecurityContext} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Params} from '@angular/router';
import {CommunicationType, Profile, Property, PropertyTuple, PropertyType} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';

import {getPropertyTuples} from '../helpers/context-functions';
import {propertyCommunicationType} from '../helpers/property-functions';
import {existingFilter, isOfType, typeFilter} from '../util/filter.util';
import {and} from '../util/predicate.util';

export function communicationHref(
  type: CommunicationType | undefined,
  value: string | SafeUrl,
  sanitizer: DomSanitizer,
  params?: Params
): SafeUrl | undefined {
  const sanitizedValue = sanitizer.sanitize(SecurityContext.URL, value);
  if (!sanitizedValue) {
    return undefined;
  }

  switch (type) {
    case CommunicationType.CALL:
      return sanitizer.bypassSecurityTrustUrl(`tel:${sanitizedValue.replaceAll(' ', '')}`);
    case CommunicationType.MESSAGE:
      return sanitizer.bypassSecurityTrustUrl(`sms:${sanitizedValue.replaceAll(' ', '')}`);
    case CommunicationType.MAIL:
      return sanitizer.bypassSecurityTrustUrl(`mailto:${sanitizedValue}`);
    case CommunicationType.MAP:
      return urlParams(`https://maps.google.com/?q=${sanitizedValue}`, sanitizer, params);
    case CommunicationType.URL:
      return urlParams(
        sanitizedValue.startsWith('http') ? sanitizedValue : `http://${sanitizedValue}`,
        sanitizer,
        params
      );
    default:
      return undefined;
  }
}

export function urlParams(
  value: string | SafeUrl | null,
  sanitizer: DomSanitizer,
  params?: Params
): SafeUrl | undefined {
  const sanitizedValue = sanitizer.sanitize(SecurityContext.URL, value);
  if (!sanitizedValue) {
    return undefined;
  }

  try {
    const parsedUrl = new URL(sanitizedValue);
    for (const [key, value] of Object.entries(params || {})) {
      parsedUrl.searchParams.append(key, value);
    }
    return sanitizer.bypassSecurityTrustUrl(parsedUrl.toString());
  } catch {
    return sanitizer.bypassSecurityTrustUrl(sanitizedValue);
  }
}

export function aTargetByPropertyType(value: Property | PropertyType | undefined): string {
  const type = <PropertyType>(typeof value === 'string' || !value ? value : value.type);
  return aTarget(propertyCommunicationType(type));
}

export function aTarget(type: CommunicationType | undefined): string {
  return isOfType({type}, CommunicationType.CALL, CommunicationType.MESSAGE, CommunicationType.MAIL)
    ? '_self'
    : '_blank';
}

export function propertiesForType(type: CommunicationType, profile: Profile): PropertyTuple[] {
  switch (type) {
    case CommunicationType.CALL:
      return callPropertiesForProfile(profile);
    case CommunicationType.MESSAGE:
      return messagePropertiesForProfile(profile);
    case CommunicationType.MAIL:
      return mailPropertiesForProfile(profile);
    case CommunicationType.MAP:
      return mapPropertiesForProfile(profile);
    case CommunicationType.URL:
      return urlPropertiesForProfile(profile);
    default:
      return [];
  }
}

function callPropertiesForProfile(profile: Profile | ProfileRepresentation): PropertyTuple[] {
  return getProperties(profile, PropertyType.MOBILE_PHONE, PropertyType.PHONE);
}

function messagePropertiesForProfile(profile: Profile | ProfileRepresentation): PropertyTuple[] {
  return getProperties(profile, PropertyType.MOBILE_PHONE);
}

function mailPropertiesForProfile(profile: Profile | ProfileRepresentation): PropertyTuple[] {
  return getProperties(profile, PropertyType.EMAIL);
}

function mapPropertiesForProfile(profile: Profile | ProfileRepresentation): PropertyTuple[] {
  return getProperties(profile, PropertyType.ADDRESS, PropertyType.REGION);
}

function urlPropertiesForProfile(profile: Profile | ProfileRepresentation): PropertyTuple[] {
  return getProperties(profile, PropertyType.WEBSITE);
}

function getProperties(
  profile: Profile | ProfileRepresentation,
  ...types: PropertyType[]
): PropertyTuple[] {
  if (!profile?.propertyContexts) {
    return [];
  }
  return getPropertyTuples(profile.propertyContexts, and(existingFilter, typeFilter(...types)));
}

export function communicationTypesForProfile(
  profile: Profile | ProfileRepresentation
): CommunicationType[] {
  if (!profile) {
    return [];
  }
  const types = [];
  if (callPropertiesForProfile(profile).length > 0) {
    types.push(CommunicationType.CALL);
  }
  if (messagePropertiesForProfile(profile).length > 0) {
    types.push(CommunicationType.MESSAGE);
  }
  if (mailPropertiesForProfile(profile).length > 0) {
    types.push(CommunicationType.MAIL);
  }
  if (mapPropertiesForProfile(profile).length > 0) {
    types.push(CommunicationType.MAP);
  }
  return types;
}
