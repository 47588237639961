/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {Query} from './query';
import {LatLong} from './latLong';

export interface SearchQueriesRepresentation {
  queries?: Array<Query>;
  format?: SearchQueriesRepresentation.FormatEnum;
  profileTypes?: Array<SearchQueriesRepresentation.ProfileTypesEnum>;
  profileContexts?: Array<SearchQueriesRepresentation.ProfileContextsEnum>;
  profileIds?: Array<string>;
  excludedProfileIds?: Array<string>;
  excludeContacts?: boolean;
  size?: number;
  location?: LatLong;
}
export namespace SearchQueriesRepresentation {
  export type FormatEnum = 'MINI' | 'SUMMARY' | 'FULL' | 'ID';
  export const FormatEnum = {
    MINI: 'MINI' as FormatEnum,
    SUMMARY: 'SUMMARY' as FormatEnum,
    FULL: 'FULL' as FormatEnum,
    ID: 'ID' as FormatEnum,
  };
  export type ProfileTypesEnum = 'PUBLIC_PROFILE' | 'ME_PROFILE' | 'CONTACT';
  export const ProfileTypesEnum = {
    PUBLIC_PROFILE: 'PUBLIC_PROFILE' as ProfileTypesEnum,
    ME_PROFILE: 'ME_PROFILE' as ProfileTypesEnum,
    CONTACT: 'CONTACT' as ProfileTypesEnum,
  };
  export type ProfileContextsEnum = 'INDIVIDUAL' | 'ORGANISATION';
  export const ProfileContextsEnum = {
    INDIVIDUAL: 'INDIVIDUAL' as ProfileContextsEnum,
    ORGANISATION: 'ORGANISATION' as ProfileContextsEnum,
  };
}
