import {Pipe, PipeTransform} from '@angular/core';
import {PropertyType} from '@aztrix/models';

@Pipe({
  name: 'errorTranslationKey',
})
export class ErrorTranslationKeyPipe implements PipeTransform {
  transform(errorType: string | undefined, propertyType?: PropertyType | string) {
    switch (errorType) {
      case 'propertyRequired':
        return 'property.validation.required';
      case 'dateNotWithinRange':
        return 'property.validation.date.notWithinInterval';
      case 'timeNotBefore':
        return 'property.validation.time.notBefore';
      case 'timeNotAfter':
        return 'property.validation.time.notAfter';
      case 'timeInvalid':
        return 'property.validation.time.invalid';
      case 'timeRequired':
        return 'property.validation.time.required';
      case 'timeRangeInvalid':
        return 'property.validation.time-range.invalid';
      case 'timeRangeOverlap':
        return 'property.validation.time-range.overlap';
      case 'dayTimeRangeOverlap':
        return 'property.validation.day-time-range.invalid';
      case 'propertyInvalidCharacters':
        return 'property.validation.invalidCharacters';
      case 'propertyNoSpace':
        return 'property.validation.invalidCharacters.space';
      case 'propertyOnlyLowercase':
        return 'property.validation.onlyLowercase';
      case 'propertyOnlyUppercase':
        return 'property.validation.onlyUppercase';
      case 'propertyOnlyNumeric':
        return 'property.validation.mustBeNumeric';
      case 'propertyOptionalSelected':
        return 'property.validation.optionalSelected';
      case 'pattern':
      case 'propertyInvalid':
        return this._propertyInvalidTranslationKey(<PropertyType>propertyType);
      case 'typeRequired':
        return 'proposal.validation.missing-type';
      case 'maxReached':
        return 'property.validation.max-reached';
      case 'maxValueControlsReached':
        return 'property.validation.valueControls.max-reached';
      case 'minValueLabels':
        return 'property.validation.valueControls.min-value';
      case 'propertyAlreadyExists':
        return 'property.validation.already-exists';
      default:
        console.warn(`unknown errorType: ${errorType}`);
        return 'property.validation.invalid';
    }
  }

  private _propertyInvalidTranslationKey(propertyType?: PropertyType) {
    switch (propertyType) {
      case PropertyType.LINKEDIN:
        return 'property.validation.invalid.LINKEDIN';
      default:
        return 'property.validation.invalid';
    }
  }
}
