/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GroupRepresentation {
  id?: string;
  format?: GroupRepresentation.FormatEnum;
  name?: string;
  avatar?: string;
  type?: GroupRepresentation.TypeEnum;
  profileContext?: GroupRepresentation.ProfileContextEnum;
  ownerId?: string;
  importProvider?: string;
  visibility?: GroupRepresentation.VisibilityEnum;
  canAddMembers?: boolean;
  membersVisible?: boolean;
  editable?: boolean;
  member?: boolean;
  follower?: boolean;
  memberCount?: number;
  followerCount?: number;
  visibleToMembers?: boolean;
  visibleAsMember?: boolean;
  memberIds?: Array<string>;
}
export namespace GroupRepresentation {
  export type FormatEnum = 'MINI' | 'SUMMARY' | 'FULL' | 'ID';
  export const FormatEnum = {
    MINI: 'MINI' as FormatEnum,
    SUMMARY: 'SUMMARY' as FormatEnum,
    FULL: 'FULL' as FormatEnum,
    ID: 'ID' as FormatEnum,
  };
  export type TypeEnum =
    | 'EXPLICIT'
    | 'CONTACTS'
    | 'FAMILY'
    | 'FOLLOWERS'
    | 'IMPORTING'
    | 'BLOCKED'
    | 'CUSTOMERS'
    | 'FAVORITES';
  export const TypeEnum = {
    EXPLICIT: 'EXPLICIT' as TypeEnum,
    CONTACTS: 'CONTACTS' as TypeEnum,
    FAMILY: 'FAMILY' as TypeEnum,
    FOLLOWERS: 'FOLLOWERS' as TypeEnum,
    IMPORTING: 'IMPORTING' as TypeEnum,
    BLOCKED: 'BLOCKED' as TypeEnum,
    CUSTOMERS: 'CUSTOMERS' as TypeEnum,
    FAVORITES: 'FAVORITES' as TypeEnum,
  };
  export type ProfileContextEnum = 'INDIVIDUAL' | 'ORGANISATION';
  export const ProfileContextEnum = {
    INDIVIDUAL: 'INDIVIDUAL' as ProfileContextEnum,
    ORGANISATION: 'ORGANISATION' as ProfileContextEnum,
  };
  export type VisibilityEnum = 'PUBLIC' | 'PRIVATE';
  export const VisibilityEnum = {
    PUBLIC: 'PUBLIC' as VisibilityEnum,
    PRIVATE: 'PRIVATE' as VisibilityEnum,
  };
}
