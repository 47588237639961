export interface PropertySharedInfoRepresentation {
  propertyId: string;
  groupIds: string[];
  profileIdsExplicit: string[];
  profileIdsGroup: string[];
}

export enum SharedType {
  GROUPS = 'GROUPS',
  PROFILES = 'PROFILES',
}

export enum ProfileIdType {
  EXPLICIT = 'EXPLICIT',
  GROUP = 'GROUP',
}
