import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {IResult, UAParser} from 'ua-parser-js';

@Injectable({providedIn: 'root'})
export class PlatformService {
  private parsed: IResult;

  constructor(@Inject(DOCUMENT) doc: Document) {
    this.parsed = UAParser(doc.defaultView?.navigator?.userAgent);
  }

  /**
   * Checks whether we're currently running a native app (Capacitor)
   */
  isNativeApp(): boolean {
    return Capacitor.isNativePlatform();
  }

  /**
   * Checks whether the platform is native
   * Can be:
   *   * a native app (Capacitor)
   *   * a browser on a native platform (iOS, Android)
   */
  isNativePlatform(): boolean {
    return this.isNativeApp() || ['iOS', 'Android[-x86]'].includes(this.parsed.os.name || '');
  }

  /**
   * Checks whether the app is running on iOS
   * Can be:
   *   * a native app (Capacitor) running iOS
   *   * a browser running on iOS
   */
  isIOS(): boolean {
    return Capacitor.getPlatform() === 'ios' || this.parsed.os.name === 'iOS';
  }

  /**
   * Checks whether the app is running on Android
   * Can be:
   *   * a native app (Capacitor) running on Android
   *   * a browser running on Android
   */
  isAndroid(): boolean {
    return Capacitor.getPlatform() === 'android' || this.parsed.os.name === 'Android[-x86]';
  }

  /**
   * Checks whether the app is Apple related
   * Can be:
   *   * a native app (Capacitor) running iOS
   *   * a browser running on iOS
   *   * a browser running on Mac OS
   */
  isApple(): boolean {
    return this.isIOS() || this.parsed.os.name === 'Mac OS';
  }
}
