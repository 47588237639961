import {Visibility} from '../visibility/visibility';

export interface Page {
  id: string;
  ownerId: string;
  templateId: string;
  name: string;
  languages: PageLanguage[];
  visibility: Visibility;
}

export interface PageLanguage {
  id: string;
  languageCode: string;
  pending: boolean;
  isDefault: boolean;
  creationTimestamp?: number;
  fieldValues: FieldValue[];
  appearance: PageAppearance;
  settings?: PageSettings;
}

export interface PageAppearance {
  background: string;
  font: string;
  fontColor: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface PageSettings {
  url: string;
  domain?: string;
  metadata?: PageSettingsMetadata;
}

export interface PageSettingsMetadata {
  title?: string;
  description?: string;
  keywords?: string[];
}

export interface PageTemplate {
  id: string;
  name: string;
  availableFor?: string[];
  fieldDefinitions: FieldDefinition[];
}

export interface FieldDefinition {
  id: string;
  label?: string;
  description?: string;
  type: FieldType;
  validation?: {[validatorName: string]: any};
  fieldDefinitions?: FieldDefinition[];
  visibleBasedOnFieldValue?: VisibleBasedOnFieldValue;
}

export interface VisibleBasedOnFieldValue {
  fieldDefinitionId: string;
  value: string;
}

export interface FieldValue {
  id: string;
  value?: string;
  orderIndex?: number;
  fieldValues?: FieldValue[];
}

export enum FieldType {
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  RICH_TEXT = 'RICH_TEXT',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  PROPOSAL = 'PROPOSAL',
  PROPERTY = 'PROPERTY',
  PAGE = 'PAGE',
  LIST = 'LIST',
  SET = 'SET',
  GROUP = 'GROUP',
  PROFILE = 'PROFILE',
  OR = 'OR',
}
