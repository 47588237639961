import * as addMoreFieldsCategories from './addMoreFieldsCategories.json';
import * as addressFormats from './addressFormats.json';
import * as groupSubdivisions from './groupSubdivisions.json';
import * as metadata from './metadata.json';
import * as regionFormats from './regionFormats.json';
import * as routerLinks from './routerLinks.json';
import * as templates from './templates.json';

export const ADD_MORE_FIELDS_CATEGORIES = addMoreFieldsCategories;
export const ADDRESS_FORMATS = addressFormats;
export const GROUP_SUBDIVISIONS = groupSubdivisions;
export const METADATA = metadata;
export const REGION_FORMATS = regionFormats;
export const TEMPLATES = templates;
export const ROUTER_LINKS = routerLinks;

export const REGISTER_MINIMUM_AGE: number =
  METADATA.registrationRequirements.INDIVIDUAL.minimumAgeForRegistration;
