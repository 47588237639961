import {ModalSize} from './overlay.service';

export function modalSizeToPixels(size: ModalSize): number {
  switch (size) {
    case ModalSize.EXTRA_SMALL:
      return 300;
    case ModalSize.SMALL:
      return 490;
    case ModalSize.MEDIUM:
      return 780;
    case ModalSize.LARGE:
      return 900;
    default:
      return Infinity;
  }
}
