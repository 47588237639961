import {ModuleWithProviders, NgModule, Provider} from '@angular/core';

import {IconComponent} from './icon.component';
import {ICON_SERVICE_PROVIDER, ICONS_CONFIGURATION, IconService} from './icon.service';
import {IconConfig} from './icon-config';

@NgModule({
  imports: [IconComponent],
  exports: [IconComponent],
})
export class IconsModule {
  constructor(public iconService: IconService) {}

  static forRoot(config?: IconConfig): ModuleWithProviders<IconsModule> {
    return {
      ngModule: IconsModule,
      providers: provideIcons(config),
    };
  }
}

export function provideIcons(config?: IconConfig): Provider[] {
  return [
    {
      provide: ICONS_CONFIGURATION,
      useValue: config,
    },
    ICON_SERVICE_PROVIDER,
  ];
}
