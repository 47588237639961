import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  ValidatorFn,
} from '@angular/forms';
import {MutableProperty, Property, PropertyType} from '@aztrix/models';
import {
  ProfilePropertyRepresentation,
  PropertyRepresentation,
  SubPropertyRepresentation,
} from '@aztrix/sdk';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';

import {subTypeRepsForProperty} from '../metadata/property-type-provider';
import {dummyPropertyForType} from './property-functions';

export class PropertyFormControl {
  static createByType(
    type: PropertyType | ProfilePropertyRepresentation.TypeEnum,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    disabled = false
  ): FormGroup<ControlsOf<Property | ProfilePropertyRepresentation>> {
    const dummy = dummyPropertyForType(type);
    return PropertyFormControl.create(dummy || {}, validatorOrOpts, asyncValidator, disabled);
  }

  static create(
    property: Property | ProfilePropertyRepresentation,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    disabled = false
  ): FormGroup<ControlsOf<Property | ProfilePropertyRepresentation>> {
    const dummy = dummyPropertyForType(property.type);
    const fullProperty = <ProfilePropertyRepresentation>{...dummy, ...property};

    const allSubPropertyTypes = subTypeRepsForProperty(dummy).map((rep) => rep.name);

    if (allSubPropertyTypes.length) {
      const propertyGroup = new FormGroup<ControlsOf<ProfilePropertyRepresentation>>({
        id: new FormControl(fullProperty.id),
        type: new FormControl(fullProperty.type),
        visibility: new FormControl(fullProperty.visibility),
        accessStatus: new FormControl(fullProperty.accessStatus),
        verification: new FormControl(fullProperty.verification),
        properties: new FormArray<ProfilePropertyRepresentation>([]),
      });

      PropertyFormControl.createSubProperties(
        propertyGroup,
        property,
        validatorOrOpts,
        asyncValidator
      );

      return propertyGroup;
    } else {
      const propertyGroup = new FormGroup({
        id: new FormControl(fullProperty.id),
        type: new FormControl(fullProperty.type),
        value: new FormControl<string | undefined>(
          {value: undefined, disabled},
          validatorOrOpts,
          asyncValidator
        ),
        visibility: new FormControl(fullProperty.visibility),
        accessStatus: new FormControl(fullProperty.accessStatus),
        verification: new FormControl(fullProperty.verification),
      });

      propertyGroup.get('value').setValue(fullProperty.value);

      return propertyGroup;
    }
  }

  static createSubProperties(
    form: FormGroup<ControlsOf<ProfilePropertyRepresentation>>,
    property: Property | ProfilePropertyRepresentation,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ): void {
    if (!property) {
      return;
    }

    const dummy = dummyPropertyForType(property.type);
    const fullProperty = <ProfilePropertyRepresentation>{...dummy, ...property};
    const allSubPropertyTypes = subTypeRepsForProperty(dummy).map((rep) => rep.name);

    const subPropertiesForm = form.get('properties');

    if (!subPropertiesForm) {
      return;
    }

    for (const subPropertyType of allSubPropertyTypes) {
      let subProperty = {};
      if (fullProperty.properties) {
        const property = fullProperty.properties.find((p) => p.type === subPropertyType);
        if (property) {
          subProperty = property;
        }
      }
      subPropertiesForm.push(
        <FormGroup<ControlsOf<ProfilePropertyRepresentation>>>(
          PropertyFormControl.create(
            {...subProperty, type: <ProfilePropertyRepresentation.TypeEnum>subPropertyType},
            validatorOrOpts,
            asyncValidator
          )
        )
      );
    }
  }

  static update(
    form: FormGroup<ControlsOf<ProfilePropertyRepresentation>>,
    property?: Property | ProfilePropertyRepresentation,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    disabled = false
  ): void {
    if (!property) {
      return;
    }

    const dummy = dummyPropertyForType(property.type);
    const fullProperty = <ProfilePropertyRepresentation>{...dummy, ...property};
    const allSubPropertyTypes = subTypeRepsForProperty(dummy).map((rep) => rep.name);

    if (!!property?.properties !== !!form.value.properties) {
      form.removeControl('properties', {emitEvent: false});
      form.removeControl('value', {emitEvent: false});
      if (allSubPropertyTypes.length) {
        form.addControl('properties', new FormArray<ProfilePropertyRepresentation>([]), {
          emitEvent: false,
        });
      } else {
        form.addControl(
          'value',
          new FormControl({value: undefined, disabled}, validatorOrOpts, asyncValidator),
          {emitEvent: false}
        );
      }
    }

    if (allSubPropertyTypes.length) {
      const subPropertiesForm = form.get('properties');

      if (!subPropertiesForm) {
        return;
      }

      for (const subPropertyType of allSubPropertyTypes) {
        const subPropertyForm = subPropertiesForm.controls.find(
          (c) => c.get('type')?.value === subPropertyType
        );

        let subProperty: SubPropertyRepresentation = {};
        if (fullProperty.properties) {
          const property = fullProperty.properties.find((p) => p.type === subPropertyType);
          if (property) {
            subProperty = property;
          }
        }

        if (!subPropertyForm) {
          subPropertiesForm.push(
            <FormGroup<ControlsOf<ProfilePropertyRepresentation>>>(
              PropertyFormControl.create(
                {...subProperty, type: <ProfilePropertyRepresentation.TypeEnum>subPropertyType},
                validatorOrOpts,
                asyncValidator
              )
            ),
            {emitEvent: false}
          );
        }
      }

      const propertyTypesToRemove = Object.values(subPropertiesForm.controls)
        .map((c) => c.get('type')?.value)
        .filter((type) => {
          return !allSubPropertyTypes.find((spt) => spt === type);
        });

      for (const type of propertyTypesToRemove) {
        const indexToRemove = Object.values(subPropertiesForm.controls).findIndex(
          (c) => c.get('type')?.value === type
        );
        subPropertiesForm?.removeAt(indexToRemove, {emitEvent: false});
      }
    }

    PropertyFormControl.setValue(form, fullProperty);
  }

  static setValue(
    propertyFormControl: AbstractControl,
    property: Property | PropertyRepresentation,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ): void {
    if (!property) {
      return;
    }

    const fullProperty = <MutableProperty>{
      ...dummyPropertyForType(property.type),
      ...property,
    };

    const propertyControls = (<FormArray<ControlsOf<Property>>>(
      propertyFormControl.get('properties')
    ))?.controls;

    if (propertyControls?.length) {
      const newProperties = [];
      for (const propertyControl of propertyControls) {
        const type = propertyControl.get('type')?.value;
        const foundProperty = (fullProperty.properties || []).find((p) => p.type === type);
        if (foundProperty) {
          newProperties.push({
            ...dummyPropertyForType(foundProperty.type),
            ...foundProperty,
          });
        } else {
          newProperties.push(dummyPropertyForType(type));
        }
      }
      fullProperty.properties = <MutableProperty[]>newProperties.filter((p) => !!p);
    }

    propertyFormControl.patchValue(fullProperty, options);
  }

  static getSubPropertyControl(
    propertyControl: FormGroup<ControlsOf<Property>>,
    type: PropertyType
  ): FormGroup<ControlsOf<Property>> | undefined {
    const propertiesControl = propertyControl.get('properties');

    if (!propertiesControl?.controls?.length) {
      return undefined;
    }

    return <FormGroup<ControlsOf<Property>>>(
      propertiesControl.controls.find((propertyControl) => propertyControl.value?.type === type)
    );
  }
}
