/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface StaticAgreementRepresentation {
  id?: string;
  organisationId?: string;
  organisationName?: string;
  title?: string;
  description?: string;
  proposalType?: StaticAgreementRepresentation.ProposalTypeEnum;
  startTimestamp?: number;
  endTimestamp?: number;
  clientNumber?: string;
  note?: string;
}
export namespace StaticAgreementRepresentation {
  export type ProposalTypeEnum =
    | 'NONE'
    | 'NEWSLETTER'
    | 'REGISTRATION'
    | 'INVOICE'
    | 'CONTRACT'
    | 'REVIEW';
  export const ProposalTypeEnum = {
    NONE: 'NONE' as ProposalTypeEnum,
    NEWSLETTER: 'NEWSLETTER' as ProposalTypeEnum,
    REGISTRATION: 'REGISTRATION' as ProposalTypeEnum,
    INVOICE: 'INVOICE' as ProposalTypeEnum,
    CONTRACT: 'CONTRACT' as ProposalTypeEnum,
    REVIEW: 'REVIEW' as ProposalTypeEnum,
  };
}
