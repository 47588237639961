import {
  Context,
  DeflatedProfile,
  ForwardType,
  Profile,
  ProfileContext,
  ProfileStatus,
  ProfileType,
  ProfileVisibility,
  Property,
  PropertyType,
  Suggestion,
} from '@aztrix/models';
import {ContextRepresentation, ProfileRepresentation, PropertyRepresentation} from '@aztrix/sdk';

import {getLink} from '../metadata/links';
import {ROUTER_LINKS} from '../metadata/metadata';
import {isOfType, typeFilter} from '../util/filter.util';
import {addQueryParametersToLink} from '../util/http.util';

export function isProfile(profile: any): profile is ProfileRepresentation | Profile {
  return Object.keys(ProfileType).includes(profile?.type);
}

export function isDeflatedProfile(profile: any): profile is DeflatedProfile {
  return isProfile(profile) && 'name' in profile;
}

export function isSuggestion(data: any): data is Suggestion {
  return 'seen' in data && 'causes' in data;
}

export function isMeProfile(profile?: Profile | ProfileRepresentation | DeflatedProfile): boolean {
  return profile?.type === ProfileType.ME_PROFILE;
}

export function isContact(profile?: Profile | ProfileRepresentation | DeflatedProfile): boolean {
  return profile?.type === ProfileType.CONTACT;
}

export function isPublicProfile(
  profile?: Profile | ProfileRepresentation | DeflatedProfile
): boolean {
  return profile?.type === ProfileType.PUBLIC_PROFILE;
}

export function isOrganisation(profile?: Profile | ProfileRepresentation): boolean {
  return profile?.profileContext === ProfileContext.ORGANISATION;
}

export function isIndividual(profile?: Profile | ProfileRepresentation): boolean {
  return profile?.profileContext === ProfileContext.INDIVIDUAL;
}

export function isPublicWithinAztrix(profile?: Profile | ProfileRepresentation): boolean {
  if (!profile || !isMeProfile(profile)) {
    return false;
  }
  return profile.visibility === ProfileVisibility.WITHIN_AZTRIX;
}

export function displayNameByProperties(
  properties: readonly (Property | PropertyRepresentation)[],
  filter: (p: any) => boolean = () => true
): string {
  let types = [];
  if (
    properties
      .find((p) => p.type === PropertyType.FIRST_NAME || p.type === PropertyType.LAST_NAME)
      ?.value?.trim()
  ) {
    types = [PropertyType.FIRST_NAME, PropertyType.MIDDLE_NAME, PropertyType.LAST_NAME];
  } else if (properties.find((p) => p.type === PropertyType.ORGANISATION_NAME)?.value?.trim()) {
    types = [PropertyType.ORGANISATION_NAME];
  } else {
    types = [PropertyType.USERNAME];
  }
  const name = types
    .map((propertyType: PropertyType) => properties.find(typeFilter(propertyType)))
    .filter((property) => !!property)
    .filter(filter)
    .map((property) =>
      property?.type === PropertyType.USERNAME ? property?.value + '*' : property?.value
    )
    .join(' ');

  return name.replace(new RegExp('^\\s*'), '');
}

export function firstName(profile: Profile | ProfileRepresentation): string {
  const propertyType = isOrganisation(profile)
    ? PropertyType.ORGANISATION_NAME
    : PropertyType.FIRST_NAME;
  const context = profileContext(profile);
  const property = context?.properties?.find(typeFilter(propertyType));
  return property?.value ?? '';
}

export function avatarUrl(
  profile: Profile | DeflatedProfile | ProfileRepresentation | undefined
): string {
  const subject = isDeflatedProfile(profile) ? profile : profileContext(profile);
  const avatarProperty = (<PropertyRepresentation[]>subject?.properties)?.find(
    typeFilter(PropertyType.AVATAR)
  );
  return avatarProperty?.value ?? '';
}

export function profileUsername(
  profile: DeflatedProfile | ProfileRepresentation
): string | undefined {
  if (isDeflatedProfile(profile)) {
    return profile.properties.find((p) => p.type === PropertyType.USERNAME)?.value;
  } else {
    return profile?.propertyContexts
      ?.flatMap((context) => context.properties)
      ?.find(typeFilter(PropertyType.USERNAME))?.value;
  }
}

export function profileName(
  profile: Profile | DeflatedProfile | ProfileRepresentation
): string | undefined {
  if (isDeflatedProfile(profile)) {
    return profile.name;
  } else {
    if (isOrganisation(profile)) {
      return organisationName(<ProfileRepresentation>profile);
    }

    let profilename = '';
    if (profileFirstName(<ProfileRepresentation>profile)) {
      profilename += profileFirstName(<ProfileRepresentation>profile);
    }

    if (profileMiddleName(<ProfileRepresentation>profile)) {
      profilename += ` ${profileMiddleName(<ProfileRepresentation>profile)}`;
    }

    if (profileLastName(<ProfileRepresentation>profile)) {
      profilename += ` ${profileLastName(<ProfileRepresentation>profile)}`;
    }

    return profilename;
  }
}

export function profileFirstName(profile: ProfileRepresentation): string | undefined {
  return profile?.propertyContexts
    ?.flatMap((context) => context.properties)
    ?.find(typeFilter(PropertyRepresentation.TypeEnum.FIRST_NAME))?.value;
}

export function profileMiddleName(profile: ProfileRepresentation): string | undefined {
  return profile?.propertyContexts
    ?.flatMap((context) => context.properties)
    ?.find(typeFilter(PropertyRepresentation.TypeEnum.MIDDLE_NAME))?.value;
}

export function profileLastName(profile: ProfileRepresentation): string | undefined {
  return profile?.propertyContexts
    ?.flatMap((context) => context.properties)
    ?.find(typeFilter(PropertyRepresentation.TypeEnum.LAST_NAME))?.value;
}

export function organisationName(profile: Profile | ProfileRepresentation): string | undefined {
  return (<ProfileRepresentation>profile)?.propertyContexts
    ?.flatMap((context: ContextRepresentation) => context.properties)
    ?.find(typeFilter(PropertyRepresentation.TypeEnum.ORGANISATION_NAME))?.value;
}

export function profileUsernameUrl(
  baseUrl: string,
  profile: Profile | ProfileRepresentation
): string {
  return `${baseUrl}/${profileUsername(<ProfileRepresentation>profile) || ''}*`;
}

export function getContexts(
  profile: Profile | ProfileRepresentation,
  filter: (context: Context | ContextRepresentation) => boolean = () => true
): (Context | ContextRepresentation)[] {
  return [...(<ContextRepresentation[]>(profile?.propertyContexts || []))].filter(filter);
}

export function getContext(
  profile: Profile | ProfileRepresentation,
  filter: (context: Context | ContextRepresentation) => boolean
): Context | ContextRepresentation | undefined {
  return profile?.propertyContexts?.find(filter);
}

export function getGroupIds(profile: Profile | ProfileRepresentation): string[] {
  const context = getContext(profile, (c) => isOfType(c, 'GROUP'));
  return <string[]>(
    (context?.properties?.map((group) => group.value)?.filter((value) => !!value) || [])
  );
}

export function isStatic(contact: Profile | ProfileRepresentation | DeflatedProfile): boolean {
  if (!isContact(contact)) {
    return false;
  }
  return contact.status === ProfileStatus.STATIC;
}

export function isDynamic(contact: Profile | ProfileRepresentation): boolean {
  if (!isContact(contact)) {
    return false;
  }
  return contact.status === ProfileStatus.DYNAMIC;
}

export function canBeInvited(contact: Profile | ProfileRepresentation): boolean {
  if (!isContact(contact) || isOrganisation(contact)) {
    return false;
  }
  return !contact.invited && isStatic(contact);
}

export function canBeDisconnectedWith(contact: Profile | ProfileRepresentation): boolean {
  return isContact(contact) && isDynamic(contact);
}

export function profileCanBeDeleted(profile: Profile | ProfileRepresentation): boolean {
  return isContact(profile) && isStatic(profile);
}

export function canViewProposals(profile: Profile | ProfileRepresentation): boolean {
  return isOrganisation(profile) && !isStatic(profile);
}

export function canViewAgreements(profile: Profile | ProfileRepresentation): boolean {
  return isMeProfile(profile) || (isOrganisation(profile) && !isStatic(profile));
}

export function profileContext(
  profile: Profile | ProfileRepresentation | undefined
): ContextRepresentation | undefined {
  return (<ContextRepresentation[]>profile?.propertyContexts)?.find(
    typeFilter(ContextRepresentation.TypeEnum.PROFILE)
  );
}

export function profileWithIdLink(id?: string, forwardCode?: string): string {
  const link = getLink(ROUTER_LINKS.profile, {id});
  return forwardCode ? addQueryParametersToLink(link, {forwardCode}) : link;
}

export function profileWithUsernameLink(username: string): string {
  return getLink(ROUTER_LINKS.profile_username, {username});
}

export function profileLink(profile: Profile | ProfileRepresentation | DeflatedProfile): string {
  if (!profile) {
    return '';
  }
  if (isStatic(profile)) {
    return getLink(ROUTER_LINKS.contact, {id: profile.id});
  }
  const username = profileUsername(<ProfileRepresentation>profile);
  if (username) {
    return profileWithUsernameLink(username);
  }
  return profileWithIdLink(profile.targetId || profile.id);
}

export function profileForwardLink(
  profile: Profile | ProfileRepresentation,
  baseUrl: string,
  forwardOption?: ForwardType,
  completeLink = true
): string {
  const link = completeLink
    ? isMeProfile(profile)
      ? `${baseUrl || ''}/share/${profile.forwardCode}`
      : `${baseUrl || ''}${profileLink(profile)}`
    : isDynamic(profile) || isMeProfile(profile) || isPublicProfile(profile)
      ? getLink(ROUTER_LINKS.profile_forward, {id: profile.id})
      : getLink(ROUTER_LINKS.contact_forward, {id: profile.id});
  return forwardOption ? addQueryParametersToLink(link, {forwardType: forwardOption}) : link;
}
