import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';

import {isPublic} from '../../helpers/subject-functions';

@Pipe({name: 'propertyIsPublic'})
export class PropertyIsPublicPipe implements PipeTransform {
  transform(property: Property | PropertyRepresentation | undefined) {
    if (!property) {
      return false;
    }
    return isPublic(property);
  }
}
