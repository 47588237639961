import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {aTargetByPropertyType} from '../../util/communication.util';

@Pipe({name: 'propertyCommunicationTarget', pure: false})
export class PropertyCommunicationTargetPipe implements PipeTransform {
  transform(value: Property | undefined): string {
    return aTargetByPropertyType(value);
  }
}
