import {Context, Profile, PropertyType} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';

import {possiblePropertyTypesForContext} from '../helpers/profile-enriching';
import {ADD_MORE_FIELDS_CATEGORIES} from './metadata';

const {MESSAGING, SOCIAL} = ADD_MORE_FIELDS_CATEGORIES;

export interface AddMoreFieldsRepresentation {
  general: PropertyType[];
  social: PropertyType[];
  messaging: PropertyType[];
}

export class AddMoreFieldsFilter {
  static empty(rep: AddMoreFieldsRepresentation): boolean {
    if (rep.general.length > 0) {
      return false;
    }
    if (rep.social.length > 0) {
      return false;
    }
    if (rep.messaging.length > 0) {
      return false;
    }
    return true;
  }
}

export class AddMoreFields {
  public static canAddFields(
    context: Context,
    profile: Profile,
    filter: (propertyType: PropertyType) => boolean = () => true
  ): boolean {
    return !AddMoreFieldsFilter.empty(
      AddMoreFields.getTypesToAddCategories(context, profile, filter)
    );
  }

  public static getTypesToAddCategories(
    context: Context,
    profile: Profile,
    filter: (propertyType: PropertyType) => boolean = () => true
  ): AddMoreFieldsRepresentation {
    return AddMoreFields.addMoreFieldsForContext(context, profile, filter);
  }

  public static addMoreFieldsForContext(
    context: Context,
    profile: Profile | ProfileRepresentation,
    filter: (propertyType: PropertyType) => boolean = () => true
  ): AddMoreFieldsRepresentation {
    return AddMoreFields.createRepresentation(
      possiblePropertyTypesForContext(profile, context),
      filter
    );
  }

  private static createRepresentation(
    types: PropertyType[],
    filter: (propertyType: PropertyType) => boolean = () => true
  ): AddMoreFieldsRepresentation {
    types = types.filter(filter);

    return <AddMoreFieldsRepresentation>{
      general: types
        .filter((type: PropertyType) => !SOCIAL.includes(type))
        .filter((type: PropertyType) => !MESSAGING.includes(type)),
      social: types.filter((type: PropertyType) => SOCIAL.includes(type)),
      messaging: types.filter((type: PropertyType) => MESSAGING.includes(type)),
    };
  }
}
