import {Pipe, PipeTransform} from '@angular/core';

import {isProfile} from '../../helpers/profile-functions';

@Pipe({
  name: 'isProfile',
})
export class IsProfilePipe implements PipeTransform {
  transform(value: unknown): boolean {
    return isProfile(value);
  }
}
