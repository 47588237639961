import {HttpErrorResponse} from '@angular/common/http';
import {ErrorType} from '@aztrix/models';

export function isUnauthorizedError(error: any): boolean {
  if (!(error instanceof HttpErrorResponse)) {
    return false;
  }
  return error.status === 401; //&& error.error === ErrorType.INVALID_AUTHTOKEN;
}

export function isInvalidPasssword(error: unknown): boolean {
  if (error instanceof HttpErrorResponse) {
    return error.error === ErrorType.LOGIN_INVALID_PASSWORD;
  }
  return false;
}

export function isForbiddenError(error: any): boolean {
  if (!(error instanceof HttpErrorResponse)) {
    return false;
  }
  return error.status === 403;
}

export function isNotFoundError(error: any): boolean {
  if (!(error instanceof HttpErrorResponse)) {
    return false;
  }
  return error.status === 404;
}

export function isServerUnavailableError(error: HttpErrorResponse): boolean {
  if (!(error instanceof HttpErrorResponse)) {
    return false;
  }
  return [503, 504].includes(error.status);
}

export function addPathParametersToLink(link: string, ...params: string[]): string {
  for (const param of params) {
    link = link.endsWith('/') ? `${link}${param}` : `${link}/${param}`;
  }
  return link;
}

export function addQueryParametersToLink(
  link: string,
  params: {[key: string]: string | undefined} | undefined
): string {
  if (!params) {
    return link;
  }
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      continue;
    }

    const linkHasQueryParams = link.includes('?');
    if (!linkHasQueryParams) {
      link = `${link}?${key}=${value}`;
    } else {
      link = `${link}&${key}=${value}`;
    }
  }
  return link;
}

export function sessionHasHistory(): boolean {
  return history?.length > 2;
}
