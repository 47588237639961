import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'languageCodeLabel',
  pure: false,
})
export class LanguageCodeLabelPipe implements PipeTransform {
  constructor(
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  transform(languageCode: string | undefined): string {
    return new TranslatePipe(
      this._translate,
      this._changeDetector,
      this._languageOverride$
    ).transform(`languageCode.${(languageCode || 'nl').toLowerCase()}.label`);
  }
}
