import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';
import {ReplaySubject} from 'rxjs';

import {
  OverlayOutletRootComponent,
  OverlayOutletShadowDomRootComponent,
} from './overlay-outlet/overlay-outlet.component';

@Component({
  selector: 'ax-overlay-root-container',
  template: `<ax-overlay-outlet-root
      [primaryColor]="primaryColor"
      [accentColor]="accentColor"
      [warnColor]="warnColor"
      [fontColor]="fontColor"
      [fontFamily]="fontFamily"
      [backgroundColor]="backgroundColor"
    ></ax-overlay-outlet-root>
    <ax-overlay-outlet-shadow-dom-root
      [primaryColor]="primaryColor"
      [accentColor]="accentColor"
      [warnColor]="warnColor"
      [fontColor]="fontColor"
      [fontFamily]="fontFamily"
      [backgroundColor]="backgroundColor"
    ></ax-overlay-outlet-shadow-dom-root>`,
  styleUrls: [`./overlay-container.scss`],
  standalone: true,
  imports: [OverlayOutletRootComponent, OverlayOutletShadowDomRootComponent],
})
export class OverlayRootContainerComponent implements AfterViewInit {
  @Input() primaryColor: string;
  @Input() accentColor: string;
  @Input() warnColor: string;
  @Input() fontColor: string;
  @Input() fontFamily: string;
  @Input() backgroundColor: string;

  afterViewInit$ = new ReplaySubject(1);

  constructor(public elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.afterViewInit$.next(true);
  }
}
