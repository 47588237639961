import {Property, PropertyType} from '@aztrix/models';
import {ProfilePropertyRepresentation, PropertyRepresentation} from '@aztrix/sdk';

import {METADATA} from './metadata';

export interface PropertyTypeRepresentation {
  name: PropertyType;
  verifiable: boolean;
  regex?: string;
  subTypes: PropertySubTypeRepresentation[];
  subTypesPerCountryCode?: {[index: string]: PropertySubTypeRepresentation[]};
  prefixBasedRegex?: Map<string, string>;
}

export interface PropertySubTypeRepresentation {
  name: PropertyType | ProfilePropertyRepresentation.TypeEnum;
  required: boolean;
  dummy: boolean;
}

export const PROPERTY_TYPE_REPS: Map<PropertyType, PropertyTypeRepresentation> = (<
  PropertyTypeRepresentation[]
>(<unknown>METADATA.propertyTypes)).reduce(
  (
    propertyTypeMap: Map<PropertyType, PropertyTypeRepresentation>,
    propertyTypeRep: PropertyTypeRepresentation
  ) => propertyTypeMap.set(propertyTypeRep.name, propertyTypeRep),
  new Map<PropertyType, PropertyTypeRepresentation>()
);

export const PROPERTY_TYPES: PropertyType[] = Array.from(PROPERTY_TYPE_REPS.values()).map(
  (rep: PropertyTypeRepresentation) => rep.name
);

export function subTypeRepsForProperty(
  property?: Property | PropertyRepresentation
): PropertySubTypeRepresentation[] {
  if (!property || !property.type) {
    return [];
  }

  const propertyTypeRep = PROPERTY_TYPE_REPS.get(<PropertyType>property.type);

  if (!propertyTypeRep) {
    return [];
  }

  if (propertyTypeRep.subTypesPerCountryCode) {
    const country = (<Property[]>property.properties || []).find(
      (prop) => prop.type === PropertyType.COUNTRY
    );
    return (
      propertyTypeRep.subTypesPerCountryCode[country?.value || ''] ||
      propertyTypeRep.subTypesPerCountryCode['']
    );
  } else {
    return propertyTypeRep.subTypes || [];
  }
}

export function subPropertyTypes(
  property: Property | PropertyRepresentation
): PropertyType[] | PropertyRepresentation.TypeEnum[] {
  return <PropertyType[]>subTypeRepsForProperty(property).map((typeRep) => typeRep.name);
}

export function requiredSubPropertyTypes(
  property: Property | PropertyRepresentation
): PropertyType[] | PropertyRepresentation.TypeEnum[] {
  return <PropertyType[]>subTypeRepsForProperty(property)
    .filter((typeRep) => typeRep.required)
    .map((typeRep) => typeRep.name);
}
