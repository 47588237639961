import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, first, switchMap} from 'rxjs';

import {EnvironmentService} from './environment.service';
import {UrlTransformerService} from './url-transformer.service';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(
    private _transformer: UrlTransformerService,
    private _environment: EnvironmentService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._environment.ready$.pipe(
      first(),
      switchMap(() => {
        return next.handle(req.clone({url: this._transformer.transformUrl(req.url)}));
      })
    );
  }
}
