export interface Webhook {
  id: string;
  proposalId: string;
  url: string;
  secret: string;
  types: WebhookType[];
}

export enum WebhookType {
  NONE = 'NONE',
  AGREEMENT_UPDATED_BY_OWNER = 'AGREEMENT_UPDATED_BY_OWNER',
  AGREEMENT_UPDATED_BY_SUBSCRIBER = 'AGREEMENT_UPDATED_BY_SUBSCRIBER',
  AGREEMENT_NON_CONFIRMED_CREATED = 'AGREEMENT_NON_CONFIRMED_CREATED',
  AGREEMENT_SUBSCRIBED = 'AGREEMENT_SUBSCRIBED',
  AGREEMENT_UNSUBSCRIBED = 'AGREEMENT_UNSUBSCRIBED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
