import {Context, Profile, Property} from '@aztrix/models';
import {ProfileRepresentation, PropertyRepresentation} from '@aztrix/sdk';

import {isMeProfile} from '../helpers/profile-functions';
import {
  isRequested,
  isVerified,
  propertyIsDynamic,
  verificationIsRequested,
} from '../helpers/property-functions';
import {verifiablePropertyTypes} from '../metadata/context-type-provider';

export function verificationColorForProperty(
  property: Property | PropertyRepresentation,
  context: Context,
  profile: Profile
): string | undefined {
  if (isVerified(property)) {
    return 'green';
  }
  if (verificationIsRequested(property) && isMeProfile(<ProfileRepresentation>profile)) {
    return 'orange';
  }
  if (
    profile.profileContext &&
    verifiablePropertyTypes(profile.profileContext, context.type).includes(<any>property.type)
  ) {
    return 'red';
  }
  return undefined;
}

export function colorForProperty(property: Property): string {
  if (!property) {
    return '';
  }
  if (propertyIsDynamic(property)) {
    return 'green';
  }
  if (isRequested(property)) {
    return 'orange';
  }
  return 'grey-light1';
}

export function mapColor(color: string | undefined) {
  switch (color) {
    case 'primary':
    case 'primary-dark':
    case 'primary-inverse':
    case 'accent':
    case 'white-to-green':
    case 'white-orange':
    case 'warning':
    case 'danger':
    case 'white':
    case 'light':
    case 'transparent':
      return color;
    case 'ios':
      return 'social ios';
    case 'android':
      return 'social android';
    case 'facebook':
      return 'social facebook';
    case 'twitter':
      return 'social twitter';
    case 'yahoo':
      return 'social yahoo';
    case 'microsoft':
      return 'social microsoft';
    case 'google':
      return 'social google';
    case 'linkedin':
      return 'social linkedin';
    case 'whatsapp':
      return 'social whatsapp';
    case 'instagram':
      return 'social instagram';
    case 'vcard':
      return 'social vcard';
    default:
      return '';
  }
}

export function isColor(string: string | null | undefined): boolean {
  return !!string && (string[0] === '#' || string.includes('rgb') || string.includes('rgba'));
}
