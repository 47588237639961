import {Pipe, PipeTransform} from '@angular/core';
import {CommunicationType} from '@aztrix/models';

import {aTarget} from '../../util/communication.util';

@Pipe({name: 'communicationTarget'})
export class CommunicationTargetPipe implements PipeTransform {
  transform(type: CommunicationType): string {
    return aTarget(type);
  }
}
