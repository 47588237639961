import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Locale} from '@aztrix/models';
import {FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  isWithinInterval,
  parse,
  setDate,
  setISODay,
  setMonth,
  setYear,
  toDate,
} from 'date-fns';

import {toDateFnsLocale} from '../helpers/date-fns-date-adapter';
import {DAYS_OF_WEEK, OpeningDay} from '../helpers/opening-hours-functions';

export function createRangesForm(locale: Locale, count = 1): FormArray<any> {
  const ranges = [];
  for (let index = 0; index < count; index++) {
    ranges.push(createRangeForm());
  }
  return new FormArray(ranges, TimeValidators.validTimeRanges(locale));
}

export function createRangeForm(): FormGroup<any> {
  const from = new FormControl(new Date(1970, 0, 1, 9), [
    TimeValidators.valid,
    TimeValidators.required,
  ]);
  const to = new FormControl(new Date(1970, 0, 1, 17), [
    TimeValidators.valid,
    TimeValidators.required,
  ]);
  return new FormGroup({from, to}, TimeValidators.fromAndToShouldBeValid);
}

export class TimeValidators {
  static valid(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    return isValid(control.value) ? null : {timeInvalid: control.value};
  }

  static required(control: AbstractControl): ValidationErrors | null {
    const openControl = control?.parent?.parent?.parent?.get('open');
    if (!openControl) {
      return null;
    }

    return control.value || !openControl.value ? null : {timeRequired: control.value};
  }

  static fromAndToShouldBeValid(group: AbstractControl): ValidationErrors | null {
    const controlFromValid = isValid(group?.get('from')?.value);
    const controlTovalid = isValid(group?.get('to')?.value);

    if ((!controlFromValid && !controlTovalid) || (controlFromValid && controlTovalid)) {
      return null;
    }

    return {timeRangeInvalid: {}};
  }

  static validTimeRanges(locale: Locale): ValidatorFn {
    const validTimeRangesFn = (c: AbstractControl): ValidationErrors | null =>
      TimeValidators._validTimeRanges(c.value, c.value, locale);
    return validTimeRangesFn;
  }

  static validTimeRangesInBetweenDates(locale: Locale): ValidatorFn {
    const validTimeRangesInBetweenDatesFn = (c: AbstractControl): ValidationErrors | null => {
      const days: OpeningDay[] = c.value;
      for (const day of days) {
        for (const compareDay of days) {
          if (day.day !== compareDay.day) {
            const result = TimeValidators._validTimeRanges(
              day.ranges,
              compareDay.ranges,
              locale,
              day.day,
              compareDay.day
            );
            if (result && day.open && compareDay.open) {
              const now = new Date();
              const d1 = parse(day.day, 'EEEEEE', now);
              const d2 = parse(compareDay.day, 'EEEEEE', now);
              return {
                dayTimeRangeOverlap: {
                  day1: format(d1, 'EEEE', {
                    locale: toDateFnsLocale(locale),
                  }),
                  day2: format(d2, 'EEEE', {
                    locale: toDateFnsLocale(locale),
                  }),
                },
              };
            }
          }
        }
      }
      return null;
    };
    return validTimeRangesInBetweenDatesFn;
  }

  private static _validTimeRanges(
    ranges1: {from: Date | null; to: Date | null}[],
    ranges2: {from: Date | null; to: Date | null}[],
    locale: Locale,
    dayOfWeek1?: string,
    dayOfWeek2?: string
  ): ValidationErrors | null {
    for (const range of ranges1) {
      const foundBoundingRange = ranges2.find((r) =>
        TimeValidators._overlaps(range, r, dayOfWeek1, dayOfWeek2)
      );

      if (foundBoundingRange) {
        return {
          timeRangeOverlap: {
            range1From: TimeValidators._formatTime(range.from, locale),
            range1To: TimeValidators._formatTime(range.to, locale),
            range2From: TimeValidators._formatTime(foundBoundingRange.from, locale),
            range2To: TimeValidators._formatTime(foundBoundingRange.to, locale),
          },
        };
      }
    }
    return null;
  }

  private static _overlaps(
    range1: {from: Date | null; to: Date | null},
    range2: {from: Date | null; to: Date | null},
    dayOfWeek1?: string,
    dayOfWeek2?: string
  ) {
    const now = new Date();
    const {from: from1, to: to1} = TimeValidators._adjustRangeToCorrectDate(
      range1,
      now,
      dayOfWeek1
    );
    const {from: from2, to: to2} = TimeValidators._adjustRangeToCorrectDate(
      range2,
      now,
      dayOfWeek2
    );

    return (
      range1 !== range2 &&
      from1 &&
      to1 &&
      from2 &&
      to2 &&
      isValid(from1) &&
      isValid(to1) &&
      isValid(from2) &&
      isValid(to2) &&
      isBefore(from2, to2) &&
      (isWithinInterval(from1, {start: from2, end: to2}) ||
        isWithinInterval(to1, {start: from2, end: to2}))
    );
  }

  private static _timeAfter(value: Date | null, after: Date | null): boolean {
    const now = toDate(new Date());
    const currentTime = TimeValidators._overwriteDate(value, now);
    const afterTime = TimeValidators._overwriteDate(after, now);
    return (
      isValid(currentTime) && isAfter(currentTime, afterTime) && !isEqual(currentTime, afterTime)
    );
  }

  private static _adjustRangeToCorrectDate(
    range: {from: Date | null; to: Date | null},
    overwrite: Date,
    dayOfWeek?: string
  ): {from?: Date | null; to?: Date | null} {
    if (!range) {
      return {};
    }

    if (!isValid(range.from) || !isValid(range.to)) {
      return range;
    }

    let rangeFrom = range.from;
    let rangeTo = range.to;
    if (dayOfWeek) {
      const isoDay = TimeValidators._getIsoDay(dayOfWeek);
      const overwriteFrom = setISODay(overwrite, isoDay);
      rangeFrom = TimeValidators._overwriteDate(range.from, overwriteFrom);

      let overwriteTo = setISODay(overwrite, isoDay);
      if (TimeValidators._timeAfter(range.from, range.to)) {
        overwriteTo = addDays(overwriteTo, 1);
      }
      rangeTo = TimeValidators._overwriteDate(range.to, overwriteTo);
    }

    return {
      from: rangeFrom,
      to: rangeTo,
    };
  }

  private static _overwriteDate(date: Date | null, overwrite: Date): Date {
    if (!date || !isValid(date)) {
      return overwrite;
    }
    date = setYear(date, overwrite.getFullYear());
    date = setMonth(date, overwrite.getMonth());
    date = setDate(date, overwrite.getDate());
    return date;
  }

  private static _formatTime(value: Date | null, locale: Locale): string {
    if (!value || !isValid(value)) {
      return '';
    }
    return format(value, 'p', {locale: toDateFnsLocale(locale)});
  }

  private static _getIsoDay(dayOfWeek: string) {
    return DAYS_OF_WEEK.findIndex((d) => d === dayOfWeek) + 1;
  }
}
