import {ContextType, ProfileContext, ProfileType} from '@aztrix/models';
import {
  ContextRepresentation,
  ContextTypeRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  PropertyTypeInfo,
} from '@aztrix/sdk';

import {METADATA} from './metadata';

const {propertyContextTypes} = METADATA;

export function contextTypeReps(
  profileType?: ProfileRepresentation.TypeEnum,
  profileContext?: ProfileRepresentation.ProfileContextEnum
): ContextTypeRepresentation[] {
  if (!profileType || !profileContext) {
    return [];
  }
  return <ContextTypeRepresentation[]>(
    propertyContextTypes[
      profileTypeMapper(<ProfileRepresentation.TypeEnum>profileType.toUpperCase())
    ][profileContext]
  );
}

export function contextTypeRep(
  profileType?: ProfileRepresentation.TypeEnum,
  profileContext?: ProfileRepresentation.ProfileContextEnum,
  contextType?: ContextTypeRepresentation.NameEnum | ContextRepresentation.TypeEnum
): ContextTypeRepresentation | undefined {
  if (!contextType) {
    return undefined;
  }

  return contextTypeReps(profileType, profileContext).find(
    (rep: ContextTypeRepresentation) => rep.name === contextType.toUpperCase()
  );
}

export function propertyTypeRepsForContext(
  profileType?: ProfileRepresentation.TypeEnum | ProfileType,
  profileContext?: ProfileRepresentation.ProfileContextEnum | ProfileContext,
  contextType?: ContextTypeRepresentation.NameEnum | ContextType
): PropertyTypeInfo[] {
  const rep = contextTypeRep(profileType, profileContext, contextType);
  return rep?.propertyTypes || [];
}

export function propertyTypesForContextType(
  profileType: ProfileRepresentation.TypeEnum,
  profileContext: ProfileRepresentation.ProfileContextEnum,
  contextType: ContextTypeRepresentation.NameEnum
): PropertyRepresentation.TypeEnum[] {
  const rep = contextTypeRep(profileType, profileContext, contextType);
  return <PropertyRepresentation.TypeEnum[]>(
    (rep?.propertyTypes || []).map((typeRep) => typeRep.name).filter((name) => !!name)
  );
}

export function contextTypesForPropertyType(
  profileType: ProfileRepresentation.TypeEnum,
  profileContext: ProfileRepresentation.ProfileContextEnum,
  propertyType: PropertyTypeInfo.NameEnum
): ContextTypeRepresentation.NameEnum[] {
  return <ContextTypeRepresentation.NameEnum[]>contextTypeReps(profileType, profileContext)
    .filter((rep) =>
      (rep.propertyTypes || []).map((propType) => propType.name).includes(propertyType)
    )
    .map((c) => c.name)
    .filter((name) => !!name);
}

export function requiredContextTypes(
  profileType?: ProfileRepresentation.TypeEnum,
  profileContext?: ProfileRepresentation.ProfileContextEnum | undefined
): ContextTypeRepresentation.NameEnum[] {
  return <ContextTypeRepresentation.NameEnum[]>contextTypeReps(profileType, profileContext)
    .filter((typeRep) => typeRep.required)
    .map((typeRep) => typeRep.name)
    .filter((name) => !!name);
}

export function requiredPropertyTypes(
  profileType?: ProfileRepresentation.TypeEnum,
  profileContext?: ProfileRepresentation.ProfileContextEnum,
  contextType?: ContextTypeRepresentation.NameEnum | ContextRepresentation.TypeEnum
): PropertyRepresentation.TypeEnum[] {
  const rep = contextTypeRep(profileType, profileContext, contextType);
  return <PropertyRepresentation.TypeEnum[]>(rep?.propertyTypes || [])
    .filter((typeRep) => typeRep.required)
    .map((typeRep) => typeRep.name)
    .filter((name) => !!name);
}

export function verifiablePropertyTypes(
  profileContext?: ProfileRepresentation.ProfileContextEnum,
  contextType?: ContextTypeRepresentation.NameEnum | ContextRepresentation.TypeEnum
): PropertyRepresentation.TypeEnum[] {
  const rep = contextTypeRep(
    ProfileRepresentation.TypeEnum.PUBLIC_PROFILE,
    profileContext,
    contextType
  );
  return <PropertyRepresentation.TypeEnum[]>(rep?.propertyTypes || [])
    .filter((typeRep) => typeRep.verification)
    .map((typeRep) => typeRep.name)
    .filter((name) => !!name);
}

function profileTypeMapper(profileType: ProfileRepresentation.TypeEnum) {
  switch (profileType) {
    case ProfileRepresentation.TypeEnum.ME_PROFILE:
    case ProfileRepresentation.TypeEnum.PUBLIC_PROFILE:
      return 'PROFILE';
    default:
      return 'CONTACT';
  }
}
