import {Inject, Injectable, Optional, ɵunwrapSafeValue} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Capacitor} from '@capacitor/core';
import {BehaviorSubject} from 'rxjs';

import {BASE_URL} from '../base_url';
import {ASSETS_URL, BACKEND_URL} from '../urls';

@Injectable({providedIn: 'root'})
export class UrlTransformerService {
  constructor(
    @Inject(BASE_URL) private _baseUrl: string,
    @Optional() @Inject(BACKEND_URL) private _backendUrl: BehaviorSubject<string>,
    @Optional() @Inject(ASSETS_URL) private _assetsUrl: BehaviorSubject<string>,
    private _sanitizer: DomSanitizer
  ) {}

  transformUrl(url: string): string;
  transformUrl(url: SafeUrl): SafeUrl;
  transformUrl(url: string | SafeUrl): string | SafeUrl {
    if (typeof url === 'string') {
      return this._transformUrl(url);
    } else {
      return this._transformSafeUrl(url);
    }
  }

  private _transformUrl(url: string): string {
    // to avoid double slash e.g. `aztrix.me//r/pages`
    if (url.indexOf('/') === 0) {
      url = url.substring(1);
    }

    if (url.startsWith('https://') || url.startsWith('http://')) {
      return url;
    } else if (
      this._startsWithOneOfPartialUrls(url, ['assets/']) &&
      Capacitor?.isNativePlatform()
    ) {
      return Capacitor.convertFileSrc(url);
    } else if (this._startsWithOneOfPartialUrls(url, ['assets/']) && this._assetsUrl?.value) {
      return this._trailingSlash(this._assetsUrl.value) + url;
    } else if (this._startsWithOneOfPartialUrls(url, ['r/', 'staticFiles/'])) {
      return this._trailingSlash(this._backendUrl?.value || this._baseUrl || '') + url;
    } else {
      return this._trailingSlash(this._baseUrl || '') + url;
    }
  }

  private _trailingSlash(url: string) {
    if (url.lastIndexOf('/') === url.length - 1) {
      return url;
    }
    return url + '/';
  }

  private _transformSafeUrl(url: SafeUrl): SafeUrl {
    const unwrappedUrl = ɵunwrapSafeValue(url);
    const transformedUrl = this._transformUrl(unwrappedUrl);
    return this._sanitizer.bypassSecurityTrustUrl(transformedUrl);
  }

  private _startsWithOneOfPartialUrls(url: string, urls: string[]): boolean {
    for (const partial of urls) {
      if (this._urlStartsWith(url, partial)) {
        return true;
      }
    }
    return false;
  }

  private _urlStartsWith(url: string, partial: string): boolean {
    const index = url.indexOf(partial);
    return index === 0 || index === 1;
  }
}
