import {FormGroup, UntypedFormGroup} from '@angular/forms';
import {Property, PropertyType, StripeAddress, StripeTax, TaxType} from '@aztrix/models';
import {StripeCardRepresentation, StripeCustomerInfoRepresentation} from '@aztrix/sdk';

import {PropertyFormControl} from './property-form-control';
import {hasValue, namePropertyValue} from './property-functions';

export function parsePropertiesFromStripeCustomerInfo(
  stripeCustomerInfo: StripeCustomerInfoRepresentation
): Property[] {
  let properties = [];
  const customerType = stripeCustomerInfo.customerType;
  const emailProperty = <Property>{
    id: undefined,
    type: PropertyType.EMAIL,
    value: stripeCustomerInfo.email,
  };

  const name = parseNameFromStripe(
    <string>stripeCustomerInfo.name,
    <StripeCustomerInfoRepresentation.CustomerTypeEnum>customerType
  );

  if (
    customerType === undefined ||
    customerType === StripeCustomerInfoRepresentation.CustomerTypeEnum.INDIVIDUAL
  ) {
    properties = [
      emailProperty,
      name,
      parseAddressFromStripe(<StripeAddress>stripeCustomerInfo.address),
    ];
  } else {
    properties = [
      emailProperty,
      name,
      parseLegalIdFromStripe(<StripeTax>stripeCustomerInfo.taxId),
      parseAddressFromStripe(<StripeAddress>stripeCustomerInfo.address),
    ];
  }

  return <Property[]>properties.filter((p) => !!p);
}

export function parseStripeCustomerInfoFromBillingForm(
  form: FormGroup | UntypedFormGroup
): StripeCustomerInfoRepresentation {
  const customerType =
    form.get('customerType')?.value ?? StripeCustomerInfoRepresentation.CustomerTypeEnum.INDIVIDUAL;
  const name = form.get(PropertyType.LEGAL_NAME)?.value?.value
    ? form.get(PropertyType.LEGAL_NAME)?.value?.value
    : parseNameForStripe(form.get(PropertyType.NAME)?.value);

  if (customerType === StripeCustomerInfoRepresentation.CustomerTypeEnum.INDIVIDUAL) {
    return {
      customerType,
      name,
      address: parseAddressForStripe(form.get(PropertyType.ADDRESS)?.value),
      email: form.get(PropertyType.EMAIL)?.value.value,
    };
  }

  return {
    customerType,
    taxId: hasValue(form?.get(PropertyType.LEGAL_ID)?.value)
      ? parseLegalIdForStripe(form?.get(PropertyType.LEGAL_ID)?.value)
      : undefined,
    name,
    address: parseAddressForStripe(form?.get(PropertyType.ADDRESS)?.value),
    email: form?.get(PropertyType.EMAIL)?.value.value,
  };
}

export function updateBillingForm(properties: Property[], form: FormGroup | UntypedFormGroup) {
  for (const property of properties) {
    const control = form?.get(<PropertyType>property.type);
    if (property.type === PropertyType.NAME) {
      control?.patchValue(property);
    } else if (control) {
      PropertyFormControl.setValue(control, property);
    }
  }
}

export function parseAddressFromStripe(stripeAddress: StripeAddress): Property | undefined {
  if (!stripeAddress) {
    return undefined;
  }

  const address = {
    type: PropertyType.ADDRESS,
    properties: <Property[]>[],
  };

  if (stripeAddress.line1) {
    address.properties.push(
      {
        id: undefined,
        type: PropertyType.STREET,
        value: removeNumbersInString(stripeAddress.line1).trim(),
      },
      {
        id: undefined,
        type: PropertyType.NUMBER,
        value: removeCharactersInString(stripeAddress.line1),
      }
    );
  }

  address.properties.push({
    id: undefined,
    type: PropertyType.BOX,
    value: stripeAddress.line2,
  });

  address.properties.push({
    id: undefined,
    type: PropertyType.ZIP,
    value: stripeAddress.postalCode,
  });

  address.properties.push({
    id: undefined,
    type: PropertyType.STATE,
    value: stripeAddress.state,
  });

  address.properties.push({
    id: undefined,
    type: PropertyType.LOCALITY,
    value: stripeAddress.city,
  });

  address.properties.push({
    id: undefined,
    type: PropertyType.COUNTRY,
    value: stripeAddress.country,
  });

  return address;
}

export function parseLegalIdFromStripe(stripeTax: StripeTax): Property {
  const legalId: Property = {
    id: undefined,
    type: PropertyType.LEGAL_ID,
    properties: [
      {
        id: undefined,
        type: PropertyType.COMPANY_NUMBER,
        value: removeCharactersInString(stripeTax?.value),
      },
      {
        id: undefined,
        type: PropertyType.JURISDICTION,
        value: removeNumbersInString(stripeTax?.value),
      },
    ],
  };
  return legalId;
}

export function parseNameFromStripe(
  stripeName: string,
  profileContext: StripeCustomerInfoRepresentation.CustomerTypeEnum
): Property {
  if (profileContext === StripeCustomerInfoRepresentation.CustomerTypeEnum.ORGANISATION) {
    return <Property>{
      id: undefined,
      type: PropertyType.LEGAL_NAME,
      value: stripeName,
    };
  }

  const names = stripeName.split(' ');
  const firstname = names.shift();
  const middleName: string | undefined = undefined;
  const lastname: string | undefined = names.join(' ').trim() || undefined;

  return {
    id: '',
    type: PropertyType.NAME,
    properties: [
      {id: '', type: PropertyType.FIRST_NAME, value: firstname},
      {id: '', type: PropertyType.MIDDLE_NAME, value: middleName},
      {id: '', type: PropertyType.LAST_NAME, value: lastname},
    ],
  };
}

export function parseLegalIdForStripe(legalId: Property | undefined) {
  const taxId: StripeTax = {
    type: TaxType.EU_VAT,
    value:
      getPropertyByPropertyType(legalId?.properties, PropertyType.JURISDICTION) +
      '' +
      getPropertyByPropertyType(legalId?.properties, PropertyType.COMPANY_NUMBER),
  };
  return taxId;
}

export function parseAddressForStripe(address: Property) {
  const stripeAddress = {
    line1:
      getPropertyByPropertyType(address.properties, PropertyType.STREET) +
      ' ' +
      getPropertyByPropertyType(address.properties, PropertyType.NUMBER),
    line2: getPropertyByPropertyType(address.properties, PropertyType.BOX),
    city: getPropertyByPropertyType(address.properties, PropertyType.LOCALITY),
    postalCode: getPropertyByPropertyType(address.properties, PropertyType.ZIP),
    state: getPropertyByPropertyType(address.properties, PropertyType.STATE),
    country: getPropertyByPropertyType(address.properties, PropertyType.COUNTRY),
  };
  return stripeAddress;
}

export function parseNameForStripe(nameProperty: Property) {
  return namePropertyValue(nameProperty);
}

export function getPropertyByPropertyType(
  properties: Property[] | undefined,
  propertyType: PropertyType
) {
  const propertyValue = (properties || []).find((prop) => {
    return prop.value !== undefined && prop.type === propertyType;
  })?.value;
  return properties && propertyValue ? propertyValue : '';
}

export function removeSpacesInString(str: string): string {
  return str.replace(/\s+/g, '');
}

export function removeCharactersInString(str: string): string {
  return str?.replace(/^\D+/g, '');
}

export function removeNumbersInString(str: string): string {
  return str?.replace(/[0-9]/g, '');
}

export function cardExpired(card: StripeCardRepresentation | undefined): boolean {
  const cardExpiredMonth = card?.exp_month;
  const cardExpiredYear = card?.exp_year;
  const today = new Date();
  const expireDate = new Date();
  if (cardExpiredYear && cardExpiredMonth) {
    expireDate.setFullYear(cardExpiredYear, cardExpiredMonth - 1, 1);
  }

  return expireDate < today;
}
