import {OverlayContainer} from '@angular/cdk/overlay';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {OVERLAY_CDK_CONTAINER} from './overlay-theming';

@Injectable({
  providedIn: 'root',
})
export class AztrixOverlayContainer extends OverlayContainer {
  constructor(
    @Inject(DOCUMENT) document: unknown,
    platform: Platform,
    @Inject(OVERLAY_CDK_CONTAINER) private _cdkContainer$: BehaviorSubject<any>
  ) {
    super(document, platform);
  }

  protected override _createContainer(): void {
    this._cdkContainer$.subscribe((containerElement) => {
      this._containerElement = containerElement;
    });
  }
}
