import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {CommunicationType, Property} from '@aztrix/models';

import {communicationHref} from '../../util/communication.util';

@Pipe({name: 'communicationHref'})
export class CommunicationHrefPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | Property, type: CommunicationType): SafeUrl | undefined {
    const propertyValue = typeof value === 'string' ? value : value.value || '';
    return communicationHref(type, propertyValue, this.sanitizer);
  }
}
