import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';

import {propertySocialName} from '../../helpers/property-functions';

@Pipe({name: 'propertySocialName'})
export class PropertySocialNamePipe implements PipeTransform {
  transform(property?: Property | PropertyRepresentation): string | undefined {
    return propertySocialName(property);
  }
}
