import {Pipe, PipeTransform} from '@angular/core';
import {ProfileRepresentation} from '@aztrix/sdk';

import {isMeProfile, isProfile} from '../../helpers/profile-functions';

@Pipe({
  name: 'isMeProfile',
})
export class IsMeProfilePipe implements PipeTransform {
  transform(profile: unknown): boolean {
    if (!isProfile(profile)) {
      return false;
    }
    return isMeProfile(<ProfileRepresentation>profile);
  }
}
