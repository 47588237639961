import {ImportProvider} from '../import/import-provider';
import {RequestType} from '../request/request';

export enum UserNotificationType {
  NONE = 'NONE',
  INCOMING_CONNECTION = 'INCOMING_CONNECTION',
  DISCONNECT = 'DISCONNECT',
  INCOMING_PROPERTY_REQUESTS = 'INCOMING_PROPERTY_REQUESTS',
  INCOMING_FAMILY_REQUESTS = 'INCOMING_FAMILY_REQUESTS',
  INCOMING_INVITATION_REQUESTS = 'INCOMING_INVITATION_REQUESTS',
  INCOMING_GROUP_REQUESTS = 'INCOMING_GROUP_REQUESTS',
  PROPERTIES_CHANGED = 'PROPERTIES_CHANGED',
  GROUP_FOLLOW = 'GROUP_FOLLOW',
  GROUP_UNFOLLOW = 'GROUP_UNFOLLOW',
  GROUP_JOIN = 'GROUP_JOIN',
  GROUP_LEAVE = 'GROUP_LEAVE',
  GROUP_ADDED_AS_MEMBER = 'GROUP_ADDED_AS_MEMBER',
  GROUP_REMOVED_AS_MEMBER = 'GROUP_REMOVED_AS_MEMBER',
  BIRTHDAY = 'BIRTHDAY',
  NEW_SUGGESTIONS = 'NEW_SUGGESTIONS',
  IMPORT_SUCCEEDED = 'IMPORT_SUCCEEDED',
  SOCIAL_VERIFICATION_SUCCEEDED = 'SOCIAL_VERIFICATION_SUCCEEDED',
  SOCIAL_VERIFICATION_FAILED = 'SOCIAL_VERIFICATION_FAILED',
  INVITATION_CLAIMED = 'INVITATION_CLAIMED',
  UNRECOGNIZED = 'UNRECOGNIZED',
  SUBSCRIBE_TO_AZTRIX_NEWSLETTER = 'SUBSCRIBE_TO_AZTRIX_NEWSLETTER',
  CONFIRM_NON_CONFIRMED_AGREEMENT = 'CONFIRM_NON_CONFIRMED_AGREEMENT',
  NON_CONFIRMED_AGREEMENT_BASED_ON_PROFILE = 'NON_CONFIRMED_AGREEMENT_BASED_ON_PROFILE',
  NEW_SUBSCRIBER = 'NEW_SUBSCRIBER',
  AGREEMENT_BECAME_UNCONFIRMED = 'AGREEMENT_BECAME_UNCONFIRMED',
  INCOMING_ROLE_REQUEST = 'INCOMING_ROLE_REQUEST',
  UPDATED_TERMS_AND_CONDITIONS = 'UPDATED_TERMS_AND_CONDITIONS',
  UPDATED_PRIVACY_POLICY = 'UPDATED_PRIVACY_POLICY',
  AGREEMENT_DELETED_BY_OWNER = 'AGREEMENT_DELETED_BY_OWNER',
  AGREEMENT_UPDATED_BY_SUBSCRIBER = 'AGREEMENT_UPDATED_BY_SUBSCRIBER',
}

export interface UserNotification {
  id: string;
  profileId: string;
  timestamp: number;
  type: UserNotificationType;
  seen: boolean;
  data: {
    targetId: string;
    groupId: string;
    contactIds: string[];
    contactId: string;
    importProvider: ImportProvider;
    propertyId?: string;
    proposalId?: string;
    agreementId?: string;
    agreementCode?: string;
  };
}

export enum SystemNotificationType {
  NONE = 'NONE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  NEW_CONTACTS = 'NEW_CONTACTS',
  REMOVED_CONTACTS = 'REMOVED_CONTACTS',
  NEW_GROUP = 'NEW_GROUP',
  REMOVED_GROUP = 'REMOVED_GROUP',
  NEW_MEMBER_GROUP = 'NEW_MEMBER_GROUP',
  REMOVED_MEMBER_GROUP = 'REMOVED_MEMBER_GROUP',
  UPDATE_REQUESTS = 'UPDATE_REQUESTS',
  UPDATE_SUGGESTIONS = 'UPDATE_SUGGESTIONS',
  IMPORT_SUCCEEDED = 'IMPORT_SUCCEEDED',
  IMPORT_FAILED = 'IMPORT_FAILED',
  LOGGED_OUT = 'LOGGED_OUT',
  SOCIAL_VERIFICATION_SUCCEEDED = 'SOCIAL_VERIFICATION_SUCCEEDED',
  SOCIAL_VERIFICATION_FAILED = 'SOCIAL_VERIFICATION_FAILED',
}

export interface SystemNotification {
  id: string;
  profileId: string;
  timestamp: number;
  type: SystemNotificationType;
  data: {
    targetId: string;
    groupId: string;
    contactId: string;
    importProvider: string;
    requestType: RequestType;
    sessionId: string;
    propertyId: string;
    contactIds: string[];
  };
}

export interface NotificationsSettingsRepresentation {
  profileId: string;
  agreement: {
    nonConfirmedAgreements: {aztrix: boolean; push: boolean; email: boolean};
  };
  means: {
    email: {emails: {value: boolean; id: string}[]};
  };
  contact: {
    changes: {aztrix: boolean; push: boolean; email: boolean};
    birthdays: {aztrix: boolean; push: boolean; email: boolean};
    requests: {aztrix: boolean; push: boolean; email: boolean};
    connections: {aztrix: boolean; push: boolean; email: boolean};
  };
  suggestions: {aztrix: boolean; push: boolean; email: boolean};
  group: {
    ownerChanges: {aztrix: boolean; push: boolean; email: boolean};
    memberChanges: {aztrix: boolean; push: boolean; email: boolean};
  };
  weeklyEmail: boolean;
}
