/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {SigningOrderRecipientRepresentation} from './signingOrderRecipientRepresentation';
import {DocumentRepresentation} from './documentRepresentation';

export interface SigningOrderRepresentation {
  id?: string;
  title?: string;
  description?: string;
  language?: string;
  signatureProcess?: SigningOrderRepresentation.SignatureProcessEnum;
  signatureType?: SigningOrderRepresentation.SignatureTypeEnum;
  creationTimestamp?: number;
  expirationTimestamp?: number;
  ownerId?: string;
  recipients?: Array<SigningOrderRecipientRepresentation>;
  documents?: Array<DocumentRepresentation>;
}
export namespace SigningOrderRepresentation {
  export type SignatureProcessEnum = 'NONE' | 'STANDARD' | 'SPLIT' | 'SEQUENTIAL';
  export const SignatureProcessEnum = {
    NONE: 'NONE' as SignatureProcessEnum,
    STANDARD: 'STANDARD' as SignatureProcessEnum,
    SPLIT: 'SPLIT' as SignatureProcessEnum,
    SEQUENTIAL: 'SEQUENTIAL' as SignatureProcessEnum,
  };
  export type SignatureTypeEnum = 'ADVANCED' | 'QUALIFIED';
  export const SignatureTypeEnum = {
    ADVANCED: 'ADVANCED' as SignatureTypeEnum,
    QUALIFIED: 'QUALIFIED' as SignatureTypeEnum,
  };
}
