import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {documentLink} from '../../helpers/property-functions';

@Pipe({
  name: 'propertyDocumentLink',
})
export class PropertyDocumentLinkPipe implements PipeTransform {
  transform(property: Property | undefined, backendUrl?: string | null): unknown {
    return documentLink(property, backendUrl || undefined);
  }
}
