import {Directive, Input} from '@angular/core';

@Directive()
export abstract class AbstractButton {
  @Input() type: string;
  @Input() color: string;
  @Input() icon: string;

  _labelHideMobile = false;
  @Input()
  set labelHideMobile(labelHideMobile: any) {
    this._labelHideMobile = typeof labelHideMobile === 'boolean' ? labelHideMobile : true;
  }

  _smallButton = false;
  @Input()
  set smallButton(smallButton: any) {
    this._smallButton = typeof smallButton === 'boolean' ? smallButton : true;
  }

  _bigButton = false;
  @Input()
  set bigButton(bigButton: any) {
    this._bigButton = typeof bigButton === 'boolean' ? bigButton : true;
  }

  _fullWidth = false;
  @Input()
  set fullWidth(fullWidth: any) {
    this._fullWidth = typeof fullWidth === 'boolean' ? fullWidth : true;
  }

  _filled = false;
  @Input()
  set filled(filled: any) {
    this._filled = typeof filled === 'boolean' ? filled : true;
  }

  _outline = false;
  @Input()
  set outline(outline: any) {
    this._outline = typeof outline === 'boolean' ? outline : true;
  }

  _iconButton = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('icon-button')
  set iconButton(iconButton: any) {
    this._iconButton = typeof iconButton === 'boolean' ? iconButton : true;
  }

  _hoverLabel = false;
  @Input()
  set hoverLabel(hoverLabel: any) {
    this._hoverLabel = typeof hoverLabel === 'boolean' ? hoverLabel : true;
  }

  _lowercase = false;
  @Input()
  set lowercase(lowercase: any) {
    this._lowercase = typeof lowercase === 'boolean' ? lowercase : true;
  }

  _hover = true;
  @Input()
  set hover(hover: any) {
    this._hover = typeof hover === 'boolean' ? hover : true;
  }

  _loading = false;
  @Input()
  set loading(loading: any) {
    this._loading = typeof loading === 'boolean' ? loading : true;
  }

  @Input() disabled = false;
}
