import {TierSubscriptionInfoRepresentation, TierSubscriptionRepresentation} from '@aztrix/sdk';

export enum FeatureType {
  LANGUAGES = 'LANGUAGES',
  HANDLE = 'HANDLE',
  CUSTOMIZE_PROFILE = 'CUSTOMIZE_PROFILE',
  APPEARANCE = 'APPEARANCE',
  ORDERING = 'ORDERING',
  LINKS = 'LINKS',
  FORMATS = 'FORMATS',
  SHARE_OPTIONS = 'SHARE_OPTIONS',
  LEGAL = 'LEGAL',
  PAYMENT = 'PAYMENT',
  FORM = 'FORM',
  WHITE_LABEL = 'WHITE_LABEL',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
  ANALYTICS_INTEGRATION = 'ANALYTICS_INTEGRATION',
  SEO = 'SEO',
  QR_LOGO = 'QR_LOGO',
  MULTIPLE_ALISTS = 'MULTIPLE_ALISTS',
}

export function isFeatureAllowed(
  currentTier: TierSubscriptionInfoRepresentation.CurrentTierEnum | null | undefined,
  featureType: FeatureType
): boolean {
  if (!featureType) {
    return false;
  }

  if ([FeatureType.PAYMENT, FeatureType.FORM].includes(featureType)) {
    return isBasic(currentTier);
  } else if (
    [
      FeatureType.ANALYTICS_INTEGRATION,
      FeatureType.WHITE_LABEL,
      FeatureType.BACKGROUND_IMAGE,
      FeatureType.SEO,
    ].includes(featureType)
  ) {
    return isPro(currentTier);
  } else if ([FeatureType.QR_LOGO, FeatureType.MULTIPLE_ALISTS].includes(featureType)) {
    return isEnterprise(currentTier);
  } else {
    return true;
  }
}

export function isBasic(tier: TierSubscriptionRepresentation.TierEnum | null | undefined) {
  if (!tier) {
    return false;
  }
  return (
    tier === TierSubscriptionRepresentation.TierEnum.BASIC ||
    tier === TierSubscriptionRepresentation.TierEnum.PRO ||
    tier === TierSubscriptionRepresentation.TierEnum.ENTERPRISE
  );
}

export function isPro(tier: TierSubscriptionRepresentation.TierEnum | null | undefined) {
  if (!tier) {
    return false;
  }
  return (
    tier === TierSubscriptionRepresentation.TierEnum.PRO ||
    tier === TierSubscriptionRepresentation.TierEnum.ENTERPRISE
  );
}

export function isEnterprise(tier: TierSubscriptionRepresentation.TierEnum | null | undefined) {
  if (!tier) {
    return false;
  }
  return tier === TierSubscriptionRepresentation.TierEnum.ENTERPRISE;
}

export function isTierUpgrade(
  currentTier: TierSubscriptionInfoRepresentation.CurrentTierEnum,
  nextTier: TierSubscriptionRepresentation.TierEnum
) {
  const tierArray = [
    TierSubscriptionInfoRepresentation.CurrentTierEnum.FREE,
    TierSubscriptionInfoRepresentation.CurrentTierEnum.BASIC,
    TierSubscriptionInfoRepresentation.CurrentTierEnum.PRO,
    TierSubscriptionInfoRepresentation.CurrentTierEnum.ENTERPRISE,
  ];

  const currentIndex = tierArray.indexOf(currentTier);
  const nextIndex = tierArray.indexOf(nextTier);

  if (currentTier === TierSubscriptionInfoRepresentation.CurrentTierEnum.FREE) {
    return true;
  }

  return nextIndex > currentIndex;
}
