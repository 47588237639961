import {ContextType} from '../context/context-type';
import {Profile} from '../profile/profile';
import {MutableProperty} from '../property/mutable-property';
import {Property} from '../property/property';

export interface InvitationRepresentation {
  status: InvitationStatus;
  requestor: Profile;
  requestedProperties: InvitationPropertyRequest[];
  inviteeProfile: Profile;
}

export enum InvitationStatus {
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE',
}

export interface InvitationPropertyRequest {
  requestId: string;
  contextType?: ContextType;
  property: Property | MutableProperty;
  requestType?: string;
  requestStats?: RequestStatus;
}

export enum RequestStatus {
  NONE = 'NONE',
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface InvitationRequestInfo {
  contextType: ContextType;
  requestedProperties: InvitationPropertyRequest[];
}
