import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class RouterHistoryService {
  private _history: string[] = [];

  constructor(private _router: Router, private _location: Location) {
    this._history.push(this._router.routerState.snapshot.url);
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._history.push(event.urlAfterRedirects);
      }
    });
  }

  back(fallback: string): void {
    this._history.pop();
    if (this._history.length > 0) {
      this._location.back();
    } else {
      this._router.navigateByUrl(fallback);
    }
  }
}
