import {ElementRef} from '@angular/core';

import {unique} from '../util/array.util';
import {contrastingFontColor, getColor} from './color-functions';

export function setPrimaryPalette(primaryColor: string | undefined, elementRef?: ElementRef): void {
  _setPalette('primary-color', primaryColor, '#27ae60', elementRef);
}

export function setAccentPalette(accentColor: string | undefined, elementRef?: ElementRef): void {
  _setPalette('accent-color', accentColor, '#27ae60', elementRef);
}

export function setWarnPalette(warnColor: string | undefined, elementRef?: ElementRef): void {
  _setPalette('warn-color', warnColor, '#ed1c24', elementRef);
}

export function setFontColor(fontColor: string | undefined, elementRef?: ElementRef): void {
  if (!elementRef) {
    return;
  }

  const elem = elementRef.nativeElement;
  const color = getColor(fontColor, 0, 0, '#363f48');
  elem.style.setProperty('--font-color', color);
  elem.style.setProperty('color', color);
}

export function setBackgroundColor(
  backgroundColor: string | undefined,
  elementRef?: ElementRef
): void {
  if (!elementRef) {
    return;
  }

  const elem = elementRef.nativeElement;
  const color = getColor(backgroundColor, 0, 0, '#ebebeb');
  elem.style.setProperty('--background-color', color);
}

export function setFontFamily(fontFamily: string | undefined, elementRef?: ElementRef): void {
  if (!elementRef) {
    return;
  }

  const fallbackFonts = ['lato', '"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'];
  const fonts = (fontFamily ?? '').split(', ').filter((f) => !!f);

  const fontWithFallbacks = unique([...fonts, ...fallbackFonts]).join(', ');

  const elem = elementRef.nativeElement;
  elem.style.setProperty('--font', fontWithFallbacks);
  elem.style.setProperty('font-family', fontWithFallbacks);
}

function _setPalette(
  name: string,
  color: string | undefined,
  defaultColor: string,
  elementRef?: ElementRef
): void {
  if (!elementRef) {
    return;
  }

  const elem = elementRef.nativeElement;
  _setColor(elem, name, '50', getColor(color, 1.23, -0.28, defaultColor));
  _setColor(elem, name, '100', getColor(color, 0.98, -0.28, defaultColor));
  _setColor(elem, name, '200', getColor(color, 0.7, -0.28, defaultColor));
  _setColor(elem, name, '300', getColor(color, 0.4, -0.28, defaultColor));
  _setColor(elem, name, '400', getColor(color, 0.2, -0.28, defaultColor));
  _setColor(elem, name, '500', getColor(color, 0, 0, defaultColor));
  elem.style.setProperty(`--${name}-500-decimal`, getColor(color, 0, 0, defaultColor, true));
  _setColor(elem, name, '600', getColor(color, -0.08, 0, defaultColor));
  _setColor(elem, name, '700', getColor(color, -0.2, 0, defaultColor));
  _setColor(elem, name, '800', getColor(color, -0.35, 0, defaultColor));
  _setColor(elem, name, '900', getColor(color, -0.5, 0, defaultColor));
  _setColor(elem, name, 'A100', getColor(color, 0.91, 0.28, defaultColor));
  _setColor(elem, name, 'A200', getColor(color, 0.45, 0.28, defaultColor));
  _setColor(elem, name, 'A400', getColor(color, 0.1, 0.28, defaultColor));
  _setColor(elem, name, 'A700', getColor(color, 0, 0, defaultColor));
}

function _setColor(el: any, name: string, type: string, color: string) {
  el.style.setProperty(`--${name}-${type}`, color);
  el.style.setProperty(`--${name}-${type}-contrast`, contrastingFontColor(color));
}
