/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RoleRepresentation {
  roleType?: RoleRepresentation.RoleTypeEnum;
  granteeCount?: number;
  requestedGranteeCount?: number;
}
export namespace RoleRepresentation {
  export type RoleTypeEnum = 'ADMIN';
  export const RoleTypeEnum = {
    ADMIN: 'ADMIN' as RoleTypeEnum,
  };
}
