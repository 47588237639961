import { Subject, Subscription } from 'rxjs';
import { ɵNG_PIPE_DEF } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

const NG_PIPE_DEF = ɵNG_PIPE_DEF;
function isPipe(target) {
    return !!target[NG_PIPE_DEF];
}

/**
 * Applied to instances and stores `Subject` instance when
 * no custom destroy method is provided.
 */
const DESTROY = Symbol('__destroy');
/**
 * Applied to definitions and informs that class is decorated
 */
const DECORATOR_APPLIED = Symbol('__decoratorApplied');
/**
 * If we use the `untilDestroyed` operator multiple times inside the single
 * instance providing different `destroyMethodName`, then all streams will
 * subscribe to the single subject. If any method is invoked, the subject will
 * emit and all streams will be unsubscribed. We wan't to prevent this behavior,
 * thus we store subjects under different symbols.
 */
function getSymbol(destroyMethodName) {
    if (typeof destroyMethodName === 'string') {
        return Symbol(`__destroy__${destroyMethodName}`);
    }
    else {
        return DESTROY;
    }
}
function markAsDecorated(type) {
    // Store this property on the prototype if it's an injectable class, component or directive.
    // We will be able to handle class extension this way.
    type.prototype[DECORATOR_APPLIED] = true;
}
function createSubjectOnTheInstance(instance, symbol) {
    if (!instance[symbol]) {
        instance[symbol] = new Subject();
    }
}
function completeSubjectOnTheInstance(instance, symbol) {
    if (instance[symbol]) {
        instance[symbol].next();
        instance[symbol].complete();
        // We also have to re-assign this property thus in the future
        // we will be able to create new subject on the same instance.
        instance[symbol] = null;
    }
}

function unsubscribe(property) {
    if (property instanceof Subscription) {
        property.unsubscribe();
    }
}
function unsubscribeIfPropertyIsArrayLike(property) {
    Array.isArray(property) && property.forEach(unsubscribe);
}
function decorateNgOnDestroy(ngOnDestroy, options) {
    return function () {
        var _a;
        // Invoke the original `ngOnDestroy` if it exists
        ngOnDestroy && ngOnDestroy.call(this);
        // It's important to use `this` instead of caching instance
        // that may lead to memory leaks
        completeSubjectOnTheInstance(this, getSymbol());
        // Check if subscriptions are pushed to some array
        if (options.arrayName) {
            return unsubscribeIfPropertyIsArrayLike(this[options.arrayName]);
        }
        // Loop through the properties and find subscriptions
        if (options.checkProperties) {
            for (const property in this) {
                if ((_a = options.blackList) === null || _a === void 0 ? void 0 : _a.includes(property)) {
                    continue;
                }
                unsubscribe(this[property]);
            }
        }
    };
}
function decorateProviderDirectiveOrComponent(type, options) {
    type.prototype.ngOnDestroy = decorateNgOnDestroy(type.prototype.ngOnDestroy, options);
}
function decoratePipe(type, options) {
    const def = type.ɵpipe;
    def.onDestroy = decorateNgOnDestroy(def.onDestroy, options);
}
function UntilDestroy(options = {}) {
    return (type) => {
        if (isPipe(type)) {
            decoratePipe(type, options);
        }
        else {
            decorateProviderDirectiveOrComponent(type, options);
        }
        markAsDecorated(type);
    };
}

function overrideNonDirectiveInstanceMethod(instance, destroyMethodName, symbol) {
    const originalDestroy = instance[destroyMethodName];
    if (ngDevMode && typeof originalDestroy !== 'function') {
        throw new Error(`${instance.constructor.name} is using untilDestroyed but doesn't implement ${destroyMethodName}`);
    }
    createSubjectOnTheInstance(instance, symbol);
    instance[destroyMethodName] = function () {
        // eslint-disable-next-line prefer-rest-params
        originalDestroy.apply(this, arguments);
        completeSubjectOnTheInstance(this, symbol);
        // We have to re-assign this property back to the original value.
        // If the `untilDestroyed` operator is called for the same instance
        // multiple times, then we will be able to get the original
        // method again and not the patched one.
        instance[destroyMethodName] = originalDestroy;
    };
}
function untilDestroyed(instance, destroyMethodName) {
    return (source) => {
        const symbol = getSymbol(destroyMethodName);
        // If `destroyMethodName` is passed then the developer applies
        // this operator to something non-related to Angular DI system
        if (typeof destroyMethodName === 'string') {
            overrideNonDirectiveInstanceMethod(instance, destroyMethodName, symbol);
        }
        else {
            ngDevMode && ensureClassIsDecorated(instance);
            createSubjectOnTheInstance(instance, symbol);
        }
        return source.pipe(takeUntil(instance[symbol]));
    };
}
function ensureClassIsDecorated(instance) {
    const prototype = Object.getPrototypeOf(instance);
    const missingDecorator = !(DECORATOR_APPLIED in prototype);
    if (missingDecorator) {
        throw new Error('untilDestroyed operator cannot be used inside directives or ' +
            'components or providers that are not decorated with UntilDestroy decorator');
    }
}

/**
 * Generated bundle index. Do not edit.
 */

export { UntilDestroy, untilDestroyed };

