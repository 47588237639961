import {Pipe, PipeTransform} from '@angular/core';
import {Profile} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';

import {isPublicProfile} from '../../helpers/profile-functions';

@Pipe({
  name: 'isPublicProfile',
})
export class IsPublicProfilePipe implements PipeTransform {
  transform(profile: Profile | ProfileRepresentation): boolean {
    return profile && isPublicProfile(<ProfileRepresentation>profile);
  }
}
