/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FirebaseRegistrationRepresentation {
  registrationToken?: string;
  platform?: FirebaseRegistrationRepresentation.PlatformEnum;
}
export namespace FirebaseRegistrationRepresentation {
  export type PlatformEnum = 'IOS' | 'ANDROID';
  export const PlatformEnum = {
    IOS: 'IOS' as PlatformEnum,
    ANDROID: 'ANDROID' as PlatformEnum,
  };
}
