import {ROUTER_LINKS} from './metadata';

/**
 * Replace url placeholders with params.
 *
 * Usage:
 * getLink('/r/profile/:id', {id: '123'}) --> '/r/profile/123'
 * getLink(ROUTER_LINKS.contact, {id: '123'});  --> '/contact/123'
 *
 * @param link A url with placeholders.
 * @param params An object where the keys represent the url params.
 */
export function getLink(link: string, params: any = null) {
  if (!link) {
    throw new Error('No link is supplied');
  }

  if (!link.startsWith('/r/') && !link.startsWith('r/')) {
    const found = Object.values(ROUTER_LINKS).find((l) => l === link);
    if (!found) {
      throw Error(`This link does not exist in routerLinks.json: ${link}`);
    }
  }

  return link
    .split('/')
    .map((part) => (isPlaceholder(part) ? paramForPlaceHolder(part, params) : part))
    .reduce((s1, s2) => s1 + '/' + s2);
}

function isPlaceholder(placeholder: string) {
  return placeholder.startsWith(':');
}

function paramForPlaceHolder(placeholder: string, params: any) {
  const placeholderName = placeholder.substring(1);

  const matches = placeholderName.match(/[a-zA-Z]*/g);

  if (!placeholderName || !matches) {
    throw new Error('Placeholder name empty');
  }

  const value = params[matches[0]];
  if (value === undefined) {
    throw new Error('Missing param for placeholder: ' + placeholderName);
  }
  return placeholderName.replace(matches[0], value);
}
