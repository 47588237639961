/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TierSubscriptionRepresentation {
  tier?: TierSubscriptionRepresentation.TierEnum;
  interval?: string;
  currency?: string;
  price?: number;
  credits?: number;
}
export namespace TierSubscriptionRepresentation {
  export type TierEnum = 'FREE' | 'BASIC' | 'PRO' | 'ENTERPRISE';
  export const TierEnum = {
    FREE: 'FREE' as TierEnum,
    BASIC: 'BASIC' as TierEnum,
    PRO: 'PRO' as TierEnum,
    ENTERPRISE: 'ENTERPRISE' as TierEnum,
  };
}
