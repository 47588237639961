import {formatNumber as fNumber, formatPercent as fPercent} from '@angular/common';
import {ɵfindLocaleData} from '@angular/core';

// default to locale 'en' instead of throwing an error
function getAngularLocale(locale: string): string {
  try {
    ɵfindLocaleData(locale);
    return locale;
  } catch (e) {
    console.warn(e);
    return 'en';
  }
}

/**
 * Derivative of Angular's formatNumber, extended with:
 *  * `-` when NaN
 *  * all numbers 1000 or above get kilo denomination (1200 -> `1.2k`)
 * @param value The number to format
 * @param locale The locale to format the number in
 * @param minIntegerDigits the minimum integer length (2: 5 -> `05`)
 * @param minFractionDigits the minimum fraction length (2: 5 -> `5,00`)
 * @param maxFractionDigits the maximum fraction length (2: 5,123 -> `5,12`)
 * @returns The formatted text string
 */
export function formatNumber(
  value: unknown,
  locale: string,
  minIntegerDigits = 1,
  minFractionDigits = 0,
  maxFractionDigits = 2
): string {
  if (typeof value !== 'number' || isNaN(value)) {
    return '-';
  } else if (value <= 999 || value === Infinity) {
    return fNumber(
      value,
      getAngularLocale(locale),
      `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`
    );
  } else {
    return (
      fNumber(
        value / 1000,
        getAngularLocale(locale),
        `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`
      ) + 'k'
    );
  }
}

/**
 * Derivative of Angular's formatPercent, extended with:
 *  * `-` when NaN
 * @param value The number to format
 * @param locale The locale to format the number in
 * @param minIntegerDigits the minimum integer length (2: 5 -> `05%`)
 * @param minFractionDigits the minimum fraction length (2: 5 -> `5,00%`)
 * @param maxFractionDigits the maximum fraction length (2: 5,123 -> `5,12%`)
 * @returns The formatted text string
 */
export function formatPercent(
  value: unknown,
  locale: string,
  minIntegerDigits = 1,
  minFractionDigits = 0,
  maxFractionDigits = 2
): string {
  if (typeof value !== 'number' || isNaN(value)) {
    return '-';
  } else {
    return fPercent(
      value,
      getAngularLocale(locale),
      `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`
    );
  }
}
