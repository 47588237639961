import {ChangeDetectorRef, Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Params} from '@angular/router';
import {CommunicationType, Locale, Property} from '@aztrix/models';
import {BehaviorSubject, defer, Observable, of, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {
  displayValue$,
  hasEqualValues,
  propertyCommunicationType,
} from '../../helpers/property-functions';
import {LANGUAGE_OVERRIDE, LOCALE, TranslateService} from '@aztrix/translate';
import {communicationHref} from '../../util/communication.util';
import {COMMUNICATION_URL_PARAMS} from '../../../communication_url_params';

@Pipe({name: 'propertyCommunicationHref', pure: false})
export class PropertyCommunicationHrefPipe implements PipeTransform, OnDestroy {
  private _value?: Property | Property[];
  private _latestValue?: string;
  private _latestReturnedValue?: string;

  private _subscription = new Subscription();

  constructor(
    private sanitizer: DomSanitizer,
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Inject(LOCALE) private _locale$: BehaviorSubject<Locale>,
    @Inject(COMMUNICATION_URL_PARAMS) private _params$: BehaviorSubject<Params>,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  transform(
    value?: Property,
    communicationType?: CommunicationType,
    params?: Params
  ): SafeUrl | undefined {
    if (!this._value) {
      this._value = value;

      const observable$ = this._communicationHref$(value, communicationType, params);
      this._subscribe(observable$);

      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (!hasEqualValues(value, this._value)) {
      this._dispose();
      return this.transform(value);
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  ngOnDestroy() {
    this._dispose();
  }

  private _subscribe(obs: Observable<any>): void {
    this._subscription.add(
      obs.subscribe((value) => {
        this._latestValue = value;
        this._changeDetector.markForCheck();
      })
    );
  }

  private _dispose() {
    this._subscription.unsubscribe();
    this._value = undefined;
    this._latestValue = undefined;
    this._latestReturnedValue = undefined;
  }

  private _communicationHref$(
    property: Property | undefined,
    communicationType?: CommunicationType,
    params?: Params
  ): Observable<SafeUrl | undefined> {
    return defer(() => this._params$ ?? of(<Params>{})).pipe(
      switchMap((p) => {
        return displayValue$(
          this._translate,
          property,
          this._locale$.value,
          this._languageOverride$?.value
        ).pipe(
          map((value) =>
            communicationHref(
              communicationType || propertyCommunicationType(property),
              value,
              this.sanitizer,
              {...(params || {}), ...p}
            )
          )
        );
      })
    );
  }
}
