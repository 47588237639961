/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TaxId {
  type?: TaxId.TypeEnum;
  value?: string;
}
export namespace TaxId {
  export type TypeEnum =
    | 'AD_NRT'
    | 'AE_TRN'
    | 'AR_CUIT'
    | 'AU_ABN'
    | 'AU_ARN'
    | 'BG_UIC'
    | 'BH_VAT'
    | 'BO_TIN'
    | 'BR_CNPJ'
    | 'BR_CPF'
    | 'CA_BN'
    | 'CA_GST_HST'
    | 'CA_PST_BC'
    | 'CA_PST_MB'
    | 'CA_PST_SK'
    | 'CA_QST'
    | 'CH_VAT'
    | 'CL_TIN'
    | 'CN_TIN'
    | 'CO_NIT'
    | 'CR_TIN'
    | 'DO_RCN'
    | 'EC_RUC'
    | 'EG_TIN'
    | 'ES_CIF'
    | 'EU_OSS_VAT'
    | 'EU_VAT'
    | 'GB_VAT'
    | 'GE_VAT'
    | 'HK_BR'
    | 'HU_TIN'
    | 'ID_NPWP'
    | 'IL_VAT'
    | 'IN_GST'
    | 'IS_VAT'
    | 'JP_CN'
    | 'JP_RN'
    | 'JP_TRN'
    | 'KE_PIN'
    | 'KR_BRN'
    | 'KZ_BIN'
    | 'LI_UID'
    | 'MX_RFC'
    | 'MY_FRP'
    | 'MY_ITN'
    | 'MY_SST'
    | 'NG_TIN'
    | 'NO_VAT'
    | 'NO_VOEC'
    | 'NZ_GST'
    | 'OM_VAT'
    | 'PE_RUC'
    | 'PH_TIN'
    | 'RO_TIN'
    | 'RS_PIB'
    | 'RU_INN'
    | 'RU_KPP'
    | 'SA_VAT'
    | 'SG_GST'
    | 'SG_UEN'
    | 'SI_TIN'
    | 'SV_NIT'
    | 'TH_VAT'
    | 'TR_TIN'
    | 'TW_VAT'
    | 'UA_VAT'
    | 'US_EIN'
    | 'UY_RUC'
    | 'VE_RIF'
    | 'VN_TIN'
    | 'ZA_VAT';
  export const TypeEnum = {
    AD_NRT: 'AD_NRT' as TypeEnum,
    AE_TRN: 'AE_TRN' as TypeEnum,
    AR_CUIT: 'AR_CUIT' as TypeEnum,
    AU_ABN: 'AU_ABN' as TypeEnum,
    AU_ARN: 'AU_ARN' as TypeEnum,
    BG_UIC: 'BG_UIC' as TypeEnum,
    BH_VAT: 'BH_VAT' as TypeEnum,
    BO_TIN: 'BO_TIN' as TypeEnum,
    BR_CNPJ: 'BR_CNPJ' as TypeEnum,
    BR_CPF: 'BR_CPF' as TypeEnum,
    CA_BN: 'CA_BN' as TypeEnum,
    CA_GST_HST: 'CA_GST_HST' as TypeEnum,
    CA_PST_BC: 'CA_PST_BC' as TypeEnum,
    CA_PST_MB: 'CA_PST_MB' as TypeEnum,
    CA_PST_SK: 'CA_PST_SK' as TypeEnum,
    CA_QST: 'CA_QST' as TypeEnum,
    CH_VAT: 'CH_VAT' as TypeEnum,
    CL_TIN: 'CL_TIN' as TypeEnum,
    CN_TIN: 'CN_TIN' as TypeEnum,
    CO_NIT: 'CO_NIT' as TypeEnum,
    CR_TIN: 'CR_TIN' as TypeEnum,
    DO_RCN: 'DO_RCN' as TypeEnum,
    EC_RUC: 'EC_RUC' as TypeEnum,
    EG_TIN: 'EG_TIN' as TypeEnum,
    ES_CIF: 'ES_CIF' as TypeEnum,
    EU_OSS_VAT: 'EU_OSS_VAT' as TypeEnum,
    EU_VAT: 'EU_VAT' as TypeEnum,
    GB_VAT: 'GB_VAT' as TypeEnum,
    GE_VAT: 'GE_VAT' as TypeEnum,
    HK_BR: 'HK_BR' as TypeEnum,
    HU_TIN: 'HU_TIN' as TypeEnum,
    ID_NPWP: 'ID_NPWP' as TypeEnum,
    IL_VAT: 'IL_VAT' as TypeEnum,
    IN_GST: 'IN_GST' as TypeEnum,
    IS_VAT: 'IS_VAT' as TypeEnum,
    JP_CN: 'JP_CN' as TypeEnum,
    JP_RN: 'JP_RN' as TypeEnum,
    JP_TRN: 'JP_TRN' as TypeEnum,
    KE_PIN: 'KE_PIN' as TypeEnum,
    KR_BRN: 'KR_BRN' as TypeEnum,
    KZ_BIN: 'KZ_BIN' as TypeEnum,
    LI_UID: 'LI_UID' as TypeEnum,
    MX_RFC: 'MX_RFC' as TypeEnum,
    MY_FRP: 'MY_FRP' as TypeEnum,
    MY_ITN: 'MY_ITN' as TypeEnum,
    MY_SST: 'MY_SST' as TypeEnum,
    NG_TIN: 'NG_TIN' as TypeEnum,
    NO_VAT: 'NO_VAT' as TypeEnum,
    NO_VOEC: 'NO_VOEC' as TypeEnum,
    NZ_GST: 'NZ_GST' as TypeEnum,
    OM_VAT: 'OM_VAT' as TypeEnum,
    PE_RUC: 'PE_RUC' as TypeEnum,
    PH_TIN: 'PH_TIN' as TypeEnum,
    RO_TIN: 'RO_TIN' as TypeEnum,
    RS_PIB: 'RS_PIB' as TypeEnum,
    RU_INN: 'RU_INN' as TypeEnum,
    RU_KPP: 'RU_KPP' as TypeEnum,
    SA_VAT: 'SA_VAT' as TypeEnum,
    SG_GST: 'SG_GST' as TypeEnum,
    SG_UEN: 'SG_UEN' as TypeEnum,
    SI_TIN: 'SI_TIN' as TypeEnum,
    SV_NIT: 'SV_NIT' as TypeEnum,
    TH_VAT: 'TH_VAT' as TypeEnum,
    TR_TIN: 'TR_TIN' as TypeEnum,
    TW_VAT: 'TW_VAT' as TypeEnum,
    UA_VAT: 'UA_VAT' as TypeEnum,
    US_EIN: 'US_EIN' as TypeEnum,
    UY_RUC: 'UY_RUC' as TypeEnum,
    VE_RIF: 'VE_RIF' as TypeEnum,
    VN_TIN: 'VN_TIN' as TypeEnum,
    ZA_VAT: 'ZA_VAT' as TypeEnum,
  };
}
