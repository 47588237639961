import {TitleCasePipe} from '@angular/common';
import {
  format,
  getDay,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parse,
} from 'date-fns';

import {equals} from '../util/array.util';

export interface OpeningDates {
  dates: Date[];
  openingDays: OpeningDay[];
}

export interface OpeningDay {
  day: string;
  open: boolean;
  ranges: TimeRange[];
  text: string;
}

export interface OpeningDayLine {
  dates: Date[];
  days: string[];
  open: boolean;
  ranges: TimeRange[];
  text: string;
}

export interface TimeRange {
  from: Date | null;
  to: Date | null;
}

export const DAYS_OF_WEEK = Object.freeze(['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']);

export class OpeningHoursSerializer {
  static serialize(openingDates: OpeningDates[]): string {
    let days: string[] = [];
    for (const openingDate of openingDates || []) {
      days = [...days, ...this._serializeDayRange(openingDate.dates, openingDate.openingDays)];
    }

    return days.filter((day) => !!day).join(';');
  }

  private static _serializeDayRange(dates: Date[], openingDays: OpeningDay[]): string[] {
    const days = [];
    for (const openingDay of openingDays) {
      days.push(this._serializeDay({...openingDay, dates, days: [openingDay.day]}));
    }

    return days;
  }

  private static _serializeDay(openingDay: OpeningDayLine): string {
    let ranges = [];
    for (const range of openingDay?.ranges || []) {
      ranges.push(this._serializeRange(range));
    }

    ranges = ranges.filter((day) => !!day);

    if (!ranges.length && openingDay.open) {
      return '';
    }

    const dates = openingDay.dates.map((date) => this._serializeDate(date));
    const datesString = dates.length ? dates.join('-') + ' ' : '';

    const titleCasePipe = new TitleCasePipe();
    const days = openingDay.days.map((day) => titleCasePipe.transform(day));

    const text = openingDay.text ? ` "${openingDay.text}"` : '';

    const serializedRange = openingDay.open ? ranges.join(',') : 'off';

    return `${datesString}${days.join(',')} ${serializedRange}${text}`;
  }

  private static _serializeRange(range: {from: Date | null; to: Date | null}): string {
    try {
      return `${this._serializeTime(range.from)}-${this._serializeTime(range.to)}`;
    } catch {
      return '';
    }
  }

  private static _serializeTime(time: Date | null): string {
    return format(time || 0, 'HH:mm');
  }

  private static _serializeDate(date: Date): string {
    return format(date, 'yyyy MMM dd');
  }
}

export class OpeningHoursParser {
  static parse(value: string | undefined): OpeningDates[] {
    if (!value?.length) {
      return [];
    }

    const days = value.split(';');

    let parsedLines: OpeningDayLine[] = [];
    for (const day of days) {
      const parsedLine = this._parseLine(day);
      if (parsedLine) {
        parsedLines.push(parsedLine);
      }
    }

    if (!parsedLines.find((pl) => !pl.dates.length)) {
      parsedLines = [{dates: [], days: [], open: true, ranges: [], text: ''}, ...parsedLines];
    }

    const openingDatesExpanded: {
      dates: Date[];
      openingDays: OpeningDayLine[];
    }[] = [];
    for (const parsedLine of parsedLines) {
      openingDatesExpanded.push({
        dates: parsedLine.dates,
        openingDays: [parsedLine],
      });
    }

    const openingDatesCollapsed: {
      dates: Date[];
      openingDays: OpeningDayLine[];
    }[] = openingDatesExpanded.reduce(
      (ranges, current) => {
        const day = ranges.find((d) => equals(d.dates, current.dates));
        if (day) {
          day.openingDays.push(...current.openingDays);
        } else {
          ranges.push({
            dates: current.dates,
            openingDays: current.openingDays,
          });
        }

        return ranges;
      },
      <
        {
          dates: Date[];
          openingDays: OpeningDayLine[];
        }[]
      >[]
    );

    const openingDates: OpeningDates[] = [];
    for (const openingDateLine of openingDatesCollapsed) {
      const openingDate = <OpeningDates>{
        dates: openingDateLine.dates,
        openingDays: [],
      };
      openingDates.push(openingDate);
      for (const dayOfWeek of DAYS_OF_WEEK) {
        const openingDayLines = openingDateLine.openingDays.filter((od) => {
          if (od?.days) {
            return od.days.find((d) => d === dayOfWeek);
          } else {
            return false;
          }
        });
        if (days.length) {
          const lastLine = openingDayLines[openingDayLines.length - 1];
          if (!openingDate.dates.length || openingDayLines.length) {
            openingDate.openingDays.push({
              day: dayOfWeek,
              open: lastLine?.open || false,
              ranges: lastLine?.ranges?.length ? lastLine.ranges : [{from: null, to: null}],
              text: lastLine?.text || '',
            });
          }
        } else {
          openingDate.openingDays.push({
            day: dayOfWeek,
            open: false,
            ranges: [],
            text: '',
          });
        }
      }
    }

    return openingDates;
  }

  private static _parseLine(day: string): OpeningDayLine | undefined {
    if (!day) {
      return undefined;
    }

    let text = '';
    if (day.indexOf('"') > -1) {
      const daySplit = day.split('"');
      day = daySplit[0].substring(0, daySplit[0].length - 1);
      text = daySplit[1];
    }

    const dayParts = day.split(' ');
    let dayPart = dayParts.pop();

    const ranges = [];
    if (dayPart !== 'off' && dayPart) {
      const hours = dayPart.split(',');
      for (const hour of hours) {
        const [from, to] = hour.split('-');
        const fromTime = this._parseTime(from);
        const toTime = this._parseTime(to);
        ranges.push({from: fromTime, to: toTime});
      }
    }

    dayPart = dayParts.pop();
    const days = (dayPart || '').split(',').flatMap((d) => {
      const dayRanges = d.split('-');
      if (dayRanges.length === 1) {
        return dayRanges;
      } else {
        const startIndex = DAYS_OF_WEEK.indexOf(dayRanges[0]);
        const endIndex = DAYS_OF_WEEK.indexOf(dayRanges[1]);
        const daysOfWeekExclLowerOutOfRange = DAYS_OF_WEEK.slice(startIndex);
        const daysOfWeekExclOutOfRange = daysOfWeekExclLowerOutOfRange.splice(0, endIndex + 1);
        return daysOfWeekExclOutOfRange;
      }
    });

    dayPart = dayParts.join(' ');
    const dates = [];
    if (dayPart) {
      const dateStrings = dayPart.split('-');
      for (const date of dateStrings) {
        dates.push(this._parseDate(date));
      }
    }

    if (dates.some((date) => !isValid(date))) {
      return undefined;
    }

    return {dates, days, open: !!ranges.length, ranges, text};
  }

  private static _parseTime(time: string): Date {
    const emptyDate = new Date(1970, 0, 1, 0, 0, 0);
    try {
      return parse(time, 'HH:mm', emptyDate);
    } catch {
      return emptyDate;
    }
  }

  private static _parseDate(date: string): Date {
    const emptyDate = new Date(1970, 0, 1, 0, 0, 0);
    try {
      return parse(date, 'yyyy MMM dd', emptyDate);
    } catch {
      return emptyDate;
    }
  }
}

/**
 * Checks whether the provided openingDates are equal to being always open (24/7 open)
 * Special opening hours are excluded from this check
 * @param openingDates the ranges to check
 * @returns
 */
export function isAlwaysOpen(openingDates: OpeningDates[]): boolean {
  const openingDate = openingDates.find((o) => o?.dates?.length === 0);

  if (!openingDate) {
    return false;
  }

  for (const openingDay of openingDate.openingDays) {
    if (openingDay?.ranges?.length !== 1) {
      return false;
    }

    const range = openingDay.ranges[0];
    if (
      !range.from ||
      !range.to ||
      !(getHours(range.from) === 0 && getMinutes(range.from) === 0) ||
      !(getHours(range.to) === 23 && getMinutes(range.to) === 59)
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Checks whether the dateTime matches an OpeningDay
 * @param dateTime the dateTime to check against
 * @param openingDates the ranges to search in
 * @returns the dates, day, range, open and text related to where the dateTime is between,
 * if there's no match it returns undefined
 */
export function matchingOpeningHours(
  dateTime: Date,
  openingDates: OpeningDates[]
):
  | {
      dates: Date[];
      day: string;
      range: {from: Date | null; to: Date | null};
      open: boolean;
      text: string;
    }
  | undefined {
  const matchingOpeningDates = [...openingDates].reverse().filter((openingDate) => {
    if (openingDate?.dates?.length === 0) {
      return true;
    } else if (openingDate?.dates?.length === 2) {
      const start = openingDate?.dates[0];
      const end = openingDate?.dates[1];
      return isAfter(dateTime, start) && isBefore(dateTime, end);
    } else {
      return isSameDay(dateTime, openingDate?.dates[0]);
    }
  });

  if (!matchingOpeningDates.length) {
    return undefined;
  }

  const dayMatch = (time: Date, openingDay: OpeningDay) => {
    switch (getDay(time)) {
      case 0:
        return openingDay.day === 'Su';
      case 1:
        return openingDay.day === 'Mo';
      case 2:
        return openingDay.day === 'Tu';
      case 3:
        return openingDay.day === 'We';
      case 4:
        return openingDay.day === 'Th';
      case 5:
        return openingDay.day === 'Fr';
      case 6:
        return openingDay.day === 'Sa';
    }
  };

  for (const openingDate of matchingOpeningDates) {
    for (const openingDay of openingDate.openingDays) {
      if (!dayMatch(dateTime, openingDay)) {
        continue;
      }

      for (const range of openingDay.ranges) {
        if (range.from === null && range.to === null) {
          return {
            dates: openingDate.dates,
            day: openingDay.day,
            open: openingDay.open,
            range: range,
            text: openingDay.text,
          };
        }

        const time = new Date(
          1970,
          0,
          1,
          dateTime.getHours(),
          dateTime.getMinutes(),
          dateTime.getSeconds()
        );
        if (isAfter(time, range.from || 0) && isBefore(time, range.to || 0)) {
          return {
            dates: openingDate.dates,
            day: openingDay.day,
            open: openingDay.open,
            range: range,
            text: openingDay.text,
          };
        }
      }
    }
  }
  return undefined;
}

/**
 * Checks whether the dateTime matches an open OpeningDay
 * @param dateTime the dateTime to check against
 * @param openingDates the ranges to search in
 * @returns a boolean
 */
export function isOpen(dateTime: Date, openingDates: OpeningDates[]): boolean {
  return matchingOpeningHours(dateTime, openingDates)?.open || false;
}
