export enum SnackbarType {
  GROUP_SHARE = 'GROUP_SHARE',
  PROFILE_SHARE = 'PROFILE_SHARE',
  PROFILE_REQUEST = 'PROFILE_REQUEST',
  PROFILE_VISIBILITY = 'PROFILE_VISIBILITY',
  FIRST_VISIT_SUBSCRIBE = 'FIRST_VISIT_SUBSCRIBE',
  FIRST_VISIT_ORGANISATIONS = 'FIRST_VISIT_ORGANISATIONS',
  FIRST_VISIT_IND_PROPOSALS = 'FIRST_VISIT_IND_PROPOSALS',
  FIRST_VISIT_IND_AGREEMENTS = 'FIRST_VISIT_IND_AGREEMENTS',
  FIRST_VISIT_ORG_PROPOSALS = 'FIRST_VISIT_ORG_PROPOSALS',
}
