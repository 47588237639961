/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {ContextRepresentation} from '../model/contextRepresentation';
// @ts-ignore
import {PropertyRepresentation} from '../model/propertyRepresentation';
// @ts-ignore
import {TypeRequestInfo} from '../model/typeRequestInfo';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class ContextsService {
  protected basePath = '/r';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Add property to context
   * @param contextId
   * @param propertyRepresentation property representation to add
   * @param validation Validate properties &amp; check context limits
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addProperty(
    contextId: string,
    propertyRepresentation: PropertyRepresentation,
    validation?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<PropertyRepresentation>;
  public addProperty(
    contextId: string,
    propertyRepresentation: PropertyRepresentation,
    validation?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<PropertyRepresentation>>;
  public addProperty(
    contextId: string,
    propertyRepresentation: PropertyRepresentation,
    validation?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<PropertyRepresentation>>;
  public addProperty(
    contextId: string,
    propertyRepresentation: PropertyRepresentation,
    validation?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (contextId === null || contextId === undefined) {
      throw new Error(
        'Required parameter contextId was null or undefined when calling addProperty.'
      );
    }
    if (propertyRepresentation === null || propertyRepresentation === undefined) {
      throw new Error(
        'Required parameter propertyRepresentation was null or undefined when calling addProperty.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (validation !== undefined && validation !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>validation,
        'validation'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/contexts/${this.configuration.encodeParam({
      name: 'contextId',
      value: contextId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/properties`;
    return this.httpClient.request<PropertyRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: propertyRepresentation,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Delete a context
   * @param contextId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteContext(
    contextId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: '*/*'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any>;
  public deleteContext(
    contextId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: '*/*'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpResponse<any>>;
  public deleteContext(
    contextId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: '*/*'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpEvent<any>>;
  public deleteContext(
    contextId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: '*/*'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any> {
    if (contextId === null || contextId === undefined) {
      throw new Error(
        'Required parameter contextId was null or undefined when calling deleteContext.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/contexts/${this.configuration.encodeParam({
      name: 'contextId',
      value: contextId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get a context by ID
   * @param contextId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getContext(
    contextId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ContextRepresentation>;
  public getContext(
    contextId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ContextRepresentation>>;
  public getContext(
    contextId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ContextRepresentation>>;
  public getContext(
    contextId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (contextId === null || contextId === undefined) {
      throw new Error(
        'Required parameter contextId was null or undefined when calling getContext.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/contexts/${this.configuration.encodeParam({
      name: 'contextId',
      value: contextId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ContextRepresentation>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Request a property of certain type
   * @param contextId
   * @param propertyType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestType(
    contextId: string,
    propertyType:
      | 'USERNAME'
      | 'FIRST_NAME'
      | 'MIDDLE_NAME'
      | 'LAST_NAME'
      | 'NAME'
      | 'AVATAR'
      | 'GENDER'
      | 'BIRTHDAY'
      | 'FOUNDED'
      | 'DISSOLVED'
      | 'DAY'
      | 'MONTH'
      | 'YEAR'
      | 'EMAIL'
      | 'MOBILE_PHONE'
      | 'PHONE'
      | 'FAX'
      | 'WEBSITE'
      | 'BIC'
      | 'JOB_TITLE'
      | 'INDUSTRY'
      | 'GROUP'
      | 'REGION'
      | 'ADDRESS'
      | 'STREET'
      | 'COUNTRY'
      | 'BUILDING'
      | 'BOX'
      | 'LOCALITY'
      | 'NUMBER'
      | 'ZIP'
      | 'DISTRICT'
      | 'PROVINCE'
      | 'SUBURB'
      | 'STATE'
      | 'COUNTY'
      | 'LAT_LONG'
      | 'FACEBOOK'
      | 'YOUTUBE'
      | 'INSTAGRAM'
      | 'WHATSAPP'
      | 'WECHAT'
      | 'TIKTOK'
      | 'FACEBOOK_MESSENGER'
      | 'TELEGRAM'
      | 'SNAPCHAT'
      | 'PINTEREST'
      | 'TWITTER'
      | 'REDDIT'
      | 'LINKEDIN'
      | 'VIMEO'
      | 'DISCORD'
      | 'TWITCH'
      | 'TUMBLR'
      | 'MASTODON'
      | 'PATREON'
      | 'KICKSTARTER'
      | 'INDIEGOGO'
      | 'GOFUNDME'
      | 'TAKEAWAY'
      | 'UBEREATS'
      | 'DELIVEROO'
      | 'SHIPTO'
      | 'WEIBO'
      | 'QQ'
      | 'KUAISHOU'
      | 'QZONE'
      | 'SKYPE'
      | 'NOTE'
      | 'ORGANISATION_NAME'
      | 'LEGAL_NAME'
      | 'LEGAL_FORM'
      | 'LEGAL_ID'
      | 'COMPANY_NUMBER'
      | 'JURISDICTION'
      | 'IMPORT_SOURCE'
      | 'PROPOSAL'
      | 'CATEGORY'
      | 'OPENING_HOURS'
      | 'CUSTOM'
      | 'FILE'
      | 'IMAGE'
      | 'FILE_KEY'
      | 'FILE_NAME'
      | 'TERMS_AND_CONDITIONS'
      | 'PRIVACY_POLICY'
      | 'DOCUMENT'
      | 'DOCUMENT_NAME'
      | 'ORGANISATION_TYPE',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<TypeRequestInfo>;
  public requestType(
    contextId: string,
    propertyType:
      | 'USERNAME'
      | 'FIRST_NAME'
      | 'MIDDLE_NAME'
      | 'LAST_NAME'
      | 'NAME'
      | 'AVATAR'
      | 'GENDER'
      | 'BIRTHDAY'
      | 'FOUNDED'
      | 'DISSOLVED'
      | 'DAY'
      | 'MONTH'
      | 'YEAR'
      | 'EMAIL'
      | 'MOBILE_PHONE'
      | 'PHONE'
      | 'FAX'
      | 'WEBSITE'
      | 'BIC'
      | 'JOB_TITLE'
      | 'INDUSTRY'
      | 'GROUP'
      | 'REGION'
      | 'ADDRESS'
      | 'STREET'
      | 'COUNTRY'
      | 'BUILDING'
      | 'BOX'
      | 'LOCALITY'
      | 'NUMBER'
      | 'ZIP'
      | 'DISTRICT'
      | 'PROVINCE'
      | 'SUBURB'
      | 'STATE'
      | 'COUNTY'
      | 'LAT_LONG'
      | 'FACEBOOK'
      | 'YOUTUBE'
      | 'INSTAGRAM'
      | 'WHATSAPP'
      | 'WECHAT'
      | 'TIKTOK'
      | 'FACEBOOK_MESSENGER'
      | 'TELEGRAM'
      | 'SNAPCHAT'
      | 'PINTEREST'
      | 'TWITTER'
      | 'REDDIT'
      | 'LINKEDIN'
      | 'VIMEO'
      | 'DISCORD'
      | 'TWITCH'
      | 'TUMBLR'
      | 'MASTODON'
      | 'PATREON'
      | 'KICKSTARTER'
      | 'INDIEGOGO'
      | 'GOFUNDME'
      | 'TAKEAWAY'
      | 'UBEREATS'
      | 'DELIVEROO'
      | 'SHIPTO'
      | 'WEIBO'
      | 'QQ'
      | 'KUAISHOU'
      | 'QZONE'
      | 'SKYPE'
      | 'NOTE'
      | 'ORGANISATION_NAME'
      | 'LEGAL_NAME'
      | 'LEGAL_FORM'
      | 'LEGAL_ID'
      | 'COMPANY_NUMBER'
      | 'JURISDICTION'
      | 'IMPORT_SOURCE'
      | 'PROPOSAL'
      | 'CATEGORY'
      | 'OPENING_HOURS'
      | 'CUSTOM'
      | 'FILE'
      | 'IMAGE'
      | 'FILE_KEY'
      | 'FILE_NAME'
      | 'TERMS_AND_CONDITIONS'
      | 'PRIVACY_POLICY'
      | 'DOCUMENT'
      | 'DOCUMENT_NAME'
      | 'ORGANISATION_TYPE',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<TypeRequestInfo>>;
  public requestType(
    contextId: string,
    propertyType:
      | 'USERNAME'
      | 'FIRST_NAME'
      | 'MIDDLE_NAME'
      | 'LAST_NAME'
      | 'NAME'
      | 'AVATAR'
      | 'GENDER'
      | 'BIRTHDAY'
      | 'FOUNDED'
      | 'DISSOLVED'
      | 'DAY'
      | 'MONTH'
      | 'YEAR'
      | 'EMAIL'
      | 'MOBILE_PHONE'
      | 'PHONE'
      | 'FAX'
      | 'WEBSITE'
      | 'BIC'
      | 'JOB_TITLE'
      | 'INDUSTRY'
      | 'GROUP'
      | 'REGION'
      | 'ADDRESS'
      | 'STREET'
      | 'COUNTRY'
      | 'BUILDING'
      | 'BOX'
      | 'LOCALITY'
      | 'NUMBER'
      | 'ZIP'
      | 'DISTRICT'
      | 'PROVINCE'
      | 'SUBURB'
      | 'STATE'
      | 'COUNTY'
      | 'LAT_LONG'
      | 'FACEBOOK'
      | 'YOUTUBE'
      | 'INSTAGRAM'
      | 'WHATSAPP'
      | 'WECHAT'
      | 'TIKTOK'
      | 'FACEBOOK_MESSENGER'
      | 'TELEGRAM'
      | 'SNAPCHAT'
      | 'PINTEREST'
      | 'TWITTER'
      | 'REDDIT'
      | 'LINKEDIN'
      | 'VIMEO'
      | 'DISCORD'
      | 'TWITCH'
      | 'TUMBLR'
      | 'MASTODON'
      | 'PATREON'
      | 'KICKSTARTER'
      | 'INDIEGOGO'
      | 'GOFUNDME'
      | 'TAKEAWAY'
      | 'UBEREATS'
      | 'DELIVEROO'
      | 'SHIPTO'
      | 'WEIBO'
      | 'QQ'
      | 'KUAISHOU'
      | 'QZONE'
      | 'SKYPE'
      | 'NOTE'
      | 'ORGANISATION_NAME'
      | 'LEGAL_NAME'
      | 'LEGAL_FORM'
      | 'LEGAL_ID'
      | 'COMPANY_NUMBER'
      | 'JURISDICTION'
      | 'IMPORT_SOURCE'
      | 'PROPOSAL'
      | 'CATEGORY'
      | 'OPENING_HOURS'
      | 'CUSTOM'
      | 'FILE'
      | 'IMAGE'
      | 'FILE_KEY'
      | 'FILE_NAME'
      | 'TERMS_AND_CONDITIONS'
      | 'PRIVACY_POLICY'
      | 'DOCUMENT'
      | 'DOCUMENT_NAME'
      | 'ORGANISATION_TYPE',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<TypeRequestInfo>>;
  public requestType(
    contextId: string,
    propertyType:
      | 'USERNAME'
      | 'FIRST_NAME'
      | 'MIDDLE_NAME'
      | 'LAST_NAME'
      | 'NAME'
      | 'AVATAR'
      | 'GENDER'
      | 'BIRTHDAY'
      | 'FOUNDED'
      | 'DISSOLVED'
      | 'DAY'
      | 'MONTH'
      | 'YEAR'
      | 'EMAIL'
      | 'MOBILE_PHONE'
      | 'PHONE'
      | 'FAX'
      | 'WEBSITE'
      | 'BIC'
      | 'JOB_TITLE'
      | 'INDUSTRY'
      | 'GROUP'
      | 'REGION'
      | 'ADDRESS'
      | 'STREET'
      | 'COUNTRY'
      | 'BUILDING'
      | 'BOX'
      | 'LOCALITY'
      | 'NUMBER'
      | 'ZIP'
      | 'DISTRICT'
      | 'PROVINCE'
      | 'SUBURB'
      | 'STATE'
      | 'COUNTY'
      | 'LAT_LONG'
      | 'FACEBOOK'
      | 'YOUTUBE'
      | 'INSTAGRAM'
      | 'WHATSAPP'
      | 'WECHAT'
      | 'TIKTOK'
      | 'FACEBOOK_MESSENGER'
      | 'TELEGRAM'
      | 'SNAPCHAT'
      | 'PINTEREST'
      | 'TWITTER'
      | 'REDDIT'
      | 'LINKEDIN'
      | 'VIMEO'
      | 'DISCORD'
      | 'TWITCH'
      | 'TUMBLR'
      | 'MASTODON'
      | 'PATREON'
      | 'KICKSTARTER'
      | 'INDIEGOGO'
      | 'GOFUNDME'
      | 'TAKEAWAY'
      | 'UBEREATS'
      | 'DELIVEROO'
      | 'SHIPTO'
      | 'WEIBO'
      | 'QQ'
      | 'KUAISHOU'
      | 'QZONE'
      | 'SKYPE'
      | 'NOTE'
      | 'ORGANISATION_NAME'
      | 'LEGAL_NAME'
      | 'LEGAL_FORM'
      | 'LEGAL_ID'
      | 'COMPANY_NUMBER'
      | 'JURISDICTION'
      | 'IMPORT_SOURCE'
      | 'PROPOSAL'
      | 'CATEGORY'
      | 'OPENING_HOURS'
      | 'CUSTOM'
      | 'FILE'
      | 'IMAGE'
      | 'FILE_KEY'
      | 'FILE_NAME'
      | 'TERMS_AND_CONDITIONS'
      | 'PRIVACY_POLICY'
      | 'DOCUMENT'
      | 'DOCUMENT_NAME'
      | 'ORGANISATION_TYPE',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (contextId === null || contextId === undefined) {
      throw new Error(
        'Required parameter contextId was null or undefined when calling requestType.'
      );
    }
    if (propertyType === null || propertyType === undefined) {
      throw new Error(
        'Required parameter propertyType was null or undefined when calling requestType.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/contexts/${this.configuration.encodeParam({
      name: 'contextId',
      value: contextId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/properties/request/${this.configuration.encodeParam({
      name: 'propertyType',
      value: propertyType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'USERNAME' | 'FIRST_NAME' | 'MIDDLE_NAME' | 'LAST_NAME' | 'NAME' | 'AVATAR' | 'GENDER' | 'BIRTHDAY' | 'FOUNDED' | 'DISSOLVED' | 'DAY' | 'MONTH' | 'YEAR' | 'EMAIL' | 'MOBILE_PHONE' | 'PHONE' | 'FAX' | 'WEBSITE' | 'BIC' | 'JOB_TITLE' | 'INDUSTRY' | 'GROUP' | 'REGION' | 'ADDRESS' | 'STREET' | 'COUNTRY' | 'BUILDING' | 'BOX' | 'LOCALITY' | 'NUMBER' | 'ZIP' | 'DISTRICT' | 'PROVINCE' | 'SUBURB' | 'STATE' | 'COUNTY' | 'LAT_LONG' | 'FACEBOOK' | 'YOUTUBE' | 'INSTAGRAM' | 'WHATSAPP' | 'WECHAT' | 'TIKTOK' | 'FACEBOOK_MESSENGER' | 'TELEGRAM' | 'SNAPCHAT' | 'PINTEREST' | 'TWITTER' | 'REDDIT' | 'LINKEDIN' | 'VIMEO' | 'DISCORD' | 'TWITCH' | 'TUMBLR' | 'MASTODON' | 'PATREON' | 'KICKSTARTER' | 'INDIEGOGO' | 'GOFUNDME' | 'TAKEAWAY' | 'UBEREATS' | 'DELIVEROO' | 'SHIPTO' | 'WEIBO' | 'QQ' | 'KUAISHOU' | 'QZONE' | 'SKYPE' | 'NOTE' | 'ORGANISATION_NAME' | 'LEGAL_NAME' | 'LEGAL_FORM' | 'LEGAL_ID' | 'COMPANY_NUMBER' | 'JURISDICTION' | 'IMPORT_SOURCE' | 'PROPOSAL' | 'CATEGORY' | 'OPENING_HOURS' | 'CUSTOM' | 'FILE' | 'IMAGE' | 'FILE_KEY' | 'FILE_NAME' | 'TERMS_AND_CONDITIONS' | 'PRIVACY_POLICY' | 'DOCUMENT' | 'DOCUMENT_NAME' | 'ORGANISATION_TYPE'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<TypeRequestInfo>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update existing context
   * @param contextId
   * @param contextRepresentation context representation with updated values
   * @param validation Validate properties &amp; check context limits
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateContext(
    contextId: string,
    contextRepresentation: ContextRepresentation,
    validation?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ContextRepresentation>;
  public updateContext(
    contextId: string,
    contextRepresentation: ContextRepresentation,
    validation?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ContextRepresentation>>;
  public updateContext(
    contextId: string,
    contextRepresentation: ContextRepresentation,
    validation?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ContextRepresentation>>;
  public updateContext(
    contextId: string,
    contextRepresentation: ContextRepresentation,
    validation?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (contextId === null || contextId === undefined) {
      throw new Error(
        'Required parameter contextId was null or undefined when calling updateContext.'
      );
    }
    if (contextRepresentation === null || contextRepresentation === undefined) {
      throw new Error(
        'Required parameter contextRepresentation was null or undefined when calling updateContext.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (validation !== undefined && validation !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>validation,
        'validation'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/contexts/${this.configuration.encodeParam({
      name: 'contextId',
      value: contextId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ContextRepresentation>(
      'put',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: contextRepresentation,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }
}
