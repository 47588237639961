import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {AgreementState} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'agreementStateLabel',
  pure: false,
})
export class AgreementStateLabelPipe implements PipeTransform {
  private _translatePipe: TranslatePipe;
  constructor(
    translate: TranslateService,
    changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    languageOverride$?: BehaviorSubject<string | undefined>
  ) {
    this._translatePipe = new TranslatePipe(translate, changeDetector, languageOverride$);
  }

  transform(state: AgreementState | undefined): string {
    let translateKey = '';
    switch (state) {
      case AgreementState.CONFIRMED:
        translateKey = 'agreement.status.confirmed';
        break;
      case AgreementState.UNCONFIRMED:
        translateKey = 'agreement.status.not-confirmed';
        break;
      case AgreementState.EXPIRED:
        translateKey = 'agreement.expired';
        break;
      default:
        break;
    }
    return this._translatePipe.transform(translateKey);
  }
}
