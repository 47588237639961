import {ChangeDetectorRef, Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {Property, PropertyType} from '@aztrix/models';
import {ProfilePropertyRepresentation, PropertyRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

import {propertyTypeLabel$} from '../../helpers/property-functions';
import {LANGUAGE_OVERRIDE, TranslateService} from '@aztrix/translate';

/**
 * Transforms a `property` OR `property type` into its `property type label`.
 **/
@Pipe({name: 'propertyTypeLabel', pure: false})
export class PropertyTypeLabelPipe implements PipeTransform, OnDestroy {
  private _type?: PropertyType;
  private _latestValue?: string;
  private _latestReturnedValue?: string;

  private _subscription = new Subscription();

  constructor(
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}
  /**
   * @param propertyOrPropertyType the `property` OR the `property type` to get the label for.
   */
  transform(
    propertyOrPropertyType:
      | Property
      | PropertyRepresentation
      | ProfilePropertyRepresentation
      | PropertyType
      | string
      | undefined
  ): any {
    if (!this._type) {
      this._type = this._getType(propertyOrPropertyType);

      const observable$ = this._translate$(this._type, this._languageOverride$?.value);
      this._subscribe(observable$);

      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    const type = this._getType(propertyOrPropertyType);
    if (this._type !== type) {
      this._dispose();
      return this.transform(type);
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  ngOnDestroy() {
    this._dispose();
  }

  private _translate$(type?: PropertyType, lang?: string): Observable<string> {
    return propertyTypeLabel$(this._translate, type, lang);
  }

  private _getType(
    propertyOrPropertyType:
      | Property
      | PropertyRepresentation
      | ProfilePropertyRepresentation
      | PropertyType
      | string
      | undefined
  ): PropertyType {
    return <PropertyType>(
      (typeof propertyOrPropertyType === 'string' || !propertyOrPropertyType
        ? <PropertyType>propertyOrPropertyType
        : propertyOrPropertyType.type)
    );
  }

  private _subscribe(obs: Observable<any>): void {
    this._subscription.add(
      obs.subscribe((value) => {
        this._latestValue = value;
        this._changeDetector.markForCheck();
      })
    );
  }

  private _dispose() {
    this._subscription.unsubscribe();
    this._type = undefined;
    this._latestValue = undefined;
    this._latestReturnedValue = undefined;
  }
}
