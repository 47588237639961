import {Pipe, PipeTransform} from '@angular/core';
import {PropertyType} from '@aztrix/models';

import {getPrefixForPropertyType} from '../../helpers/property-functions';

@Pipe({name: 'prefixForPropertyType'})
export class PrefixForPropertyTypePipe implements PipeTransform {
  transform(type: PropertyType): string {
    return getPrefixForPropertyType(type);
  }
}
