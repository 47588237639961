import {Profile} from '@aztrix/models';

export function idFilter<T extends {id?: string; targetId?: string}>(
  id: string
): (subject: T | undefined) => boolean {
  return (subject) => {
    let match = subject?.id === id;
    if (subject?.targetId) {
      match = subject?.targetId === id;
    }
    return match;
  };
}

export function existingFilter<T extends {id?: string}>(subject?: T): boolean {
  return !!subject?.id;
}

export function typeFilter<T extends Partial<{type: U}>, U>(
  ...types: Array<U | null | undefined>
): (subject: T | null | undefined) => boolean {
  return (subject) => (subject ? (types || []).includes(subject.type) : false);
}

export function isOfType<T extends Partial<{type: U}>, U>(
  subject: T | null | undefined,
  ...types: Array<U>
): boolean {
  return typeFilter(...types)(subject);
}

export function seenFilter<T extends {seen: boolean}>(subject: T): boolean {
  return subject?.seen;
}

export function unseenFilter<T extends {seen: boolean}>(subject: T): boolean {
  return !subject?.seen;
}

export function profileFilter(profile: Profile): (subject: any) => boolean {
  return (subject) => {
    const {profileId, targetId} = subject;
    return [profileId, targetId].some((id) => !!id && [profile.id, profile.targetId].includes(id));
  };
}
