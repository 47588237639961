import {
  AlistItemRepresentation,
  AlistItemWithItemsRepresentation,
  AlistItemWithPropertiesRepresentation,
  AlistItemWithPropertyRepresentation,
  AlistItemWithStringRepresentation,
  AlistPropertyRepresentation,
  AlistSubPropertyRepresentation,
  ProfilePropertyRepresentation,
  PropertyRepresentation,
  ProposalRepresentation,
  SubPropertyRepresentation,
  TierSubscriptionInfoRepresentation,
} from '@aztrix/sdk';
import {ControlsOf, FormArray, FormControl, FormGroup, ValuesOf} from '@ngneat/reactive-forms';
import {map, Observable, of} from 'rxjs';

import {METADATA} from '../metadata/metadata';
import {subPropertyTypes} from '../metadata/property-type-provider';
import {deepEqual} from '../util/deep-equal.util';
import {iconNameForPropertyType} from '../util/icon.util';
import {
  hasValue,
  isSocialRelated,
  propertyCommunicationType,
  propertySocialName,
  propertyTypeLabel$,
} from './property-functions';
import {FeatureType, isFeatureAllowed} from './tier-functions';
import {TranslateService} from '@aztrix/translate';

export function itemCanBeDisplayed(
  item?: AlistItemRepresentation | ValuesOf<ControlsOf<AlistItemRepresentation>> | undefined
): boolean {
  if (!item) {
    return false;
  }

  switch (item.type) {
    case AlistItemWithPropertyRepresentation.TypeEnum.HEADER:
      return !!item.title;
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTY:
      return hasValue(<PropertyRepresentation>item.data);
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTIES: {
      const properties = <AlistPropertyRepresentation[]>item.data;
      return properties.length > 0;
    }
    default:
      return !!item.data;
  }
}

export function sortItems(items: AlistItemRepresentation[]): AlistItemRepresentation[] {
  return [...(items || [])].sort((item1, item2) =>
    (item1.orderIndex ?? Infinity) > (item2.orderIndex ?? Infinity) ? 1 : -1
  );
}

export function propertyToAlistProperty(
  property: ProfilePropertyRepresentation | AlistPropertyRepresentation | undefined
): AlistPropertyRepresentation | undefined {
  if (!property) {
    return undefined;
  }

  let alistProperty: AlistPropertyRepresentation = {
    type: property.type,
  };

  if (property.properties) {
    alistProperty = {
      ...alistProperty,
      properties: profileSubPropertiesToAlistSubProperties(property),
    };
  } else {
    alistProperty = {...alistProperty, value: property.value};
  }

  return alistProperty;
}

function profileSubPropertiesToAlistSubProperties(
  property: ProfilePropertyRepresentation
): AlistSubPropertyRepresentation[] {
  if (!property.properties) {
    return [];
  }
  const allowedProperties = subPropertyTypes(property);
  return property.properties
    .filter((subProperty) => {
      return allowedProperties.find((type) => type === subProperty.type);
    })
    .map(profileSubPropertyToAlistSubProperty);
}

function profileSubPropertyToAlistSubProperty(
  subProperty: SubPropertyRepresentation
): AlistSubPropertyRepresentation {
  return {type: subProperty.type, value: subProperty.value};
}

export function propertyTypesThatCanBeAdded(): PropertyRepresentation.TypeEnum[] {
  const metadataPropertyTypes = METADATA.propertyTypes;
  const subPropertyTypes = metadataPropertyTypes
    .map((propertyType) => {
      if (propertyType?.subTypesPerCountryCode) {
        return Object.values(propertyType?.subTypesPerCountryCode)[0];
      } else {
        return propertyType?.subTypes;
      }
    })
    .filter((pt) => pt)
    .flat()
    .map((t) => t?.name);

  return <PropertyRepresentation.TypeEnum[]>(
    metadataPropertyTypes
      .map((propertyType) => propertyType.name)
      .filter((propertyType) => !subPropertyTypes.includes(propertyType))
  );
}

export function itemDataType(
  type:
    | AlistItemWithPropertyRepresentation.TypeEnum
    | AlistItemWithStringRepresentation.TypeEnum
    | AlistItemWithItemsRepresentation.TypeEnum
    | undefined
):
  | AlistItemWithPropertyRepresentation.DataTypeEnum
  | AlistItemWithStringRepresentation.DataTypeEnum
  | AlistItemWithPropertiesRepresentation.DataTypeEnum {
  switch (type) {
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTY:
      return AlistItemWithPropertyRepresentation.DataTypeEnum.PROPERTY;
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTIES:
      return AlistItemWithPropertiesRepresentation.DataTypeEnum.ITEMS;
    case AlistItemWithPropertyRepresentation.TypeEnum.PRODUCT_CARD:
    case AlistItemWithItemsRepresentation.TypeEnum.ITEMS:
      return AlistItemWithPropertiesRepresentation.DataTypeEnum.ITEMS;
    default:
      return AlistItemWithPropertyRepresentation.DataTypeEnum.STRING;
  }
}

export function itemIsLegalRelated(value: AlistItemRepresentation): boolean {
  if (!value.data) {
    return false;
  }
  if (typeof value.data === 'string' || Array.isArray(value.data) || value.data?.type) {
    return false;
  } else {
    if (value.data.type) {
      return (
        value.dataType === AlistItemWithPropertyRepresentation.DataTypeEnum.PROPERTY &&
        [
          AlistPropertyRepresentation.TypeEnum.TERMS_AND_CONDITIONS,
          AlistPropertyRepresentation.TypeEnum.PRIVACY_POLICY,
        ].includes(value.data.type)
      );
    } else {
      return false;
    }
  }
}

export function itemDownloadable(item: AlistItemRepresentation) {
  if (!item.type) {
    return false;
  }

  if (
    ![
      AlistItemWithPropertyRepresentation.TypeEnum.STRING,
      AlistItemWithPropertyRepresentation.TypeEnum.PROPERTY,
      AlistItemWithPropertyRepresentation.TypeEnum.PROPERTIES,
    ].includes(item.type)
  ) {
    return false;
  }

  if (!itemCanBeDisplayed(item)) {
    return false;
  }

  if (item.type === AlistItemWithPropertyRepresentation.TypeEnum.PROPERTY) {
    return propertyCommunicationType(<PropertyRepresentation>item.data);
  }

  return true;
}

export function iconForItemType(
  value:
    | AlistItemWithStringRepresentation
    | AlistItemWithPropertyRepresentation
    | ValuesOf<ControlsOf<AlistItemRepresentation>>
    | undefined
): string {
  switch (value?.type) {
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPOSAL:
      return 'file-sign';
    case AlistItemWithPropertyRepresentation.TypeEnum.STRING:
      return 'link';
    case AlistItemWithPropertyRepresentation.TypeEnum.TEXT:
      return 'text-short';
    case AlistItemWithPropertyRepresentation.TypeEnum.PAYMENT:
      return 'credit-card-outline';
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTIES:
      return 'account-details-outline';
    case AlistItemWithPropertyRepresentation.TypeEnum.ITEMS:
      return 'list-box-outline';
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTY:
      return iconNameForPropertyType((<AlistItemWithPropertyRepresentation>value.data)?.type);
    default:
      return 'alphabetical';
  }
}

export function itemAllowedInTier(
  currentTier:
    | TierSubscriptionInfoRepresentation.CurrentTierEnum
    | undefined = TierSubscriptionInfoRepresentation.CurrentTierEnum.FREE,
  item: AlistItemRepresentation | ValuesOf<ControlsOf<AlistItemRepresentation>> | undefined
): boolean {
  if (!item) {
    return false;
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.PAYMENT) {
    return isFeatureAllowed(currentTier, FeatureType.PAYMENT);
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.PROPOSAL) {
    return isFeatureAllowed(currentTier, FeatureType.FORM);
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.PROPERTY) {
    if (!(<AlistItemWithPropertyRepresentation>item).data?.type) {
      return true;
    }
    if (
      (<AlistItemWithPropertyRepresentation>item).data?.type ===
        AlistPropertyRepresentation.TypeEnum.PRIVACY_POLICY ||
      (<AlistItemWithPropertyRepresentation>item).data?.type ===
        AlistPropertyRepresentation.TypeEnum.TERMS_AND_CONDITIONS
    ) {
      return isFeatureAllowed(currentTier, FeatureType.LEGAL);
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function proposalType(proposal: ProposalRepresentation) {
  if (!proposal) {
    return null;
  }
  if (proposal.requiredProperties?.length === 1 && proposal.optionalProperties?.length === 0) {
    return proposal.requiredProperties?.[0].requestedPropertyTypes?.[0].type;
  }
  return null;
}

export function itemLabel$(
  translate: TranslateService,
  item: AlistItemRepresentation | undefined
): Observable<string | undefined> {
  if (!item) {
    return of(undefined);
  } else if (item.title && item.title !== '') {
    return of(item.title);
  } else {
    return itemTypeLabel$(translate, item).pipe(
      map((label) => {
        if (label) {
          return label;
        } else {
          return (<AlistItemWithStringRepresentation>item).data;
        }
      })
    );
  }
}

export function itemTypeLabel$(
  translate: TranslateService,
  item: AlistItemRepresentation
): Observable<string | undefined> {
  if (item.type === AlistItemWithStringRepresentation.TypeEnum.TEXT) {
    return translate.get('label.text');
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.HEADER) {
    return translate.get('label.header');
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.STRING) {
    return translate.get('label.link');
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.PROPERTY) {
    const property = (<AlistItemWithPropertyRepresentation>item).data;
    const socialName = propertySocialName(property);
    if (isSocialRelated(property?.type) && socialName) {
      return of(socialName);
    } else {
      return propertyTypeLabel$(translate, property?.type);
    }
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.PROPOSAL) {
    return translate.get('label.eForm');
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.PAYMENT) {
    return translate.get('modal.payment.title');
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.PROPERTIES) {
    return translate.get('item.type.PROPERTIES');
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.ITEMS) {
    if (
      item.format === AlistItemWithStringRepresentation.FormatEnum.BUTTON ||
      item.format === AlistItemWithStringRepresentation.FormatEnum.ICON ||
      item.format === AlistItemWithStringRepresentation.FormatEnum.COLLAPSE
    ) {
      return translate.get('item.type.CARD');
    }
    if (item.format === AlistItemWithStringRepresentation.FormatEnum.PAGE) {
      return translate.get('item.type.PAGE');
    }
    return of(undefined);
  } else if (item.type === AlistItemWithStringRepresentation.TypeEnum.PRODUCT_CARD) {
    return translate.get('item.type.PRODUCT_CARD');
  }
  return of(undefined);
}

export function productCardDataLabels(index: number, translate: TranslateService): string {
  const translateLabel = [
    'digital.product.passport.create.fields.owner',
    'digital.product.passport.create.fields.registryProductId',
    'digital.product.passport.create.fields.additionalProductId',
    'digital.product.passport.create.fields.manufacturer',
    'digital.product.passport.create.fields.manufacturerFacilityLocation',
    'digital.product.passport.create.fields.productTypology',
    'digital.product.passport.create.fields.dppLink',
    'digital.product.passport.create.fields.counterfeitLink',
  ];

  return translate.instant(translateLabel[index]);
}

export function siblingsOfItem(
  item: AlistItemRepresentation,
  items: AlistItemRepresentation[]
): AlistItemRepresentation[] | undefined {
  for (const searchItem of items) {
    if (searchItem.dataType === AlistItemWithStringRepresentation.DataTypeEnum.ITEMS) {
      const foundItem = siblingsOfItem(item, <AlistItemRepresentation[]>searchItem.data);
      if (foundItem) {
        return foundItem;
      }
    }
    if (deepEqual({...item, id: undefined}, {...searchItem, id: undefined})) {
      return items;
    }
  }

  return undefined;
}

export function getItemDataForm(itemFrom: FormGroup<ControlsOf<AlistItemRepresentation>>) {
  const itemValue = itemFrom.value;
  switch (itemValue.type) {
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTY:
      return <FormControl<AlistPropertyRepresentation>>itemFrom.get('data');
    case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTIES:
    case AlistItemWithPropertyRepresentation.TypeEnum.PRODUCT_CARD:
    case AlistItemWithItemsRepresentation.TypeEnum.ITEMS:
      return <FormArray<AlistItemRepresentation>>itemFrom.get('data');
    default:
      return <FormControl<string>>itemFrom.get('data');
  }
}

// export function parentOfItem(
//   item: AlistItemRepresentation,
//   alistLanguageOrItemWithItems?: AlistLanguageRepresentation | AlistItemWithItemsRepresentation
// ): AlistItemRepresentation | undefined {
//   if (!alistLanguageOrItemWithItems) {
//     return undefined;
//   }
//   const items =
//     (<AlistLanguageRepresentation>alistLanguageOrItemWithItems).items ??
//     (<AlistItemWithItemsRepresentation>alistLanguageOrItemWithItems).data ??
//     [];

//   if (items.find((i) => i.id === item.id)) {
//     return alistLanguageOrItemWithItems;
//   }
//   for (const i of items) {
//     const parent = parentOfItem(item, i);
//     if (parent) {
//       return parent;
//     }
//   }

//   return undefined;
// }
