<ng-template #content>
  <ax-icon *ngIf="icon && !_loading" [name]="icon" class="icon"></ax-icon>
  <ax-spinner class="button-spinner" *ngIf="_loading"></ax-spinner>
  <ng-content></ng-content>
</ng-template>

<a
  *ngIf="href"
  axButton
  [attr.type]="type"
  [color]="color"
  [hover]="hover"
  [labelHideMobile]="_labelHideMobile"
  [smallButton]="_smallButton"
  [fullWidth]="_fullWidth"
  [filled]="_filled"
  [outline]="_outline"
  [icon-button]="_iconButton"
  [hover]="_hover"
  [hoverLabel]="_hoverLabel"
  [lowercase]="_lowercase"
  [disabled]="disabled || _loading"
  [attr.href]="url"
  [attr.aria-label]="ariaLabel"
  [attr.target]="target"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>

<a
  *ngIf="routerLink"
  axButton
  [attr.type]="type"
  [color]="color"
  [hover]="hover"
  [labelHideMobile]="_labelHideMobile"
  [smallButton]="_smallButton"
  [fullWidth]="_fullWidth"
  [filled]="_filled"
  [outline]="_outline"
  [icon-button]="_iconButton"
  [hover]="_hover"
  [hoverLabel]="_hoverLabel"
  [lowercase]="_lowercase"
  [disabled]="disabled || _loading"
  [routerLink]="routerLink"
  [attr.aria-label]="ariaLabel"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>

<button
  *ngIf="!url"
  axButton
  [attr.type]="type"
  [color]="color"
  [hover]="hover"
  [labelHideMobile]="_labelHideMobile"
  [smallButton]="_smallButton"
  [fullWidth]="_fullWidth"
  [filled]="_filled"
  [outline]="_outline"
  [icon-button]="_iconButton"
  [hover]="_hover"
  [hoverLabel]="_hoverLabel"
  [lowercase]="_lowercase"
  [disabled]="disabled || _loading"
  [attr.aria-label]="ariaLabel"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</button>
