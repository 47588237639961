/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GrantedRolesRepresentation {
  roles?: {[key: string]: Array<string>};
}
export namespace GrantedRolesRepresentation {
  export type RolesEnum = 'ADMIN';
  export const RolesEnum = {
    ADMIN: 'ADMIN' as RolesEnum,
  };
}
