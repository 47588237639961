import {HttpClientModule} from '@angular/common/http';
import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {Locale} from '@aztrix/models';
import {
  defaultConfig,
  provideTransloco,
  TRANSLOCO_LOADER,
  TranslocoModule,
} from '@ngneat/transloco';
import {BehaviorSubject} from 'rxjs';

import {LOCALE} from './locale';
import {FALLBACK_LANG, TranslationsLoader} from './translate-loader';

export const LOCALE_VALUE = new InjectionToken<Locale>('LOCALE_VALUE');

export function localeProviderFactory(locale: Locale) {
  return new BehaviorSubject(locale);
}

export function provideTranslation(config: {
  availableLangs: string[];
  defaultLang: string;
  production: boolean;
  locale?: Locale;
}) {
  return [
    {provide: TRANSLOCO_LOADER, useClass: TranslationsLoader},
    provideTransloco({
      config: {
        ...defaultConfig,
        availableLangs: [...config.availableLangs, FALLBACK_LANG],
        defaultLang: config.defaultLang || FALLBACK_LANG,
        fallbackLang: FALLBACK_LANG,
        reRenderOnLangChange: true,
        prodMode: config.production,
        missingHandler: {
          useFallbackTranslation: true,
          logMissingKey: !config.production,
        },
      },
    }),
    {
      provide: LOCALE_VALUE,
      useValue: config.locale || {languageCode: navigator.language},
    },
    {
      provide: LOCALE,
      useFactory: localeProviderFactory,
      deps: [LOCALE_VALUE],
    },
  ];
}

@NgModule({
  imports: [TranslocoModule, HttpClientModule],
})
export class TranslateModule {
  static forRoot(config: {
    availableLangs: string[];
    defaultLang: string;
    production: boolean;
    locale?: Locale;
  }): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: provideTranslation(config),
    };
  }
}
