import {
  CommunicationType,
  ContextType,
  Group,
  GroupType,
  ImportProvider,
  Property,
  PropertyType,
  Proposal,
  ProposalCustomType,
  ProposalType,
} from '@aztrix/models';
import {
  GroupRepresentation,
  PropertyRepresentation,
  ProposalRepresentation,
  RequestedPropertyTypeRepresentation,
} from '@aztrix/sdk';

import {isGroup} from '../helpers/group-functions';
import {isProperty} from '../helpers/property-functions';
import {isProposal} from '../helpers/proposal-functions';
import {isProfile} from './../helpers/profile-functions';
import {isOfType} from './filter.util';

export function iconNameForContextType(type: ContextType | string): string | undefined {
  switch (type) {
    case ContextType.PROFILE:
      return 'card-account-details';
    case ContextType.COMMON:
      return 'account';
    case ContextType.PERSONAL:
      return 'home';
    case ContextType.WORK:
      return 'briefcase';
    case ContextType.LEGAL:
      return 'gavel';
    case ContextType.GENERAL:
      return 'factory';
    case ContextType.GROUP:
      return 'account-group';
    case ContextType.DOCUMENTS:
      return 'file';
    default:
      return undefined;
  }
}

export function iconNameForTemplate(template: any): string {
  return template['icon'] || 'account-group';
}

export function iconNameForPropertyType(
  type: PropertyType | RequestedPropertyTypeRepresentation.TypeEnum | string | undefined
): string {
  if (!type) {
    return 'custom:notebook-account';
  }

  switch (type) {
    case PropertyType.FIRST_NAME:
    case PropertyType.LAST_NAME:
    case PropertyType.MIDDLE_NAME:
      return 'account';
    case PropertyType.AVATAR:
      return 'account-box-outline';
    case PropertyType.GENDER:
      return 'gender-male-female';
    case PropertyType.MOBILE_PHONE:
      return 'cellphone';
    case PropertyType.PHONE:
      return 'phone';
    case PropertyType.FAX:
      return 'fax';
    case PropertyType.ORGANISATION_NAME:
    case PropertyType.INDUSTRY:
      return 'office-building';
    case PropertyType.JOB_TITLE:
      return 'briefcase';
    case PropertyType.EMAIL:
      return 'email';
    case PropertyType.ADDRESS:
    case PropertyType.REGION:
      return 'map-marker';
    case PropertyType.BIRTHDAY:
    case PropertyType.FOUNDED:
      return 'calendar';
    case PropertyType.WEBSITE:
      return 'web';
    case PropertyType.IBAN:
    case PropertyType.BANK_ACCOUNT:
    case PropertyType.BIC:
      return 'credit-card-outline';
    case PropertyType.FACEBOOK:
      return 'brands:facebook';
    case PropertyType.YOUTUBE:
      return 'brands:youtube';
    case PropertyType.INSTAGRAM:
      return 'brands:instagram';
    case PropertyType.WHATSAPP:
      return 'brands:whatsapp';
    case PropertyType.WECHAT:
      return 'brands:wechat';
    case PropertyType.TIKTOK:
      return 'brands:tiktok';
    case PropertyType.FACEBOOK_MESSENGER:
      return 'brands:facebook-messenger';
    case PropertyType.TELEGRAM:
      return 'brands:telegram';
    case PropertyType.SNAPCHAT:
      return 'brands:snapchat';
    case PropertyType.PINTEREST:
      return 'brands:pinterest';
    case PropertyType.TWITTER:
      return 'brands:twitter';
    case PropertyType.REDDIT:
      return 'brands:reddit';
    case PropertyType.LINKEDIN:
      return 'brands:linkedin';
    case PropertyType.VIMEO:
      return 'brands:vimeo';
    case PropertyType.DISCORD:
      return 'brands:discord';
    case PropertyType.TWITCH:
      return 'brands:twitch';
    case PropertyType.TUMBLR:
      return 'brands:tumblr';
    case PropertyType.MASTODON:
      return 'brands:mastodon';
    case PropertyType.PATREON:
      return 'brands:patreon';
    case PropertyType.KICKSTARTER:
      return 'brands:kickstarter';
    case PropertyType.INDIEGOGO:
      return 'brands:indiegogo';
    case PropertyType.GOFUNDME:
      return 'brands:gofundme';
    case PropertyType.TAKEAWAY:
      return 'brands:takeaway';
    case PropertyType.UBEREATS:
      return 'brands:uber-eats';
    case PropertyType.DELIVEROO:
      return 'brands:deliveroo';
    case PropertyType.SHIPTO:
      return 'brands:ship-to';
    case PropertyType.WEIBO:
      return 'brands:weibo';
    case PropertyType.QQ:
      return 'brands:qq';
    case PropertyType.KUAISHOU:
      return 'brands:kuaishou';
    case PropertyType.QZONE:
      return 'brands:qzone';
    case PropertyType.SKYPE:
      return 'brands:skype';
    case PropertyType.OPENING_HOURS:
      return 'calendar-clock';
    case PropertyType.LEGAL_ID:
      return 'office-building';
    case PropertyType.LEGAL_FORM:
    case PropertyType.LEGAL_NAME:
      return 'gavel';
    case PropertyType.NOTE:
      return 'note';
    case PropertyType.CATEGORY:
      return 'tag-multiple';
    case PropertyType.PROPOSAL:
      return 'file-document-edit';
    case PropertyType.IMAGE:
      return 'image';
    case PropertyType.FILE:
    case PropertyType.TERMS_AND_CONDITIONS:
    case PropertyType.PRIVACY_POLICY:
    case PropertyType.DOCUMENT:
      return 'file';
    default:
      return 'custom:notebook-account';
  }
}

export function iconNameForCustomPropertyType(
  type: ProposalCustomType | string | undefined
): string {
  switch (type) {
    case ProposalCustomType.CHECK:
      return 'checkbox-marked';
    case ProposalCustomType.RADIO:
      return 'radiobox-marked';
    case ProposalCustomType.DROPDOWN:
      return 'form-select';
    case ProposalCustomType.CHIPS:
      return 'custom:chip';
    case ProposalCustomType.DATE:
      return 'calendar';
    case ProposalCustomType.RATING:
      return 'message-star';
    case ProposalCustomType.RICH_TEXT:
      return 'text-box';
    case ProposalCustomType.TOGGLE:
      return 'toggle-switch';
    case ProposalCustomType.NUMBER:
      return 'numeric';
    case ProposalCustomType.URL:
      return 'link';
    default:
      return 'card-text';
  }
}

export function iconNameForCustomPropertyAndPropertyType(
  type: PropertyType | ProposalCustomType | string | undefined
): string {
  if (!type) {
    return 'card-text';
  }

  let icon = iconNameForPropertyType(type);
  if (icon === 'custom:notebook-account') {
    icon = iconNameForCustomPropertyType(type);
  }
  return icon;
}

export function iconNameForCategory(category?: string): string {
  switch (category) {
    case 'BANK':
    case 'BANKING':
      return 'bank';
    case 'INSURANCE':
      return 'shield';
    case 'PHARMACY':
      return 'pharmacy';
    case 'ENERGY':
    case 'ENERGY_UTILITIES':
      return 'flash';
    case 'LEGAL':
      return 'gavel';
    case 'NEWSPAPER':
      return 'newspaper';
    case 'TELECOM':
    case 'TELECOM_TV':
      return 'wifi';
    case 'WATER':
      return 'water';
    case 'EMERGENCY':
      return 'hospital';
    case 'FITNESS':
      return 'dumbbell';
    case 'DIGITAL_TV':
      return 'television';
    case 'GOOD_CAUSE':
      return 'charity';
    case 'CINEMA':
      return 'movie-open';
    case 'BROADBAND':
      return 'access-point';
    case 'FAMILY':
      return 'home-heart';
    case 'HOTEL':
      return 'hotel';
    case 'SPORTS':
      return 'run';
    case 'FINANCE':
      return 'cash';
    case 'FUEL':
      return 'fuel';
    case 'HOME_PHONE':
      return 'phone';
    case 'HOUSE':
      return 'home-city';
    case 'FUNERAL':
      return 'coffin';
    case 'CAR_MOBILITY':
      return 'train-car';
    case 'MAGAZINES':
      return 'book-open-variant';
    case 'TRAVEL':
      return 'hiking';
    case 'LOYALTY_CARDS':
      return 'wallet-membership';
    case 'ANIMAL':
      return 'paw';
    case 'THEATRE':
      return 'drama-masks';
    case 'AIRLINES':
      return 'airplane';
    case 'HOLIDAYS':
      return 'palm-tree';
    case 'NEWSPAPER_MAGAZINES':
      return 'book-open';
    case 'MOBILE_PHONE':
      return 'cellphone';
    case 'CAR':
      return 'car';
    case 'SHOPPING':
      return 'basket';
    case 'HOSPITAL':
      return 'hospital-building';
    case 'RENTAL':
      return 'car-key';
    case 'STREAMING_SERVICE':
      return 'television-play';
    case 'SPORTS_LEISURE_CULTURE':
      return 'beach';
    default:
      return 'office-building';
  }
}

export function iconNameForCommunicationType(type: CommunicationType | string): string | undefined {
  switch (type) {
    case CommunicationType.CALL:
      return 'phone';
    case CommunicationType.MESSAGE:
      return 'message';
    case CommunicationType.MAIL:
      return 'email';
    case CommunicationType.MAP:
      return 'map-marker';
    default:
      return undefined;
  }
}

export function iconForProvider(importProvider?: ImportProvider): string | undefined {
  switch (importProvider) {
    case ImportProvider.FACEBOOK:
      return 'brands:facebook';
    case ImportProvider.TWITTER:
      return 'brands:twitter';
    case ImportProvider.MICROSOFT:
      return 'brands:microsoft';
    case ImportProvider.YAHOO:
      return 'brands:yahoo';
    case ImportProvider.GOOGLE:
      return 'brands:google';
    default:
      return undefined;
  }
}

export function groupIconClass(group: Group): string {
  if (isProfile(group)) {
    return '';
  } else {
    if (isOfType(group, GroupType.CONTACTS)) {
      return 'custom:notebook-account';
    }
    if (isOfType(group, GroupType.FOLLOWERS)) {
      return 'custom:account-follow';
    }
    if (isOfType(group, GroupType.FAVORITES)) {
      return 'star';
    }
    if (isOfType(group, GroupType.FAMILY)) {
      return 'heart';
    }
    if (isOfType(group, GroupType.BLOCKED)) {
      return 'cancel';
    }
    if (isOfType(group, GroupType.EMERGENCIES)) {
      return 'ambulance';
    }
    if (isOfType(group, GroupType.IMPORTING)) {
      return iconForProvider(group.importProvider) || 'card-account-details';
    }
    return 'account-group-outline';
  }
}

export function iconForProposalType(proposalType: ProposalType | string): string {
  switch (proposalType) {
    case ProposalType.CONTRACT:
      return 'file-document-edit';
    case ProposalType.NEWSLETTER:
      return 'email-newsletter';
    case ProposalType.REGISTRATION:
      return 'account-plus';
    case ProposalType.INVOICE:
      return 'receipt';
    case ProposalType.REVIEW:
      return 'message-star';
    default:
      return 'file-check';
  }
}

export function iconForPageTemplate(id: string): string {
  switch (id) {
    case 'business':
      return 'office-building';
    case 'contact':
      return 'custom:notebook-account';
    case 'restaurant':
      return 'silverware-fork-knife';
    case 'search':
      return 'magnify';
    case 'vertical-home':
      return 'home';
    case 'blog-post':
      return 'post';
    case 'blog-overview':
      return 'post';
    case 'vertical-offers':
      return 'tag';
    default:
      return 'file-check';
  }
}

export function iconName(
  subject: ProposalRepresentation | GroupRepresentation | PropertyRepresentation
) {
  if (isProposal(subject)) {
    return iconForProposalType((<Proposal>subject).type);
  } else if (isGroup(subject)) {
    return groupIconClass(subject);
  } else if (isProperty(subject)) {
    return iconNameForPropertyType((<Property>subject).type);
  }

  return undefined;
}
