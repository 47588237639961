import {Injectable} from '@angular/core';
import {Agreement, ExternalAgreement} from '@aztrix/models';
import {
  AgreementRepresentation,
  ProposalsService,
  SubscribeRepresentation,
  UpdateAgreementRepresentation,
} from '@aztrix/sdk';
import {map, Observable} from 'rxjs';

import {externalAgreementLegacyToExternalAgreement} from '../helpers/server-representation';

@Injectable({providedIn: 'root'})
export class SubscribeService {
  constructor(private _proposalsApiService: ProposalsService) {}

  subscribe(
    data: UpdateAgreementRepresentation | SubscribeRepresentation,
    proposalId: string,
    agreementCode?: string,
    external = false
  ): Observable<Agreement | AgreementRepresentation | ExternalAgreement> {
    if (external) {
      return this.subscribeExternally(data, proposalId, agreementCode);
    } else {
      return this.subscribeInternally(data, proposalId, agreementCode);
    }
  }

  subscribeInternally(
    data: UpdateAgreementRepresentation | SubscribeRepresentation,
    proposalId: string,
    agreementCode?: string
  ): Observable<Agreement | AgreementRepresentation> {
    if (agreementCode) {
      return this._proposalsApiService
        .subscribeWithAgreementCode(proposalId, agreementCode, data)
        .pipe(map((agreement) => <Agreement>agreement));
    } else {
      return this._proposalsApiService
        .subscribe(proposalId, data)
        .pipe(map((agreement) => <Agreement>agreement));
    }
  }

  subscribeExternally(
    data: UpdateAgreementRepresentation | SubscribeRepresentation,
    proposalId: string,
    agreementCode?: string
  ): Observable<ExternalAgreement> {
    if (agreementCode) {
      return this._proposalsApiService
        .subscribeExternallyWithAgreementCode(proposalId, agreementCode, data)
        .pipe(
          map((externalAgreementLegacy) =>
            externalAgreementLegacyToExternalAgreement(externalAgreementLegacy)
          )
        );
    } else {
      return this._proposalsApiService
        .subscribeExternally(proposalId, data)
        .pipe(
          map((externalAgreementLegacy) =>
            externalAgreementLegacyToExternalAgreement(externalAgreementLegacy)
          )
        );
    }
  }
}
