import {Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {Locale, Profile, Property, PropertyType} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, Subject, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {getPropertyTuple} from '../../helpers/context-functions';
import {displayValue$} from '../../helpers/property-functions';
import {LANGUAGE_OVERRIDE, LOCALE, TranslateService} from '@aztrix/translate';
import {typeFilter} from '../../util/filter.util';

@Pipe({
  name: 'profilePropertyValue',
  pure: false,
})
export class ProfilePropertyValuePipe implements PipeTransform, OnDestroy {
  private _property$ = new Subject<Property>();
  private _subscription = new Subscription();

  currentValue = '';

  constructor(
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>,
    private _translate: TranslateService,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {
    this._subscription = combineLatest([this._property$, this._locale$])
      .pipe(
        switchMap(([property, locale]) => {
          return displayValue$(this._translate, property, locale, this._languageOverride$?.value);
        })
      )
      .subscribe((value) => {
        this.currentValue = value;
      });
  }

  transform(
    profile: Profile | ProfileRepresentation | null | undefined,
    type: PropertyType
  ): string {
    const propertyTuple = getPropertyTuple(profile?.propertyContexts || [], typeFilter(type));
    if (propertyTuple.property) {
      this._property$.next(propertyTuple.property);
    }
    return this.currentValue;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
