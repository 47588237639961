/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CustomFieldInfoRepresentation {
  type?: CustomFieldInfoRepresentation.TypeEnum;
  values?: Array<string>;
  maxAmountOfValues?: number;
}
export namespace CustomFieldInfoRepresentation {
  export type TypeEnum =
    | 'TEXT'
    | 'CHECK'
    | 'RADIO'
    | 'DROPDOWN'
    | 'CHIPS'
    | 'DATE'
    | 'RATING'
    | 'RICH_TEXT'
    | 'TOGGLE'
    | 'NUMBER'
    | 'URL';
  export const TypeEnum = {
    TEXT: 'TEXT' as TypeEnum,
    CHECK: 'CHECK' as TypeEnum,
    RADIO: 'RADIO' as TypeEnum,
    DROPDOWN: 'DROPDOWN' as TypeEnum,
    CHIPS: 'CHIPS' as TypeEnum,
    DATE: 'DATE' as TypeEnum,
    RATING: 'RATING' as TypeEnum,
    RICH_TEXT: 'RICH_TEXT' as TypeEnum,
    TOGGLE: 'TOGGLE' as TypeEnum,
    NUMBER: 'NUMBER' as TypeEnum,
    URL: 'URL' as TypeEnum,
  };
}
