import {Profile, PropertyType} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';
import Fuse from 'fuse.js';

import {deflateProfiles} from '../helpers/deflated-profile-functions';

export function fuzzySort<T>(
  set: T[] | undefined,
  query: string,
  index: string[] = [],
  threshold = 1,
  limit = 50,
  shouldSort = true
): T[] {
  if (!set) {
    return [];
  }

  if (query === '') {
    return set;
  }
  if (threshold < 0) {
    threshold = 0;
  }
  if (!index) {
    index = [];
  }
  return new Fuse(set, {keys: index, threshold, ignoreLocation: true, shouldSort})
    .search(query, {limit})
    .map((result) => result.item);
}

export function fuzzySortProfiles(
  profiles: (Profile | ProfileRepresentation)[],
  query: string,
  threshold = 1
) {
  const deflatedProfiles = deflateProfiles(<Profile[]>profiles, [
    PropertyType.EMAIL,
    PropertyType.PHONE,
    PropertyType.MOBILE_PHONE,
    PropertyType.LEGAL_ID,
  ]);

  const sortedProfiles = fuzzySort(
    deflatedProfiles,
    query,
    ['name', 'properties.property.value'],
    threshold
  );

  return sortedProfiles.map((p) => profiles.find((profile) => profile.id === p.id));
}
