export enum JiraRequestTypeFieldType {
  SINGLE_LINE_TEXT = 'SINGLE_LINE_TEXT',
  MULTI_LINE_TEXT = 'MULTI_LINE_TEXT',
  ATTACHMENT = 'ATTACHMENT',
  REPORTER_EMAIL = 'REPORTER_EMAIL',
}

export interface JiraRequestType {
  id: string;
  name: string;
  description: string;
  fields: Array<{
    id: string;
    name: string;
    description: string;
    required: boolean;
    value: string;
    type: JiraRequestTypeFieldType;
    defaultValues: Array<string>;
    possibleValues: Array<string>;
  }>;
}
