import {Inject, Pipe, PipeTransform} from '@angular/core';
import {Locale} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LOCALE} from '@aztrix/translate';
import {formatDateTime} from '../util/date.util';

@Pipe({name: 'formatDateTime', pure: false})
export class FormatDateTimePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>
  ) {}

  transform(value: string | number | Date | null | undefined): string {
    return formatDateTime(value, this._locale$.value);
  }
}
