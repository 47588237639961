import {Pipe, PipeTransform} from '@angular/core';
import {Profile, Property} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';

import {getProperty} from '../../helpers/property-functions';
import {idFilter} from '../../util/filter.util';

@Pipe({
  name: 'pagePropertyById',
})
export class PagePropertyByIdPipe implements PipeTransform {
  transform(
    profile: Profile | undefined | null,
    id: string | undefined
  ): Property | PropertyRepresentation | undefined {
    if (!profile || !id) {
      return undefined;
    }
    for (const context of profile.propertyContexts || []) {
      const property = getProperty(context, idFilter(id));
      if (property) {
        return property;
      }
    }
    return undefined;
  }
}
