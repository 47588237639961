import {NgModule} from '@angular/core';

import {AgreementIsExpiredPipe} from './agreement/agreement-is-expired.pipe';
import {AgreementVerificationTypeLabelPipe} from './agreement/agreement-verification-type-label.pipe';
import {CategoryIconPipe} from './category/category-icon.pipe';
import {CategoryLabelPipe} from './category/category-label.pipe';
import {ColorOrDefaultPipe} from './color-or-default.pipe';
import {CommunicationHrefPipe} from './communication/communication-href.pipe';
import {CommunicationTargetPipe} from './communication/communication-target.pipe';
import {ContextDisplayLabelPipe} from './context/context-display-label.pipe';
import {CountryCodeLabelPipe} from './country-code-label.pipe';
import {CountrySearchPipe} from './country-search.pipe';
import {DisplayNamePipe} from './display-name.pipe';
import {DisplayVerificationWarningPipe} from './display-verification-warning.pipe';
import {ErrorTranslationKeyPipe} from './error-translation-key.pipe';
import {FlagForLanguagePipe} from './flag-for-language.pipe';
import {FormatDatePipe} from './format-date.pipe';
import {FormatDateLongFromNowPipe} from './format-date-long-from-now.pipe';
import {FormatDateTimePipe} from './format-date-time.pipe';
import {FormatDateTimeFromNowPipe} from './format-date-time-from-now.pipe';
import {FormatDayPipe} from './format-day.pipe';
import {FormatNumberPipe} from './format-number.pipe';
import {FormatPercentPipe} from './format-percent.pipe';
import {FormatTimePipe} from './format-time.pipe';
import {HighlightPipe} from './highlight.pipe';
import {IsArrayPipe} from './is-array.pipe';
import {IsOfTypePipe} from './is-of-type.pipe';
import {KebabCasePipe} from './kebab-case.pipe';
import {LanguageCodeLabelPipe} from './language-code-label.pipe';
import {PageFieldValuePipe} from './page/page-field-value.pipe';
import {PageFieldValueFieldValuesPipe} from './page/page-field-value-field-values.pipe';
import {PageFieldValueStringPipe} from './page/page-field-value-string.pipe';
import {PagePropertyByIdPipe} from './page/page-property-by-id.pipe';
import {CommunicationTypesForProfilePipe} from './profile/communication-types-for-profile.pipe';
import {IsContactPipe} from './profile/is-contact.pipe';
import {IsDeflatedProfilePipe} from './profile/is-deflated-profile.pipe';
import {IsDynamicPipe} from './profile/is-dynamic.pipe';
import {IsMeProfilePipe} from './profile/is-me-profile.pipe';
import {IsOrganisationPipe} from './profile/is-organisation.pipe';
import {IsProfilePipe} from './profile/is-profile.pipe';
import {IsPublicProfilePipe} from './profile/is-public-profile.pipe';
import {IsStaticPipe} from './profile/is-static.pipe';
import {HasUsernamePipe} from './profile/profile-has-username.pipe';
import {ProfilePropertyValuePipe} from './profile/profile-property-value.pipe';
import {ProfileVisibilityLabelPipe} from './profile/profile-visibility-label.pipe';
import {IconNameForCustomPropertyAndPropertyTypePipe} from './property/icon-name-for-custom-and-property-type.pipe';
import {IconNameForCustomPropertyTypePipe} from './property/icon-name-for-custom-property-type.pipe';
import {IconNameForPropertyTypePipe} from './property/icon-name-for-property-type.pipe';
import {PlaceholderForPropertyTypePipe} from './property/placeholder-for-property-type.pipe';
import {PrefixForPropertyTypePipe} from './property/prefix-for-property-type.pipe';
import {PropertyCommunicationHrefPipe} from './property/property-communication-href.pipe';
import {PropertyCommunicationTargetPipe} from './property/property-communication-target.pipe';
import {PropertyDisplayValuePipe} from './property/property-display-value.pipe';
import {PropertyDocumentLinkPipe} from './property/property-document-link.pipe';
import {PropertyHasValuePipe} from './property/property-has-value.pipe';
import {PropertyIconPipe} from './property/property-icon.pipe';
import {PropertyIsAddressRelatedPipe} from './property/property-is-address-related.pipe';
import {PropertyIsCommunicationRelatedPipe} from './property/property-is-communication-related.pipe';
import {PropertyIsDateRelatedPipe} from './property/property-is-date-related.pipe';
import {PropertyIsDocumentRelatedPipe} from './property/property-is-document-related.pipe';
import {PropertyIsDynamicPipe} from './property/property-is-dynamic.pipe';
import {PropertyIsPhoneRelatedPipe} from './property/property-is-phone-related.pipe';
import {PropertyIsPublicPipe} from './property/property-is-public.pipe';
import {PropertyIsSocialRelatedPipe} from './property/property-is-social-related.pipe';
import {PropertyIsVerifiedPipe} from './property/property-is-verified.pipe';
import {PropertySocialNamePipe} from './property/property-social-name.pipe';
import {PropertyTypeLabelPipe} from './property/property-type-label.pipe';
import {PropertyVerificationLabelPipe} from './property/property-verification-label.pipe';
import {PropertyVisibilityPipe} from './property/property-visibility.pipe';
import {RegionDisplayPipe} from './property/region-display.pipe';
import {AgreementStatePipe} from './proposal/agreement-state.pipe';
import {AgreementStateLabelPipe} from './proposal/agreement-state-label.pipe';
import {AgreementVerificationStateLabelPipe} from './proposal/agreement-verification-state-label.pipe';
import {ProposalPropertyDescriptionPipe} from './proposal/proposal-property-description.pipe';
import {ProposalPropertyDescriptionsPipe} from './proposal/proposal-property-descriptions.pipe';
import {ProposalPropertyTitlePipe} from './proposal/proposal-property-title.pipe';
import {ProposalPropertyTypeLabelPipe} from './proposal/proposal-property-type-label.pipe';
import {ProposalRequestedPropertyTypePipe} from './proposal/proposal-requested-property-type.pipe';
import {RouterLinkPipe} from './router-link.pipe';
import {SigningOrderStatusPipe} from './signing-order/signing-order-status.pipe';
import {IsPrivatePipe} from './subject/is-private.pipe';
import {FormPropertiesPipe} from './subscribe/form-properties.pipe';
import {IsFeatureAllowedPipe} from './tiers/is-feature-allowed.pipe';
import {UrlParamsPipe} from './url-params.pipe';

const COMPONTENTS = [
  PropertyDisplayValuePipe,
  CommunicationHrefPipe,
  CommunicationTargetPipe,
  PropertyCommunicationHrefPipe,
  PropertyCommunicationTargetPipe,
  ProfilePropertyValuePipe,
  IsDeflatedProfilePipe,
  PropertyVerificationLabelPipe,
  CommunicationTypesForProfilePipe,
  IsContactPipe,
  IsDynamicPipe,
  IsMeProfilePipe,
  IsOrganisationPipe,
  IsProfilePipe,
  HasUsernamePipe,
  IsPublicProfilePipe,
  IsStaticPipe,
  ProfileVisibilityLabelPipe,
  IsOfTypePipe,
  IconNameForCustomPropertyAndPropertyTypePipe,
  IconNameForCustomPropertyTypePipe,
  IconNameForPropertyTypePipe,
  PropertyTypeLabelPipe,
  PropertyIsVerifiedPipe,
  PropertyIsAddressRelatedPipe,
  PropertyIsCommunicationRelatedPipe,
  PropertyIsDocumentRelatedPipe,
  PropertyIsSocialRelatedPipe,
  PropertyIsDateRelatedPipe,
  PropertyIsPhoneRelatedPipe,
  PropertyHasValuePipe,
  PropertyIsDynamicPipe,
  PropertyIsPublicPipe,
  PropertyIconPipe,
  PropertyVisibilityPipe,
  PropertySocialNamePipe,
  PlaceholderForPropertyTypePipe,
  PrefixForPropertyTypePipe,
  DisplayVerificationWarningPipe,
  ErrorTranslationKeyPipe,
  ContextDisplayLabelPipe,
  CountryCodeLabelPipe,
  LanguageCodeLabelPipe,
  CategoryIconPipe,
  CategoryLabelPipe,
  IsPrivatePipe,
  RouterLinkPipe,
  ProposalPropertyDescriptionPipe,
  ProposalPropertyDescriptionsPipe,
  ProposalRequestedPropertyTypePipe,
  ProposalPropertyTitlePipe,
  ProposalPropertyTypeLabelPipe,
  FlagForLanguagePipe,
  HighlightPipe,
  AgreementIsExpiredPipe,
  AgreementStateLabelPipe,
  AgreementVerificationStateLabelPipe,
  AgreementVerificationTypeLabelPipe,
  RegionDisplayPipe,
  CountrySearchPipe,
  FormatDateTimePipe,
  FormatDatePipe,
  FormatDayPipe,
  FormatTimePipe,
  FormatDateTimeFromNowPipe,
  FormatDateLongFromNowPipe,
  FormatNumberPipe,
  FormatPercentPipe,
  PageFieldValueStringPipe,
  PageFieldValueFieldValuesPipe,
  PageFieldValuePipe,
  PagePropertyByIdPipe,
  FormPropertiesPipe,
  KebabCasePipe,
  DisplayNamePipe,
  PropertyDocumentLinkPipe,
  IsArrayPipe,
  ColorOrDefaultPipe,
  IsFeatureAllowedPipe,
  UrlParamsPipe,
  SigningOrderStatusPipe,
  AgreementStatePipe,
];

@NgModule({
  declarations: COMPONTENTS,
  exports: [...COMPONTENTS],
})
export class HelperPipesModule {}
