/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TypeRequestInfo {
  contextId?: string;
  type?: TypeRequestInfo.TypeEnum;
  requestId?: string;
}
export namespace TypeRequestInfo {
  export type TypeEnum =
    | 'USERNAME'
    | 'FIRST_NAME'
    | 'MIDDLE_NAME'
    | 'LAST_NAME'
    | 'NAME'
    | 'AVATAR'
    | 'GENDER'
    | 'BIRTHDAY'
    | 'FOUNDED'
    | 'DISSOLVED'
    | 'DAY'
    | 'MONTH'
    | 'YEAR'
    | 'EMAIL'
    | 'MOBILE_PHONE'
    | 'PHONE'
    | 'FAX'
    | 'WEBSITE'
    | 'BIC'
    | 'JOB_TITLE'
    | 'INDUSTRY'
    | 'GROUP'
    | 'REGION'
    | 'ADDRESS'
    | 'STREET'
    | 'COUNTRY'
    | 'BUILDING'
    | 'BOX'
    | 'LOCALITY'
    | 'NUMBER'
    | 'ZIP'
    | 'DISTRICT'
    | 'PROVINCE'
    | 'SUBURB'
    | 'STATE'
    | 'COUNTY'
    | 'LAT_LONG'
    | 'FACEBOOK'
    | 'YOUTUBE'
    | 'INSTAGRAM'
    | 'WHATSAPP'
    | 'WECHAT'
    | 'TIKTOK'
    | 'FACEBOOK_MESSENGER'
    | 'TELEGRAM'
    | 'SNAPCHAT'
    | 'PINTEREST'
    | 'TWITTER'
    | 'REDDIT'
    | 'LINKEDIN'
    | 'VIMEO'
    | 'DISCORD'
    | 'TWITCH'
    | 'TUMBLR'
    | 'MASTODON'
    | 'PATREON'
    | 'KICKSTARTER'
    | 'INDIEGOGO'
    | 'GOFUNDME'
    | 'TAKEAWAY'
    | 'UBEREATS'
    | 'DELIVEROO'
    | 'SHIPTO'
    | 'WEIBO'
    | 'QQ'
    | 'KUAISHOU'
    | 'QZONE'
    | 'SKYPE'
    | 'NOTE'
    | 'ORGANISATION_NAME'
    | 'LEGAL_NAME'
    | 'LEGAL_FORM'
    | 'LEGAL_ID'
    | 'COMPANY_NUMBER'
    | 'JURISDICTION'
    | 'IMPORT_SOURCE'
    | 'PROPOSAL'
    | 'CATEGORY'
    | 'OPENING_HOURS'
    | 'CUSTOM'
    | 'FILE'
    | 'IMAGE'
    | 'FILE_KEY'
    | 'FILE_NAME'
    | 'TERMS_AND_CONDITIONS'
    | 'PRIVACY_POLICY'
    | 'DOCUMENT'
    | 'DOCUMENT_NAME'
    | 'ORGANISATION_TYPE';
  export const TypeEnum = {
    USERNAME: 'USERNAME' as TypeEnum,
    FIRST_NAME: 'FIRST_NAME' as TypeEnum,
    MIDDLE_NAME: 'MIDDLE_NAME' as TypeEnum,
    LAST_NAME: 'LAST_NAME' as TypeEnum,
    NAME: 'NAME' as TypeEnum,
    AVATAR: 'AVATAR' as TypeEnum,
    GENDER: 'GENDER' as TypeEnum,
    BIRTHDAY: 'BIRTHDAY' as TypeEnum,
    FOUNDED: 'FOUNDED' as TypeEnum,
    DISSOLVED: 'DISSOLVED' as TypeEnum,
    DAY: 'DAY' as TypeEnum,
    MONTH: 'MONTH' as TypeEnum,
    YEAR: 'YEAR' as TypeEnum,
    EMAIL: 'EMAIL' as TypeEnum,
    MOBILE_PHONE: 'MOBILE_PHONE' as TypeEnum,
    PHONE: 'PHONE' as TypeEnum,
    FAX: 'FAX' as TypeEnum,
    WEBSITE: 'WEBSITE' as TypeEnum,
    BIC: 'BIC' as TypeEnum,
    JOB_TITLE: 'JOB_TITLE' as TypeEnum,
    INDUSTRY: 'INDUSTRY' as TypeEnum,
    GROUP: 'GROUP' as TypeEnum,
    REGION: 'REGION' as TypeEnum,
    ADDRESS: 'ADDRESS' as TypeEnum,
    STREET: 'STREET' as TypeEnum,
    COUNTRY: 'COUNTRY' as TypeEnum,
    BUILDING: 'BUILDING' as TypeEnum,
    BOX: 'BOX' as TypeEnum,
    LOCALITY: 'LOCALITY' as TypeEnum,
    NUMBER: 'NUMBER' as TypeEnum,
    ZIP: 'ZIP' as TypeEnum,
    DISTRICT: 'DISTRICT' as TypeEnum,
    PROVINCE: 'PROVINCE' as TypeEnum,
    SUBURB: 'SUBURB' as TypeEnum,
    STATE: 'STATE' as TypeEnum,
    COUNTY: 'COUNTY' as TypeEnum,
    LAT_LONG: 'LAT_LONG' as TypeEnum,
    FACEBOOK: 'FACEBOOK' as TypeEnum,
    YOUTUBE: 'YOUTUBE' as TypeEnum,
    INSTAGRAM: 'INSTAGRAM' as TypeEnum,
    WHATSAPP: 'WHATSAPP' as TypeEnum,
    WECHAT: 'WECHAT' as TypeEnum,
    TIKTOK: 'TIKTOK' as TypeEnum,
    FACEBOOK_MESSENGER: 'FACEBOOK_MESSENGER' as TypeEnum,
    TELEGRAM: 'TELEGRAM' as TypeEnum,
    SNAPCHAT: 'SNAPCHAT' as TypeEnum,
    PINTEREST: 'PINTEREST' as TypeEnum,
    TWITTER: 'TWITTER' as TypeEnum,
    REDDIT: 'REDDIT' as TypeEnum,
    LINKEDIN: 'LINKEDIN' as TypeEnum,
    VIMEO: 'VIMEO' as TypeEnum,
    DISCORD: 'DISCORD' as TypeEnum,
    TWITCH: 'TWITCH' as TypeEnum,
    TUMBLR: 'TUMBLR' as TypeEnum,
    MASTODON: 'MASTODON' as TypeEnum,
    PATREON: 'PATREON' as TypeEnum,
    KICKSTARTER: 'KICKSTARTER' as TypeEnum,
    INDIEGOGO: 'INDIEGOGO' as TypeEnum,
    GOFUNDME: 'GOFUNDME' as TypeEnum,
    TAKEAWAY: 'TAKEAWAY' as TypeEnum,
    UBEREATS: 'UBEREATS' as TypeEnum,
    DELIVEROO: 'DELIVEROO' as TypeEnum,
    SHIPTO: 'SHIPTO' as TypeEnum,
    WEIBO: 'WEIBO' as TypeEnum,
    QQ: 'QQ' as TypeEnum,
    KUAISHOU: 'KUAISHOU' as TypeEnum,
    QZONE: 'QZONE' as TypeEnum,
    SKYPE: 'SKYPE' as TypeEnum,
    NOTE: 'NOTE' as TypeEnum,
    ORGANISATION_NAME: 'ORGANISATION_NAME' as TypeEnum,
    LEGAL_NAME: 'LEGAL_NAME' as TypeEnum,
    LEGAL_FORM: 'LEGAL_FORM' as TypeEnum,
    LEGAL_ID: 'LEGAL_ID' as TypeEnum,
    COMPANY_NUMBER: 'COMPANY_NUMBER' as TypeEnum,
    JURISDICTION: 'JURISDICTION' as TypeEnum,
    IMPORT_SOURCE: 'IMPORT_SOURCE' as TypeEnum,
    PROPOSAL: 'PROPOSAL' as TypeEnum,
    CATEGORY: 'CATEGORY' as TypeEnum,
    OPENING_HOURS: 'OPENING_HOURS' as TypeEnum,
    CUSTOM: 'CUSTOM' as TypeEnum,
    FILE: 'FILE' as TypeEnum,
    IMAGE: 'IMAGE' as TypeEnum,
    FILE_KEY: 'FILE_KEY' as TypeEnum,
    FILE_NAME: 'FILE_NAME' as TypeEnum,
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS' as TypeEnum,
    PRIVACY_POLICY: 'PRIVACY_POLICY' as TypeEnum,
    DOCUMENT: 'DOCUMENT' as TypeEnum,
    DOCUMENT_NAME: 'DOCUMENT_NAME' as TypeEnum,
    ORGANISATION_TYPE: 'ORGANISATION_TYPE' as TypeEnum,
  };
}
