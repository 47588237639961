import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Property, PropertyType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {isAfter, isBefore, isSameDay, isValid, isWithinInterval} from 'date-fns';

import {PropertyFormControl} from '../helpers/property-form-control';
import {
  getProperties,
  getProperty,
  getRegexForProperty,
  hasValue,
  isCompound,
  isPhoneRelated,
  isSubPropertyOf,
  subPropertyOfType,
} from '../helpers/property-functions';
import {requiredSubPropertyTypes, subPropertyTypes} from '../metadata/property-type-provider';
import {unique} from './array.util';
import {isOfType, typeFilter} from './filter.util';
import {isValidPhoneNumber, prefixesForNumber} from './phone-number.util';

export enum DateError {
  STARTDATE_INVALID = 'STARTDATE_INVALID',
  STARTDATE_REQUIRED = 'STARTDATE_REQUIRED',
  ENDDATE_INVALID = 'ENDDATE_INVALID',
  STARTDATE_OUT_OF_BOUNDS_MINDATE = 'STARTDATE_OUT_OF_BOUNDS_MINDATE',
  STARTDATE_OUT_OF_BOUNDS_MAXDATE = 'STARTDATE_OUT_OF_BOUNDS_MAXDATE',
  ENDDATE_OUT_OF_BOUNDS = 'ENDDATE_OUT_OF_BOUNDS',
}

export function validateDate(
  startDate: Date,
  endDate?: Date,
  minDate?: Date,
  maxDate?: Date
): DateError | null {
  if (startDate && !isValid(startDate)) {
    return DateError.STARTDATE_INVALID;
  }
  if (minDate && startDate) {
    if (!isSameDay(startDate, minDate) && isBefore(startDate, minDate)) {
      return DateError.STARTDATE_OUT_OF_BOUNDS_MINDATE;
    }
  }
  if (maxDate && startDate) {
    if (!isValid(maxDate) || (!isSameDay(startDate, maxDate) && isAfter(startDate, maxDate))) {
      return DateError.STARTDATE_OUT_OF_BOUNDS_MAXDATE;
    }
  }
  if (endDate) {
    if (!isValid(endDate) || (maxDate && isAfter(endDate, maxDate))) {
      return DateError.ENDDATE_OUT_OF_BOUNDS;
    }
    if (startDate && !isSameDay(startDate, endDate) && isBefore(endDate, startDate)) {
      return DateError.ENDDATE_OUT_OF_BOUNDS;
    }
  }
  return null;
}

export function validateProperty(property: Property | PropertyRepresentation): boolean {
  if (!isCompound(property)) {
    if (!property || !property.value) {
      return false;
    }
    if (typeof property.value === 'number') {
      return true;
    }
    return typeof property.value === 'string'
      ? property.value.match(new RegExp(getRegexForProperty(property?.type))) !== null
      : false;
  } else {
    // If not all required sub types are in the sub properties, it is not valid
    const hasAllRequiredSubProperties = (<PropertyType[]>requiredSubPropertyTypes(property)).every(
      (type) => {
        const subProperty = subPropertyOfType(property, type);
        return !!subProperty && hasValue(subProperty);
      }
    );
    if (!hasAllRequiredSubProperties) {
      return false;
    }

    // There shouldn't be sub properties that do not belong in the property
    const possibleSubTypes = subPropertyTypes(property);
    if (
      !getProperties(property).every((p) =>
        p.type ? (<PropertyType[]>possibleSubTypes).includes(p.type) || !hasValue(p) : false
      )
    ) {
      return false;
    }

    // Validate all non-empty sub properties
    return getProperties(property)
      .filter(hasValue)
      .every((subProperty) => validateProperty(subProperty));
  }
}

export function isValidUrl(value?: string) {
  if (!value) {
    return false;
  }

  const regex = '^(https?://(www.)?[\\dA-Za-z\\.-]+)\\.([A-Za-z\\.]{2,6})([/\\w \\.-].*)*/?$';
  return value.match(regex);
}

export class CustomValidators {
  static propertyRequired(control?: AbstractControl): ValidationErrors | null {
    return CustomValidators._validateProperty(control, CustomValidators._subPropertyRequired);
  }

  static propertyValid(control?: AbstractControl): ValidationErrors | null {
    return CustomValidators._validateProperty(control, CustomValidators._propertyValid);
  }

  private static _validateProperty(control: AbstractControl | undefined, validator: ValidatorFn) {
    const parent = control?.parent;
    if (!parent) {
      return null;
    }

    const type = parent.value?.type;
    const parentProperty = parent.parent?.parent?.value;
    if (isSubPropertyOf(parentProperty?.type, type)) {
      if (!parentProperty) {
        return null;
      }

      if (
        (<PropertyType[]>requiredSubPropertyTypes(parentProperty)).includes(type) ||
        hasValue({type, value: control?.value})
      ) {
        return validator(control);
      } else {
        return null;
      }
    }

    return validator(control);
  }

  private static _subPropertyRequired(c: AbstractControl): ValidationErrors | null {
    const type = c?.parent?.value?.type;
    if (hasValue({type, value: c?.value})) {
      return null;
    }
    return {propertyRequired: {type}};
  }

  private static _propertyValid(control?: AbstractControl): ValidationErrors | null {
    const type = control?.parent?.value?.type;
    const property = {type, value: control?.value};

    if (!hasValue(property)) {
      return null;
    }

    if (isOfType({type}, PropertyType.FIRST_NAME, PropertyType.LAST_NAME)) {
      const result = CustomValidators.propertyRequired(control);
      if (result) {
        return result;
      }
    }

    const regex = getRegexForProperty(type);
    if (regex) {
      const matchesSpace = property.value.match(/\s/g);

      if (isOfType(property, PropertyType.COMPANY_NUMBER, PropertyType.IBAN, PropertyType.BIC)) {
        if (matchesSpace) {
          return {propertyNoSpace: property};
        }

        const matchesLowercase = property.value.match(/[a-z]{1,}/g);
        if (matchesLowercase) {
          return {propertyOnlyUppercase: property};
        }

        const matchesSpecial = property.value.match(/[^A-Z0-9]{1,}/g);
        if (matchesSpecial) {
          const chars = matchesSpecial.join('');
          if (chars) {
            return {propertyInvalidCharacters: {chars: unique(chars).join(' ')}};
          }
        }

        if (isOfType(property, PropertyType.COMPANY_NUMBER)) {
          const parentProperty = control?.parent?.parent?.parent?.value;
          const jurisdiction = (<Property>(
            getProperty(parentProperty, typeFilter(PropertyType.JURISDICTION))
          ))?.value;
          const companyNumber = property?.value;

          if (jurisdiction === 'BE') {
            if (companyNumber.length !== 10) {
              return {propertyInvalid: property};
            }
            const number = parseInt(companyNumber, 10);
            return !isNaN(number) && 97 - (Math.floor(number / 100) % 97) === +number % 100
              ? null
              : {propertyInvalid: property};
          }
        }
      } else if (isOfType(property, PropertyType.NUMBER)) {
        if (matchesSpace) {
          return {propertyNoSpace: property};
        }

        const matchesLowercase = property.value.match(/[a-z]{1,}/g);
        if (matchesLowercase) {
          return {propertyOnlyUppercase: property};
        }

        const matchesSpecial = property.value.match(/[^A-Z0-9./-]{1,}/g);
        if (matchesSpecial) {
          const chars = matchesSpecial.join('');
          if (chars) {
            return {propertyInvalidCharacters: {chars: unique(chars).join(' ')}};
          }
        }
      } else if (isPhoneRelated(type)) {
        if (matchesSpace) {
          return {propertyNoSpace: property};
        }

        if (!isValidPhoneNumber(property.value, type)) {
          return {propertyInvalid: property};
        }
      } else if (isOfType(property, PropertyType.EMAIL)) {
        const matchesUppercase = property.value.match(/[A-Z]{1,}/g);
        if (matchesUppercase) {
          return {propertyOnlyLowercase: property};
        }
      } else if (isOfType(property, PropertyType.USERNAME, PropertyType.EMAIL)) {
        if (matchesSpace) {
          return {propertyNoSpace: property};
        }

        const matchesUppercase = property.value.match(/[A-Z]{1,}/g);
        if (matchesUppercase) {
          return {propertyOnlyLowercase: property};
        }

        const matchesSpecial = property.value.match(/[^a-z0-9._-]{1,}/g);
        if (matchesSpecial) {
          const chars = matchesSpecial.join('');
          if (chars) {
            return {propertyInvalidCharacters: {chars: unique(chars).join(' ')}};
          }
        }
      }
      return Validators.pattern(regex)(new UntypedFormControl(property.value));
    } else {
      return validateProperty(property) ? null : {propertyInvalid: property};
    }
  }

  static validateLoginValue() {
    const validateLoginValueFn = (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let type = null;

      if (value && prefixesForNumber(value).length > 0) {
        type = PropertyType.MOBILE_PHONE;
      } else {
        type = PropertyType.EMAIL;
      }
      const propertyForm = PropertyFormControl.createByType(type);
      propertyForm.get('value')?.setValue(value);

      return CustomValidators._propertyValid(propertyForm.get('value'));
    };

    return validateLoginValueFn;
  }

  static dateWithinRange({
    minDate = undefined,
    maxDate = undefined,
  }: {
    minDate?: Date;
    maxDate?: Date;
  }): ValidatorFn {
    const dateWithinRangeValidatorFn = (c: AbstractControl) => {
      const currentDate: Date = c.value;
      if (!c.value || !minDate || !maxDate) {
        return null;
      }
      return isValid(currentDate) && isWithinInterval(currentDate, {start: minDate, end: maxDate})
        ? null
        : {
            dateNotWithinRange: {
              start: minDate,
              end: maxDate,
              value: currentDate,
            },
          };
    };
    return dateWithinRangeValidatorFn;
  }

  static propertyRequiredTrue(): ValidatorFn {
    const propertyRequiredTrueFn = (control: AbstractControl): ValidationErrors | null => {
      if (control.value !== 'true') {
        return {propertyRequired: true};
      }

      return null;
    };

    return propertyRequiredTrueFn;
  }

  static maxValuesValidator(max: number): ValidatorFn {
    const maxValuesValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      if (control.value?.value?.split(',')?.length > max) {
        return {maxReached: {max}};
      }

      return null;
    };

    return maxValuesValidatorFn;
  }

  static minArrayItemsValidator(min: number): ValidatorFn {
    const minArrayItemsValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      if (control.value?.length < min) {
        return {minRequired: {min}};
      }
      return null;
    };

    return minArrayItemsValidatorFn;
  }

  static maxArrayItemsValidator(max: number): ValidatorFn {
    const maxArrayItemsValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      if (control.value?.length > max) {
        return {maxReached: {max}};
      }
      return null;
    };

    return maxArrayItemsValidatorFn;
  }

  static lowercaseNameValidator(): ValidatorFn {
    const lowercaseNameValidationFn = (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const matchesSpace = control.value.match(/\s/g);
      const matchesUppercase = control.value.match(/[A-Z]{1,}/g);

      if (matchesSpace) {
        return {propertyNoSpace: 'NAME'};
      }

      if (matchesUppercase) {
        return {propertyOnlyLowercase: 'NAME'};
      }
      return null;
    };

    return lowercaseNameValidationFn;
  }

  static nameValidator(): ValidatorFn {
    const nameValidationFn = (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const matchesSpace = control.value.match(/\s/g);
      const matchesSpecialCharacters = control.value.match(/[^a-z0-9/._-]{1,}/g);
      const matchesMaxCharacters = control.value.match(/^(?![a-z0-9/._-]{0,30}$).*/);

      if (matchesSpace) {
        return {propertyNoSpace: 'NAME'};
      }

      if (matchesSpecialCharacters) {
        return {propertyInvalidCharacters: 'NAME'};
      }

      if (matchesMaxCharacters) {
        return {propertyMaxCharacters: 'NAME'};
      }

      return null;
    };

    return nameValidationFn;
  }
}
