import {Pipe, PipeTransform} from '@angular/core';

import {iconNameForCategory} from '../../util/icon.util';

@Pipe({name: 'categoryIcon'})
export class CategoryIconPipe implements PipeTransform {
  transform(category?: string): string {
    return iconNameForCategory(category);
  }
}
