import {Inject, Injectable, ModuleWithProviders, NgModule, Optional, Provider} from '@angular/core';
import {BACKEND_URL, BASE_URL} from '@aztrix/environment';
import {BehaviorSubject} from 'rxjs';

import {AUTH_TOKEN} from './auth_token';
import {Configuration} from './lib/configuration';

@Injectable({
  providedIn: 'root',
})
export class ApiConfigurationService {
  private _configuration = new Configuration();

  constructor(
    @Inject(AUTH_TOKEN) private _authToken: BehaviorSubject<string | undefined>,
    @Inject(BASE_URL) private _baseUrl: string,
    @Optional() @Inject(BACKEND_URL) private _backendUrl: BehaviorSubject<string>
  ) {
    this._authToken.subscribe((authToken) => {
      this._setAuthToken(authToken);
    });
    if (this._backendUrl) {
      this._backendUrl.subscribe((backendUrl) => {
        this._setBasePath(backendUrl);
      });
    } else {
      this._setBasePath(this._baseUrl);
    }
  }

  private _setAuthToken(authToken: string | undefined) {
    this._configuration.credentials = authToken ? {authToken} : {};
  }

  private _setBasePath(baseUrl: string) {
    this._configuration.basePath = `${baseUrl}/r`;
  }

  configuration(): Configuration {
    return this._configuration;
  }
}

export function apiConfigFactory(service: ApiConfigurationService) {
  return service.configuration();
}

@NgModule()
export class SdkModule {
  static forRoot(baseUrl: string): ModuleWithProviders<SdkModule> {
    return {
      ngModule: SdkModule,
      providers: provideSdk(baseUrl),
    };
  }
}

export function provideSdk(baseUrl: string): Provider[] {
  return [
    ApiConfigurationService,
    {
      provide: Configuration,
      useFactory: apiConfigFactory,
      deps: [ApiConfigurationService],
    },
    {
      provide: AUTH_TOKEN,
      useValue: new BehaviorSubject(undefined),
    },
    {
      provide: BASE_URL,
      useValue: baseUrl,
    },
  ];
}
