/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {PageLanguageRepresentation} from './pageLanguageRepresentation';

export interface PageRepresentation {
  id?: string;
  templateId?: string;
  ownerId?: string;
  name?: string;
  visibility?: PageRepresentation.VisibilityEnum;
  languages?: Array<PageLanguageRepresentation>;
}
export namespace PageRepresentation {
  export type VisibilityEnum = 'PUBLIC' | 'PRIVATE';
  export const VisibilityEnum = {
    PUBLIC: 'PUBLIC' as VisibilityEnum,
    PRIVATE: 'PRIVATE' as VisibilityEnum,
  };
}
