<div
  class="c-modal__dialog fade-in"
  [ngClass]="{'no-title': !title, 'no-title-bar': !hasTitleBar, full: fullScreenModal}"
  [style.background-color]="backgroundColor"
>
  <div *ngIf="hasTitleBar" class="c-modal__header">
    <button
      axButton
      class="c-modal__close cy-close"
      [ariaLabel]="'modal.close' | translate"
      icon-button
      (click)="didClickClose.emit()"
    >
      <ax-icon class="icon" name="close"></ax-icon>
    </button>
    <div class="c-modal__title" *ngIf="title">
      <h4>
        {{ title | translate: titleOpts }}
      </h4>
    </div>
  </div>
  <div class="c-modal__body" [ngClass]="{'top-rounded': !hasTitleBar}">
    <ng-template axOverlayContent></ng-template>
  </div>
</div>
