/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {ContextRepresentation} from './contextRepresentation';

export interface OpenCorporatesRepresentation {
  id?: string;
  profileContext?: OpenCorporatesRepresentation.ProfileContextEnum;
  type?: OpenCorporatesRepresentation.TypeEnum;
  modifiedAt?: number;
  status?: OpenCorporatesRepresentation.StatusEnum;
  visibility?: OpenCorporatesRepresentation.VisibilityEnum;
  targetId?: string;
  invited?: boolean;
  hidden?: boolean;
  verified?: boolean;
  managed?: boolean;
  format?: OpenCorporatesRepresentation.FormatEnum;
  forwardCode?: string;
  paymentVerified?: boolean;
  propertyContexts?: Array<ContextRepresentation>;
  contextIdsToDelete?: Array<string>;
  openCorporatesId?: string;
}
export namespace OpenCorporatesRepresentation {
  export type ProfileContextEnum = 'INDIVIDUAL' | 'ORGANISATION';
  export const ProfileContextEnum = {
    INDIVIDUAL: 'INDIVIDUAL' as ProfileContextEnum,
    ORGANISATION: 'ORGANISATION' as ProfileContextEnum,
  };
  export type TypeEnum = 'PUBLIC_PROFILE' | 'ME_PROFILE' | 'CONTACT';
  export const TypeEnum = {
    PUBLIC_PROFILE: 'PUBLIC_PROFILE' as TypeEnum,
    ME_PROFILE: 'ME_PROFILE' as TypeEnum,
    CONTACT: 'CONTACT' as TypeEnum,
  };
  export type StatusEnum = 'STATIC' | 'DYNAMIC';
  export const StatusEnum = {
    STATIC: 'STATIC' as StatusEnum,
    DYNAMIC: 'DYNAMIC' as StatusEnum,
  };
  export type VisibilityEnum = 'PUBLIC' | 'PRIVATE' | 'WITHIN_AZTRIX';
  export const VisibilityEnum = {
    PUBLIC: 'PUBLIC' as VisibilityEnum,
    PRIVATE: 'PRIVATE' as VisibilityEnum,
    WITHIN_AZTRIX: 'WITHIN_AZTRIX' as VisibilityEnum,
  };
  export type FormatEnum = 'MINI' | 'SUMMARY' | 'FULL' | 'ID';
  export const FormatEnum = {
    MINI: 'MINI' as FormatEnum,
    SUMMARY: 'SUMMARY' as FormatEnum,
    FULL: 'FULL' as FormatEnum,
    ID: 'ID' as FormatEnum,
  };
}
