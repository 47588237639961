export enum ContextType {
  COMMON = 'COMMON',
  PROFILE = 'PROFILE',
  LEGAL = 'LEGAL',
  PERSONAL = 'PERSONAL',
  GENERAL = 'GENERAL',
  WORK = 'WORK',
  GROUP = 'GROUP',
  PROPOSAL = 'PROPOSAL',
  MISC = 'MISC',
  DOCUMENTS = 'DOCUMENTS',
}
