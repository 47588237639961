/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {TierSubscriptionRepresentation} from './tierSubscriptionRepresentation';
import {CurrentSubscriptionInfo} from './currentSubscriptionInfo';

export interface TierSubscriptionInfoRepresentation {
  currentTier?: TierSubscriptionInfoRepresentation.CurrentTierEnum;
  credits?: number;
  pendingDowngradeTier?: TierSubscriptionInfoRepresentation.PendingDowngradeTierEnum;
  renewalDate?: number;
  currentSubscriptionInfo?: CurrentSubscriptionInfo;
  availableTiers?: Array<TierSubscriptionRepresentation>;
}
export namespace TierSubscriptionInfoRepresentation {
  export type CurrentTierEnum = 'FREE' | 'BASIC' | 'PRO' | 'ENTERPRISE';
  export const CurrentTierEnum = {
    FREE: 'FREE' as CurrentTierEnum,
    BASIC: 'BASIC' as CurrentTierEnum,
    PRO: 'PRO' as CurrentTierEnum,
    ENTERPRISE: 'ENTERPRISE' as CurrentTierEnum,
  };
  export type PendingDowngradeTierEnum = 'FREE' | 'BASIC' | 'PRO' | 'ENTERPRISE';
  export const PendingDowngradeTierEnum = {
    FREE: 'FREE' as PendingDowngradeTierEnum,
    BASIC: 'BASIC' as PendingDowngradeTierEnum,
    PRO: 'PRO' as PendingDowngradeTierEnum,
    ENTERPRISE: 'ENTERPRISE' as PendingDowngradeTierEnum,
  };
}
