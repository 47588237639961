import {Context, ContextType, Property} from '@aztrix/models';
import {ContextRepresentation, PropertyRepresentation} from '@aztrix/sdk';

import {idFilter} from '../util/filter.util';
import {not} from '../util/predicate.util';
import {getProperties} from './property-functions';

export class ContextBuilder {
  constructor(
    public id: string,
    public type: ContextType | ContextRepresentation.TypeEnum,
    public label: string,
    public properties: (Property | PropertyRepresentation)[]
  ) {}

  static fromContext(context: Context | ContextRepresentation): ContextBuilder {
    return new ContextBuilder(
      context.id || '',
      <ContextRepresentation.TypeEnum>context.type,
      context.label || '',
      context.properties ? [...context.properties] : []
    );
  }

  static empty(): ContextBuilder {
    return new ContextBuilder('', ContextType.COMMON, '', []);
  }

  static updatePropertyInContextMapper(
    oldContext: Context | ContextRepresentation,
    oldProperty: Property | PropertyRepresentation,
    newProperty: Property | PropertyRepresentation
  ): Context | ContextRepresentation {
    let filterFn = (p: Property | undefined) => p !== oldProperty;
    if (oldProperty.id) {
      filterFn = not(idFilter(oldProperty.id));
    }
    return ContextBuilder.fromContext(oldContext)
      .setProperties(getProperties(oldContext, filterFn).concat(newProperty ? [newProperty] : []))
      .build();
  }

  build(): Context {
    return <Context>{
      id: this.id,
      type: this.type,
      label: this.label,
      properties: this.properties,
    };
  }

  setId(value: string): this {
    this.id = value;
    return this;
  }

  setType(value: ContextType): this {
    this.type = value;
    return this;
  }

  setLabel(value: string): this {
    this.label = value;
    return this;
  }

  setProperties(value: Property[]): this {
    this.properties = value;
    return this;
  }
}
