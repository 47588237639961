import {Pipe, PipeTransform} from '@angular/core';

import {flagForLanguage} from '../util/locale.util';

@Pipe({
  name: 'flagForLanguage',
})
export class FlagForLanguagePipe implements PipeTransform {
  transform(languageCode: string | undefined): any {
    return flagForLanguage(languageCode);
  }
}
