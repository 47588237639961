import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {TranslocoPipe} from '@ngneat/transloco';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE} from './language_override';
import {TranslateService} from './translate.service';

@Pipe({
  name: 'translate',
  pure: false,
  standalone: true,
})
export class TranslatePipe extends TranslocoPipe implements PipeTransform {
  constructor(
    translateService: TranslateService,
    changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    languageOverride$?: BehaviorSubject<string | undefined>
  ) {
    super(translateService, undefined, languageOverride$?.value, changeDetector);
  }
}
