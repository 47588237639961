/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {AlistItemRepresentation} from './alistItemRepresentation';

export interface AlistItemWithItemsRepresentation {
  id?: string;
  dataType?: AlistItemWithItemsRepresentation.DataTypeEnum;
  type?: AlistItemWithItemsRepresentation.TypeEnum;
  format?: AlistItemWithItemsRepresentation.FormatEnum;
  title?: string;
  visible?: boolean;
  orderIndex?: number;
  icon?: string;
  data?: Array<AlistItemRepresentation>;
}
export namespace AlistItemWithItemsRepresentation {
  export type DataTypeEnum = 'STRING' | 'PROPERTY' | 'PROPERTIES' | 'ITEMS';
  export const DataTypeEnum = {
    STRING: 'STRING' as DataTypeEnum,
    PROPERTY: 'PROPERTY' as DataTypeEnum,
    PROPERTIES: 'PROPERTIES' as DataTypeEnum,
    ITEMS: 'ITEMS' as DataTypeEnum,
  };
  export type TypeEnum =
    | 'HEADER'
    | 'TEXT'
    | 'PROPERTY'
    | 'PROPERTIES'
    | 'PRODUCT_CARD'
    | 'ITEMS'
    | 'PROPOSAL'
    | 'PAYMENT'
    | 'STRING';
  export const TypeEnum = {
    HEADER: 'HEADER' as TypeEnum,
    TEXT: 'TEXT' as TypeEnum,
    PROPERTY: 'PROPERTY' as TypeEnum,
    PROPERTIES: 'PROPERTIES' as TypeEnum,
    PRODUCT_CARD: 'PRODUCT_CARD' as TypeEnum,
    ITEMS: 'ITEMS' as TypeEnum,
    PROPOSAL: 'PROPOSAL' as TypeEnum,
    PAYMENT: 'PAYMENT' as TypeEnum,
    STRING: 'STRING' as TypeEnum,
  };
  export type FormatEnum = 'BUTTON' | 'ICON' | 'LINK' | 'PAGE' | 'COLLAPSE' | 'PROPERTY';
  export const FormatEnum = {
    BUTTON: 'BUTTON' as FormatEnum,
    ICON: 'ICON' as FormatEnum,
    LINK: 'LINK' as FormatEnum,
    PAGE: 'PAGE' as FormatEnum,
    COLLAPSE: 'COLLAPSE' as FormatEnum,
    PROPERTY: 'PROPERTY' as FormatEnum,
  };
}
