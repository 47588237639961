import {Inject, Injectable, Optional} from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_FALLBACK_STRATEGY,
  TRANSLOCO_INTERCEPTOR,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  TRANSLOCO_TRANSPILER,
  TranslocoConfig,
  TranslocoFallbackStrategy,
  TranslocoInterceptor,
  TranslocoLoader,
  TranslocoMissingHandler,
  TranslocoService,
  TranslocoTranspiler,
} from '@ngneat/transloco';
import {BehaviorSubject, Observable, of} from 'rxjs';

import {FALLBACK_LANG} from './translate-loader';

@Injectable({
  providedIn: 'root',
})
export class TranslateService extends TranslocoService {
  lang$ = new BehaviorSubject(super.getActiveLang());

  constructor(
    @Optional() @Inject(TRANSLOCO_LOADER) loader: TranslocoLoader,
    @Inject(TRANSLOCO_TRANSPILER) parser: TranslocoTranspiler,
    @Inject(TRANSLOCO_MISSING_HANDLER) missingHandler: TranslocoMissingHandler,
    @Inject(TRANSLOCO_INTERCEPTOR) interceptor: TranslocoInterceptor,
    @Inject(TRANSLOCO_CONFIG) userConfig: TranslocoConfig,
    @Inject(TRANSLOCO_FALLBACK_STRATEGY)
    fallbackStrategy: TranslocoFallbackStrategy
  ) {
    super(loader, parser, missingHandler, interceptor, userConfig, fallbackStrategy);
    this.langChanges$.subscribe((lang) => {
      this.lang$.next(lang);
    });
  }

  override setActiveLang(lang: string | undefined): this {
    if (!lang || !this._getAvailableLangs().includes(lang)) {
      lang = this.getDefaultLang();
    }

    if (lang !== this.getActiveLang()) {
      super.setActiveLang(lang);
    }
    return this;
  }

  getLangs() {
    const langs = this.getAvailableLangs();
    if (this.getDefaultLang() !== FALLBACK_LANG) {
      langs.splice(langs.indexOf(FALLBACK_LANG), 1);
    }
    return langs;
  }

  override getAvailableLangs(): string[] {
    return [...new Set(this._getAvailableLangs())];
  }

  get(key: string, placeholder: {[key: string]: string} = {}, lang?: string): Observable<string> {
    if (this._validTranslationKey(key)) {
      return this.selectTranslate(key, placeholder, lang);
    }
    return of(key);
  }

  instant(key: string, placeholder?: {[key: string]: string}, lang?: string) {
    if (this._validTranslationKey(key)) {
      return this.translate(key, placeholder, lang);
    }
    return key;
  }

  private _getAvailableLangs(): string[] {
    return <string[]>super.getAvailableLangs();
  }

  private _validTranslationKey(key: string): boolean {
    const valid = (key.match(/\w*[.]\w+/gm) || []).length > 0;

    if (!valid) {
      if (this.config.prodMode) {
        console.warn(`invalid translation key '${key}'`);
      } else {
        throw new Error(`invalid translation key '${key}'`);
      }
    }

    return valid;
  }
}
