import {Injectable} from '@angular/core';
import {
  AlistItemRepresentation,
  AlistItemWithItemsRepresentation,
  AlistItemWithPropertyRepresentation,
  AlistPropertyRepresentation,
  ContextRepresentation,
  ProfilePropertyRepresentation,
  VcardsService,
} from '@aztrix/sdk';
import {first} from 'rxjs';

import {itemDownloadable} from '../helpers/item-functions';
import {isSocialRelated} from '../helpers/property-functions';
import {downloadDataAsFile} from '../util/file.util';

@Injectable({
  providedIn: 'any',
})
export class DownloadVcardService {
  constructor(private _vcardsService: VcardsService) {}

  downloadItemAsVcf(name: string, item: AlistItemRepresentation) {
    if (!itemDownloadable(item)) {
      return;
    }

    const properties: ProfilePropertyRepresentation[] = [];
    if (item.type === AlistItemWithPropertyRepresentation.TypeEnum.PROPERTIES) {
      const itemWithProperties = <AlistItemWithItemsRepresentation>item;
      const itemData = itemWithProperties.data ?? [];
      for (const propertyItem of itemData) {
        this._addPropertyData(<AlistPropertyRepresentation>propertyItem.data, properties);
      }
    } else {
      if (item.type === AlistItemWithPropertyRepresentation.TypeEnum.STRING) {
        properties.push(<AlistPropertyRepresentation>{
          type: AlistPropertyRepresentation.TypeEnum.WEBSITE,
          value: item.data,
        });
      } else {
        const data = <AlistPropertyRepresentation>item.data;
        this._addPropertyData(data, properties);
      }
    }
    this._vcardsService
      .createVcard([{type: ContextRepresentation.TypeEnum.PERSONAL, properties}])
      .pipe(first())
      .subscribe((vCard) => downloadDataAsFile(vCard, `${name}.vcf`, 'text/vcard'));
  }

  private _addPropertyData(
    property: AlistPropertyRepresentation,
    properties: ProfilePropertyRepresentation[]
  ) {
    const data = <AlistPropertyRepresentation>property;

    if (isSocialRelated(data)) {
      properties.push(<AlistPropertyRepresentation>{
        type: AlistPropertyRepresentation.TypeEnum.WEBSITE,
        value: property.value,
      });
    } else if (data.type === AlistPropertyRepresentation.TypeEnum.NAME) {
      for (const property of data.properties ?? []) {
        properties.push(property);
      }
    } else {
      properties.push(data);
    }
  }
}
