export enum ForwardType {
  QR = 'QR',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
  WHATSAPP = 'WHATSAPP',
  INSTAGRAM = 'INSTAGRAM',
  EMAIL = 'EMAIL',
  VCARD = 'VCARD',
  LINK = 'LINK',
  EMBED = 'EMBED',
}
