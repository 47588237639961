import {Inject, Pipe, PipeTransform} from '@angular/core';
import {Locale} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LOCALE} from '@aztrix/translate';
import {formatPercent} from '../util/number.util';

@Pipe({
  name: 'formatPercent',
})
export class FormatPercentPipe implements PipeTransform {
  constructor(@Inject(LOCALE) private _locale$: BehaviorSubject<Locale>) {}

  transform(value: unknown, digitsInfo?: string): string {
    try {
      let minIntegerDigits = '';
      let minFractionDigits = '';
      let maxFractionDigits = '';
      if (digitsInfo) {
        const split = digitsInfo.split('.');
        minIntegerDigits = split[0];
        [minFractionDigits, maxFractionDigits] = split[1].split('-');
      }
      return formatPercent(
        value,
        this._locale$.value.languageCode,
        parseInt(minIntegerDigits, 10) || 1,
        parseInt(minFractionDigits, 10) || 0,
        parseInt(maxFractionDigits, 10) || 2
      );
    } catch (error) {
      console.error(`Invalid formatPercent pipe arguments:\n${error}`);
      return '-';
    }
  }
}
