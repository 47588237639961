import {Inject, Pipe, PipeTransform} from '@angular/core';
import {Locale} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LOCALE} from '@aztrix/translate';
import {formatDateLongFromNow} from '../util/date.util';

/**
 * Transforms a Date object into a formatted string indicating the time difference
 * between now and the given time ('About 2 hours ago', 'Apr 29, 1453', ...).
 **/
@Pipe({name: 'formatDateLongFromNow', pure: false})
export class FormatDateLongFromNowPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>
  ) {}

  transform(value: string | number | Date): string {
    return formatDateLongFromNow(value, this._locale$.value);
  }
}
