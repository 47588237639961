import {VerificationStatus} from '../verification/verification-status';
import {Visibility} from '../visibility/visibility';
import {AccessStatus} from './access-status';
import {Property} from './property';
import {PropertyType} from './property-type';

export class MutableProperty implements Property {
  public id?: string;
  public type?: PropertyType;

  constructor(
    id?: string,
    type?: PropertyType,
    public value?: string | undefined,
    public properties?: MutableProperty[],
    public visibility?: Visibility,
    public accessStatus?: AccessStatus,
    public verification?: VerificationStatus
  ) {
    this.id = id;
    this.type = type;
  }

  public static from(property: Property): MutableProperty {
    if (!property) {
      return this.empty();
    }
    const properties =
      property.properties && property.properties.length
        ? property.properties.map((p) => MutableProperty.from(p))
        : undefined;
    return new MutableProperty(
      property.id,
      property.type,
      property.value,
      properties,
      property.visibility,
      property.accessStatus,
      property.verification
    );
  }

  public static empty(): MutableProperty {
    return new MutableProperty();
  }

  public static toProperty(property: Property): Property {
    const p = {
      id: property.id,
      type: property.type,
      value: property.value,
      properties: property.properties,
      visibility: property.visibility,
      accessStatus: property.accessStatus,
      verification: property.verification,
    };

    if (!property?.properties?.length) {
      delete p.properties;
    }

    return <Property>p;
  }
}
