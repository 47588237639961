import {Inject, Pipe, PipeTransform} from '@angular/core';
import {Locale} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LOCALE} from '@aztrix/translate';
import {formatDay} from '../util/date.util';

/**
 * Transforms a Date object into a day formatted string.
 *
 * @param short a boolean indicating short format when true ('We'),
 * or long format when false ('Wednesday')
 **/
@Pipe({name: 'formatDay', pure: false})
export class FormatDayPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>
  ) {}

  transform(value: string | number | Date, short = true, lang?: string | null): string {
    const locale: Locale = lang ? {languageCode: lang} : this._locale$.value;
    return formatDay(value, locale, short);
  }
}
