/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {LegalRepresentativeRepresentation} from './legalRepresentativeRepresentation';
import {SeoSettingsRepresentation} from './seoSettingsRepresentation';

export interface AccountSettingsRepresentation {
  loginValue?: string;
  loginType?: string;
  languageCode?: string;
  countryCode?: string;
  collectAnalytics?: boolean;
  newsletterAccepted?: boolean;
  legalRepresentative?: LegalRepresentativeRepresentation;
  tier?: AccountSettingsRepresentation.TierEnum;
  credits?: number;
  seoSettings?: SeoSettingsRepresentation;
  webId?: string;
}
export namespace AccountSettingsRepresentation {
  export type TierEnum = 'FREE' | 'BASIC' | 'PRO' | 'ENTERPRISE';
  export const TierEnum = {
    FREE: 'FREE' as TierEnum,
    BASIC: 'BASIC' as TierEnum,
    PRO: 'PRO' as TierEnum,
    ENTERPRISE: 'ENTERPRISE' as TierEnum,
  };
}
