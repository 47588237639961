import {Property, PropertyType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';

import {ADDRESS_FORMATS, REGION_FORMATS} from './metadata';

export type AddressLineRepresentation = Array<{
  propertyType: PropertyType;
  length?: number;
}>;

export class AddressFormats {
  public static addressFormatForProperty(
    property: Property | PropertyRepresentation | undefined
  ): AddressLineRepresentation[] {
    const format = this._formats(property?.type);
    const country = (<Property[]>property?.properties || []).find(
      (prop) => prop.type === PropertyType.COUNTRY
    );
    return <any>format[(country ? country.value : '') as keyof AddressFormats] || format[''];
  }

  public static addressFormatForPropertyType(
    type: PropertyType | undefined,
    countryCode?: string
  ): AddressLineRepresentation[] {
    const format = this._formats(type);
    return format[(countryCode || '') as keyof AddressFormats] || format[''];
  }

  private static _formats(type?: PropertyType) {
    return type === PropertyType.ADDRESS ? ADDRESS_FORMATS : REGION_FORMATS;
  }
}
