export class EnvFileParser {
  static parse(configuration: string): {[name: string]: string} {
    const lines = configuration.split(/\r?\n/);
    return lines.reduce((result, line) => {
      const [name, value] = line.split('=');
      if (name && value) {
        return {...result, [name]: value};
      } else {
        return result;
      }
    }, {});
  }
}
