import {KeyValue} from '@angular/common';
import {isDevMode} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FieldDefinition, FieldValue, Page, PageLanguage, PageTemplate} from '@aztrix/models';

import {unique} from '../util/array.util';

export function isPage(page: any): page is Page {
  return page?.templateId;
}

export function isPageLanguage(pageLanguage: any): pageLanguage is PageLanguage {
  if (!pageLanguage) {
    return false;
  }
  return (
    Object.keys(pageLanguage).includes('appearance') &&
    Object.keys(pageLanguage).includes('languageCode') &&
    Object.keys(pageLanguage).includes('fieldValues')
  );
}

export function isActivePageLanguage(language: PageLanguage): boolean {
  return !language?.pending;
}

export function isPendingPageLanguage(language: PageLanguage): boolean {
  return language?.pending;
}

function getLatestPageLanguages(page: Page, languageCode: string): PageLanguage[] {
  if (!page?.languages?.length) {
    return [];
  }

  const languages = getPageLanguages(page, languageCode);
  const activeLanguages = getActivePageLanguages(page, languageCode);
  const pendingLanguages = getPendingPageLanguages(page, languageCode);
  return languages
    .filter(
      (language) =>
        !activeLanguages.find((l) => (l.creationTimestamp || 0) > (language.creationTimestamp || 0))
    )
    .filter(
      (language) =>
        !pendingLanguages.find(
          (l) => (l.creationTimestamp || 0) > (language.creationTimestamp || 0)
        )
    );
}

/**
 * Gets all the last languages for all languageCodes
 * For example:
 * [{creationTimestamp: 0, languageCode: 'nl'}, {creationTimestamp: 1, languageCode: 'en'}, {creationTimestamp: 1, pending: true, languageCode: 'en'}]
 * Result:
 * [{creationTimestamp: 0, languageCode: 'nl'}, {creationTimestamp: 1, pending: true, languageCode: 'en'}]
 *
 * @param page the page to get the languages from
 */
export function getLastPageLanguages(page: Page): PageLanguage[] {
  const languageCodes = unique(page.languages.map((l) => l.languageCode));
  return languageCodes.map((languageCode) => {
    return getLatestPageLanguage(page, languageCode);
  });
}

/**
 * Gets the latest language for the provided languageCode
 * For example:
 * [{creationTimestamp: 0}, {creationTimestamp: 1}, {creationTimestamp: 1, pending: true}]
 * Result:
 * {creationTimestamp: 1, pending: true}
 *
 * @param page the page to get the language from
 * @param languageCode the languageCode to find a language for
 */
export function getLatestPageLanguage(page: Page, languageCode: string): PageLanguage {
  const languages = getLatestPageLanguages(page, languageCode);
  languages.reverse();
  return languages[0];
}

/**
 * Gets all the active languages for the provided languageCode
 * For example:
 * [{creationTimestamp: 0}, {creationTimestamp: 1}, {creationTimestamp: 1, pending: true}]
 * Result:
 * [{creationTimestamp: 0}, {creationTimestamp: 1}]
 *
 * @param page the page to get the languages from
 * @param languageCode the languageCode to find languages for
 */
export function getActivePageLanguages(page: Page, languageCode: string): PageLanguage[] {
  const languages = getPageLanguages(page, languageCode);
  return languages.filter(isActivePageLanguage);
}

/**
 * Gets the active language for the provided languageCode
 *
 * @param page the page to get the languages from
 * @param languageCode the languageCode to find languages for
 */
export function getActivePageLanguage(page: Page, languageCode: string): PageLanguage {
  const languages = getActivePageLanguages(page, languageCode);
  languages.reverse();
  return languages[0];
}

/**
 * Gets all the pending languages for the provided languageCode
 * For example:
 * [{creationTimestamp: 0, pending: true}, {creationTimestamp: 1},
 * {creationTimestamp: 1, pending: true}]
 * Result:
 * [{creationTimestamp: 0, pending: true}, {creationTimestamp: 1, pending: true}]
 *
 * @param page the page to get the languages from
 * @param languageCode the languageCode to find languages for
 */
export function getPendingPageLanguages(page: Page, languageCode: string): PageLanguage[] {
  const languages = getPageLanguages(page, languageCode);
  return languages.filter(isPendingPageLanguage);
}

/**
 * Gets the default language
 * For example:
 * [{creationTimestamp: 0}, {creationTimestamp: 1, isDefault: true},
 * {creationTimestamp: 1, pending: true}]
 * Result:
 * {creationTimestamp: 1, isDefault: true}
 *
 * @param page the page to get the language of
 */
export function getDefaultPageLanguage(page: Page): PageLanguage | undefined {
  if (!page || !page.languages?.length) {
    return undefined;
  }
  return (page?.languages || []).find((l) => l.isDefault) ?? page?.languages[0];
}

/**
 * Gets all the languages for the provided languageCode
 *
 * @param page the page to get the languages from
 * @param languageCode the languageCode to find languages for
 */
export function getPageLanguages(page: Page, languageCode: string): PageLanguage[] {
  if (!page?.languages?.length) {
    return [];
  }
  return page.languages
    .filter((l) => l.languageCode === languageCode)
    .sort((l1, l2) => {
      const creationTimestamp1 = l1.creationTimestamp || 0;
      const creationTimestamp2 = l2.creationTimestamp || 0;

      if (creationTimestamp1 === creationTimestamp2) {
        if (l1.pending === l2.pending) {
          return 0;
        }
        return l1.pending && !l2.pending ? 1 : -1;
      }
      return creationTimestamp1 > creationTimestamp2 ? 1 : -1;
    });
}

/**
 * Gets the latest pending language for the provided languageCode
 * For example:
 * [{creationTimestamp: 0}, {creationTimestamp: 1}, {creationTimestamp: 1, pending: true}]
 * Result:
 * {creationTimestamp: 1, pending: true}
 *
 * @param page the page to get the language from
 * @param languageCode the languageCode to find a language for
 */
export function getPendingPageLanguage(page: Page, languageCode: string): PageLanguage | undefined {
  const languages = getLatestPageLanguages(page, languageCode);
  if (languages?.length) {
    languages.reverse();
    return languages?.find(isPendingPageLanguage) || languages?.find(isActivePageLanguage);
  } else {
    return undefined;
  }
}

interface FieldValueLike {
  id: string;
  value?: string;
  fieldValues?: FieldValue[];
}

export function getFieldValue(
  language: PageLanguage | FieldValue | undefined,
  ...ids: any[]
): string | FieldValue[] {
  let subject = <FieldValueLike>(<any>language);
  for (const id of ids) {
    if (!(subject?.fieldValues || [])?.length) {
      if (isDevMode()) {
        console.error(`unable to find id '${id}' in undefined subject`);
      }
      return '';
    }
    const foundSubject = (subject?.fieldValues || []).find((f) => f.id === id);
    if (foundSubject) {
      subject = foundSubject;
    } else if (isDevMode()) {
      console.error(`unable to find id '${id}' in '${subject?.id}'`);
      return '';
    }
  }

  if (!subject) {
    return '';
  }
  return Array.isArray(subject.fieldValues) && subject.fieldValues.length
    ? subject.fieldValues
    : subject.value ?? [];
}

export function getFieldValueBoolean(language: PageLanguage | FieldValue, ...ids: any[]): boolean {
  return getFieldValue(language, ...ids) === 'true';
}

export function getFieldValueString(language: PageLanguage | FieldValue, ...ids: string[]): string {
  return <string>getFieldValue(language, ...ids);
}

export function getFieldValueFieldValues(
  language: PageLanguage | FieldValue,
  ...ids: any[]
): FieldValue[] {
  return <FieldValue[]>getFieldValue(language, ...ids);
}

export function findFieldDefinition(
  fieldForm: UntypedFormGroup,
  fieldDefinition: PageTemplate | FieldDefinition
): FieldDefinition | undefined {
  if (!fieldForm || !fieldDefinition) {
    return undefined;
  }
  const id = fieldForm.get('id')?.value;
  if (!id) {
    return undefined;
  }

  if (!fieldDefinition?.fieldDefinitions) {
    return <FieldDefinition>fieldDefinition;
  }
  let foundFieldDefinition = fieldDefinition.fieldDefinitions.find((f) => f.id === id);
  if (!foundFieldDefinition) {
    foundFieldDefinition = fieldDefinition.fieldDefinitions[0];
  }
  return foundFieldDefinition;
}

export function pagePreviewUrlPrefix(): string {
  if (isDevMode()) {
    return 'pages.develop.aztrix.me/';
  } else {
    return `pages.${location.host}/`;
  }
}

export function pagePreviewUrlSuffix(
  languageCode: string,
  queryParams?: KeyValue<string, string>[]
): string {
  let suffix = `?lang=${languageCode}`;

  if (queryParams) {
    for (const param of queryParams) {
      suffix += `&${param.key}=${param.value}`;
    }
  }

  return suffix;
}

export function getPageLanguageUrl(
  language: PageLanguage,
  queryParams?: KeyValue<string, string>[]
) {
  return `https://${pagePreviewUrlPrefix()}${language.settings?.url}${pagePreviewUrlSuffix(
    language.languageCode,
    queryParams
  )}`;
}
