import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

import {AbstractButton} from './abstract-button';

@Component({
  selector: 'ax-button',
  templateUrl: 'button.component.html',
})
export class ButtonComponent extends AbstractButton implements OnChanges {
  @Input() url: string;
  @Input() ariaLabel: string;
  @Input() target: '_blank' | undefined;
  @HostBinding('style.pointer-events') pointerEvents = 'auto';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled !== undefined || changes.loading !== undefined) {
      this.pointerEvents = this.disabled || this._loading ? 'none' : 'auto';
    }
  }

  get routerLink() {
    if (this.url?.includes('https://') || this.url?.includes('http://')) {
      return undefined;
    } else {
      return this.url;
    }
  }

  get href() {
    if (this.url?.includes('https://') || this.url?.includes('http://')) {
      return this.url;
    } else {
      return undefined;
    }
  }
}
