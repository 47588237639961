<svg
  viewBox="0 0 28 24"
  version="1.1"
  width="28"
  height="24"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
>
  <circle class="circle circle1" cx="4" cy="12" r="4" />
  <circle class="circle circle2" cx="14" cy="12" r="4" />
  <circle class="circle circle3" cx="24" cy="12" r="4" />
</svg>
<ng-content></ng-content>
