import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DocumentRepresentation} from '@aztrix/sdk';

export class DocumentsForm {
  static createSigningOrderDocumentSignForm(documents: DocumentRepresentation[]): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    const verifiedDocuments = documents.filter((document) => !document.signingRequired);
    const signableDocuments = documents.filter((document) => document.signingRequired);
    form.addControl('verifiedDocuments', DocumentsForm.createDocumentForm(verifiedDocuments));
    form.addControl('signableDocuments', DocumentsForm.createDocumentForm(signableDocuments));
    return form;
  }

  static createDocumentForm(documents: DocumentRepresentation[]): UntypedFormArray {
    const documentForm = new UntypedFormArray([]);
    for (const document of documents) {
      documentForm.push(
        new UntypedFormGroup({
          id: new UntypedFormControl(document.id),
          name: new UntypedFormControl(document.name),
          type: new UntypedFormControl(document.type),
          filename: new UntypedFormControl(document.filename),
          checkboxLabel: new UntypedFormControl(document.checkboxLabel),
          value: new UntypedFormControl(document.value),
          description: new UntypedFormControl(document.description),
          orderIndex: new UntypedFormControl(document.orderIndex),
          signingRequired: new UntypedFormControl(document.signingRequired),
          signed: new UntypedFormControl(false, Validators.requiredTrue),
        })
      );
    }
    return documentForm;
  }
}
