import {Group, Page, Profile, Property, Proposal} from '@aztrix/models';
import {ProfileRepresentation, ProposalRepresentation} from '@aztrix/sdk';
import {Observable, of} from 'rxjs';

import {isGroup} from './group-functions';
import {isPage} from './page-functions';
import {
  displayNameByProperties,
  isDeflatedProfile,
  profileContext,
  profileUsername,
} from './profile-functions';
import {isProposal, proposalTitle} from './proposal-functions';
import {TranslateService} from '@aztrix/translate';

/**
 * Transforms a `profile`, `group`, `proposal` or `page` into a name.
 **/
export function displayName(
  translate: TranslateService,
  value: Profile | Group | Proposal | Page | ProfileRepresentation | ProposalRepresentation,
  noNameLabel: string = translate ? translate.instant(`label.noname`) : '',
  filter: (p: Property) => boolean = () => true
): string {
  if (!value) {
    return '';
  }

  let name: string | undefined = '';
  if (isDeflatedProfile(value)) {
    name = value.name;
  } else if (isGroup(value)) {
    if (value.name.length > 0) {
      return value.name;
    }
    name = translate.instant(`group.${value.type}.label`);
  } else if (isProposal(value)) {
    name = proposalTitle(value, translate.getActiveLang());
  } else if (isPage(value)) {
    name = value.name;
  } else {
    const context = profileContext(<ProfileRepresentation>value);
    if (!context) {
      return '';
    }

    if (context.properties) {
      return displayNameByProperties(context.properties, filter);
    } else {
      return '';
    }
  }
  return !name || !name.length ? noNameLabel || '' : name;
}

export function displayName$(
  translate: TranslateService,
  value:
    | Profile
    | ProfileRepresentation
    | Group
    | Proposal
    | ProposalRepresentation
    | Page
    | null
    | undefined,
  noNameLabel?: string,
  lang?: string,
  filter: (p: Property) => boolean = () => true
): Observable<string> {
  if (!value) {
    return of('');
  }

  let name: string | undefined = '';
  if (isDeflatedProfile(value)) {
    if (value.name !== '') {
      name = value.name.trim();
    } else {
      name = profileUsername(value);
    }
  } else if (isGroup(value)) {
    if (value.name.length > 0) {
      return of(value.name);
    }
    return translate.get(`group.${value.type}.label`, undefined, lang);
  } else if (isProposal(value)) {
    name = proposalTitle(value, lang || translate.getActiveLang());
  } else if (isPage(value)) {
    name = value.name;
  } else {
    const context = profileContext(<ProfileRepresentation>value);
    if (!context) {
      return of('');
    }

    if (context.properties) {
      return of(displayNameByProperties(context.properties, filter));
    } else {
      return of('');
    }
  }

  if (!name || !name.length) {
    if (noNameLabel) {
      return of(noNameLabel);
    } else {
      return translate.get('label.noname', undefined, lang);
    }
  } else {
    return of(name);
  }
}
