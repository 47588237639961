import {Pipe, PipeTransform} from '@angular/core';
import {ProposalLanguage} from '@aztrix/models';
import {RequestedPropertyRepresentation} from '@aztrix/sdk';

import {getPropertyTypeDescriptions} from '../../helpers/proposal-functions';

@Pipe({name: 'proposalPropertyDescriptions'})
export class ProposalPropertyDescriptionsPipe implements PipeTransform {
  transform(property: RequestedPropertyRepresentation, language: ProposalLanguage): string[] {
    return <string[]>getPropertyTypeDescriptions(property, language)
      .map((ptd) => ptd?.description)
      .filter((desc) => !!desc);
  }
}
