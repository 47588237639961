import {Component, Input} from '@angular/core';

import {
  OverlayOutletComponent,
  OverlayOutletShadowDomComponent,
} from './overlay-outlet/overlay-outlet.component';

@Component({
  selector: 'ax-overlay-container',
  template: `<ng-content class="overlay-container-content"></ng-content>
    <ax-overlay-outlet [preview]="preview"></ax-overlay-outlet>
    <ax-overlay-outlet-shadow-dom [preview]="preview"></ax-overlay-outlet-shadow-dom>`,
  styleUrls: [`./overlay-container.scss`],
  standalone: true,
  imports: [OverlayOutletComponent, OverlayOutletShadowDomComponent],
})
export class OverlayContainerComponent {
  @Input() preview = false;
}
