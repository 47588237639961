/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {ProfileRepresentation} from './profileRepresentation';
import {PropertyRequest} from './propertyRequest';

export interface InvitationRepresentation {
  status?: InvitationRepresentation.StatusEnum;
  requestor?: ProfileRepresentation;
  requestedProperties?: Array<PropertyRequest>;
  inviteeProfile?: ProfileRepresentation;
}
export namespace InvitationRepresentation {
  export type StatusEnum = 'ACTIVE' | 'COMPLETED' | 'CANCELED';
  export const StatusEnum = {
    ACTIVE: 'ACTIVE' as StatusEnum,
    COMPLETED: 'COMPLETED' as StatusEnum,
    CANCELED: 'CANCELED' as StatusEnum,
  };
}
