import {Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {PropertyType, ProposalLanguage, ProposalProperty} from '@aztrix/models';
import {ProposalLanguageRepresentation, RequestedPropertyRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {getPropertyTypeDescription} from '../../helpers/proposal-functions';
import {LANGUAGE_OVERRIDE, TranslateService} from '@aztrix/translate';
import {ProposalPropertyTypeLabelPipe} from './proposal-property-type-label.pipe';

@Pipe({name: 'proposalPropertyTitle', pure: false})
export class ProposalPropertyTitlePipe implements PipeTransform, OnDestroy {
  changes$ = new Subject<{
    property: ProposalProperty | RequestedPropertyRepresentation | undefined;
    language: ProposalLanguage | ProposalLanguageRepresentation | undefined;
    limit: number;
  }>();
  currentValue = '';

  subscription = new Subscription();

  constructor(
    private translate: TranslateService,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    languageOverride$?: BehaviorSubject<string | undefined>
  ) {
    this.subscription = this.changes$
      .pipe(
        switchMap(({property, language, limit}) =>
          ProposalPropertyTitlePipe.proposalPropertyTitle$(
            property,
            language,
            this.translate,
            limit,
            languageOverride$?.value
          )
        )
      )
      .subscribe((value) => {
        this.currentValue = value;
      });
  }

  static proposalPropertyTitle$(
    property: ProposalProperty | RequestedPropertyRepresentation | undefined,
    language: ProposalLanguage | ProposalLanguageRepresentation | undefined,
    translate: TranslateService,
    limit = Infinity,
    lang?: string
  ): Observable<string> {
    if (!property || !property.requestedPropertyTypes) {
      return of('');
    }

    const propertyTypes = [...property.requestedPropertyTypes]
      .sort((p1, p2) => {
        return (p1?.orderIndex || 0) < (p2?.orderIndex || 0) ? -1 : 1;
      })
      .map((pp) => pp.type);

    return combineLatest([
      combineLatest([
        ...propertyTypes.map((type) => {
          const description = getPropertyTypeDescription(
            language,
            property.requestedPropertyId,
            type
          );

          return ProposalPropertyTypeLabelPipe.proposalPropertyTypeLabel$(
            <PropertyType>type,
            description,
            translate,
            lang
          );
        }),
      ]),
      translate.get('label.or', {}, lang),
    ]).pipe(
      map(([propertyTypeLabels, orLabel]) => {
        if (propertyTypeLabels.length > limit) {
          return propertyTypeLabels.slice(0, limit).join(` ${orLabel} `) + ` ${orLabel} ` + '...';
        } else {
          return propertyTypeLabels.join(` ${orLabel} `);
        }
      })
    );
  }

  transform(
    property: ProposalProperty | RequestedPropertyRepresentation | undefined,
    language: ProposalLanguage | ProposalLanguageRepresentation | undefined,
    limit = Infinity
  ): string {
    this.changes$.next({property, language, limit});
    return this.currentValue;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
