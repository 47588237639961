import Color from 'color';

export function validColor(color: unknown): boolean {
  if (!color) {
    return false;
  }
  try {
    return !!Color(color).toString();
  } catch {
    return false;
  }
}

export function colorOrDefault(color: unknown, defaultColor: string): string {
  if (!validColor(defaultColor)) {
    throw new Error(`Invalid default color: '${defaultColor}'`);
  }

  if (validColor(color)) {
    return Color(<any>color).hex();
  } else {
    return Color(defaultColor).hex();
  }
}

export function getColor(
  color: string | undefined,
  lighten: number,
  saturate: number,
  defaultColor: string,
  isDecimal = false
): string {
  let colorResult = Color(colorOrDefault(color, defaultColor));

  if (lighten > 0) {
    colorResult = colorResult.lighten(lighten);
  } else if (lighten < 0) {
    colorResult = colorResult.darken(Math.abs(lighten));
  }

  if (saturate > 0) {
    colorResult = colorResult.saturate(saturate);
  } else if (saturate < 0) {
    colorResult = colorResult.desaturate(Math.abs(saturate));
  }

  if (isDecimal) {
    const rgbColor: number[] = colorResult.rgb().array();
    return `${rgbColor[0].toFixed(0)}, ${rgbColor[1].toFixed(0)}, ${rgbColor[2].toFixed(0)}`;
  } else {
    return colorResult.hex();
  }
}

export function colorIsLight(color: string) {
  return !validColor(color) || Color(color).isLight();
}

export function contrastingFontColor(color: string) {
  const contrast1 = contrast(color, '#000');
  const contrast2 = contrast(color, '#fff');

  return contrast1 > contrast2 ? '#000' : '#fff';
}

export function contrast(color1: string, color2: string): number {
  if (!validColor(color1) || !validColor(color2)) {
    return 0;
  }
  return Color(color1).contrast(Color(color2));
}
