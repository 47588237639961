export interface PaymentMethod {
  card: Card;
  created: number;
  id: string;
  type: string;
}

export interface Card {
  brand: string;
  country: string;
  expMonth: number;
  expYear: number;
  funding: string;
  last4: string;
}

export enum CurrencyType {
  EUR = 'eur',
  USD = 'usd',
}

export enum PaymentType {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export enum PaymentFrequency {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum PricingMethod {
  STANDARD = 'STANDARD',
  PAY_WHAT_YOU_WANT = 'PAY_WHAT_YOU_WANT',
}
