import {Pipe, PipeTransform} from '@angular/core';
import {Property, PropertyType} from '@aztrix/models';
import {RequestedPropertyTypeRepresentation} from '@aztrix/sdk';

import {iconNameForPropertyType} from '../../util/icon.util';

/**
 * Transforms a `property` OR `property type` into its `property type icon`.
 **/
@Pipe({name: 'propertyIcon'})
export class PropertyIconPipe implements PipeTransform {
  /**
   * @param value the `property` OR the `property type` to get the icon for.
   */
  transform(
    value?:
      | Property
      | PropertyType
      | RequestedPropertyTypeRepresentation.TypeEnum
      | null
      | undefined
  ): string {
    const type = typeof value === 'string' || !value ? value : value.type;
    return iconNameForPropertyType(<string>type);
  }
}
