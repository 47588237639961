import {Locale} from '@aztrix/models';

export function stringToLocale(locale: string, separator = '_'): Locale {
  try {
    if (!locale) {
      throw Error;
    }
    new Date().toLocaleDateString(locale.replace(separator, '-'));
  } catch {
    console.error('Invalid string or separator passed!');
    return <Locale>{
      languageCode: 'en',
      countryCode: 'US',
    };
  }
  if (!locale.includes(separator)) {
    return {languageCode: locale};
  }
  const split = locale.split(separator);
  return <Locale>{
    languageCode: split[0],
    countryCode: split[1],
  };
}

export function localeToString(locale: Locale): string {
  return `${locale.languageCode}-${locale.countryCode}`;
}

export function flagForLanguage(languageCode: string | undefined): string {
  switch (languageCode) {
    case 'en':
      return 'gb';
    case 'cs':
      return 'cz';
    case 'et':
      return 'ee';
    case 'sv':
      return 'se';
    default:
      return languageCode || 'be';
  }
}
