/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {AgreementPropertyRepresentation} from './agreementPropertyRepresentation';
import {AgreementDocumentRepresentation} from './agreementDocumentRepresentation';

export interface AgreementDataRepresentation {
  createdBy?: AgreementDataRepresentation.CreatedByEnum;
  creatorId?: string;
  startTimestamp?: number;
  endTimestamp?: number;
  ip?: string;
  userAgent?: string;
  manageLink?: string;
  confirmed?: boolean;
  language?: string;
  agreementProperties?: Array<AgreementPropertyRepresentation>;
  documents?: Array<AgreementDocumentRepresentation>;
}
export namespace AgreementDataRepresentation {
  export type CreatedByEnum = 'OWNER' | 'SUBSCRIBER';
  export const CreatedByEnum = {
    OWNER: 'OWNER' as CreatedByEnum,
    SUBSCRIBER: 'SUBSCRIBER' as CreatedByEnum,
  };
}
