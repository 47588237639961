import {Pipe, PipeTransform} from '@angular/core';
import {TierSubscriptionInfoRepresentation} from '@aztrix/sdk';

import {FeatureType, isFeatureAllowed} from '../../helpers/tier-functions';

@Pipe({name: 'isFeatureAllowed'})
export class IsFeatureAllowedPipe implements PipeTransform {
  transform(
    currentTier: TierSubscriptionInfoRepresentation.CurrentTierEnum | null | undefined,
    featureType: FeatureType
  ): boolean {
    return isFeatureAllowed(currentTier, featureType);
  }
}
