import {Pipe, PipeTransform} from '@angular/core';

import {isStatic} from '../../helpers/profile-functions';

@Pipe({
  name: 'isStatic',
})
export class IsStaticPipe implements PipeTransform {
  transform(value: any): boolean {
    return value && isStatic(value);
  }
}
