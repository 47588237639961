import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {AgreementRepresentation} from '@aztrix/sdk';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'agreementState',
  pure: false,
})
export class AgreementStatePipe implements PipeTransform {
  private _translatePipe: TranslatePipe;
  constructor(
    translate: TranslateService,
    changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    languageOverride$?: BehaviorSubject<string | undefined>
  ) {
    this._translatePipe = new TranslatePipe(translate, changeDetector, languageOverride$);
  }

  transform(agreement: AgreementRepresentation): string {
    return this._translatePipe.transform(this.agreementStateKey(agreement));
  }

  agreementStateKey(agreement: AgreementRepresentation) {
    if (!agreement.active) {
      return 'agreement.expired';
    }

    if (!agreement?.agreementData?.confirmed) {
      return 'agreement.status.not-confirmed';
    }

    if (agreement?.verified) {
      return 'agreement.verified';
    }

    return 'agreement.status.confirmed';
  }
}
