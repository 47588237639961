/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {FieldDefinitionValidation} from './fieldDefinitionValidation';
import {FieldDefinitionVisibleBasedOnFieldValue} from './fieldDefinitionVisibleBasedOnFieldValue';

export interface FieldDefinition {
  id?: string;
  label?: string;
  description?: string;
  type?: FieldDefinition.TypeEnum;
  validation?: FieldDefinitionValidation;
  visibleBasedOnFieldValue?: FieldDefinitionVisibleBasedOnFieldValue;
}
export namespace FieldDefinition {
  export type TypeEnum =
    | 'BOOLEAN'
    | 'TEXT'
    | 'RICH_TEXT'
    | 'TEXT_AREA'
    | 'IMAGE'
    | 'LIST'
    | 'SET'
    | 'PROPOSAL'
    | 'PROPERTY'
    | 'GROUP'
    | 'PROFILE'
    | 'FILE'
    | 'OR'
    | 'URL'
    | 'PAGE';
  export const TypeEnum = {
    BOOLEAN: 'BOOLEAN' as TypeEnum,
    TEXT: 'TEXT' as TypeEnum,
    RICH_TEXT: 'RICH_TEXT' as TypeEnum,
    TEXT_AREA: 'TEXT_AREA' as TypeEnum,
    IMAGE: 'IMAGE' as TypeEnum,
    LIST: 'LIST' as TypeEnum,
    SET: 'SET' as TypeEnum,
    PROPOSAL: 'PROPOSAL' as TypeEnum,
    PROPERTY: 'PROPERTY' as TypeEnum,
    GROUP: 'GROUP' as TypeEnum,
    PROFILE: 'PROFILE' as TypeEnum,
    FILE: 'FILE' as TypeEnum,
    OR: 'OR' as TypeEnum,
    URL: 'URL' as TypeEnum,
    PAGE: 'PAGE' as TypeEnum,
  };
}
