import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class FontsService {
  private _loadedFonts: string[] = [];

  fonts = [
    {value: 'lato', label: 'Lato', index: 'LATO', type: 'sans-serif'},
    {value: 'Roboto', label: 'Roboto', index: 'ROBOTO', type: 'sans-serif'},
    {value: 'OpenSans', label: 'Open Sans', index: 'OPEN_SANS', type: 'sans-serif'},
    {value: 'MavenPro', label: 'Maven Pro', index: 'MAVEN_PRO', type: 'sans-serif'},
    {value: 'NotoSans', label: 'Noto Sans', index: 'NOTO', type: 'sans-serif'},
    {value: 'Raleway', label: 'Raleway', index: 'RALEWAY', type: 'sans-serif'},
    {value: 'Oswald', label: 'Oswald', index: 'OSWALD', type: 'sans-serif'},
    {value: 'Barlow', label: 'Barlow', index: 'BARLOW', type: 'sans-serif'},
    {value: 'Merriweather', label: 'Merriweather', index: 'MERRIWEATHER', type: 'serif'},
    {value: 'Arvo', label: 'Arvo', index: 'ARVO', type: 'serif'},
    {value: 'RobotoSlab', label: 'Roboto Slab', index: 'ROBOTO_SLAB', type: 'serif'},
    {value: 'Bitter', label: 'Bitter', index: 'BITTER', type: 'serif'},
    {value: 'NotoSerif', label: 'Noto Serif', index: 'NOTO_SERIF', type: 'serif'},
    {value: 'IBMPlexSerif', label: 'IBM Plex Serif', index: 'IBM_PLEX_SERIF', type: 'serif'},
    {value: 'CrimsonText', label: 'Crimson Text', index: 'CRIMSON_TEXT', type: 'serif'},
    {
      value: 'PlayfairDisplay',
      label: 'Playfair Display',
      index: 'PLAYFAIR_DISPLAY',
      type: 'serif',
    },
    {value: 'Courgette', label: 'Courgette', index: 'COURGETTE', type: 'special'},
    {value: 'Calistoga', label: 'Calistoga', index: 'CALISTOGA', type: 'special'},
    {value: 'SpecialElite', label: 'Special Elite', index: 'SPECIAL_ELITE', type: 'special'},
    {value: 'BebasNeue', label: 'Bebas Neue', index: 'BEBAS_NEUE', type: 'special'},
    {value: 'Fredoka', label: 'Fredoka', index: 'FREDOKA', type: 'special'},
    {value: 'KaushanScript', label: 'Kaushan Script', index: 'KAUSHAN_SCRIPT', type: 'special'},
    {
      value: 'NanumPenScript',
      label: 'Nanum Pen Script',
      index: 'NANUM_PEN_SCRIPT',
      type: 'special',
    },
    {value: 'RobotoMono', label: 'Roboto Mono', index: 'ROBOTO_MONO', type: 'mono'},
    {value: 'SpaceMono', label: 'Space Mono', index: 'SPACE_MONO', type: 'mono'},
  ];

  async loadAllFonts() {
    for (const font of this.fonts) {
      this.loadFont(font.value);
    }
  }

  async loadFont(fontName: string) {
    if (this._loadedFonts.find((fn) => fn === fontName)) {
      return;
    }

    if (!this.fonts.find((f) => f.value === fontName) || fontName === 'lato') {
      return;
    }

    const fontFace = new FontFace(
      fontName,
      `url(assets/fonts/${fontName}.woff2), url(assets/fonts/${fontName}.woff), url(assets/fonts/${fontName}.ttf)`
    );
    const font = await fontFace.load();
    await (<any>document.fonts).add(font);
    this._loadedFonts.push(fontName);
  }
}
