/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {UserSuggestionCause} from './userSuggestionCause';

export interface UserSuggestionRepresentation {
  id?: string;
  targetId?: string;
  type?: UserSuggestionRepresentation.TypeEnum;
  seen?: boolean;
  causes?: Array<UserSuggestionCause>;
}
export namespace UserSuggestionRepresentation {
  export type TypeEnum =
    | 'NONE'
    | 'CONTACT_BASED'
    | 'PROFILE_BASED'
    | 'LOCATION_BASED'
    | 'UNRECOGNIZED';
  export const TypeEnum = {
    NONE: 'NONE' as TypeEnum,
    CONTACT_BASED: 'CONTACT_BASED' as TypeEnum,
    PROFILE_BASED: 'PROFILE_BASED' as TypeEnum,
    LOCATION_BASED: 'LOCATION_BASED' as TypeEnum,
    UNRECOGNIZED: 'UNRECOGNIZED' as TypeEnum,
  };
}
