import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {isDocumentRelated} from '../../helpers/property-functions';

@Pipe({name: 'propertyIsDocumentRelated'})
export class PropertyIsDocumentRelatedPipe implements PipeTransform {
  transform(property: Property | undefined): boolean {
    return isDocumentRelated(property);
  }
}
