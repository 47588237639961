import {Inject, Injectable, isDevMode, Optional} from '@angular/core';
import {DateFnsAdapter as MaterialDateFnsAdapter} from '@angular/material-date-fns-adapter';
import {Locale} from '@aztrix/models';
import {LOCALE} from '@aztrix/translate';
import {Locale as DateFnsLocale} from 'date-fns';
import {cs, enUS, fr, it, nl, nlBE} from 'date-fns/locale';
import {BehaviorSubject} from 'rxjs';

export const defaultFormat = 'yyyy/MM/dd';

const LOCALES = {cs, enUS, fr, it, nl, nlBE};

export const MAT_DATE_FNS_DATE_FORMATS = {
  parse: {
    dateInput: defaultFormat,
  },
  display: {
    dateInput: defaultFormat,
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export function toDateFnsLocale(locale?: Locale): DateFnsLocale {
  if (!locale) {
    if (isDevMode()) {
      console.error('undefined locale');
    }
    return LOCALES.enUS;
  }

  if (locale.languageCode) {
    switch (locale.languageCode) {
      case 'nl':
        return LOCALES.nl;
      case 'fr':
        return LOCALES.fr;
      case 'cs':
        return LOCALES.cs;
      case 'it':
        return LOCALES.it;
      default:
        return LOCALES.enUS;
    }
  }

  switch (locale.languageCode) {
    case 'BE':
      return LOCALES.nlBE;
    case 'NL':
      return LOCALES.nl;
    case 'FR':
      return LOCALES.fr;
    case 'CZ':
      return LOCALES.cs;
    case 'IT':
      return LOCALES.it;
    default:
      return LOCALES.enUS;
  }
}

@Injectable()
export class DateFnsDateAdapter extends MaterialDateFnsAdapter {
  constructor(@Optional() @Inject(LOCALE) locale$: BehaviorSubject<Locale>) {
    super(toDateFnsLocale(locale$.value));
    locale$.subscribe((locale) => (this.locale = toDateFnsLocale(locale)));
  }
}
