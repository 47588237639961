import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Event, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {EnvironmentService} from '@aztrix/environment';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';

import {MATOMO_SITE_ID} from '../../matomo-site-id';

declare let window: {
  [key: string]: any;
  prototype: Window;
  new (): Window;
};

declare let Matomo: any;

export interface MatomoConfig {
  trackerUrl?: string;
  siteId?: string;
  cookieDomain?: string;
}

export enum RegisterStepsCompletedType {
  CLAIM = 'CLAIM',
  AUTHENTICATION = 'AUTHENTICATION',
  USER_DATA = 'USER_DATA',
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',
  PROFILE_DATA = 'PROFILE_DATA',
  VERIFICATION = 'VERIFICATION',
  SUCCESS = 'SUCCESS',
}

export enum UpgradeTierActionType {
  TIER_UPGRADE = 'TIER_UPGRADE',
  TIER_UPGRADE_CHECKOUT_BILLING_INFO = 'TIER_UPGRADE_CHECKOUT_BILLING_INFO',
  TIER_UPGRADE_CHECKOUT_PAYMENT = 'TIER_UPGRADE_CHECKOUT_PAYMENT',
  TIER_UPGRADE_CHECKOUT_COMPLETE = 'TIER_UPGRADE_CHECKOUT_COMPLETE',
}

@Injectable({providedIn: 'root'})
export class MatomoService implements OnDestroy {
  private _subscription: Subscription;
  private _customDimension1?: string;
  private _customDimension2?: string;

  private _matomoScript = document.createElement('script');
  tracker: any;

  constructor(
    _router: Router,
    private _environment: EnvironmentService,
    private _title: Title,
    @Optional() @Inject(MATOMO_SITE_ID) private siteId: string
  ) {
    if (!window.Matomo && this.siteId) {
      this._matomoScript.type = 'text/javascript';
      this._matomoScript.async = false;
      this._matomoScript.id = 'matomo-script';
      this._matomoScript.src = '//cdn.matomo.cloud/matomo.js';
      document.body.insertBefore(this._matomoScript, document.body.firstChild);
    }

    if (!this.siteId) {
      window._paq = window._paq || [];
    }

    this._environment
      .string$('MATOMO_TRACKER_URL')
      .pipe(first((trackerUrl) => !!trackerUrl))
      .subscribe((trackerUrl) => {
        const matomoTrackerUrl = `https://${trackerUrl}/matomo.php`;
        if (this.siteId) {
          const script = document.querySelector('#matomo-script');
          script?.addEventListener('load', () => {
            this.tracker = Matomo.getTracker(matomoTrackerUrl, this.siteId);
          });
        } else {
          window._paq.push(['setTrackerUrl', matomoTrackerUrl]);
        }
      });

    if (!this.siteId) {
      this._environment
        .string$('MATOMO_SITE_ID')
        .pipe(first((siteId) => !!siteId))
        .subscribe((siteId) => {
          window._paq.push(['setSiteId', siteId]);
        });

      this._environment
        .string$('MATOMO_COOKIE_DOMAIN')
        .pipe(first((cookieDomain) => !!cookieDomain))
        .subscribe((cookieDomain) => {
          window._paq.push(['setCookieDomain', cookieDomain]);
        });

      if (this.consentGiven) {
        window._paq.push(['setCookieConsentGiven']);
      }

      this._subscription = _router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this._customDimension1 = undefined;
          this._customDimension2 = undefined;
        }
        if (event instanceof NavigationEnd) {
          window._paq.push(['setCustomUrl', event.url]);
          window._paq.push(['setDocumentTitle', this._title.getTitle()]);
          window._paq.push([
            'trackPageView',
            this._title.getTitle(),
            {dimension1: this._customDimension1, dimension2: this._customDimension2},
          ]);
        }
      });
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  setConsentGiven() {
    this._setConsentGivenLocalStorage(true);
    window._paq.push(['setCookieConsentGiven']);
  }

  removeConsentGiven() {
    this._setConsentGivenLocalStorage(false);
    window._paq.push(['forgetCookieConsentGiven']);
  }

  trackEvent(category: string, action: string, name?: string, value?: string | number) {
    if (this.tracker) {
      this.tracker.trackEvent(category, action, name, value, {
        dimension1: this._customDimension1,
        dimension2: this._customDimension2,
      });
    } else {
      window._paq.push([
        'trackEvent',
        category,
        action,
        name,
        value,
        {dimension1: this._customDimension1, dimension2: this._customDimension2},
      ]);
    }
  }

  setAlistDimension(alistId: string | undefined) {
    this._customDimension1 = alistId;
  }

  setAlistLanguageDimension(alistLanguageId: string | undefined) {
    this._customDimension2 = alistLanguageId;
  }

  get consentAsked(): boolean {
    return localStorage.getItem('matomoConsentGiven') !== null;
  }
  get consentGiven(): boolean {
    return this.consentAsked && localStorage.getItem('matomoConsentGiven') === 'true';
  }

  private _setConsentGivenLocalStorage(value: boolean) {
    localStorage.setItem('matomoConsentGiven', value ? 'true' : 'false');
  }
}
