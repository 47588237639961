import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {isDateRelated} from '../../helpers/property-functions';

@Pipe({name: 'propertyIsDateRelated'})
export class PropertyIsDateRelatedPipe implements PipeTransform {
  transform(property: Property): any {
    return isDateRelated(property);
  }
}
