import {
  AccessStatus,
  CommunicationType,
  Context,
  ImportProvider,
  Locale,
  MutableProperty,
  Profile,
  Property,
  PropertyType,
  ProposalCustomType,
  ProposalLanguage,
  ProposalPropertyType,
  SharedInfoRepresentation,
  VerificationStatus,
} from '@aztrix/models';
import {
  AgreementPropertyRepresentation,
  AlistPropertyRepresentation,
  ContextRepresentation,
  ProfilePropertyRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  SubPropertyRepresentation,
} from '@aztrix/sdk';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {AddressFormats} from '../metadata/address-formats';
import {requiredPropertyTypes, verifiablePropertyTypes} from '../metadata/context-type-provider';
import {
  PROPERTY_TYPE_REPS,
  PROPERTY_TYPES,
  PropertySubTypeRepresentation,
  PropertyTypeRepresentation,
  subPropertyTypes,
  subTypeRepsForProperty,
} from '../metadata/property-type-provider';
import {unique} from '../util/array.util';
import {isEuCountry} from '../util/country.util';
import {formatDate} from '../util/date.util';
import {isOfType, typeFilter} from '../util/filter.util';
import {formattedValueForNumber, prefixForNumber} from '../util/phone-number.util';
import {isContact, isMeProfile, isOrganisation} from './profile-functions';
import {isPublic} from './subject-functions';
import {TranslateService} from '@aztrix/translate';

export const PROPERTY_TYPES_AVAILABLE_TO_USERS = unique([
  ...PROPERTY_TYPES.filter((t) => isAvailableToUsers(t)),
  PropertyType.NAME,
]);

const namePropertyTypeRep: PropertyTypeRepresentation = {
  name: PropertyType.NAME,
  verifiable: true,
  regex: undefined,
  subTypesPerCountryCode: undefined,
  prefixBasedRegex: undefined,
  subTypes: [
    {name: PropertyType.FIRST_NAME, required: true, dummy: true},
    {name: PropertyType.MIDDLE_NAME, required: false, dummy: true},
    {name: PropertyType.LAST_NAME, required: false, dummy: true},
  ],
};
export function dummyPropertyForType(
  propertyType: PropertyType | PropertyRepresentation.TypeEnum | undefined
): MutableProperty | PropertyRepresentation {
  const propertyTypeRep =
    propertyType === PropertyType.NAME
      ? namePropertyTypeRep
      : PROPERTY_TYPE_REPS.get(<PropertyType>propertyType?.toUpperCase());
  if (propertyTypeRep?.subTypes) {
    return dummyCompoundProperty(propertyTypeRep);
  }
  return dummySimpleProperty(propertyTypeRep);
}

/**
 * Returns dummy sub properties of the given property
 *
 * @param property property of which the dummy sub properties will be returned
 */
export function enrichProperty(property: MutableProperty): MutableProperty {
  if (!isCompound(property)) {
    return property;
  }
  if (isAddressRelated(property)) {
    return enrichAddressProperty(property);
  }

  if (!property.type) {
    return property;
  }

  const additionalProperties = PROPERTY_TYPE_REPS.set(PropertyType.NAME, namePropertyTypeRep)
    .get(property.type)
    ?.subTypes.filter(
      (rep: PropertySubTypeRepresentation) =>
        // Only keep the sub types the property does not have yet
        !subPropertyOfType(property, <PropertyType>rep.name)
    )
    .map((rep: PropertySubTypeRepresentation) => <MutableProperty>dummyPropertyForType(rep.name))
    .filter((subProperty) => subProperty !== null);
  property.properties = (property.properties || []).concat(additionalProperties || []);
  return property;
}

export function isProperty(property: any): property is Property {
  if (typeof property !== 'object') {
    return false;
  }
  return 'type' in property && Object.keys(PropertyType).includes(property['type']);
}

/**
 * Checks whether the property has a value (excluding prefixes) or all required subproperties have values
 */
export function hasValue(property?: Property | PropertyRepresentation | undefined | null): boolean {
  if (!property?.type) {
    return false;
  }
  if (isOfType(property, PropertyType.LEGAL_ID)) {
    return hasValue(subPropertyOfType(property, PropertyType.COMPANY_NUMBER));
  }
  if (isCompound(property)) {
    if (!property?.properties) {
      return false;
    }

    if (isOfType(property, PropertyType.ADDRESS, PropertyType.REGION)) {
      return (<PropertyType[]>subPropertyTypes(property))
        .filter((type) => type !== PropertyType.COUNTRY)
        .some((subType: PropertyType) => hasValue(subPropertyOfType(property, subType)));
    } else {
      if (isOfType(property, PropertyType.NAME)) {
        return property.properties.some((property) => property.value);
      }
      return (<PropertyType[]>subPropertyTypes(property)).some((subType: PropertyType) =>
        hasValue(subPropertyOfType(property, subType))
      );
    }
  }
  if (isPhoneRelated(property.type)) {
    const prefix = prefixForNumber(property?.value);
    if (!prefix?.phonePrefix) {
      return !!property.value;
    }
    if (!property.value) {
      return false;
    }
    return property.value.length > prefix.phonePrefix.length;
  }
  if (isOfType(property, PropertyType.WEBSITE)) {
    return !!property.value && property.value !== 'https://' && property.value !== 'http://';
  }
  if (typeof property.value === 'number') {
    return !!property.value;
  }
  return !!property.value?.trim();
}

export function isSubProperty(propertyType: PropertyType | undefined): boolean {
  if (!propertyType) {
    return false;
  }
  if (propertyType === PropertyType.NAME) {
    return false;
  }

  for (const type of PROPERTY_TYPES) {
    const reps = PROPERTY_TYPE_REPS.get(type);
    const subTypes = reps?.subTypes?.length
      ? reps?.subTypes
      : reps?.subTypesPerCountryCode
        ? reps?.subTypesPerCountryCode['']
        : [];
    if (subTypes.find((subType) => subType.name === propertyType)) {
      return true;
    }
  }

  return false;
}

export function isCompound(
  property: Property | PropertyRepresentation | undefined | null
): boolean {
  if (!property?.type) {
    return false;
  }
  if (property.type === PropertyType.NAME) {
    return true;
  }
  const reps = PROPERTY_TYPE_REPS.get(property.type);
  return !!reps?.subTypes || !!reps?.subTypesPerCountryCode;
}

export function canBeShared(property: Property, sharedInfo: SharedInfoRepresentation): boolean {
  return !sharedInfo?.sharedPropertyIds.find((id) => id === property.id);
}
export function canBeUnshared(property: Property, sharedInfo: SharedInfoRepresentation): boolean {
  return !!sharedInfo?.sharedPropertyIds.find((id) => id === property.id);
}
export function visibilityCanBeChanged(property: Property, profile: Profile): boolean {
  const context = (profile.propertyContexts || []).find((c) =>
    c.properties.find((p) => p.id === property.id)
  );

  if (!context) {
    return false;
  }

  return property.type !== PropertyRepresentation.TypeEnum.USERNAME;
}
export function propertyIsDynamic(property: Property): boolean {
  return isGranted(property) || isPublic(property);
}
export function isGranted(property: Property): boolean {
  return property?.accessStatus === AccessStatus.GRANTED;
}
export function canBeVerified(
  property?: Property | PropertyRepresentation,
  profile?: Profile | ProfileRepresentation,
  context?: Context | ContextRepresentation
): boolean {
  return (
    verifiablePropertyTypes(profile?.profileContext, context?.type).includes(<any>property?.type) ||
    !!ImportProvider[property?.type as keyof typeof ImportProvider]
  );
}
export function isVerified(property: Property | undefined): boolean {
  return property?.verification === VerificationStatus.VERIFIED;
}
export function verificationIsRequested(property: Property | undefined): boolean {
  return property?.verification === VerificationStatus.PENDING;
}

export function getProperties(
  subject: Property | PropertyRepresentation | Context | ContextRepresentation | undefined,
  filter: (property: Property | PropertyRepresentation | undefined) => boolean = () => true
): ReadonlyArray<Property | PropertyRepresentation> {
  return (subject?.properties || []).filter(filter);
}
export function getProperty<
  T extends {
    properties?:
      | (Property | PropertyRepresentation)[]
      | ReadonlyArray<Property | PropertyRepresentation>;
  },
>(
  subject: T | undefined,
  filter: (property: Property | PropertyRepresentation) => boolean
): Property | PropertyRepresentation | undefined {
  return subject?.properties?.find(filter) ?? undefined;
}
export function isPartOfMinimumProfile(
  property: Property,
  profile: Profile | ProfileRepresentation
): boolean {
  if (isOfType(property, PropertyType.AVATAR)) {
    return true;
  }
  if (
    !isOrganisation(profile) &&
    isOfType(property, PropertyType.FIRST_NAME, PropertyType.LAST_NAME)
  ) {
    return true;
  }
  if (isOrganisation(profile) && isOfType(property, PropertyType.ORGANISATION_NAME)) {
    return true;
  }
  return false;
}
export function isAddressRelated(property: Property | PropertyRepresentation | undefined): boolean {
  return isOfType(property, PropertyType.ADDRESS, PropertyType.REGION);
}

export function isDocumentRelated(propertyOrType: Property | PropertyType | undefined): boolean {
  if (!propertyOrType) {
    return false;
  }

  const type = <PropertyType>(
    (typeof propertyOrType === 'string' || !propertyOrType ? propertyOrType : propertyOrType.type)
  );

  return isOfType(
    {type: <PropertyRepresentation.TypeEnum>type},
    PropertyType.TERMS_AND_CONDITIONS,
    PropertyType.PRIVACY_POLICY,
    PropertyType.DOCUMENT
  );
}

export function isSocialRelated(propertyOrType: Property | PropertyType | undefined): boolean {
  if (!propertyOrType) {
    return false;
  }

  const type = <PropertyType>(
    (typeof propertyOrType === 'string' || !propertyOrType ? propertyOrType : propertyOrType.type)
  );

  return isOfType(
    {type: <PropertyRepresentation.TypeEnum>type},
    PropertyRepresentation.TypeEnum.FACEBOOK,
    PropertyRepresentation.TypeEnum.LINKEDIN,
    PropertyRepresentation.TypeEnum.TWITTER,
    PropertyRepresentation.TypeEnum.INSTAGRAM,
    PropertyRepresentation.TypeEnum.UBEREATS,
    PropertyRepresentation.TypeEnum.SHIPTO,
    PropertyRepresentation.TypeEnum.DELIVEROO,
    PropertyRepresentation.TypeEnum.TAKEAWAY,
    PropertyRepresentation.TypeEnum.TIKTOK,
    PropertyRepresentation.TypeEnum.WHATSAPP,
    PropertyRepresentation.TypeEnum.YOUTUBE,
    PropertyRepresentation.TypeEnum.WECHAT,
    PropertyRepresentation.TypeEnum.FACEBOOK_MESSENGER,
    PropertyRepresentation.TypeEnum.WEIBO,
    PropertyRepresentation.TypeEnum.QQ,
    PropertyRepresentation.TypeEnum.KUAISHOU,
    PropertyRepresentation.TypeEnum.QZONE,
    PropertyRepresentation.TypeEnum.SNAPCHAT,
    PropertyRepresentation.TypeEnum.TELEGRAM,
    PropertyRepresentation.TypeEnum.PINTEREST,
    PropertyRepresentation.TypeEnum.REDDIT,
    PropertyRepresentation.TypeEnum.DISCORD,
    PropertyRepresentation.TypeEnum.TWITCH,
    PropertyRepresentation.TypeEnum.TUMBLR,
    PropertyRepresentation.TypeEnum.MASTODON,
    PropertyRepresentation.TypeEnum.PATREON,
    PropertyRepresentation.TypeEnum.KICKSTARTER,
    PropertyRepresentation.TypeEnum.INDIEGOGO,
    PropertyRepresentation.TypeEnum.GOFUNDME,
    PropertyRepresentation.TypeEnum.VIMEO
  );
}

export function isPhoneRelated(
  type: PropertyType | PropertyRepresentation.TypeEnum | undefined
): boolean {
  return isOfType({type}, PropertyType.PHONE, PropertyType.MOBILE_PHONE, PropertyType.FAX);
}

export function isDateRelated(property: Property | PropertyRepresentation): boolean {
  return isOfType(property, PropertyType.BIRTHDAY, PropertyType.FOUNDED, PropertyType.DISSOLVED);
}

export function isAvailableToUsers(type: PropertyType): boolean {
  return (
    !isOfType(
      {type},
      PropertyType.FILE_KEY,
      PropertyType.FILE_NAME,
      PropertyType.DOCUMENT_NAME,
      PropertyType.IMPORT_SOURCE,
      PropertyType.LAT_LONG,
      PropertyType.FIRST_NAME,
      PropertyType.MIDDLE_NAME,
      PropertyType.LAST_NAME,
      PropertyType.CUSTOM
    ) && !isSubProperty(type)
  );
}

export function isRequested(property: Property): boolean {
  return property?.accessStatus === AccessStatus.REQUESTED;
}
export function isTypeRequested(property: Property): boolean {
  return isRequested(property) && !property?.id;
}
export function canBeDeleted(
  profile: Profile | ProfileRepresentation,
  context: Context | ContextRepresentation,
  property: Property | PropertyRepresentation
): boolean {
  if (!property.id) {
    return false;
  }
  if (isContact(<ProfileRepresentation>profile) && propertyIsDynamic(property)) {
    return false;
  }
  if (
    property.type &&
    requiredPropertyTypes((<Profile>profile).type, profile.profileContext, context.type).includes(
      <any>property.type
    )
  ) {
    return false;
  }
  if (property.type === PropertyType.USERNAME && !isMeProfile(<ProfileRepresentation>profile)) {
    return false;
  }

  return true;
}

export function subPropertyOfType(
  property: Property | PropertyRepresentation | undefined,
  type: PropertyType | PropertyRepresentation.TypeEnum
): Property | PropertyRepresentation | undefined {
  return getProperty(property, typeFilter(type));
}

export function hasEqualValue(
  property1: Property | PropertyRepresentation | undefined | null,
  property2: Property | PropertyRepresentation | undefined | null
): boolean {
  if (property1?.type !== property2?.type) {
    return false;
  }
  if (isCompound(property1)) {
    const subProperties1 = (<PropertyRepresentation[]>property1?.properties).filter(
      (p) => p.value && p.type !== PropertyRepresentation.TypeEnum.LAT_LONG
    );
    const subProperties2 = (<PropertyRepresentation[]>property2?.properties).filter(
      (p) => p.value && p.type !== PropertyRepresentation.TypeEnum.LAT_LONG
    );
    return (
      subProperties1.length === subProperties2.length &&
      subProperties1.every((prop) =>
        hasEqualValue(prop, subProperties2.find(typeFilter(prop.type)))
      )
    );
  } else {
    return property1?.value === property2?.value;
  }
}

export function hasEqualValues(
  propertyOrProperties1:
    | Property
    | Property[]
    | PropertyRepresentation
    | PropertyRepresentation[]
    | ProfilePropertyRepresentation
    | ProfilePropertyRepresentation[]
    | undefined
    | null,
  propertyOrProperties2:
    | Property
    | Property[]
    | PropertyRepresentation
    | PropertyRepresentation[]
    | ProfilePropertyRepresentation
    | ProfilePropertyRepresentation[]
    | undefined
    | null
): boolean {
  if (Array.isArray(propertyOrProperties1) && Array.isArray(propertyOrProperties2)) {
    if (propertyOrProperties1.length !== propertyOrProperties2.length) {
      return false;
    }
    return propertyOrProperties1.every((property1) =>
      propertyOrProperties2.some((property2) => hasEqualValue(property1, property2))
    );
  } else if (!Array.isArray(propertyOrProperties1) && !Array.isArray(propertyOrProperties2)) {
    return hasEqualValue(propertyOrProperties1, propertyOrProperties2);
  } else {
    return false;
  }
}

export function propertyCommunicationType(
  propertyOrType: Property | PropertyType | AlistPropertyRepresentation | string | undefined
): CommunicationType | undefined {
  const type = <PropertyType>(
    (typeof propertyOrType === 'string' || !propertyOrType ? propertyOrType : propertyOrType.type)
  );

  if (isPhoneRelated(type)) {
    return CommunicationType.CALL;
  }
  if (isOfType({type}, PropertyType.EMAIL)) {
    return CommunicationType.MAIL;
  }
  if (isAddressRelated({type})) {
    return CommunicationType.MAP;
  }
  if (type === PropertyType.WEBSITE || isSocialRelated(type)) {
    return CommunicationType.URL;
  }
  return undefined;
}

export function getRegexForProperty(type: PropertyType | undefined): string {
  if (!type) {
    return '';
  }

  const typeRep = PROPERTY_TYPE_REPS.get(type);
  return typeRep?.regex ?? '';
}

export function getPrefixForPropertyType(type: PropertyType | undefined): string {
  switch (type) {
    case PropertyType.FACEBOOK:
      return 'facebook.com/';
    case PropertyType.YOUTUBE:
      return 'youtube.com/';
    case PropertyType.INSTAGRAM:
      return 'instagram.com/';
    case PropertyType.WHATSAPP:
      return 'wa.me/';
    case PropertyType.TIKTOK:
      return 'tiktok.com/';
    case PropertyType.FACEBOOK_MESSENGER:
      return 'm.me/';
    case PropertyType.TELEGRAM:
      return 't.me/';
    case PropertyType.SNAPCHAT:
      return 'snapchat.com/';
    case PropertyType.PINTEREST:
      return 'pinterest.com/';
    case PropertyType.TWITTER:
      return 'twitter.com/';
    case PropertyType.REDDIT:
      return 'reddit.com/';
    case PropertyType.LINKEDIN:
      return 'linkedin.com/';
    case PropertyType.VIMEO:
      return 'vimeo.com/';
    case PropertyType.DISCORD:
      return 'discord.gg/';
    case PropertyType.TWITCH:
      return 'twitch.tv/';
    case PropertyType.TUMBLR:
      return 'tumblr.com/';
    case PropertyType.PATREON:
      return 'patreon.com/';
    case PropertyType.KICKSTARTER:
      return 'kickstarter.com/';
    case PropertyType.INDIEGOGO:
      return 'indiegogo.com/';
    case PropertyType.GOFUNDME:
      return 'gofund.me/';
    case PropertyType.TAKEAWAY:
      return 'takeaway.com/';
    case PropertyType.UBEREATS:
      return 'ubereats.com/';
    case PropertyType.DELIVEROO:
      return 'deliveroo.be/';
    case PropertyType.SHIPTO:
      return 'shipto.be/';
    case PropertyType.WEIBO:
      return 'weibo.com/';
    case PropertyType.KUAISHOU:
      return 'kuaishou.com/';
    case PropertyType.SKYPE:
      return 'join.skype.com/invite/';
    default:
      return '';
  }
}

export function getPropertyTypeForPrefixedValue(
  value: string | undefined
): PropertyType | undefined {
  if (!value) {
    return undefined;
  }

  let valueCleaned = value;
  if (value.indexOf('https://') === 0) {
    valueCleaned = value.substring(8);
  }

  const socialPropertyTypes = PROPERTY_TYPES.filter(isSocialRelated);
  for (const type of socialPropertyTypes) {
    const prefix = getPrefixForPropertyType(type);
    if (valueCleaned.indexOf(prefix) === 0) {
      return type;
    }
  }
  return undefined;
}

export function getPlaceholderForPropertyType(type: PropertyType): string {
  switch (type) {
    case PropertyType.FACEBOOK:
      return 'myProfile';
    case PropertyType.YOUTUBE:
      return '@myChannel';
    case PropertyType.INSTAGRAM:
      return 'myProfile';
    case PropertyType.WHATSAPP:
      return '32479123456';
    case PropertyType.TIKTOK:
      return '@myProfile';
    case PropertyType.FACEBOOK_MESSENGER:
      return '32479123456';
    case PropertyType.TELEGRAM:
      return '+123456789';
    case PropertyType.SNAPCHAT:
      return 'add/myProfile';
    case PropertyType.PINTEREST:
      return 'myProfile';
    case PropertyType.TWITTER:
      return 'myProfile';
    case PropertyType.REDDIT:
      return 'user/myProfile';
    case PropertyType.LINKEDIN:
      return 'in/myProfile';
    case PropertyType.VIMEO:
      return '123456789';
    case PropertyType.DISCORD:
      return 'AbCdEfGh';
    case PropertyType.TWITCH:
      return 'myChannel';
    case PropertyType.TUMBLR:
      return 'myBlog';
    case PropertyType.PATREON:
      return 'myPage';
    case PropertyType.KICKSTARTER:
      return 'myProject';
    case PropertyType.INDIEGOGO:
      return 'myProject';
    case PropertyType.GOFUNDME:
      return '1abc23e4';
    case PropertyType.TAKEAWAY:
      return 'be/menu/my-restaurant';
    case PropertyType.UBEREATS:
      return 'be-en/store/my-restaurant';
    case PropertyType.DELIVEROO:
      return 'en/menu/location/my-restaurant';
    case PropertyType.WEIBO:
      return 'u/1234567890';
    case PropertyType.KUAISHOU:
      return 'profile/1abcde234fghijk';
    case PropertyType.SKYPE:
      return 'myProfile';
    default:
      return '';
  }
}

export function sortPropertyTypes(propertyTypes: PropertyType[]): PropertyType[] {
  return (
    propertyTypes?.sort(
      (t1: PropertyType, t2: PropertyType) =>
        PROPERTY_TYPES.indexOf(t1) - PROPERTY_TYPES.indexOf(t2)
    ) ?? []
  );
}

export function sortProperties(prop1: Property, prop2: Property): number {
  if (!prop1.type || !prop2.type) {
    return 0;
  }
  return PROPERTY_TYPES.indexOf(prop1.type) - PROPERTY_TYPES.indexOf(prop2.type);
}

export function subPropertyTypesForType(
  type: PropertyType | PropertyRepresentation.TypeEnum
): PropertyType[] | PropertyRepresentation.TypeEnum[] {
  if (!type) {
    return [];
  }
  const dummy = dummyPropertyForType(type);
  return subTypeRepsForProperty(dummy).map((rep) => <PropertyType>rep.name);
}

export function isSubPropertyOf(
  compoundType: PropertyType | PropertyRepresentation.TypeEnum,
  type: PropertyType | PropertyRepresentation.TypeEnum
) {
  const subPropertyTypes = <PropertyType[]>subPropertyTypesForType(compoundType);
  return subPropertyTypes.includes(<PropertyType>type);
}

function enrichAddressProperty(property: MutableProperty): MutableProperty {
  const additionalProperties = subTypeRepsForProperty(property)
    .filter((rep: PropertySubTypeRepresentation) => rep.dummy) // We only take the dummy values
    .filter(
      (rep: PropertySubTypeRepresentation) => !subPropertyOfType(property, <PropertyType>rep.name)
    )
    // We only take the ones we do not have yet
    .map((rep: PropertySubTypeRepresentation) => {
      const subProperty = <MutableProperty>dummyPropertyForType(rep.name);
      // We do not prefill its sub properties if the address property is existing (we do not
      // want the default country here)
      if (property.id) {
        subProperty.value = '';
      }
      return subProperty;
    });
  property.properties = (property.properties || []).concat(additionalProperties);
  return property;
}

function dummySimpleProperty(propertyTypeRep?: PropertyTypeRepresentation): MutableProperty {
  const property = MutableProperty.empty();
  if (propertyTypeRep?.name) {
    property.type = propertyTypeRep.name;
  }
  delete property.properties;
  return property;
}

function dummyCompoundProperty(propertyTypeRep: PropertyTypeRepresentation): MutableProperty {
  const property = MutableProperty.empty();
  delete property.value;
  property.type = propertyTypeRep.name;

  // Enrich the property
  if (isAddressRelated(property)) {
    // Add a country sub property
    property.properties = [<MutableProperty>dummyPropertyForType(PropertyType.COUNTRY)];
  }
  enrichProperty(property);
  return property;
}

export function displayValue(
  translate: TranslateService,
  property: Property | PropertyRepresentation | undefined | null,
  locale?: Locale,
  lang?: string,
  noValuePlaceholder?: string,
  short = false
): string {
  return <string>_displayValue$(translate, property, locale, lang, noValuePlaceholder, short, true);
}

export function displayValue$(
  translate: TranslateService,
  property: Property | PropertyRepresentation | undefined | null,
  locale?: Locale,
  lang?: string,
  noValuePlaceholder?: string,
  short = false
): Observable<string> {
  return <Observable<string>>(
    _displayValue$(translate, property, locale, lang, noValuePlaceholder, short, false)
  );
}

function _displayValue$(
  translateService: TranslateService,
  property: Property | PropertyRepresentation | undefined | null,
  locale?: Locale,
  lang?: string,
  noValuePlaceholder?: string,
  short = false,
  instant?: boolean
): string | Observable<string> {
  const translate = (key: string) => {
    if (instant === true) {
      return translateService.instant(key, undefined, lang);
    } else {
      return translateService.get(key, undefined, lang);
    }
  };

  const valueOrDelayedValue = (value: string) => {
    if (instant === true) {
      return value;
    } else {
      return of(value);
    }
  };

  if (!property || !hasValue(property)) {
    if (noValuePlaceholder) {
      return translate(noValuePlaceholder);
    }
    return valueOrDelayedValue('');
  }
  if (isOfType(property, PropertyType.NAME)) {
    return valueOrDelayedValue(namePropertyValue(property));
  }
  if (isOfType(property, PropertyType.CATEGORY)) {
    return translate(`category.${property.value}.label`);
  }
  if (isOfType(property, PropertyType.USERNAME)) {
    return valueOrDelayedValue(property.value + '*');
  }
  if (isOfType(property, PropertyType.DOCUMENT)) {
    return valueOrDelayedValue(
      (<Property[]>property.properties).find(
        (p: Property | SubPropertyRepresentation) => p.type === PropertyType.DOCUMENT_NAME
      )?.value || ''
    );
  }
  if (isOfType(property, PropertyType.TERMS_AND_CONDITIONS, PropertyType.PRIVACY_POLICY)) {
    return translate(`property.${property.type}.label`);
  }
  if (isOfType(property, PropertyType.GENDER)) {
    return translate(property.value === 'M' ? 'label.male' : 'label.female');
  }
  if (isOfType(property, PropertyType.BOX)) {
    if (instant === true) {
      const label = translateService.instant('property.BOX.label');
      return `${label} ${property.value}`;
    } else {
      return translateService
        .get('property.BOX.label')
        .pipe(map((boxLabel) => `${boxLabel} ${property.value}`));
    }
  }
  if (property.value && isOfType(property, PropertyType.COUNTRY, PropertyType.JURISDICTION)) {
    if (property.value.length > 3) {
      return valueOrDelayedValue(property.value);
    } else {
      return translate(`countryCode.${property.value.toUpperCase()}.label`);
    }
  }
  if (isPhoneRelated(property.type)) {
    if (short) {
      return valueOrDelayedValue(property.value || '');
    } else {
      return valueOrDelayedValue(formattedValueForNumber(property.value) || '');
    }
  }
  if (isAddressRelated(property)) {
    return _addressValueInOneLine$(
      translateService,
      property,
      locale,
      lang,
      noValuePlaceholder,
      short,
      instant
    );
  }
  if (isDateRelated(property)) {
    const date = propertyToDate(property);
    return valueOrDelayedValue(
      !subPropertyOfType(property, PropertyType.YEAR)
        ? formatDate(date, locale).substring(0, 5)
        : formatDate(date, locale)
    );
  }
  if (isOfType(property, PropertyType.BANK_ACCOUNT)) {
    const ibanDisplayValue$ = _displayValue$(
      translateService,
      subPropertyOfType(property, PropertyType.IBAN),
      locale,
      lang,
      noValuePlaceholder,
      short,
      instant
    );
    const bicDisplayValue$ = _displayValue$(
      translateService,
      subPropertyOfType(property, PropertyType.BIC),
      locale,
      lang,
      noValuePlaceholder,
      short,
      instant
    );
    return combineLatest([ibanDisplayValue$, bicDisplayValue$]).pipe(
      map(([iban, bic]) => `${iban} (${bic})`)
    );
  }
  if (isOfType(property, PropertyType.LEGAL_ID)) {
    const companyNumberDisplayValue$ = _displayValue$(
      translateService,
      subPropertyOfType(property, PropertyType.COMPANY_NUMBER),
      locale,
      lang,
      noValuePlaceholder,
      short,
      instant
    );
    const jurisdictionProperty = subPropertyOfType(property, PropertyType.JURISDICTION);

    if (isEuCountry(jurisdictionProperty?.value)) {
      if (typeof companyNumberDisplayValue$ === 'string') {
        return `${jurisdictionProperty?.value}${companyNumberDisplayValue$}`;
      } else {
        return companyNumberDisplayValue$.pipe(
          map((companyNumber) => `${jurisdictionProperty?.value}${companyNumber}`)
        );
      }
    } else {
      const jurisdictionDisplayValue$ = _displayValue$(
        translateService,
        jurisdictionProperty,
        locale,
        lang,
        noValuePlaceholder,
        short,
        instant
      );
      if (typeof jurisdictionDisplayValue$ === 'string') {
        if (short) {
          return companyNumberDisplayValue$;
        } else {
          return `${companyNumberDisplayValue$} (${jurisdictionDisplayValue$})`;
        }
      } else {
        return combineLatest([companyNumberDisplayValue$, jurisdictionDisplayValue$]).pipe(
          map(([companyNumber, jurisdiction]) => {
            if (short) {
              return companyNumber;
            } else {
              return `${companyNumber} (${jurisdiction})`;
            }
          })
        );
      }
    }
  }

  return valueOrDelayedValue(property.value || '');
}

export function propertyTypeLabel(
  translate: TranslateService,
  propertyType: PropertyType | PropertyRepresentation.TypeEnum | undefined,
  lang?: string
): string {
  if (!propertyType) {
    return '';
  }
  return translate.instant(`property.${propertyType}.label`, {}, lang);
}

export function propertyTypeLabel$(
  translate: TranslateService,
  propertyType?: PropertyType | PropertyRepresentation.TypeEnum,
  lang?: string
): Observable<string> {
  if (!propertyType) {
    return of('');
  }
  return translate.get(`property.${propertyType}.label`, {}, lang);
}

export function propertySocialName(
  property: Property | PropertyRepresentation | undefined
): string | undefined {
  if (!property) {
    return undefined;
  }
  try {
    const url = new URL(property.value || '');
    if (url.pathname.startsWith('/')) {
      return url.pathname.substring(1);
    } else {
      return url.pathname;
    }
  } catch {
    return undefined;
  }
}

export function addressValueInOneLine(
  translate: TranslateService,
  property: Property,
  locale?: Locale,
  lang?: string,
  noValuePlaceholder?: string
): string {
  return <string>(
    _addressValueInOneLine$(translate, property, locale, lang, noValuePlaceholder, true)
  );
}

export function addressValueInOneLine$(
  translate: TranslateService,
  property: Property,
  locale?: Locale,
  lang?: string,
  noValuePlaceholder?: string
): Observable<string> {
  return <Observable<string>>(
    _addressValueInOneLine$(translate, property, locale, lang, noValuePlaceholder, false)
  );
}

function _addressValueInOneLine$(
  translateService: TranslateService,
  property: Property | PropertyRepresentation,
  locale?: Locale,
  lang?: string,
  noValuePlaceholder?: string,
  short = false,
  instant?: boolean
): string | Observable<string> {
  // Same line - SPACE
  // New line - COMMA

  const translate = (
    valuesOrObservables: (string | Observable<string>)[],
    joint: string
  ): string | Observable<string> => {
    if (instant === true) {
      return (<string[]>valuesOrObservables).filter((value) => value).join(joint);
    } else {
      return combineLatest(valuesOrObservables).pipe(
        map((values) => values.filter((value) => value).join(joint))
      );
    }
  };

  const lines: (string | Observable<string>)[] = [];
  for (const line of AddressFormats.addressFormatForProperty(property)) {
    const types = line
      .map((lineInfo) => lineInfo.propertyType)
      .filter((type) => type !== PropertyType.COUNTRY);

    const values: (string | Observable<string>)[] = [];
    for (const type of types) {
      const p = getProperty(property, typeFilter(type));
      values.push(
        _displayValue$(translateService, p, locale, lang, noValuePlaceholder, short, instant)
      );
    }
    if (values.length) {
      lines.push(translate(values, ' '));
    }
  }

  return translate(lines, ', ');
}

export function namePropertyValue(property: Property | PropertyRepresentation) {
  return (property.properties || [])
    .map((subProperty) => subProperty.value)
    .join(' ')
    .replace(/\s+/g, ' ');
}

export function proposalPropertyDisplayValue$(
  translate: TranslateService,
  agreementProperty: AgreementPropertyRepresentation,
  proposalPropertyType?: ProposalPropertyType,
  locale?: Locale,
  proposalLanguage?: ProposalLanguage | ProposalLanguageRepresentation,
  noValuePlaceholder?: string
) {
  if (isOfType(agreementProperty.property, PropertyType.CUSTOM)) {
    if (proposalPropertyType?.customFieldInfo?.type === ProposalCustomType.DATE) {
      return of(formatDate(agreementProperty.property?.value, locale));
    }

    if (proposalPropertyType?.customFieldInfo?.type === ProposalCustomType.RATING) {
      if (agreementProperty.property?.value) {
        return of(`${agreementProperty.property?.value}${translate.instant('rating.stars.value')}`);
      } else {
        return of(null);
      }
    }

    if (proposalPropertyType?.customFieldInfo?.type === ProposalCustomType.CHECK) {
      const values = agreementProperty.property?.value?.split(',');
      const labels =
        proposalLanguage?.requestedPropertyDescriptions
          ?.find((rpd) => rpd.requestedPropertyId === agreementProperty.requestedPropertyId)
          ?.requestedPropertyTypeDescriptions?.[0].customFieldDescription?.valueLabels?.filter(
            (label) => values?.includes(label.value || '')
          )
          .map((label) => label.label) || [];

      return of(labels.join(', '));
    }

    if (
      proposalPropertyType?.customFieldInfo?.type === ProposalCustomType.RADIO ||
      proposalPropertyType?.customFieldInfo?.type === ProposalCustomType.DROPDOWN
    ) {
      return of(
        proposalLanguage?.requestedPropertyDescriptions
          ?.find((rpd) => rpd.requestedPropertyId === agreementProperty.requestedPropertyId)
          ?.requestedPropertyTypeDescriptions?.[0].customFieldDescription?.valueLabels?.find(
            (label) => label.value === agreementProperty.property?.value
          )?.label || ''
      );
    }
  }
  return displayValue$(translate, agreementProperty.property, locale, noValuePlaceholder);
}

export function propertyToDate(property: Property | PropertyRepresentation): Date | undefined {
  if (!isDateRelated(property)) {
    return undefined;
  }
  const [dayProperty, monthProperty, yearProperty] = [
    PropertyType.DAY,
    PropertyType.MONTH,
    PropertyType.YEAR,
  ].map((type) => subPropertyOfType(property, type));

  if (!dayProperty?.value && !monthProperty?.value && !yearProperty?.value) {
    return undefined;
  }

  return new Date(
    parseInt(yearProperty?.value || '2023', 10),
    parseInt(monthProperty?.value || '1', 10) - 1,
    parseInt(dayProperty?.value || '0', 10)
  );
}

export function documentLink(property: Property | undefined, backendUrl?: string): string {
  if (!property) {
    return backendUrl || '';
  }
  if (backendUrl) {
    return `${backendUrl}/staticFiles/profileDocuments/${property.properties?.find(
      (p: Property) => p.type === PropertyType.FILE_KEY
    )?.value}`;
  }
  return `/staticFiles/profileDocuments/${property.properties?.find(
    (p: Property) => p.type === PropertyType.FILE_KEY
  )?.value}`;
}
