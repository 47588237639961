import {OverlayContainer} from '@angular/cdk/overlay';
import {EnvironmentProviders, ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {AztrixOverlayContainer} from './aztrix-overlay-container';
import {OverlayComponent} from './overlay.component';
import {OverlayService} from './overlay.service';
import {OverlayContainerComponent} from './overlay-container/overlay-container';
import {OVERLAY_CDK_CONTAINER} from './overlay-theming';

@NgModule({
  imports: [OverlayContainerComponent],
  exports: [OverlayContainerComponent],
})
export class OverlayModule {
  static forRoot(): ModuleWithProviders<OverlayModule> {
    return {
      ngModule: OverlayModule,
      providers: provideOverlay(),
    };
  }
}

export function provideOverlay(): (Provider | EnvironmentProviders)[] {
  return [
    OverlayComponent,
    OverlayService,
    {
      provide: OVERLAY_CDK_CONTAINER,
      useValue: new BehaviorSubject(undefined),
    },
    {provide: OverlayContainer, useClass: AztrixOverlayContainer},
  ];
}
