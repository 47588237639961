import {Pipe, PipeTransform} from '@angular/core';
import {PropertyType} from '@aztrix/models';

import {getPlaceholderForPropertyType} from '../../helpers/property-functions';

@Pipe({name: 'placeholderForPropertyType'})
export class PlaceholderForPropertyTypePipe implements PipeTransform {
  transform(type: PropertyType): string {
    return getPlaceholderForPropertyType(type);
  }
}
