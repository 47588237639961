import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {ASSETS_URL, BACKEND_URL, UrlTransformerService} from '@aztrix/environment';
import {Translation, TranslocoLoader} from '@ngneat/transloco';
import {
  BehaviorSubject,
  Observable,
  defer,
  first,
  map,
  of,
  retry,
  switchMap,
  tap,
  timer,
} from 'rxjs';

export const FALLBACK_LANG = 'en';

@Injectable({providedIn: 'root'})
export class TranslationsLoader implements TranslocoLoader {
  private _languages: any = {};

  constructor(
    private _http: HttpClient,
    private _urlTransformer: UrlTransformerService,
    @Optional() @Inject(BACKEND_URL) private _backendUrl: BehaviorSubject<string>,
    @Optional() @Inject(ASSETS_URL) private _assetsUrl: BehaviorSubject<string>
  ) {}

  getTranslation(lang: string): Observable<Translation> {
    if (this._languages[lang]) {
      return of(this._languages[lang]);
    }

    return defer(() => {
      if (this._assetsUrl) {
        return this._assetsUrl.pipe(map((assetsUrl) => `${assetsUrl}/assets/i18n/${lang}.json`));
      } else if (this._backendUrl) {
        return this._backendUrl.pipe(map((backendUrl) => `${backendUrl}/assets/i18n/${lang}.json`));
      } else {
        return of(this._urlTransformer.transformUrl(`assets/i18n/${lang}.json`));
      }
    }).pipe(
      switchMap((url) =>
        this._http.get<Translation>(url, {
          params: {_noAuth: 'true'},
        })
      ),
      retry({delay: (_err, retryCount) => timer(retryCount * 500)}),
      first(),
      tap((language) => (this._languages[lang] = language))
    );
  }
}
