import {Pipe, PipeTransform} from '@angular/core';
import {PropertyType, Proposal, ProposalPropertyType} from '@aztrix/models';
import {ProposalRepresentation} from '@aztrix/sdk';

import {getProposalRequestedPropertyType} from '../../helpers/helpers-api';

@Pipe({name: 'proposalRequestedPropertyType'})
export class ProposalRequestedPropertyTypePipe implements PipeTransform {
  transform(
    proposal: Proposal | ProposalRepresentation,
    requestedId: string | undefined,
    type: PropertyType | undefined
  ): ProposalPropertyType | undefined {
    return getProposalRequestedPropertyType(<ProposalRepresentation>proposal, requestedId, type);
  }
}
