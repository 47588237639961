import {Pipe, PipeTransform} from '@angular/core';
import {FieldValue, PageLanguage} from '@aztrix/models';

import {getFieldValueString} from '../../helpers/page-functions';

@Pipe({name: 'pageFieldValueString'})
export class PageFieldValueStringPipe implements PipeTransform {
  transform(language: PageLanguage | FieldValue, ...ids: string[]): string {
    return getFieldValueString(language, ...ids);
  }
}
