import {Context} from '../context/context';
import {ContextType} from '../context/context-type';
import {Profile} from '../profile/profile';
import {Property} from '../property/property';
import {PropertyTuple} from '../property/property-tuple';
import {PropertyType} from '../property/property-type';

export enum RequestType {
  REQUEST_PROPERTY = 'REQUEST_PROPERTY',
  REQUEST_GROUP_JOIN = 'REQUEST_GROUP_JOIN',
  REQUEST_FAMILY_RELATIONSHIP = 'REQUEST_FAMILY_RELATIONSHIP',
  REQUEST_EMPLOYEE_RELATIONSHIP = 'REQUEST_EMPLOYEE_RELATIONSHIP',
  REQUEST_EMPLOYER_RELATIONSHIP = 'REQUEST_EMPLOYER_RELATIONSHIP',
  REQUEST_INVITATION = 'REQUEST_INVITATION',
  REQUEST_ROLE = 'REQUEST_ROLE',
}

export enum PropertyRequestType {
  REQUEST_TYPE = 'REQUEST_TYPE',
  REQUEST_CONFIRMATION = 'REQUEST_CONFIRMATION',
}

export enum RoleRequestType {
  ADMIN = 'ADMIN',
}

export interface PropertyRequestRepresentation {
  id: string;
  type: PropertyRequestType;
  context: {label: string; type: ContextType};
  missingProperty: Property;
  alternatives: {propertyId: string; shared: boolean}[];
  requestedAlternative?: string;
}

export interface RoleRequestRepresentation {
  id: string;
  type: RoleRequestType;
}

export interface RequestInfo {
  propertyRequests: PropertyRequestInfo[];
  typeRequests: TypeRequestInfo[];
}

export interface PropertyRequestInfo {
  propertyId: string;
  requestId: string;
}

export interface TypeRequestInfo {
  contextId: string;
  type: string;
  requestId: string;
}

export interface PropertiesToRequestExternal {
  propertyIdsToRequest: string[];
  propertyTypesToRequest: {
    contextId: string;
    contextType: string;
    propertyType: string;
  }[];
}

export interface RequestPacketRepresentation {
  partyId: string;
  timestamp: number;
  propertyRequests: {requests: PropertyRequestRepresentation[]};
}

export interface RoleRequestPacketRepresentation {
  partyId: string;
  timestamp: number;
  roleRequests: {requests: RoleRequestRepresentation[]};
}

export interface RequestsSettingsRepresentation {
  individual: boolean;
  organisation: boolean;
  partyWithOneCommonProperty: {
    types: {propertyType: PropertyType; value: boolean}[];
  };
  group: {groups: {id: string; value: boolean}[]};
}

// @dynamic
export class RequestPacket {
  constructor(
    public readonly profileId: string,
    public readonly timestamp: number,
    public readonly propertyRequests: PropertyRequest[]
  ) {}

  public static profileFilter(profile: Profile): (requestPacket: RequestPacket) => boolean {
    return (requestPacket) =>
      profile.id === requestPacket.profileId || profile?.targetId === requestPacket.profileId;
  }

  public static packetToKeepFilter = (packet: RequestPacket) => packet?.propertyRequests.length > 0;
}

// @dynamic
export class PropertyRequest {
  constructor(
    public id: string,
    private type: PropertyRequestType,
    public context: {type: ContextType; label?: string},
    public missingProperty: Property,
    private alternatives: {propertyId: string; shared: boolean}[],
    public requestedAlternativeId: string | null
  ) {}

  public static fromRepresentation(rep: PropertyRequestRepresentation): PropertyRequest {
    return new PropertyRequest(
      rep.id,
      rep.type,
      {label: rep.context.label, type: rep.context.type},
      rep.missingProperty,
      rep.alternatives,
      rep.requestedAlternative || null
    );
  }

  public contextType(): ContextType {
    return this.context.type;
  }

  public propertyType(profile: Profile): PropertyType | undefined {
    if (this.missingProperty) {
      return this.missingProperty.type;
    }
    const alternative: PropertyTuple = getPropertyTupleById(
      profile.propertyContexts,
      this.alternatives[0].propertyId
    );
    return alternative.property ? alternative.property.type : undefined;
  }

  public isTypeRequest(): boolean {
    return this.type === PropertyRequestType.REQUEST_TYPE;
  }

  public isConfirmationRequest(): boolean {
    return this.type === PropertyRequestType.REQUEST_CONFIRMATION;
  }

  public hasMissingProperty(): boolean {
    return !!this.missingProperty;
  }

  public getRequestedAlternative(profile: Profile): PropertyTuple {
    return getPropertyTupleById(profile.propertyContexts, this.requestedAlternativeId);
  }

  public getNotSharedAlternatives(profile: Profile): PropertyTuple[] {
    return this.alternatives
      .filter((alternative) => !alternative.shared)
      .map((alternative) => getPropertyTupleById(profile.propertyContexts, alternative.propertyId));
  }

  public getSharedAlternatives(profile: Profile): PropertyTuple[] {
    return this.alternatives
      .filter((alternative) => alternative.shared)
      .map((alternative) => getPropertyTupleById(profile.propertyContexts, alternative.propertyId));
  }
}

function getPropertyTupleById(
  contexts: Context[] | ReadonlyArray<Context> | null | undefined,
  id: string | null | undefined
): PropertyTuple {
  if (contexts && id) {
    for (const c of contexts) {
      const p = c.properties.find((prop) => prop.id === id);
      if (p) {
        return <PropertyTuple>{property: p, context: c};
      }
    }
  }
  return {};
}
