/**
 * Generic functions which can be used on many different object types
 */
import {ProfileVisibility, Visibility} from '@aztrix/models';

import {isMeProfile, isProfile} from './profile-functions';

export function isPrivate<T extends {visibility?: Visibility | ProfileVisibility}>(subject: T) {
  if (isProfile(subject) && !isMeProfile(subject)) {
    return false;
  }
  return subject?.visibility === Visibility.PRIVATE;
}

export function isPublic<T extends {visibility?: Visibility | ProfileVisibility}>(subject: T) {
  if (isProfile(subject) && !isMeProfile(subject)) {
    return true;
  }
  return subject?.visibility === Visibility.PUBLIC;
}
