import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {isVerified, verificationIsRequested} from '../../helpers/property-functions';
import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'propertyVerificationLabel',
})
export class PropertyVerificationLabelPipe implements PipeTransform {
  private _translatePipe = new TranslatePipe(
    this._translate,
    this._changeDetector,
    this._languageOverride$
  );
  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _translate: TranslateService,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  transform(property: Property | undefined): any {
    if (isVerified(property)) {
      return this._translatePipe.transform('label.verified');
    }
    if (verificationIsRequested(property)) {
      return this._translatePipe.transform('label.verification.requested');
    }
    return this._translatePipe.transform('label.not.verified');
  }
}
