/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GroupTypeRepresentation {
  name?: GroupTypeRepresentation.NameEnum;
  _default?: boolean;
  limit?: number;
  editable?: boolean;
  shareable?: boolean;
  visibilityDefault?: GroupTypeRepresentation.VisibilityDefaultEnum;
  canAddMembers?: boolean;
  ownerCanBeMember?: boolean;
  followable?: boolean;
  sharedInfo?: boolean;
  deletable?: boolean;
  canChangeMembershipVisibility?: boolean;
  membersVisiblePossibilities?: Array<boolean>;
  membersVisibleDefault?: boolean;
}
export namespace GroupTypeRepresentation {
  export type NameEnum =
    | 'EXPLICIT'
    | 'CONTACTS'
    | 'FAMILY'
    | 'FOLLOWERS'
    | 'IMPORTING'
    | 'BLOCKED'
    | 'CUSTOMERS'
    | 'FAVORITES';
  export const NameEnum = {
    EXPLICIT: 'EXPLICIT' as NameEnum,
    CONTACTS: 'CONTACTS' as NameEnum,
    FAMILY: 'FAMILY' as NameEnum,
    FOLLOWERS: 'FOLLOWERS' as NameEnum,
    IMPORTING: 'IMPORTING' as NameEnum,
    BLOCKED: 'BLOCKED' as NameEnum,
    CUSTOMERS: 'CUSTOMERS' as NameEnum,
    FAVORITES: 'FAVORITES' as NameEnum,
  };
  export type VisibilityDefaultEnum = 'PUBLIC' | 'PRIVATE';
  export const VisibilityDefaultEnum = {
    PUBLIC: 'PUBLIC' as VisibilityDefaultEnum,
    PRIVATE: 'PRIVATE' as VisibilityDefaultEnum,
  };
}
