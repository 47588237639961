import {Pipe, PipeTransform} from '@angular/core';
import {Profile} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';

import {isDynamic} from '../../helpers/profile-functions';

@Pipe({
  name: 'isDynamic',
})
export class IsDynamicPipe implements PipeTransform {
  transform(profile: Profile | ProfileRepresentation): boolean {
    return isDynamic(<ProfileRepresentation>profile);
  }
}
