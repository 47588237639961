/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {AlistLanguageRepresentation} from './alistLanguageRepresentation';

export interface AlistRepresentation {
  id?: string;
  name?: string;
  ownerId?: string;
  type?: AlistRepresentation.TypeEnum;
  active?: boolean;
  claimCode?: string;
  domain?: string;
  domainEnabled?: boolean;
  languages?: Array<AlistLanguageRepresentation>;
}
export namespace AlistRepresentation {
  export type TypeEnum = 'BIO' | 'PRODUCT_PASSPORT';
  export const TypeEnum = {
    BIO: 'BIO' as TypeEnum,
    PRODUCT_PASSPORT: 'PRODUCT_PASSPORT' as TypeEnum,
  };
}
