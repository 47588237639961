import {Pipe, PipeTransform} from '@angular/core';

import {isDeflatedProfile} from '../../helpers/profile-functions';

@Pipe({
  name: 'isDeflatedProfile',
})
export class IsDeflatedProfilePipe implements PipeTransform {
  transform(profile: any): boolean {
    return isDeflatedProfile(profile);
  }
}
