import {Pipe, PipeTransform} from '@angular/core';
import {SigningOrderRecipientRepresentation, SigningOrderRepresentation} from '@aztrix/sdk';

import {getSigningOrderStatus, SigningOrderStatus} from '../../helpers/signing-order-functions';

@Pipe({
  name: 'signingOrderStatus',
})
export class SigningOrderStatusPipe implements PipeTransform {
  transform(
    subject: SigningOrderRepresentation | SigningOrderRecipientRepresentation
  ): SigningOrderStatus {
    return getSigningOrderStatus(subject);
  }
}
