/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PropertyTypeInfo {
  name?: PropertyTypeInfo.NameEnum;
  dummy?: boolean;
  limit?: number;
  verification?: PropertyTypeInfo.VerificationEnum;
  required?: boolean;
  immutable?: boolean;
  userAddable?: boolean;
}
export namespace PropertyTypeInfo {
  export type NameEnum =
    | 'USERNAME'
    | 'FIRST_NAME'
    | 'MIDDLE_NAME'
    | 'LAST_NAME'
    | 'NAME'
    | 'AVATAR'
    | 'GENDER'
    | 'BIRTHDAY'
    | 'FOUNDED'
    | 'DISSOLVED'
    | 'DAY'
    | 'MONTH'
    | 'YEAR'
    | 'EMAIL'
    | 'MOBILE_PHONE'
    | 'PHONE'
    | 'FAX'
    | 'WEBSITE'
    | 'BIC'
    | 'JOB_TITLE'
    | 'INDUSTRY'
    | 'GROUP'
    | 'REGION'
    | 'ADDRESS'
    | 'STREET'
    | 'COUNTRY'
    | 'BUILDING'
    | 'BOX'
    | 'LOCALITY'
    | 'NUMBER'
    | 'ZIP'
    | 'DISTRICT'
    | 'PROVINCE'
    | 'SUBURB'
    | 'STATE'
    | 'COUNTY'
    | 'LAT_LONG'
    | 'FACEBOOK'
    | 'YOUTUBE'
    | 'INSTAGRAM'
    | 'WHATSAPP'
    | 'WECHAT'
    | 'TIKTOK'
    | 'FACEBOOK_MESSENGER'
    | 'TELEGRAM'
    | 'SNAPCHAT'
    | 'PINTEREST'
    | 'TWITTER'
    | 'REDDIT'
    | 'LINKEDIN'
    | 'VIMEO'
    | 'DISCORD'
    | 'TWITCH'
    | 'TUMBLR'
    | 'MASTODON'
    | 'PATREON'
    | 'KICKSTARTER'
    | 'INDIEGOGO'
    | 'GOFUNDME'
    | 'TAKEAWAY'
    | 'UBEREATS'
    | 'DELIVEROO'
    | 'SHIPTO'
    | 'WEIBO'
    | 'QQ'
    | 'KUAISHOU'
    | 'QZONE'
    | 'SKYPE'
    | 'NOTE'
    | 'ORGANISATION_NAME'
    | 'LEGAL_NAME'
    | 'LEGAL_FORM'
    | 'LEGAL_ID'
    | 'COMPANY_NUMBER'
    | 'JURISDICTION'
    | 'IMPORT_SOURCE'
    | 'PROPOSAL'
    | 'CATEGORY'
    | 'OPENING_HOURS'
    | 'CUSTOM'
    | 'FILE'
    | 'IMAGE'
    | 'FILE_KEY'
    | 'FILE_NAME'
    | 'TERMS_AND_CONDITIONS'
    | 'PRIVACY_POLICY'
    | 'DOCUMENT'
    | 'DOCUMENT_NAME'
    | 'ORGANISATION_TYPE';
  export const NameEnum = {
    USERNAME: 'USERNAME' as NameEnum,
    FIRST_NAME: 'FIRST_NAME' as NameEnum,
    MIDDLE_NAME: 'MIDDLE_NAME' as NameEnum,
    LAST_NAME: 'LAST_NAME' as NameEnum,
    NAME: 'NAME' as NameEnum,
    AVATAR: 'AVATAR' as NameEnum,
    GENDER: 'GENDER' as NameEnum,
    BIRTHDAY: 'BIRTHDAY' as NameEnum,
    FOUNDED: 'FOUNDED' as NameEnum,
    DISSOLVED: 'DISSOLVED' as NameEnum,
    DAY: 'DAY' as NameEnum,
    MONTH: 'MONTH' as NameEnum,
    YEAR: 'YEAR' as NameEnum,
    EMAIL: 'EMAIL' as NameEnum,
    MOBILE_PHONE: 'MOBILE_PHONE' as NameEnum,
    PHONE: 'PHONE' as NameEnum,
    FAX: 'FAX' as NameEnum,
    WEBSITE: 'WEBSITE' as NameEnum,
    BIC: 'BIC' as NameEnum,
    JOB_TITLE: 'JOB_TITLE' as NameEnum,
    INDUSTRY: 'INDUSTRY' as NameEnum,
    GROUP: 'GROUP' as NameEnum,
    REGION: 'REGION' as NameEnum,
    ADDRESS: 'ADDRESS' as NameEnum,
    STREET: 'STREET' as NameEnum,
    COUNTRY: 'COUNTRY' as NameEnum,
    BUILDING: 'BUILDING' as NameEnum,
    BOX: 'BOX' as NameEnum,
    LOCALITY: 'LOCALITY' as NameEnum,
    NUMBER: 'NUMBER' as NameEnum,
    ZIP: 'ZIP' as NameEnum,
    DISTRICT: 'DISTRICT' as NameEnum,
    PROVINCE: 'PROVINCE' as NameEnum,
    SUBURB: 'SUBURB' as NameEnum,
    STATE: 'STATE' as NameEnum,
    COUNTY: 'COUNTY' as NameEnum,
    LAT_LONG: 'LAT_LONG' as NameEnum,
    FACEBOOK: 'FACEBOOK' as NameEnum,
    YOUTUBE: 'YOUTUBE' as NameEnum,
    INSTAGRAM: 'INSTAGRAM' as NameEnum,
    WHATSAPP: 'WHATSAPP' as NameEnum,
    WECHAT: 'WECHAT' as NameEnum,
    TIKTOK: 'TIKTOK' as NameEnum,
    FACEBOOK_MESSENGER: 'FACEBOOK_MESSENGER' as NameEnum,
    TELEGRAM: 'TELEGRAM' as NameEnum,
    SNAPCHAT: 'SNAPCHAT' as NameEnum,
    PINTEREST: 'PINTEREST' as NameEnum,
    TWITTER: 'TWITTER' as NameEnum,
    REDDIT: 'REDDIT' as NameEnum,
    LINKEDIN: 'LINKEDIN' as NameEnum,
    VIMEO: 'VIMEO' as NameEnum,
    DISCORD: 'DISCORD' as NameEnum,
    TWITCH: 'TWITCH' as NameEnum,
    TUMBLR: 'TUMBLR' as NameEnum,
    MASTODON: 'MASTODON' as NameEnum,
    PATREON: 'PATREON' as NameEnum,
    KICKSTARTER: 'KICKSTARTER' as NameEnum,
    INDIEGOGO: 'INDIEGOGO' as NameEnum,
    GOFUNDME: 'GOFUNDME' as NameEnum,
    TAKEAWAY: 'TAKEAWAY' as NameEnum,
    UBEREATS: 'UBEREATS' as NameEnum,
    DELIVEROO: 'DELIVEROO' as NameEnum,
    SHIPTO: 'SHIPTO' as NameEnum,
    WEIBO: 'WEIBO' as NameEnum,
    QQ: 'QQ' as NameEnum,
    KUAISHOU: 'KUAISHOU' as NameEnum,
    QZONE: 'QZONE' as NameEnum,
    SKYPE: 'SKYPE' as NameEnum,
    NOTE: 'NOTE' as NameEnum,
    ORGANISATION_NAME: 'ORGANISATION_NAME' as NameEnum,
    LEGAL_NAME: 'LEGAL_NAME' as NameEnum,
    LEGAL_FORM: 'LEGAL_FORM' as NameEnum,
    LEGAL_ID: 'LEGAL_ID' as NameEnum,
    COMPANY_NUMBER: 'COMPANY_NUMBER' as NameEnum,
    JURISDICTION: 'JURISDICTION' as NameEnum,
    IMPORT_SOURCE: 'IMPORT_SOURCE' as NameEnum,
    PROPOSAL: 'PROPOSAL' as NameEnum,
    CATEGORY: 'CATEGORY' as NameEnum,
    OPENING_HOURS: 'OPENING_HOURS' as NameEnum,
    CUSTOM: 'CUSTOM' as NameEnum,
    FILE: 'FILE' as NameEnum,
    IMAGE: 'IMAGE' as NameEnum,
    FILE_KEY: 'FILE_KEY' as NameEnum,
    FILE_NAME: 'FILE_NAME' as NameEnum,
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS' as NameEnum,
    PRIVACY_POLICY: 'PRIVACY_POLICY' as NameEnum,
    DOCUMENT: 'DOCUMENT' as NameEnum,
    DOCUMENT_NAME: 'DOCUMENT_NAME' as NameEnum,
    ORGANISATION_TYPE: 'ORGANISATION_TYPE' as NameEnum,
  };
  export type VerificationEnum = 'NONE' | 'INTERNAL' | 'EXTERNAL';
  export const VerificationEnum = {
    NONE: 'NONE' as VerificationEnum,
    INTERNAL: 'INTERNAL' as VerificationEnum,
    EXTERNAL: 'EXTERNAL' as VerificationEnum,
  };
}
