export function unique<T>(array: T[]): T[] {
  if (array.length && Object(array[0]) === array[0]) {
    // compare objects
    return array.filter((value, index) => array.indexOf(value) === index);
  } else {
    // compare primitives (more performant)
    return [...new Set(array)];
  }
}

export function difference<T>(a: T[], b: T[]): T[] {
  const setA = new Set(a);
  const setB = new Set(b);
  const differences = [
    ...new Set([...setA].filter((x) => !setB.has(x))),
    ...new Set([...setB].filter((x) => !setA.has(x))),
  ];
  return Array.from(differences);
}

export function equals<T>(a: T[] | undefined | null, b: T[] | undefined | null): boolean {
  const setA = new Set(a);
  const setB = new Set(b);
  return [...setA].every((x) => setB.has(x)) && [...setB].every((x) => setA.has(x));
}
