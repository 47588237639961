import {Directive, ElementRef, HostBinding, Input, Renderer2} from '@angular/core';

import {CanColor, CanColorCtor, mixinColor} from './can-color';

class ButtonBase {
  constructor(public _elementRef: ElementRef) {}
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _ButtonMixinBase: CanColorCtor & typeof ButtonBase = mixinColor(ButtonBase);
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
@Directive({
  selector: '[axButton]',
  inputs: ['color'],
})
export class ButtonDirective extends _ButtonMixinBase implements CanColor {
  @HostBinding('class.hide-label-mobile') _labelHideMobile = false;
  @Input()
  set labelHideMobile(labelHideMobile: any) {
    this._labelHideMobile = typeof labelHideMobile === 'boolean' ? labelHideMobile : true;
  }

  @HostBinding('class.small') _smallButton = false;
  @Input()
  set smallButton(smallButton: any) {
    this._smallButton = typeof smallButton === 'boolean' ? smallButton : true;
  }

  @HostBinding('class.big-button') _bigButton = false;
  @Input()
  set bigButton(bigButton: any) {
    this._bigButton = typeof bigButton === 'boolean' ? bigButton : true;
  }

  @HostBinding('class.button-full-width') _fullWidth = false;
  @Input()
  set fullWidth(fullWidth: any) {
    this._fullWidth = typeof fullWidth === 'boolean' ? fullWidth : true;
  }

  @HostBinding('class.button-filled') _filled = false;
  @Input()
  set filled(filled: any) {
    this._filled = typeof filled === 'boolean' ? filled : true;
  }

  @HostBinding('class.button-outline') _outline = false;
  @Input()
  set outline(outline: any) {
    this._outline = typeof outline === 'boolean' ? outline : true;
  }

  @HostBinding('class.icon-button') _iconButton = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('icon-button')
  set iconButton(iconButton: any) {
    this._iconButton = typeof iconButton === 'boolean' ? iconButton : true;
  }

  @HostBinding('class.has-hover-label') _hoverLabel = false;
  @Input()
  set hoverLabel(hoverLabel: any) {
    this._hoverLabel = typeof hoverLabel === 'boolean' ? hoverLabel : true;
  }

  @HostBinding('class.no-hover') _noHover = false;
  @Input()
  set hover(hover: any) {
    this._noHover = typeof hover === 'boolean' ? !hover : true;
  }

  @HostBinding('class.button-lowercase') _lowercase = false;
  @Input()
  set lowercase(lowercase: any) {
    this._lowercase = typeof lowercase === 'boolean' ? lowercase : true;
  }

  @Input() @HostBinding('class.is-disabled') disabled = false;

  constructor(_el: ElementRef, _renderer: Renderer2) {
    super(_el);
    _renderer.addClass(_el.nativeElement, 'ax-button');
  }
}
