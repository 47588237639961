import {Injectable, OnDestroy} from '@angular/core';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';

declare global {
  interface Window {
    dataLayer: any[];
    fdq: any[];
  }
}

export interface ConsentStatus {
  ad_storage?: 'denied' | 'granted';
  ad_user_data?: 'denied' | 'granted';
  ad_personalization?: 'denied' | 'granted';
  analytics_storage?: 'denied' | 'granted';
  facebook_consent?: 'revoked' | 'grant';
}

export const defaultConsent: ConsentStatus = {
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  analytics_storage: 'denied',
  facebook_consent: 'revoked',
};

export const grantedConsent: ConsentStatus = {
  ad_storage: 'granted',
  ad_user_data: 'granted',
  ad_personalization: 'granted',
  analytics_storage: 'granted',
  facebook_consent: 'grant',
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements OnDestroy {
  private _fbScript = document.createElement('script');
  private _fbScript2 = document.createElement('script');
  private _gaScript = document.createElement('script');
  private _gaScript2 = document.createElement('script');

  private _head = document.head;

  ownerId: string | undefined;

  consentForm = new FormGroup<ControlsOf<ConsentStatus>>({
    ad_storage: new FormControl<'denied' | 'granted'>('denied'),
    ad_user_data: new FormControl<'denied' | 'granted'>('denied'),
    ad_personalization: new FormControl<'denied' | 'granted'>('denied'),
    analytics_storage: new FormControl<'denied' | 'granted'>('denied'),
  });

  ngOnDestroy() {
    this.removeAnalytics();
  }

  initFacebook(pixelId: string) {
    if (!pixelId) {
      return;
    }

    this._fbScript.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('consent', 'revoke');fbq('init','${pixelId}');fbq('track', 'PageView');`;
    this._head.insertBefore(this._fbScript, document.head.firstChild);
  }

  initGoogle(measurementId: string) {
    if (!measurementId) {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    this._gaScript.innerText = `function gtag(){dataLayer.push(arguments);}`;
    this._head.insertBefore(this._gaScript, document.head.firstChild);

    this._gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    });

    this._gaScript2.setAttribute('async', 'true');
    this._gaScript2.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${measurementId}`
    );
    this._head.insertBefore(this._gaScript2, document.head.firstChild);

    this._gtag('js', new Date());
    this._gtag('config', measurementId);
  }

  consentUpdateAdStorage(consent: 'denied' | 'granted' = 'denied') {
    this._gtag('consent', 'update', {
      ad_storage: consent,
    });
  }

  consentUpdateAdUserData(consent: 'denied' | 'granted' = 'denied') {
    this._gtag('consent', 'update', {
      ad_user_data: consent,
    });
  }

  consentUpdateAdPersonalization(consent: 'denied' | 'granted' = 'denied') {
    this._gtag('consent', 'update', {
      ad_personalization: consent,
    });
  }

  consentUpdateAnalyticsStorage(consent: 'denied' | 'granted' = 'denied') {
    this._gtag('consent', 'update', {
      analytics_storage: consent,
    });
  }

  consentGrantFacebook() {
    this._fbScript2.innerHTML = `fbq('consent', 'grant');`;
    this._head.insertBefore(this._fbScript2, document.head.firstChild);
  }

  consentRevokeFacebook() {
    this._fbScript2.innerHTML = ``;
  }

  removeFacebookAnalytics() {
    this._fbScript.remove();
    this._fbScript2.remove();
  }

  removeGoogleAnalytics() {
    this._gaScript.remove();
    this._gaScript2.remove();
  }

  removeAnalytics() {
    this.removeFacebookAnalytics();
    this.removeGoogleAnalytics();
  }

  updateConsent(consentStatus: ConsentStatus) {
    const currentConsent = this.getConsentFromLocalStorage() || defaultConsent;

    if (currentConsent.ad_storage !== consentStatus['ad_storage']) {
      this.consentUpdateAdStorage(consentStatus['ad_storage']);
    }
    if (currentConsent.ad_user_data !== consentStatus['ad_user_data']) {
      this.consentUpdateAdStorage(consentStatus['ad_user_data']);
    }
    if (currentConsent.ad_personalization !== consentStatus['ad_personalization']) {
      this.consentUpdateAdStorage(consentStatus['ad_personalization']);
    }
    if (currentConsent.analytics_storage !== consentStatus['analytics_storage']) {
      this.consentUpdateAdStorage(consentStatus['analytics_storage']);
      if (consentStatus['analytics_storage'] === 'granted') {
        this.consentGrantFacebook();
        consentStatus.facebook_consent = 'grant';
      } else {
        this.consentRevokeFacebook();
        consentStatus.facebook_consent = 'revoked';
      }
    }

    this.consentForm.patchValue(consentStatus);
    this.setConsentInLocalStorage(consentStatus);
  }

  getConsentFromLocalStorage(): ConsentStatus | undefined {
    const analyticsConsent = localStorage.getItem('analyticsConsent' + this.ownerId);
    if (!analyticsConsent) {
      return undefined;
    }
    return JSON.parse(analyticsConsent);
  }

  setConsentInLocalStorage(updatedConsent: ConsentStatus = defaultConsent) {
    const analyticsConsent = JSON.parse(
      <string>localStorage.getItem('analyticsConsent' + this.ownerId)
    );
    const newConsentObject = {...analyticsConsent, ...updatedConsent};
    localStorage.setItem('analyticsConsent' + this.ownerId, JSON.stringify(newConsentObject));
  }

  private _gtag(...args: any[]): void {
    window.dataLayer.push(args);
  }
}
