import {Pipe, PipeTransform} from '@angular/core';

import {isOfType} from '../util/filter.util';

@Pipe({name: 'isOfType'})
export class IsOfTypePipe implements PipeTransform {
  transform<T extends {type?: string}>(subject?: T, ...types: string[]): boolean {
    if (!subject?.type) {
      return false;
    }
    return isOfType(subject, ...types);
  }
}
