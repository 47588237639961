import {AfterViewInit, Component, ElementRef, Input, ViewEncapsulation} from '@angular/core';
import {
  setAccentPalette,
  setBackgroundColor,
  setFontColor,
  setFontFamily,
  setPrimaryPalette,
  setWarnPalette,
} from '@aztrix/helpers';

@Component({
  selector: 'ax-theme',
  template: `<ng-content></ng-content>`,
  styleUrls: ['theme.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class ThemeComponent implements AfterViewInit {
  @Input()
  set primaryColor(primaryColor: string | undefined) {
    if (!this.accentColor) {
      this.accentColor = this.primaryColor;
    }

    this._primaryColor = primaryColor;
    setPrimaryPalette(this._primaryColor, this._elementRef);
  }
  get primaryColor() {
    return this._primaryColor;
  }

  @Input()
  set accentColor(accentColor: string | undefined) {
    if (accentColor) {
      this._accentColor = accentColor;
    } else {
      this._accentColor = this.primaryColor;
    }

    setAccentPalette(this._accentColor, this._elementRef);
  }
  get accentColor() {
    return this._accentColor;
  }

  @Input()
  set warnColor(warnColor: string | undefined) {
    this._warnColor = warnColor;
    setWarnPalette(this._warnColor, this._elementRef);
  }
  get warnColor() {
    return this._warnColor;
  }

  @Input()
  set fontColor(fontColor: string | undefined) {
    this._fontColor = fontColor;
    setFontColor(this._fontColor, this._elementRef);
  }
  get fontColor() {
    return this._fontColor;
  }

  @Input()
  set fontFamily(fontFamily: string | undefined) {
    this._fontFamily = fontFamily;
    setFontFamily(this._fontFamily, this._elementRef);
  }
  get fontFamily() {
    return this._fontFamily;
  }

  @Input()
  set backgroundColor(backgroundColor: string | undefined) {
    this._backgroundColor = backgroundColor;
    setBackgroundColor(this._backgroundColor, this._elementRef);
  }
  get backgroundColor() {
    return this._backgroundColor;
  }

  private _primaryColor: string | undefined;
  private _accentColor: string | undefined;
  private _warnColor: string | undefined;
  private _fontColor: string | undefined;
  private _fontFamily: string | undefined;
  private _backgroundColor: string | undefined;

  constructor(private _elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    setPrimaryPalette(this._primaryColor, this._elementRef);
    setAccentPalette(this._accentColor, this._elementRef);
    setWarnPalette(this._warnColor, this._elementRef);
    setFontColor(this._fontColor, this._elementRef);
    setFontFamily(this._fontFamily, this._elementRef);
    setBackgroundColor(this._backgroundColor, this._elementRef);
  }
}
