import {isDevMode} from '@angular/core';
import {Locale} from '@aztrix/models';
import {format as dateFnsFormat, differenceInDays, formatDistanceToNow, isValid} from 'date-fns';

import {toDateFnsLocale} from '../helpers/date-fns-date-adapter';

export function formatDate(
  value: string | number | Date | null | undefined,
  locale?: Locale
): string {
  return _format(value, 'P', locale);
}

export function formatDateTime(
  value: string | number | Date | null | undefined,
  locale?: Locale
): string {
  return _format(value, 'Pp', locale);
}

export function formatDay(
  value: string | number | Date | null | undefined,
  locale: Locale,
  short = true
): string {
  return _format(value, short ? 'EEEEEE' : 'EEEE', locale);
}

export function formatTime(
  value: string | number | Date | null | undefined,
  locale?: Locale
): string {
  return _format(value, 'p', locale);
}

export function formatDateLong(
  value: string | number | Date | null | undefined,
  locale?: Locale
): string {
  return _format(value, 'PP', locale);
}

export function formatDateTimeFromNow(
  value: string | number | Date | null | undefined,
  locale?: Locale
) {
  return _formatFromNow(value, 'Pp', locale);
}

export function formatDateLongFromNow(
  value: string | number | Date | null | undefined,
  locale?: Locale
) {
  return _formatFromNow(value, 'PP', locale, 15);
}

function _formatFromNow(
  value: string | number | Date | null | undefined,
  format: string,
  locale?: Locale,
  dayCutoff = 0
): string {
  const date = _toDate(value);
  if (!date) {
    return '';
  }

  if (differenceInDays(date, new Date()) > dayCutoff) {
    return _format(date, format, locale);
  }

  const distance = formatDistanceToNow(date, {
    includeSeconds: true,
    addSuffix: true,
    locale: toDateFnsLocale(locale),
  });
  return distance.charAt(0).toUpperCase() + distance.slice(1);
}

function _format(
  value: string | number | Date | null | undefined,
  format: string,
  locale?: Locale
): string {
  const date = _toDate(value);
  if (date) {
    return dateFnsFormat(date, format, {
      locale: toDateFnsLocale(locale),
    });
  } else {
    return '';
  }
}

function _toDate(value: string | number | Date | null | undefined): Date | undefined {
  if (!value) {
    return undefined;
  }
  const date = new Date(value);
  if (!isValid(date)) {
    if (isDevMode()) {
      console.warn('Invalid Date (date.util.ts)');
    }
    return undefined;
  }
  return date;
}
