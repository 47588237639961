import {Pipe, PipeTransform} from '@angular/core';

import {iconNameForCustomPropertyType} from '../../util/icon.util';

@Pipe({name: 'iconNameForCustomPropertyType'})
export class IconNameForCustomPropertyTypePipe implements PipeTransform {
  transform(type: string): string {
    return iconNameForCustomPropertyType(type);
  }
}
