import {ElementRef} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {mapColor} from '@aztrix/helpers';

export interface CanColor {
  color: string;
  defaultColor: ThemePalette | undefined;
}

declare type Constructor<T> = new (...args: any[]) => T;

export type CanColorCtor = Constructor<CanColor>;

interface HasElementRef {
  _elementRef: ElementRef;
}

/** Mixin to augment a directive with a `color` property. */
export function mixinColor<T extends Constructor<HasElementRef>>(
  base: T,
  defaultColor?: ThemePalette
): CanColorCtor & T {
  return class extends base {
    private _color = '';
    defaultColor = defaultColor;

    get color(): string {
      return this._color;
    }
    set color(value: string) {
      const color = this._prefixColors(mapColor(value || this.defaultColor));
      if (color !== this._color) {
        if (this._color) {
          for (const c of this._color.split(' ')) {
            if (c.length) {
              this._elementRef.nativeElement.classList.remove(c);
            }
          }
        }
        if (color) {
          for (const c of color.split(' ')) {
            if (c.length) {
              this._elementRef.nativeElement.classList.add(c);
            }
          }
        }
        this._color = color;
      }
    }

    private _prefixColors(color: string) {
      return color
        .split(' ')
        .map((c) => (c ? `ax-${c}` : ''))
        .join(' ');
    }

    constructor(...args: any[]) {
      super(...args);

      this.color = '';
    }
  };
}
