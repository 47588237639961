import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AgreementPropertyRepresentation} from '@aztrix/sdk';
import {Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {hasValue} from '../../helpers/property-functions';
import {Subscribe} from '../../helpers/subscribe-functions';

@Pipe({
  name: 'formProperties',
  pure: false,
})
export class FormPropertiesPipe implements PipeTransform, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _form: any = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _latestValue: any = null;

  private _subscription = new Subscription();

  constructor(private _changeDetector: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this._dispose();
  }

  transform(form: UntypedFormGroup): AgreementPropertyRepresentation[] {
    if (!this._form) {
      if (form) {
        this._subscribe(form);
      }
      return this._latestValue;
    }

    if (form !== this._form) {
      this._dispose();
      return this.transform(form);
    }

    return this._latestValue;
  }

  private _subscribe(form: UntypedFormGroup) {
    this._form = form;
    this._subscription = form.valueChanges
      .pipe(
        startWith(form.value),
        map((value) =>
          Subscribe.formAgreementProperties(value).filter((ap) => hasValue(ap.property))
        )
      )
      .subscribe((value) => {
        if (form === this._form) {
          this._latestValue = value;
          this._changeDetector.markForCheck();
        }
      });
  }

  private _dispose(): void {
    this._subscription.unsubscribe();
    this._latestValue = null;
    this._form = null;
  }
}
