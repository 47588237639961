import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {hasValue} from '../../helpers/property-functions';

@Pipe({name: 'propertyHasValue'})
export class PropertyHasValuePipe implements PipeTransform {
  transform(property: Property | undefined | null): boolean {
    return hasValue(property);
  }
}
