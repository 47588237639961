import {Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Params} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import {COMMUNICATION_URL_PARAMS} from '../../communication_url_params';
import {urlParams} from '../util/communication.util';

@Pipe({name: 'urlParams'})
export class UrlParamsPipe implements PipeTransform {
  constructor(
    private _sanitizer: DomSanitizer,
    @Optional()
    @Inject(COMMUNICATION_URL_PARAMS)
    private _params$: BehaviorSubject<Params> | undefined
  ) {}

  transform(value: string, params: Params | null): SafeHtml | string | undefined {
    return urlParams(value, this._sanitizer, {...params, ...(this._params$ || {}).value});
  }
}
