import {Pipe, PipeTransform} from '@angular/core';
import {Agreement} from '@aztrix/models';

import {isExpired} from '../../helpers/agreement-functions';

@Pipe({
  name: 'agreementIsExpired',
})
export class AgreementIsExpiredPipe implements PipeTransform {
  transform(agreement: Agreement): boolean {
    return isExpired(agreement);
  }
}
