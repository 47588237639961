import {Pipe, PipeTransform} from '@angular/core';
import {Property, PropertyType} from '@aztrix/models';

import {propertyCommunicationType} from '../../helpers/property-functions';

@Pipe({name: 'propertyIsCommunicationRelated'})
export class PropertyIsCommunicationRelatedPipe implements PipeTransform {
  transform(propertyOrType: Property | PropertyType | undefined): boolean {
    return !!propertyCommunicationType(propertyOrType);
  }
}
