import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

/**
 * Transforms a `value` string to html where the `query` is highlighted.
 *
 * example: 'randomstring' | hightlight : 'omstr'
 * becomes: 'rand<span class="highlight">omstr</span>ing'
 **/
@Pipe({name: 'highlight'})
export class HighlightPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string, query: string): SafeHtml | string {
    let html = '';
    let start = false;
    for (const char of value) {
      if (query[0] === char) {
        query = query.slice(1);
        if (!start) {
          html += '<span class="highlight">';
          start = true;
        }
      } else if (start) {
        html += '</span>';
        start = false;
      }
      html += char;
    }

    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}
