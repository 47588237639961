import {CommonModule} from '@angular/common';
import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';
import {ThemeComponent} from '@aztrix/theme';

@Component({
  selector: 'ax-overlay-outlet-shadow-dom',
  template: `<div
    class="cdk-overlay-container"
    [ngClass]="{'preview-overlay-container': preview, empty: true}"
  ></div>`,
  styleUrls: ['./overlay-outlet.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [CommonModule],
})
export class OverlayOutletShadowDomComponent {
  @HostBinding('class.empty') empty = true;
  @Input() preview = false;
}

@Component({
  selector: 'ax-overlay-outlet',
  template: `<div
    class="cdk-overlay-container"
    [ngClass]="{'preview-overlay-container': preview, empty: true}"
  ></div>`,
  styleUrls: ['./overlay-outlet.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class OverlayOutletComponent {
  @HostBinding('class.empty') empty = true;
  @Input() preview = false;
}

@Component({
  selector: 'ax-overlay-outlet-shadow-dom-root',
  template: `<ax-theme
    [primaryColor]="primaryColor"
    [accentColor]="accentColor"
    [warnColor]="warnColor"
    [fontColor]="fontColor"
    [fontFamily]="fontFamily"
    [backgroundColor]="backgroundColor"
  >
    <div
      class="cdk-overlay-container"
      [ngClass]="{'preview-overlay-container': preview, empty: true}"
    ></div>
  </ax-theme>`,
  styleUrls: ['./overlay-outlet.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [CommonModule, ThemeComponent],
})
export class OverlayOutletShadowDomRootComponent {
  @HostBinding('class.empty') empty = true;
  @Input() preview = false;

  @Input() primaryColor: string;
  @Input() accentColor: string;
  @Input() warnColor: string;
  @Input() fontColor: string;
  @Input() fontFamily: string;
  @Input() backgroundColor: string;
}

@Component({
  selector: 'ax-overlay-outlet-root',
  template: `<ax-theme
    [primaryColor]="primaryColor"
    [accentColor]="accentColor"
    [warnColor]="warnColor"
    [fontColor]="fontColor"
    [fontFamily]="fontFamily"
    [backgroundColor]="backgroundColor"
  >
    <div
      class="cdk-overlay-container"
      [ngClass]="{'preview-overlay-container': preview, empty: true}"
    ></div>
  </ax-theme>`,
  styleUrls: ['./overlay-outlet.component.scss'],
  standalone: true,
  imports: [CommonModule, ThemeComponent],
})
export class OverlayOutletRootComponent {
  @HostBinding('class.empty') empty = true;
  @Input() preview = false;

  @Input() primaryColor: string;
  @Input() accentColor: string;
  @Input() warnColor: string;
  @Input() fontColor: string;
  @Input() fontFamily: string;
  @Input() backgroundColor: string;
}
