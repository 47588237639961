import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

import {TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'countryCodeLabel',
  pure: false,
})
export class CountryCodeLabelPipe implements PipeTransform, OnDestroy {
  private _countryCode?: string;
  private _latestValue: string;
  private _latestReturnedValue: string;

  private _subscription = new Subscription();

  constructor(
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  static countryCodeLabel(countryCode: string | undefined) {
    return typeof countryCode === 'string' && countryCode
      ? `countryCode.${countryCode.toUpperCase()}.label`
      : 'label.everywhere';
  }

  transform(countryCode: string | undefined): string {
    if (!this._countryCode) {
      this._countryCode = countryCode;

      const observable$ = this._translate.get(CountryCodeLabelPipe.countryCodeLabel(countryCode));
      this._subscribe(observable$);

      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (countryCode !== this._countryCode) {
      this._dispose();
      return this.transform(countryCode);
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  ngOnDestroy() {
    this._dispose();
  }

  private _subscribe(obs: Observable<string>): void {
    this._subscription.add(
      obs.subscribe((value) => {
        this._latestValue = value;
        this._changeDetector.markForCheck();
      })
    );
  }

  private _dispose() {
    this._subscription.unsubscribe();
    this._countryCode = undefined;
    this._latestValue = '';
    this._latestReturnedValue = '';
  }
}
