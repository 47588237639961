{
  "versionInfo": {"version": "0.1.1"},
  "countryCodeSpecifics": [
    {"countryCode": "AD", "phonePrefix": ["+376"], "ibanPrefix": "AD"},
    {"countryCode": "AE", "phonePrefix": ["+971"], "ibanPrefix": "AE"},
    {"countryCode": "AF", "phonePrefix": ["+93"], "ibanPrefix": "AF"},
    {"countryCode": "AG", "phonePrefix": ["+1268"], "ibanPrefix": "AG"},
    {"countryCode": "AI", "phonePrefix": ["+1264"], "ibanPrefix": "AI"},
    {"countryCode": "AL", "phonePrefix": ["+355"], "ibanPrefix": "AL"},
    {"countryCode": "AM", "phonePrefix": ["+374"], "ibanPrefix": "AM"},
    {"countryCode": "AO", "phonePrefix": ["+244"], "ibanPrefix": "AO"},
    {"countryCode": "AQ", "phonePrefix": ["+672"], "ibanPrefix": "AQ"},
    {"countryCode": "AR", "phonePrefix": ["+54"], "ibanPrefix": "AR"},
    {"countryCode": "AS", "phonePrefix": ["+1684"], "ibanPrefix": "AS"},
    {"countryCode": "AT", "phonePrefix": ["+43"], "ibanPrefix": "AT"},
    {"countryCode": "AU", "phonePrefix": ["+61"], "ibanPrefix": "AU"},
    {"countryCode": "AW", "phonePrefix": ["+297"], "ibanPrefix": "AW"},
    {"countryCode": "AX", "phonePrefix": ["+35818"], "ibanPrefix": "AX"},
    {"countryCode": "AZ", "phonePrefix": ["+994"], "ibanPrefix": "AZ"},
    {"countryCode": "BA", "phonePrefix": ["+387"], "ibanPrefix": "BA"},
    {"countryCode": "BB", "phonePrefix": ["+1246"], "ibanPrefix": "BB"},
    {"countryCode": "BD", "phonePrefix": ["+880"], "ibanPrefix": "BD"},
    {"countryCode": "BE", "phonePrefix": ["+32"], "ibanPrefix": "BE"},
    {"countryCode": "BF", "phonePrefix": ["+226"], "ibanPrefix": "BF"},
    {"countryCode": "BG", "phonePrefix": ["+359"], "ibanPrefix": "BG"},
    {"countryCode": "BH", "phonePrefix": ["+973"], "ibanPrefix": "BH"},
    {"countryCode": "BI", "phonePrefix": ["+257"], "ibanPrefix": "BI"},
    {"countryCode": "BJ", "phonePrefix": ["+229"], "ibanPrefix": "BJ"},
    {"countryCode": "BL", "phonePrefix": ["+590"], "ibanPrefix": "BL"},
    {"countryCode": "BM", "phonePrefix": ["+1441"], "ibanPrefix": "BM"},
    {"countryCode": "BN", "phonePrefix": ["+673"], "ibanPrefix": "BN"},
    {"countryCode": "BO", "phonePrefix": ["+591"], "ibanPrefix": "BO"},
    {"countryCode": "BQ", "phonePrefix": ["+599", "+5993", "+5994", "+5997"], "ibanPrefix": "BQ"},
    {"countryCode": "BR", "phonePrefix": ["+55"], "ibanPrefix": "BR"},
    {"countryCode": "BS", "phonePrefix": ["+1242"], "ibanPrefix": "BS"},
    {"countryCode": "BT", "phonePrefix": ["+975"], "ibanPrefix": "BT"},
    {"countryCode": "BV", "phonePrefix": ["+47"], "ibanPrefix": "BV"},
    {"countryCode": "BW", "phonePrefix": ["+267"], "ibanPrefix": "BW"},
    {"countryCode": "BY", "phonePrefix": ["+375"], "ibanPrefix": "BY"},
    {"countryCode": "BZ", "phonePrefix": ["+501"], "ibanPrefix": "BZ"},
    {"countryCode": "CA", "phonePrefix": ["+1"], "ibanPrefix": "CA"},
    {"countryCode": "CC", "phonePrefix": ["+6189162"], "ibanPrefix": "CC"},
    {"countryCode": "CD", "phonePrefix": ["+243"], "ibanPrefix": "CD"},
    {"countryCode": "CF", "phonePrefix": ["+236"], "ibanPrefix": "CF"},
    {"countryCode": "CG", "phonePrefix": ["+242"], "ibanPrefix": "CG"},
    {"countryCode": "CH", "phonePrefix": ["+41"], "ibanPrefix": "CH"},
    {"countryCode": "CI", "phonePrefix": ["+225"], "ibanPrefix": "CI"},
    {"countryCode": "CK", "phonePrefix": ["+682"], "ibanPrefix": "CK"},
    {"countryCode": "CL", "phonePrefix": ["+56"], "ibanPrefix": "CL"},
    {"countryCode": "CM", "phonePrefix": ["+237"], "ibanPrefix": "CM"},
    {"countryCode": "CN", "phonePrefix": ["+86"], "ibanPrefix": "CN"},
    {"countryCode": "CO", "phonePrefix": ["+57"], "ibanPrefix": "CO"},
    {"countryCode": "CR", "phonePrefix": ["+506"], "ibanPrefix": "CR"},
    {"countryCode": "CU", "phonePrefix": ["+53"], "ibanPrefix": "CU"},
    {"countryCode": "CV", "phonePrefix": ["+238"], "ibanPrefix": "CV"},
    {"countryCode": "CW", "phonePrefix": ["+5999"], "ibanPrefix": "CW"},
    {"countryCode": "CX", "phonePrefix": ["+6189164"], "ibanPrefix": "CX"},
    {"countryCode": "CY", "phonePrefix": ["+357"], "ibanPrefix": "CY"},
    {"countryCode": "CZ", "phonePrefix": ["+420"], "ibanPrefix": "CZ"},
    {"countryCode": "DE", "phonePrefix": ["+49"], "ibanPrefix": "DE"},
    {"countryCode": "DJ", "phonePrefix": ["+253"], "ibanPrefix": "DJ"},
    {"countryCode": "DK", "phonePrefix": ["+45"], "ibanPrefix": "DK"},
    {"countryCode": "DM", "phonePrefix": ["+1767"], "ibanPrefix": "DM"},
    {"countryCode": "DO", "phonePrefix": ["+1809", "+1829", "+1849"], "ibanPrefix": "DO"},
    {"countryCode": "DZ", "phonePrefix": ["+213"], "ibanPrefix": "DZ"},
    {"countryCode": "EC", "phonePrefix": ["+593"], "ibanPrefix": "EC"},
    {"countryCode": "EE", "phonePrefix": ["+372"], "ibanPrefix": "EE"},
    {"countryCode": "EG", "phonePrefix": ["+20"], "ibanPrefix": "EG"},
    {"countryCode": "EH", "phonePrefix": ["+212"], "ibanPrefix": "EH"},
    {"countryCode": "ER", "phonePrefix": ["+291"], "ibanPrefix": "ER"},
    {"countryCode": "ES", "phonePrefix": ["+34"], "ibanPrefix": "ES"},
    {"countryCode": "ET", "phonePrefix": ["+251"], "ibanPrefix": "ET"},
    {"countryCode": "FI", "phonePrefix": ["+358"], "ibanPrefix": "FI"},
    {"countryCode": "FJ", "phonePrefix": ["+679"], "ibanPrefix": "FJ"},
    {"countryCode": "FK", "phonePrefix": ["+500"], "ibanPrefix": "FK"},
    {"countryCode": "FM", "phonePrefix": ["+691"], "ibanPrefix": "FM"},
    {"countryCode": "FO", "phonePrefix": ["+298"], "ibanPrefix": "FO"},
    {"countryCode": "FR", "phonePrefix": ["+33"], "ibanPrefix": "FR"},
    {"countryCode": "GA", "phonePrefix": ["+241"], "ibanPrefix": "GA"},
    {"countryCode": "GB", "phonePrefix": ["+44", "+4428"], "ibanPrefix": "GB"},
    {"countryCode": "GD", "phonePrefix": ["+1473"], "ibanPrefix": "GD"},
    {"countryCode": "GE", "phonePrefix": ["+995", "+99534", "+99544"], "ibanPrefix": "GE"},
    {"countryCode": "GF", "phonePrefix": ["+594"], "ibanPrefix": "GF"},
    {
      "countryCode": "GG",
      "phonePrefix": ["+441481", "+447781", "+447839", "+447911"],
      "ibanPrefix": "GG"
    },
    {"countryCode": "GH", "phonePrefix": ["+233"], "ibanPrefix": "GH"},
    {"countryCode": "GI", "phonePrefix": ["+350"], "ibanPrefix": "GI"},
    {"countryCode": "GL", "phonePrefix": ["+299"], "ibanPrefix": "GL"},
    {"countryCode": "GM", "phonePrefix": ["+220"], "ibanPrefix": "GM"},
    {"countryCode": "GN", "phonePrefix": ["+224"], "ibanPrefix": "GN"},
    {"countryCode": "GP", "phonePrefix": ["+590"], "ibanPrefix": "GP"},
    {"countryCode": "GQ", "phonePrefix": ["+240"], "ibanPrefix": "GQ"},
    {"countryCode": "GR", "phonePrefix": ["+30"], "ibanPrefix": "GR"},
    {"countryCode": "GS", "phonePrefix": ["+500"], "ibanPrefix": "GS"},
    {"countryCode": "GT", "phonePrefix": ["+502"], "ibanPrefix": "GT"},
    {"countryCode": "GU", "phonePrefix": ["+1671"], "ibanPrefix": "GU"},
    {"countryCode": "GW", "phonePrefix": ["+245"], "ibanPrefix": "GW"},
    {"countryCode": "GY", "phonePrefix": ["+592"], "ibanPrefix": "GY"},
    {"countryCode": "HK", "phonePrefix": ["+852"], "ibanPrefix": "HK"},
    {"countryCode": "HM", "phonePrefix": ["+61"], "ibanPrefix": "HM"},
    {"countryCode": "HN", "phonePrefix": ["+504"], "ibanPrefix": "HN"},
    {"countryCode": "HR", "phonePrefix": ["+385"], "ibanPrefix": "HR"},
    {"countryCode": "HT", "phonePrefix": ["+509"], "ibanPrefix": "HT"},
    {"countryCode": "HU", "phonePrefix": ["+36"], "ibanPrefix": "HU"},
    {"countryCode": "ID", "phonePrefix": ["+62"], "ibanPrefix": "ID"},
    {"countryCode": "IE", "phonePrefix": ["+353"], "ibanPrefix": "IE"},
    {"countryCode": "IL", "phonePrefix": ["+972"], "ibanPrefix": "IL"},
    {
      "countryCode": "IM",
      "phonePrefix": ["+441624", "+447524", "+447624", "+447924"],
      "ibanPrefix": "IM"
    },
    {"countryCode": "IN", "phonePrefix": ["+91"], "ibanPrefix": "IN"},
    {"countryCode": "IO", "phonePrefix": ["+246"], "ibanPrefix": "IO"},
    {"countryCode": "IQ", "phonePrefix": ["+964"], "ibanPrefix": "IQ"},
    {"countryCode": "IR", "phonePrefix": ["+98"], "ibanPrefix": "IR"},
    {"countryCode": "IS", "phonePrefix": ["+354"], "ibanPrefix": "IS"},
    {"countryCode": "IT", "phonePrefix": ["+39"], "ibanPrefix": "IT"},
    {"countryCode": "JE", "phonePrefix": ["+441534"], "ibanPrefix": "JE"},
    {"countryCode": "JM", "phonePrefix": ["+1876"], "ibanPrefix": "JM"},
    {"countryCode": "JO", "phonePrefix": ["+962"], "ibanPrefix": "JO"},
    {"countryCode": "JP", "phonePrefix": ["+81"], "ibanPrefix": "JP"},
    {"countryCode": "KE", "phonePrefix": ["+254"], "ibanPrefix": "KE"},
    {"countryCode": "KG", "phonePrefix": ["+996"], "ibanPrefix": "KG"},
    {"countryCode": "KH", "phonePrefix": ["+855"], "ibanPrefix": "KH"},
    {"countryCode": "KI", "phonePrefix": ["+686"], "ibanPrefix": "KI"},
    {"countryCode": "KM", "phonePrefix": ["+269"], "ibanPrefix": "KM"},
    {"countryCode": "KN", "phonePrefix": ["+1869"], "ibanPrefix": "KN"},
    {"countryCode": "KP", "phonePrefix": ["+850"], "ibanPrefix": "KP"},
    {"countryCode": "KR", "phonePrefix": ["+82"], "ibanPrefix": "KR"},
    {"countryCode": "KW", "phonePrefix": ["+965"], "ibanPrefix": "KW"},
    {"countryCode": "KY", "phonePrefix": ["+1345"], "ibanPrefix": "KY"},
    {"countryCode": "KZ", "phonePrefix": ["+76", "+77"], "ibanPrefix": "KZ"},
    {"countryCode": "LA", "phonePrefix": ["+856"], "ibanPrefix": "LA"},
    {"countryCode": "LB", "phonePrefix": ["+961"], "ibanPrefix": "LB"},
    {"countryCode": "LC", "phonePrefix": ["+1758"], "ibanPrefix": "LC"},
    {"countryCode": "LI", "phonePrefix": ["+423"], "ibanPrefix": "LI"},
    {"countryCode": "LK", "phonePrefix": ["+94"], "ibanPrefix": "LK"},
    {"countryCode": "LR", "phonePrefix": ["+231"], "ibanPrefix": "LR"},
    {"countryCode": "LS", "phonePrefix": ["+266"], "ibanPrefix": "LS"},
    {"countryCode": "LT", "phonePrefix": ["+370"], "ibanPrefix": "LT"},
    {"countryCode": "LU", "phonePrefix": ["+352"], "ibanPrefix": "LU"},
    {"countryCode": "LV", "phonePrefix": ["+371"], "ibanPrefix": "LV"},
    {"countryCode": "LY", "phonePrefix": ["+218"], "ibanPrefix": "LY"},
    {"countryCode": "MA", "phonePrefix": ["+212"], "ibanPrefix": "MA"},
    {"countryCode": "MC", "phonePrefix": ["+377"], "ibanPrefix": "MC"},
    {"countryCode": "MD", "phonePrefix": ["+373", "+3732", "+3735"], "ibanPrefix": "MD"},
    {"countryCode": "ME", "phonePrefix": ["+382"], "ibanPrefix": "ME"},
    {"countryCode": "MF", "phonePrefix": ["+590"], "ibanPrefix": "MF"},
    {"countryCode": "MG", "phonePrefix": ["+261"], "ibanPrefix": "MG"},
    {"countryCode": "MH", "phonePrefix": ["+692"], "ibanPrefix": "MH"},
    {"countryCode": "MK", "phonePrefix": ["+389"], "ibanPrefix": "MK"},
    {"countryCode": "ML", "phonePrefix": ["+223"], "ibanPrefix": "ML"},
    {"countryCode": "MM", "phonePrefix": ["+95"], "ibanPrefix": "MM"},
    {"countryCode": "MN", "phonePrefix": ["+976"], "ibanPrefix": "MN"},
    {"countryCode": "MO", "phonePrefix": ["+853"], "ibanPrefix": "MO"},
    {"countryCode": "MP", "phonePrefix": ["+1670"], "ibanPrefix": "MP"},
    {"countryCode": "MQ", "phonePrefix": ["+596"], "ibanPrefix": "MQ"},
    {"countryCode": "MR", "phonePrefix": ["+222"], "ibanPrefix": "MR"},
    {"countryCode": "MS", "phonePrefix": ["+1664"], "ibanPrefix": "MS"},
    {"countryCode": "MT", "phonePrefix": ["+356"], "ibanPrefix": "MT"},
    {"countryCode": "MU", "phonePrefix": ["+230"], "ibanPrefix": "MU"},
    {"countryCode": "MV", "phonePrefix": ["+960"], "ibanPrefix": "MV"},
    {"countryCode": "MW", "phonePrefix": ["+265"], "ibanPrefix": "MW"},
    {"countryCode": "MX", "phonePrefix": ["+52"], "ibanPrefix": "MX"},
    {"countryCode": "MY", "phonePrefix": ["+60"], "ibanPrefix": "MY"},
    {"countryCode": "MZ", "phonePrefix": ["+258"], "ibanPrefix": "MZ"},
    {"countryCode": "NA", "phonePrefix": ["+264"], "ibanPrefix": "NA"},
    {"countryCode": "NC", "phonePrefix": ["+687"], "ibanPrefix": "NC"},
    {"countryCode": "NE", "phonePrefix": ["+227"], "ibanPrefix": "NE"},
    {"countryCode": "NF", "phonePrefix": ["+6723"], "ibanPrefix": "NF"},
    {"countryCode": "NG", "phonePrefix": ["+234"], "ibanPrefix": "NG"},
    {"countryCode": "NI", "phonePrefix": ["+505"], "ibanPrefix": "NI"},
    {"countryCode": "NL", "phonePrefix": ["+31"], "ibanPrefix": "NL"},
    {"countryCode": "NO", "phonePrefix": ["+47"], "ibanPrefix": "NO"},
    {"countryCode": "NP", "phonePrefix": ["+977"], "ibanPrefix": "NP"},
    {"countryCode": "NR", "phonePrefix": ["+674"], "ibanPrefix": "NR"},
    {"countryCode": "NU", "phonePrefix": ["+683"], "ibanPrefix": "NU"},
    {"countryCode": "NZ", "phonePrefix": ["+64"], "ibanPrefix": "NZ"},
    {"countryCode": "OM", "phonePrefix": ["+968"], "ibanPrefix": "OM"},
    {"countryCode": "PA", "phonePrefix": ["+507"], "ibanPrefix": "PA"},
    {"countryCode": "PE", "phonePrefix": ["+51"], "ibanPrefix": "PE"},
    {"countryCode": "PF", "phonePrefix": ["+689"], "ibanPrefix": "PF"},
    {"countryCode": "PG", "phonePrefix": ["+675"], "ibanPrefix": "PG"},
    {"countryCode": "PH", "phonePrefix": ["+63"], "ibanPrefix": "PH"},
    {"countryCode": "PK", "phonePrefix": ["+92"], "ibanPrefix": "PK"},
    {"countryCode": "PL", "phonePrefix": ["+48"], "ibanPrefix": "PL"},
    {"countryCode": "PM", "phonePrefix": ["+508"], "ibanPrefix": "PM"},
    {"countryCode": "PN", "phonePrefix": ["+64"], "ibanPrefix": "PN"},
    {"countryCode": "PR", "phonePrefix": ["+1787", "+1939"], "ibanPrefix": "PR"},
    {"countryCode": "PS", "phonePrefix": ["+970"], "ibanPrefix": "PS"},
    {"countryCode": "PT", "phonePrefix": ["+351"], "ibanPrefix": "PT"},
    {"countryCode": "PW", "phonePrefix": ["+680"], "ibanPrefix": "PW"},
    {"countryCode": "PY", "phonePrefix": ["+595"], "ibanPrefix": "PY"},
    {"countryCode": "QA", "phonePrefix": ["+974"], "ibanPrefix": "QA"},
    {"countryCode": "RE", "phonePrefix": ["+262"], "ibanPrefix": "RE"},
    {"countryCode": "RO", "phonePrefix": ["+40"], "ibanPrefix": "RO"},
    {"countryCode": "RS", "phonePrefix": ["+381"], "ibanPrefix": "RS"},
    {"countryCode": "RU", "phonePrefix": ["+7"], "ibanPrefix": "RU"},
    {"countryCode": "RW", "phonePrefix": ["+250"], "ibanPrefix": "RW"},
    {"countryCode": "SA", "phonePrefix": ["+966"], "ibanPrefix": "SA"},
    {"countryCode": "SB", "phonePrefix": ["+677"], "ibanPrefix": "SB"},
    {"countryCode": "SC", "phonePrefix": ["+248"], "ibanPrefix": "SC"},
    {"countryCode": "SD", "phonePrefix": ["+249"], "ibanPrefix": "SD"},
    {"countryCode": "SE", "phonePrefix": ["+46"], "ibanPrefix": "SE"},
    {"countryCode": "SG", "phonePrefix": ["+65"], "ibanPrefix": "SG"},
    {"countryCode": "SH", "phonePrefix": ["+290", "+2908"], "ibanPrefix": "SH"},
    {"countryCode": "SI", "phonePrefix": ["+386"], "ibanPrefix": "SI"},
    {"countryCode": "SJ", "phonePrefix": ["+4779"], "ibanPrefix": "SJ"},
    {"countryCode": "SK", "phonePrefix": ["+421"], "ibanPrefix": "SK"},
    {"countryCode": "SL", "phonePrefix": ["+232"], "ibanPrefix": "SL"},
    {"countryCode": "SM", "phonePrefix": ["+378"], "ibanPrefix": "SM"},
    {"countryCode": "SN", "phonePrefix": ["+221"], "ibanPrefix": "SN"},
    {"countryCode": "SO", "phonePrefix": ["+252"], "ibanPrefix": "SO"},
    {"countryCode": "SR", "phonePrefix": ["+597"], "ibanPrefix": "SR"},
    {"countryCode": "SS", "phonePrefix": ["+211"], "ibanPrefix": "SS"},
    {"countryCode": "ST", "phonePrefix": ["+239"], "ibanPrefix": "ST"},
    {"countryCode": "SV", "phonePrefix": ["+503"], "ibanPrefix": "SV"},
    {"countryCode": "SX", "phonePrefix": ["+1721"], "ibanPrefix": "SX"},
    {"countryCode": "SY", "phonePrefix": ["+963"], "ibanPrefix": "SY"},
    {"countryCode": "SZ", "phonePrefix": ["+268"], "ibanPrefix": "SZ"},
    {"countryCode": "TC", "phonePrefix": ["+1649"], "ibanPrefix": "TC"},
    {"countryCode": "TD", "phonePrefix": ["+235"], "ibanPrefix": "TD"},
    {"countryCode": "TF", "phonePrefix": ["+262"], "ibanPrefix": "TF"},
    {"countryCode": "TG", "phonePrefix": ["+228"], "ibanPrefix": "TG"},
    {"countryCode": "TH", "phonePrefix": ["+66"], "ibanPrefix": "TH"},
    {"countryCode": "TJ", "phonePrefix": ["+992"], "ibanPrefix": "TJ"},
    {"countryCode": "TK", "phonePrefix": ["+690"], "ibanPrefix": "TK"},
    {"countryCode": "TL", "phonePrefix": ["+670"], "ibanPrefix": "TL"},
    {"countryCode": "TM", "phonePrefix": ["+993"], "ibanPrefix": "TM"},
    {"countryCode": "TN", "phonePrefix": ["+216"], "ibanPrefix": "TN"},
    {"countryCode": "TO", "phonePrefix": ["+676"], "ibanPrefix": "TO"},
    {"countryCode": "TR", "phonePrefix": ["+90"], "ibanPrefix": "TR"},
    {"countryCode": "TT", "phonePrefix": ["+1868"], "ibanPrefix": "TT"},
    {"countryCode": "TV", "phonePrefix": ["+688"], "ibanPrefix": "TV"},
    {"countryCode": "TW", "phonePrefix": ["+886"], "ibanPrefix": "TW"},
    {"countryCode": "TZ", "phonePrefix": ["+255"], "ibanPrefix": "TZ"},
    {"countryCode": "UA", "phonePrefix": ["+380"], "ibanPrefix": "UA"},
    {"countryCode": "UG", "phonePrefix": ["+256"], "ibanPrefix": "UG"},
    {"countryCode": "UM", "phonePrefix": ["+1808"], "ibanPrefix": "UM"},
    {"countryCode": "US", "phonePrefix": ["+1"], "ibanPrefix": "US"},
    {"countryCode": "UY", "phonePrefix": ["+598"], "ibanPrefix": "UY"},
    {"countryCode": "UZ", "phonePrefix": ["+998"], "ibanPrefix": "UZ"},
    {"countryCode": "VA", "phonePrefix": ["+3906698"], "ibanPrefix": "VA"},
    {"countryCode": "VC", "phonePrefix": ["+1784"], "ibanPrefix": "VC"},
    {"countryCode": "VE", "phonePrefix": ["+58"], "ibanPrefix": "VE"},
    {"countryCode": "VG", "phonePrefix": ["+1284"], "ibanPrefix": "VG"},
    {"countryCode": "VI", "phonePrefix": ["+1340"], "ibanPrefix": "VI"},
    {"countryCode": "VN", "phonePrefix": ["+84"], "ibanPrefix": "VN"},
    {"countryCode": "VU", "phonePrefix": ["+678"], "ibanPrefix": "VU"},
    {"countryCode": "WF", "phonePrefix": ["+681"], "ibanPrefix": "WF"},
    {"countryCode": "WS", "phonePrefix": ["+685"], "ibanPrefix": "WS"},
    {"countryCode": "YE", "phonePrefix": ["+967"], "ibanPrefix": "YE"},
    {"countryCode": "YT", "phonePrefix": ["+262269", "+262639"], "ibanPrefix": "YT"},
    {"countryCode": "ZA", "phonePrefix": ["+27"], "ibanPrefix": "ZA"},
    {"countryCode": "ZM", "phonePrefix": ["+260"], "ibanPrefix": "ZM"},
    {"countryCode": "ZW", "phonePrefix": ["+263"], "ibanPrefix": "ZW"}
  ],
  "propertyTypes": [
    {"name": "AVATAR"},
    {"name": "USERNAME", "regex": "^[a-z0-9._-]+"},
    {"name": "FIRST_NAME", "regex": "^[\\D]{2,40}$"},
    {"name": "MIDDLE_NAME", "regex": "^[\\D]{1,20}$"},
    {"name": "LAST_NAME", "regex": "^[\\D]{2,40}$"},
    {
      "name": "NAME",
      "subTypes": [
        {"name": "FIRST_NAME", "required": true},
        {"name": "MIDDLE_NAME", "required": false},
        {"name": "LAST_NAME", "required": true}
      ]
    },
    {"name": "GENDER", "regex": "^(M|F){1}$"},
    {"name": "DAY", "regex": "^([1-9]|[12][0-9]|[3][01])$"},
    {"name": "MONTH", "regex": "^([1-9]|1[012])$"},
    {"name": "YEAR", "regex": "^[0-9]{4}$"},
    {
      "name": "BIRTHDAY",
      "subTypes": [
        {"name": "DAY", "required": true, "dummy": true},
        {"name": "MONTH", "required": true, "dummy": true},
        {"name": "YEAR", "required": true, "dummy": true}
      ]
    },
    {"name": "ORGANISATION_NAME", "regex": "^.{1,150}$"},
    {"name": "JOB_TITLE", "regex": "^[\\D]{2,50}$"},
    {"name": "INDUSTRY", "regex": "^[\\D]{2,50}$"},
    {"name": "LEGAL_NAME", "regex": "^.{1,150}$"},
    {"name": "LEGAL_FORM", "regex": "^[\\D]{2,25}$"},
    {"name": "COMPANY_NUMBER", "regex": "^[A-Z0-9]{1,30}$"},
    {"name": "JURISDICTION", "regex": "^.{1,75}$"},
    {
      "name": "LEGAL_ID",
      "uniquelyVerifiable": true,
      "subTypes": [
        {"name": "COMPANY_NUMBER", "required": true, "dummy": true},
        {"name": "JURISDICTION", "required": false, "dummy": true}
      ]
    },
    {
      "name": "FOUNDED",
      "subTypes": [
        {"name": "DAY", "required": true, "dummy": true},
        {"name": "MONTH", "required": true, "dummy": true},
        {"name": "YEAR", "required": true, "dummy": true}
      ]
    },
    {
      "name": "DISSOLVED",
      "subTypes": [
        {"name": "DAY", "required": true, "dummy": true},
        {"name": "MONTH", "required": true, "dummy": true},
        {"name": "YEAR", "required": true, "dummy": true}
      ]
    },
    {"name": "IMPORT_SOURCE"},
    {"name": "CATEGORY"},
    {"name": "PROPOSAL"},
    {
      "name": "EMAIL",
      "uniquelyVerifiable": true,
      "regex": "^[a-z0-9._-]+@[a-z0-9.-]+[\\.][a-z]{2,10}$"
    },
    {"name": "MOBILE_PHONE", "uniquelyVerifiable": true, "regex": "^\\+?(\\s?[0-9]\\s?){10,15}$"},
    {"name": "PHONE", "uniquelyVerifiable": false, "regex": "^\\+?(\\s?[0-9]\\s?){10,15}$"},
    {"name": "FAX", "regex": "^\\+?(\\s?[0-9]\\s?){10,15}$"},
    {
      "name": "BIC",
      "uniquelyVerifiable": true,
      "regex": "^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$"
    },
    {
      "name": "WEBSITE",
      "regex": "^(https?://)([\\dA-Za-z\\.-]+)\\.([A-Za-z\\.]{2,6})([/\\w \\.-].*)*/?$"
    },
    {"name": "NOTE", "regex": "^(\\s*.\\s*){1,2000}$"},
    {"name": "GROUP"},
    {"name": "STREET", "regex": "^.{1,100}$"},
    {"name": "BUILDING", "regex": "^.{1,100}$"},
    {"name": "BOX", "regex": "^.{1,20}$"},
    {"name": "LOCALITY", "regex": "^.{1,100}$"},
    {"name": "NUMBER", "regex": "^.{1,20}$"},
    {"name": "ZIP", "regex": "^.{1,20}$"},
    {"name": "DISTRICT", "regex": "^.{1,100}$"},
    {"name": "PROVINCE", "regex": "^.{1,100}$"},
    {"name": "SUBURB", "regex": "^.{1,100}$"},
    {"name": "STATE", "regex": "^.{1,100}$"},
    {"name": "COUNTY", "regex": "^.{1,100}$"},
    {"name": "COUNTRY", "regex": "^[A-Za-z]{2}$"},
    {
      "name": "LAT_LONG",
      "regex": "^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$"
    },
    {
      "name": "REGION",
      "subTypes": [],
      "subTypesPerCountryCode": {
        "": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTY", "required": false, "dummy": false},
          {"name": "DISTRICT", "required": false, "dummy": false},
          {"name": "PROVINCE", "required": false, "dummy": false},
          {"name": "STATE", "required": false, "dummy": false},
          {"name": "SUBURB", "required": false, "dummy": false},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "AT": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "BG": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "BE": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "CY": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "CZ": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "DE": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "DK": [
          {"name": "DISTRICT", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "EE": [
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "ES": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "PROVINCE", "required": false, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "FI": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "FR": [
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "GR": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "GB": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "HR": [
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "HU": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "IE": [
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "IT": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "LT": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "LU": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "MT": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "NL": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "PL": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "PT": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "RO": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "SE": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "SI": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ],
        "SK": [
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true}
        ]
      }
    },
    {
      "name": "ADDRESS",
      "subTypes": [],
      "subTypesPerCountryCode": {
        "": [
          {"name": "STREET", "required": false, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": false, "dummy": true},
          {"name": "COUNTY", "required": false, "dummy": false},
          {"name": "DISTRICT", "required": false, "dummy": false},
          {"name": "PROVINCE", "required": false, "dummy": false},
          {"name": "STATE", "required": false, "dummy": false},
          {"name": "SUBURB", "required": false, "dummy": false},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "AT": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "BG": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "BE": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": true, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "SUBURB", "required": false, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "CY": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "CZ": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "DE": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "DK": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "DISTRICT", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "EE": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "ES": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "PROVINCE", "required": false, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "FI": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "FR": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "GR": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "GB": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "HR": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "HU": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "IE": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "COUNTY", "required": false, "dummy": true},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "IT": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "PROVINCE", "required": false, "dummy": false},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "LT": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "LU": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "MT": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "NL": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "SUBURB", "required": false, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "PL": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "PT": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "SUBURB", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "RO": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "SE": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "SI": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ],
        "SK": [
          {"name": "STREET", "required": true, "dummy": true},
          {"name": "NUMBER", "required": false, "dummy": true},
          {"name": "BOX", "required": false, "dummy": true},
          {"name": "BUILDING", "required": false, "dummy": false},
          {"name": "ZIP", "required": true, "dummy": true},
          {"name": "LOCALITY", "required": true, "dummy": true},
          {"name": "COUNTRY", "required": true, "dummy": true},
          {"name": "LAT_LONG", "required": false, "dummy": false}
        ]
      }
    },
    {
      "name": "FACEBOOK",
      "uniquelyVerifiable": true,
      "regex": "^(https:\\/\\/(www\\.)?facebook\\.com\\/)(.*)"
    },
    {"name": "YOUTUBE", "regex": "^(https:\\/\\/(www\\.)?youtube\\.com\\/)(.*)"},
    {"name": "INSTAGRAM", "regex": "^(https:\\/\\/(www\\.)?instagram\\.com\\/)(.*)"},
    {"name": "WHATSAPP", "regex": "^(https:\\/\\/wa\\.me\\/)(.*)"},
    {"name": "WECHAT", "regex": "^.{2,300}$"},
    {"name": "TIKTOK", "regex": "^(https:\\/\\/(www\\.)?tiktok\\.com\\/)(.*)"},
    {"name": "FACEBOOK_MESSENGER", "regex": "^(https:\\/\\/(www\\.)?m\\.me\\/)(.*)"},
    {
      "name": "TELEGRAM",
      "regex": "^(https:\\/\\/(www\\.)?t\\.me\\/)(.*)|^(https:\\/\\/.*)(\\.t\\.me)"
    },
    {"name": "SNAPCHAT", "regex": "^(https:\\/\\/(www\\.)?snapchat\\.com\\/)(.*)"},
    {"name": "PINTEREST", "regex": "^(https:\\/\\/(www\\.)?pinterest\\.com\\/)(.*)"},
    {
      "name": "TWITTER",
      "uniquelyVerifiable": true,
      "regex": "^(https:\\/\\/(www\\.)?twitter\\.com\\/)(.*)"
    },
    {"name": "REDDIT", "regex": "^(https:\\/\\/(www\\.)?reddit\\.com\\/)(.*)"},
    {
      "name": "LINKEDIN",
      "uniquelyVerifiable": true,
      "regex": "^(https:\\/\\/(www\\.)?linkedin\\.com\\/(in|company)\\/)(.*)"
    },
    {"name": "VIMEO", "regex": "^(https:\\/\\/(www\\.)?vimeo\\.com\\/)(.*)"},
    {"name": "DISCORD", "regex": "^(https:\\/\\/(www\\.)?discord\\.gg\\/)(.*)"},
    {"name": "TWITCH", "regex": "^(https:\\/\\/(www\\.)?twitch\\.tv\\/)(.*)"},
    {"name": "TUMBLR", "regex": "^(https:\\/\\/(www\\.)?tumblr\\.com\\/)(.*)"},
    {"name": "MASTODON", "regex": "^.{2,300}$"},
    {"name": "PATREON", "regex": "^(https:\\/\\/(www\\.)?patreon\\.com\\/)(.*)"},
    {"name": "KICKSTARTER", "regex": "^(https:\\/\\/(www\\.)?kickstarter\\.com\\/)(.*)"},
    {"name": "INDIEGOGO", "regex": "^(https:\\/\\/(www\\.)?indiegogo\\.com\\/)(.*)"},
    {"name": "GOFUNDME", "regex": "^(https:\\/\\/(www\\.)?gofund\\.me\\/)(.*)"},
    {"name": "TAKEAWAY", "regex": "^(https:\\/\\/(www\\.)?takeaway\\.com)([\\/\\w \\.-].*)*\\/?$"},
    {"name": "UBEREATS", "regex": "^(https:\\/\\/(www\\.)?ubereats\\.com)([\\/\\w \\.-].*)*\\/?$"},
    {"name": "DELIVEROO", "regex": "^(https:\\/\\/(www\\.)?deliveroo\\.be)([\\/\\w \\.-].*)*\\/?$"},
    {
      "name": "SHIPTO",
      "regex": "^(https?://)([\\dA-Za-z\\.-]+)\\.([A-Za-z\\.]{2,6})([/\\w \\.-].*)*/?$"
    },
    {"name": "WEIBO", "regex": "^(https:\\/\\/(www\\.)?weibo\\.com\\/)(.*)"},
    {"name": "QQ", "regex": "^.{2,300}$"},
    {"name": "KUAISHOU", "regex": "^(https:\\/\\/(www\\.)?kuaishou\\.com\\/)(.*)"},
    {"name": "QZONE", "regex": "^.{2,300}$"},
    {"name": "SKYPE", "regex": "^.{2,300}$"},
    {
      "name": "OPENING_HOURS",
      "regex": "^(((20[0-9][0-9] (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-9][0-9](-20[0-9][0-9] (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-9][0-9])?)[ ]?)?((Mo|Tu|We|Th|Fr|Sa|Su)(,(Mo|Tu|We|Th|Fr|Sa|Su))*)[ ]?(off|([0-2][0-9]:[0-6][0-9])-([0-2][0-9]:[0-6][0-9])(,([0-2][0-9]:[0-6][0-9])-([0-2][0-9]:[0-6][0-9]))*)[ ]?(\"[^\"]*?\")?)([;][ ]?((20[0-9][0-9] (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-9][0-9](-20[0-9][0-9] (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) [0-9][0-9])?)[ ]?)?((Mo|Tu|We|Th|Fr|Sa|Su)(,(Mo|Tu|We|Th|Fr|Sa|Su))*)[ ]?(off|([0-2][0-9]:[0-6][0-9])-([0-2][0-9]:[0-6][0-9])(,([0-2][0-9]:[0-6][0-9])-([0-2][0-9]:[0-6][0-9]))*)[ ]?(\"[^\"]*?\")?)*$"
    },
    {"name": "CUSTOM"},
    {
      "name": "IMAGE",
      "subTypes": [
        {"name": "FILE_KEY", "required": true},
        {"name": "FILE_NAME", "required": true}
      ]
    },
    {
      "name": "FILE",
      "subTypes": [
        {"name": "FILE_KEY", "required": true},
        {"name": "FILE_NAME", "required": true}
      ]
    },
    {
      "name": "DOCUMENT",
      "subTypes": [
        {"name": "DOCUMENT_NAME", "required": true},
        {"name": "FILE_KEY", "required": true},
        {"name": "FILE_NAME", "required": true}
      ]
    },
    {
      "name": "TERMS_AND_CONDITIONS",
      "subTypes": [
        {"name": "FILE_KEY", "required": true},
        {"name": "FILE_NAME", "required": true}
      ]
    },
    {
      "name": "PRIVACY_POLICY",
      "subTypes": [
        {"name": "FILE_KEY", "required": true},
        {"name": "FILE_NAME", "required": true}
      ]
    },
    {"name": "FILE_KEY"},
    {"name": "FILE_NAME"},
    {"name": "DOCUMENT_NAME"},
    {"name": "ORGANISATION_TYPE"}
  ],
  "propertyContextTypes": {
    "PROFILE": {
      "INDIVIDUAL": [
        {
          "name": "PROFILE",
          "limit": 1,
          "required": true,
          "dummy": true,
          "propertyTypes": [
            {"name": "AVATAR", "dummy": true, "limit": 1},
            {"name": "USERNAME", "dummy": true, "limit": 1, "required": true, "immutable": true},
            {"name": "FIRST_NAME", "dummy": true, "limit": 1},
            {"name": "MIDDLE_NAME", "dummy": true, "limit": 1},
            {"name": "LAST_NAME", "dummy": true, "limit": 1}
          ]
        },
        {
          "name": "COMMON",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "BIRTHDAY", "dummy": true, "limit": 1},
            {"name": "GENDER", "dummy": true, "limit": 1},
            {"name": "REGION", "dummy": true, "limit": 1}
          ]
        },
        {
          "name": "PERSONAL",
          "limit": 10,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "ADDRESS", "dummy": true, "limit": 1},
            {"name": "PHONE", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "FAX", "dummy": false, "limit": 10},
            {"name": "MOBILE_PHONE", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "EMAIL", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "FACEBOOK", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "TWITTER", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "INSTAGRAM", "dummy": false, "limit": 10},
            {"name": "SKYPE", "dummy": false, "limit": 10}
          ]
        },
        {
          "name": "WORK",
          "limit": 10,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "ORGANISATION_NAME", "dummy": true, "limit": 1},
            {"name": "INDUSTRY", "dummy": false, "limit": 1},
            {"name": "JOB_TITLE", "dummy": true, "limit": 10},
            {"name": "ADDRESS", "dummy": true, "limit": 1},
            {"name": "PHONE", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "FAX", "dummy": false, "limit": 10},
            {"name": "MOBILE_PHONE", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "EMAIL", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "WEBSITE", "dummy": true, "limit": 10},
            {"name": "LINKEDIN", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "TWITTER", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "SKYPE", "dummy": false, "limit": 10},
            {"name": "INSTAGRAM", "dummy": false, "limit": 10}
          ]
        },
        {
          "name": "GROUP",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [{"name": "GROUP", "dummy": false, "limit": 10}]
        },
        {
          "name": "PROPOSAL",
          "limit": 1,
          "required": false,
          "dummy": false,
          "propertyTypes": [{"name": "PROPOSAL", "dummy": false, "limit": 100}]
        },
        {"name": "MISC", "limit": 1, "required": false, "dummy": true, "propertyTypes": []}
      ],
      "ORGANISATION": [
        {
          "name": "PROFILE",
          "limit": 1,
          "required": true,
          "dummy": true,
          "propertyTypes": [
            {"name": "AVATAR", "dummy": true, "limit": 1},
            {"name": "USERNAME", "dummy": true, "limit": 1, "required": true, "immutable": true},
            {"name": "ORGANISATION_NAME", "dummy": true, "limit": 1, "required": true}
          ]
        },
        {
          "name": "COMMON",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "INDUSTRY", "dummy": false, "limit": 1},
            {"name": "ORGANISATION_TYPE", "dummy": false, "limit": 1},
            {"name": "BIC", "dummy": false, "limit": 10},
            {"name": "CATEGORY", "dummy": true, "limit": 1}
          ]
        },
        {
          "name": "LEGAL",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "LEGAL_NAME", "dummy": true, "limit": 1, "verification": "EXTERNAL"},
            {"name": "LEGAL_ID", "dummy": true, "limit": 1, "verification": "EXTERNAL"},
            {"name": "LEGAL_FORM", "dummy": true, "limit": 1, "verification": "EXTERNAL"},
            {"name": "FOUNDED", "dummy": true, "limit": 1, "verification": "EXTERNAL"},
            {"name": "DISSOLVED", "dummy": false, "limit": 1, "verification": "EXTERNAL"},
            {"name": "ADDRESS", "dummy": true, "limit": 1, "verification": "EXTERNAL"}
          ]
        },
        {
          "name": "DOCUMENTS",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "TERMS_AND_CONDITIONS", "dummy": true, "limit": 1},
            {"name": "PRIVACY_POLICY", "dummy": true, "limit": 1},
            {"name": "DOCUMENT", "dummy": true, "limit": 10}
          ]
        },
        {
          "name": "GENERAL",
          "limit": 10,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "ADDRESS", "dummy": true, "limit": 1},
            {"name": "PHONE", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "FAX", "dummy": false, "limit": 10},
            {"name": "MOBILE_PHONE", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "EMAIL", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "WEBSITE", "dummy": true, "limit": 10},
            {"name": "FACEBOOK", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "TWITTER", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "LINKEDIN", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "INSTAGRAM", "dummy": false, "limit": 10},
            {"name": "SKYPE", "dummy": false, "limit": 10},
            {"name": "BIC", "dummy": false, "limit": 1, "verification": "EXTERNAL"},
            {"name": "TAKEAWAY", "dummy": false, "limit": 1},
            {"name": "DELIVEROO", "dummy": false, "limit": 1},
            {"name": "SHIPTO", "dummy": false, "limit": 1},
            {"name": "UBEREATS", "dummy": false, "limit": 1},
            {"name": "OPENING_HOURS", "dummy": true, "limit": 1}
          ]
        },
        {
          "name": "GROUP",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [{"name": "GROUP", "dummy": false, "limit": 100}]
        },
        {
          "name": "PROPOSAL",
          "limit": 1,
          "required": false,
          "dummy": false,
          "propertyTypes": [{"name": "PROPOSAL", "dummy": false, "limit": 100}]
        },
        {
          "name": "MISC",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {
              "name": "IMPORT_SOURCE",
              "dummy": false,
              "limit": 10,
              "verification": "EXTERNAL",
              "immutable": true,
              "userAddable": false
            },
            {
              "name": "CATEGORY",
              "dummy": false,
              "limit": 10,
              "verification": "EXTERNAL",
              "immutable": true,
              "userAddable": false
            }
          ]
        }
      ]
    },
    "CONTACT": {
      "INDIVIDUAL": [
        {
          "name": "PROFILE",
          "limit": 1,
          "required": true,
          "dummy": true,
          "propertyTypes": [
            {"name": "AVATAR", "dummy": true, "limit": 1},
            {"name": "USERNAME", "dummy": true, "limit": 1},
            {"name": "FIRST_NAME", "dummy": true, "limit": 1},
            {"name": "MIDDLE_NAME", "dummy": true, "limit": 1},
            {"name": "LAST_NAME", "dummy": true, "limit": 1}
          ]
        },
        {
          "name": "COMMON",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "BIRTHDAY", "dummy": true, "limit": 1},
            {"name": "GENDER", "dummy": true, "limit": 1},
            {"name": "REGION", "dummy": true, "limit": 1}
          ]
        },
        {
          "name": "PERSONAL",
          "limit": 10,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "ADDRESS", "dummy": true, "limit": 1},
            {"name": "PHONE", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "FAX", "dummy": false, "limit": 10},
            {"name": "MOBILE_PHONE", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "EMAIL", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "FACEBOOK", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "TWITTER", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "INSTAGRAM", "dummy": false, "limit": 10},
            {"name": "SKYPE", "dummy": false, "limit": 10}
          ]
        },
        {
          "name": "WORK",
          "limit": 10,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "ORGANISATION_NAME", "dummy": true, "limit": 1},
            {"name": "INDUSTRY", "dummy": false, "limit": 1},
            {"name": "JOB_TITLE", "dummy": true, "limit": 10},
            {"name": "ADDRESS", "dummy": true, "limit": 1},
            {"name": "PHONE", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "FAX", "dummy": false, "limit": 10},
            {"name": "MOBILE_PHONE", "dummy": false, "limit": 10, "verification": "INTERNAL"},
            {"name": "EMAIL", "dummy": true, "limit": 10, "verification": "INTERNAL"},
            {"name": "WEBSITE", "dummy": true, "limit": 10},
            {"name": "LINKEDIN", "dummy": false, "limit": 1, "verification": "INTERNAL"},
            {"name": "TWITTER", "dummy": false, "limit": 1, "verification": "INTERNAL"},
            {"name": "SKYPE", "dummy": false, "limit": 1},
            {"name": "INSTAGRAM", "dummy": false, "limit": 1}
          ]
        },
        {
          "name": "GROUP",
          "limit": 1,
          "required": false,
          "dummy": false,
          "propertyTypes": [
            {"name": "GROUP", "dummy": false, "limit": 10, "immutable": true, "userAddable": false}
          ]
        },
        {
          "name": "PROPOSAL",
          "limit": 1,
          "required": false,
          "dummy": false,
          "propertyTypes": [
            {
              "name": "PROPOSAL",
              "dummy": false,
              "limit": 100,
              "immutable": true,
              "userAddable": false
            }
          ]
        },
        {
          "name": "MISC",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [{"name": "NOTE", "dummy": true, "limit": 1}]
        }
      ],
      "ORGANISATION": [
        {
          "name": "PROFILE",
          "limit": 1,
          "required": true,
          "dummy": true,
          "propertyTypes": [
            {"name": "AVATAR", "dummy": true, "limit": 1},
            {"name": "USERNAME", "dummy": true, "limit": 1},
            {"name": "ORGANISATION_NAME", "dummy": true, "limit": 1, "required": true}
          ]
        },
        {
          "name": "COMMON",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "INDUSTRY", "dummy": false, "limit": 1},
            {"name": "ORGANISATION_TYPE", "dummy": false, "limit": 1},
            {"name": "BIC", "dummy": false, "limit": 10},
            {"name": "CATEGORY", "dummy": true, "limit": 1}
          ]
        },
        {
          "name": "LEGAL",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "LEGAL_NAME", "dummy": true, "limit": 1},
            {"name": "LEGAL_ID", "dummy": true, "limit": 1},
            {"name": "LEGAL_FORM", "dummy": true, "limit": 1},
            {"name": "FOUNDED", "dummy": true, "limit": 1},
            {"name": "DISSOLVED", "dummy": false, "limit": 1},
            {"name": "ADDRESS", "dummy": true, "limit": 1}
          ]
        },
        {"name": "DOCUMENTS", "limit": 1, "required": false, "dummy": false, "propertyTypes": []},
        {
          "name": "GENERAL",
          "limit": 10,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "ADDRESS", "dummy": true, "limit": 1},
            {"name": "PHONE", "dummy": true, "limit": 10},
            {"name": "FAX", "dummy": false, "limit": 10},
            {"name": "MOBILE_PHONE", "dummy": true, "limit": 10},
            {"name": "EMAIL", "dummy": true, "limit": 10},
            {"name": "WEBSITE", "dummy": true, "limit": 10},
            {"name": "FACEBOOK", "dummy": false, "limit": 1},
            {"name": "TWITTER", "dummy": false, "limit": 1},
            {"name": "LINKEDIN", "dummy": false, "limit": 1},
            {"name": "INSTAGRAM", "dummy": false, "limit": 1},
            {"name": "SKYPE", "dummy": false, "limit": 1},
            {"name": "BIC", "dummy": false, "limit": 1},
            {"name": "TAKEAWAY", "dummy": false, "limit": 1},
            {"name": "DELIVEROO", "dummy": false, "limit": 1},
            {"name": "SHIPTO", "dummy": false, "limit": 1},
            {"name": "UBEREATS", "dummy": false, "limit": 1},
            {"name": "OPENING_HOURS", "dummy": false, "limit": 1}
          ]
        },
        {
          "name": "GROUP",
          "limit": 1,
          "required": false,
          "dummy": false,
          "propertyTypes": [{"name": "GROUP", "dummy": false, "limit": 100}]
        },
        {
          "name": "PROPOSAL",
          "limit": 1,
          "required": false,
          "dummy": false,
          "propertyTypes": [
            {
              "name": "PROPOSAL",
              "dummy": false,
              "limit": 100,
              "immutable": true,
              "userAddable": false
            }
          ]
        },
        {
          "name": "MISC",
          "limit": 1,
          "required": false,
          "dummy": true,
          "propertyTypes": [
            {"name": "NOTE", "dummy": true, "limit": 1},
            {
              "name": "IMPORT_SOURCE",
              "dummy": false,
              "limit": 10,
              "immutable": true,
              "userAddable": false
            },
            {
              "name": "CATEGORY",
              "dummy": false,
              "limit": 10,
              "verification": "EXTERNAL",
              "immutable": true,
              "userAddable": false
            }
          ]
        }
      ]
    }
  },
  "groupTypes": {
    "INDIVIDUAL": [
      {
        "name": "CONTACTS",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": true,
        "canAddMembers": false,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": true,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [true],
        "membersVisibleDefault": true
      },
      {
        "name": "FAVORITES",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": false,
        "canAddMembers": true,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": true,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [false],
        "membersVisibleDefault": false
      },
      {
        "name": "FAMILY",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": false,
        "canAddMembers": true,
        "ownerCanBeMember": true,
        "followable": false,
        "sharedInfo": true,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [false],
        "membersVisibleDefault": false
      },
      {
        "name": "EXPLICIT",
        "default": false,
        "editable": true,
        "shareable": true,
        "canAddMembers": true,
        "ownerCanBeMember": true,
        "followable": true,
        "sharedInfo": true,
        "deletable": true,
        "canChangeMembershipVisibility": true,
        "membersVisiblePossibilities": [true],
        "membersVisibleDefault": true
      },
      {
        "name": "FOLLOWERS",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": true,
        "canAddMembers": false,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": true,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [true],
        "membersVisibleDefault": true
      },
      {
        "name": "IMPORTING",
        "default": false,
        "editable": false,
        "shareable": false,
        "canAddMembers": false,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": true,
        "deletable": true,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [false],
        "membersVisibleDefault": false
      },
      {
        "name": "BLOCKED",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": false,
        "canAddMembers": true,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": false,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [false],
        "membersVisibleDefault": false
      }
    ],
    "ORGANISATION": [
      {
        "name": "CONTACTS",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": true,
        "canAddMembers": false,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": true,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [true],
        "membersVisibleDefault": true
      },
      {
        "name": "FAVORITES",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": false,
        "canAddMembers": true,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": true,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [false],
        "membersVisibleDefault": false
      },
      {
        "name": "EXPLICIT",
        "default": false,
        "editable": true,
        "shareable": true,
        "canAddMembers": true,
        "ownerCanBeMember": true,
        "followable": true,
        "sharedInfo": true,
        "deletable": true,
        "canChangeMembershipVisibility": true,
        "membersVisiblePossibilities": [false, true],
        "membersVisibleDefault": false
      },
      {
        "name": "FOLLOWERS",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": true,
        "canAddMembers": false,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": true,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [true],
        "membersVisibleDefault": true
      },
      {
        "name": "IMPORTING",
        "default": false,
        "editable": false,
        "shareable": false,
        "canAddMembers": false,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": true,
        "deletable": true,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [false],
        "membersVisibleDefault": false
      },
      {
        "name": "BLOCKED",
        "default": true,
        "limit": 1,
        "editable": false,
        "shareable": false,
        "canAddMembers": true,
        "ownerCanBeMember": false,
        "followable": false,
        "sharedInfo": false,
        "deletable": false,
        "canChangeMembershipVisibility": false,
        "membersVisiblePossibilities": [false],
        "membersVisibleDefault": false
      }
    ]
  },
  "searchPropertyTypes": {
    "INDIVIDUAL": ["FIRST_NAME", "LAST_NAME", "USERNAME", "EMAIL", "NOTE"],
    "ORGANISATION": ["ORGANISATION_NAME", "EMAIL", "NOTE"]
  },
  "categories": [
    "EMERGENCY",
    "ENERGY_UTILITIES",
    "TRAVEL",
    "FINANCE",
    "SHOPPING",
    "INSURANCE",
    "GOOD_CAUSE",
    "CAR_MOBILITY",
    "TELECOM_TV",
    "SPORTS_LEISURE_CULTURE",
    "NEWSPAPER_MAGAZINES"
  ],
  "availableLanguages": ["fr", "en", "nl"],
  "registrationRequirements": {"INDIVIDUAL": {"minimumAgeForRegistration": 13}},
  "importProviders": ["TWITTER", "YAHOO", "MICROSOFT", "GOOGLE", "FACEBOOK"]
}
