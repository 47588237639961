import {Injectable, OnDestroy} from '@angular/core';
import {Geolocation} from '@capacitor/geolocation';
import {from, Observable, of, ReplaySubject} from 'rxjs';
import {first, map} from 'rxjs/operators';

export interface Coordinates {
  lat: number;
  lon: number;
}

let nextUniqueId = 0;

@Injectable({providedIn: 'root'})
export class GeolocationService implements OnDestroy {
  currentPos$ = new ReplaySubject<Coordinates>(1);

  private _idMappings: {uniqueId: number; callbackId: string}[] = [];

  ngOnDestroy(): void {
    for (const idMap of this._idMappings) {
      Geolocation.clearWatch({id: idMap.callbackId});
    }
  }

  listen(): number {
    const uniqueId = nextUniqueId++;

    from(
      Geolocation.watchPosition(
        {
          maximumAge: 3000,
          enableHighAccuracy: true,
        },
        (position) => {
          if (position && 'coords' in position) {
            this.currentPos$.next({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            });
          }
        }
      )
    )
      .pipe(first())
      .subscribe((callbackId) => {
        this._idMappings.push({uniqueId, callbackId});
      });
    return uniqueId;
  }

  destroy(id: number): void {
    const map = this._idMappings.find((m) => m.uniqueId === id);
    if (map?.callbackId) {
      Geolocation.clearWatch({id: map.callbackId});
    }
  }

  calculateDistance(coords: string | undefined): Observable<number | undefined> {
    if (!coords) {
      return of(undefined);
    }
    const coordinates = coords.split(',');
    const lat = parseFloat(coordinates[0]);
    const lon = parseFloat(coordinates[1]);

    return this.currentPos$.pipe(
      map((c) => {
        if (!c?.lat || !c?.lon) {
          return undefined;
        }
        return this._distance(c.lat, c.lon, lat, lon);
      })
    );
  }

  private _distance(lat1: number, long1: number, lat2: number, long2: number): number {
    const rad = 6371;
    const dlat = this.deg2Rad(lat2 - lat1);
    const dlon = this.deg2Rad(long2 - long1);

    const f =
      Math.sin(dlat / 2) * Math.sin(dlat / 2) +
      Math.cos(this.deg2Rad(lat2)) *
        Math.cos(this.deg2Rad(lat1)) *
        Math.sin(dlon / 2) *
        Math.sin(dlon / 2);

    const second = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(1 - f));
    const distance = rad * second;
    return parseFloat(distance.toFixed(1));
  }

  private deg2Rad(deg: number) {
    return deg * (Math.PI / 180);
  }
}
