/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {Context} from './context';
import {Alternative} from './alternative';
import {PropertyRepresentation} from './propertyRepresentation';

export interface PropertyRequest {
  id?: string;
  type?: PropertyRequest.TypeEnum;
  context?: Context;
  alternatives?: Array<Alternative>;
  missingProperty?: PropertyRepresentation;
  requestedAlternative?: string;
}
export namespace PropertyRequest {
  export type TypeEnum = 'REQUEST_TYPE' | 'REQUEST_CONFIRMATION';
  export const TypeEnum = {
    TYPE: 'REQUEST_TYPE' as TypeEnum,
    CONFIRMATION: 'REQUEST_CONFIRMATION' as TypeEnum,
  };
}
