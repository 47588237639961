/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {PropertyTypeInfo} from './propertyTypeInfo';

export interface ContextTypeRepresentation {
  name?: ContextTypeRepresentation.NameEnum;
  limit?: number;
  required?: boolean;
  dummy?: boolean;
  propertyTypes?: Array<PropertyTypeInfo>;
}
export namespace ContextTypeRepresentation {
  export type NameEnum =
    | 'PROFILE'
    | 'COMMON'
    | 'PERSONAL'
    | 'WORK'
    | 'GENERAL'
    | 'LEGAL'
    | 'GROUP'
    | 'MISC'
    | 'PROPOSAL'
    | 'DOCUMENTS';
  export const NameEnum = {
    PROFILE: 'PROFILE' as NameEnum,
    COMMON: 'COMMON' as NameEnum,
    PERSONAL: 'PERSONAL' as NameEnum,
    WORK: 'WORK' as NameEnum,
    GENERAL: 'GENERAL' as NameEnum,
    LEGAL: 'LEGAL' as NameEnum,
    GROUP: 'GROUP' as NameEnum,
    MISC: 'MISC' as NameEnum,
    PROPOSAL: 'PROPOSAL' as NameEnum,
    DOCUMENTS: 'DOCUMENTS' as NameEnum,
  };
}
