import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';

@Pipe({name: 'categoryLabel', pure: false})
export class CategoryLabelPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  transform(category: string): string {
    return new TranslatePipe(
      this.translate,
      this.changeDetector,
      this._languageOverride$
    ).transform(`category.${category}.label`);
  }
}
