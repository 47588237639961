import {Pipe, PipeTransform} from '@angular/core';

import {isPrivate} from '../../helpers/subject-functions';

@Pipe({name: 'isPrivate'})
export class IsPrivatePipe implements PipeTransform {
  transform(subject: unknown): boolean {
    return !!subject && isPrivate(subject);
  }
}
