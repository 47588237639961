import {Pipe, PipeTransform} from '@angular/core';

import {iconNameForPropertyType} from '../../util/icon.util';

@Pipe({name: 'iconNameForPropertyType'})
export class IconNameForPropertyTypePipe implements PipeTransform {
  transform(type: string | undefined): string {
    return iconNameForPropertyType(type);
  }
}
