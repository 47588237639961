import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlTransformerService} from '@aztrix/environment';
import {NEVER, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class VatCheckerApiService {
  constructor(private _http: HttpClient, private _urlTransformer: UrlTransformerService) {}

  checkVat(countryCode: string, vat: string): Observable<boolean> {
    if (!vat) {
      return NEVER;
    }
    return this._http
      .post<{valid: boolean}>(this._urlTransformer.transformUrl(`r/utils/checkVat`), {
        countryCode,
        vat,
      })
      .pipe(
        catchError(() => of({valid: false})),
        map((response: {valid: boolean}) => response.valid)
      );
  }
}
