/**
 * deep comparison of any type with a few exceptions:
 * * null values are considered equal to undefined
 * * array order is negated, array items do not need to be in the same position
 */
export function deepEqual(value1: unknown, value2: unknown): boolean {
  const clean = (value: unknown): unknown => {
    let array = [];
    if (Array.isArray(value)) {
      array = value.map((i) => clean(i)).filter((i) => i !== undefined);
    } else {
      if (typeof value === 'object' && value !== null) {
        array = Object.entries(value)
          .map((i) => ['object', i[0], clean(i[1])])
          .filter((i) => i[2] !== undefined);
      } else {
        return value ?? undefined;
      }
    }

    if (array.flat().length <= 0) {
      return undefined;
    }

    return array.map((i) => i ?? undefined);
  };

  const array1 = clean(value1);
  const array2 = clean(value2);

  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return (array1 ?? null) === (array2 ?? null);
  } else {
    if (array1.length !== array2.length) {
      return false;
    }

    const array2Copy = [...array2];
    for (const value1 of array1) {
      const foundIndex = array2Copy.findIndex((value2) => {
        return deepEqual(value1, value2);
      });

      if (foundIndex > -1) {
        array2Copy.splice(foundIndex, 1);
      } else {
        return false;
      }
    }
    return true;
  }
}
