import {Pipe, PipeTransform} from '@angular/core';
import {Property, PropertyType} from '@aztrix/models';

import {isSocialRelated} from '../../helpers/property-functions';

@Pipe({name: 'propertyIsSocialRelated'})
export class PropertyIsSocialRelatedPipe implements PipeTransform {
  transform(propertyOrType: Property | PropertyType | undefined): boolean {
    return isSocialRelated(propertyOrType);
  }
}
