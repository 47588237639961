/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DocumentRepresentation {
  id?: string;
  name?: string;
  type?: DocumentRepresentation.TypeEnum;
  filename?: string;
  value?: string;
  checkboxLabel?: string;
  description?: string;
  orderIndex?: number;
  signingRequired?: boolean;
}
export namespace DocumentRepresentation {
  export type TypeEnum = 'LINK' | 'FILE' | 'TEMPLATE';
  export const TypeEnum = {
    LINK: 'LINK' as TypeEnum,
    FILE: 'FILE' as TypeEnum,
    TEMPLATE: 'TEMPLATE' as TypeEnum,
  };
}
