import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, InjectionToken, Optional, SkipSelf} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ASSETS_URL, BACKEND_URL, UrlTransformerService} from '@aztrix/environment';
import {
  BehaviorSubject,
  NEVER,
  catchError,
  defer,
  first,
  map,
  of,
  retry,
  shareReplay,
  switchMap,
  timer,
} from 'rxjs';

import {IconConfig} from './icon-config';

export const ICONS_CONFIGURATION = new InjectionToken<IconConfig>('ICONS_CONFIGURATION');

export type IconMeta = {
  namespace: string;
  name: string;
  aliases?: string[];
  styles?: string[];
  groups?: string[];
};

@Injectable({providedIn: 'root'})
export class IconService {
  meta$ = this._httpClient
    .get<{
      meta: IconMeta[];
    }>('assets/icons/meta.json')
    .pipe(
      map((data) => data.meta),
      shareReplay(1),
      catchError(() => NEVER)
    );

  loading$ = new BehaviorSubject(true);

  constructor(
    private _registry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private _urlTransformer: UrlTransformerService,
    private _httpClient: HttpClient,
    @Optional() @Inject(BACKEND_URL) private _backendUrl: BehaviorSubject<string>,
    @Optional() @Inject(ASSETS_URL) private _assetsUrl: BehaviorSubject<string>,
    @Optional() @Inject(ICONS_CONFIGURATION) config: IconConfig
  ) {
    defer(() => {
      if (this._assetsUrl) {
        return this._assetsUrl.pipe(map((assetsUrl) => `${assetsUrl}/assets/icons/meta.json`));
      } else if (this._backendUrl) {
        return this._backendUrl.pipe(map((backendUrl) => `${backendUrl}/assets/icons/meta.json`));
      } else {
        return of(this._urlTransformer.transformUrl(`assets/icons/meta.json`));
      }
    })
      .pipe(
        switchMap((url) =>
          this._httpClient.get<{
            meta: IconMeta[];
          }>(url, {
            params: {_noAuth: 'true'},
          })
        ),
        map((data) => data.meta),
        retry({delay: (_err, retryCount) => timer(retryCount * 200)})
      )
      .subscribe((meta) => {
        for (const svg of meta) {
          this.register(svg.namespace, svg.name);
        }

        if (config && config.preload) {
          for (const svg of config.preload) {
            this._registry.getNamedSvgIcon(svg).pipe(first()).subscribe();
          }
        }

        this.loading$.next(false);
      });
  }

  private register(namespace: string, name: string): void {
    this._registry.addSvgIconInNamespace(
      namespace === 'material' ? '' : namespace,
      name,
      this._url(this._svgPath(namespace, name))
    );
  }

  private _svgPath(namespace: string, name: string) {
    return `assets/icons/${namespace}/${name}.svg`;
  }

  private _url(path: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this._urlTransformer.transformUrl(path));
  }
}

export function iconServiceProviderFactory(
  parentIconService: IconService,
  matReg: MatIconRegistry,
  dom: DomSanitizer,
  urlTransformer: UrlTransformerService,
  httpClient: HttpClient,
  backendUrl: BehaviorSubject<string>,
  assetsUrl: BehaviorSubject<string>,
  config: IconConfig
) {
  return (
    parentIconService ||
    new IconService(matReg, dom, urlTransformer, httpClient, backendUrl, assetsUrl, config)
  );
}

export const ICON_SERVICE_PROVIDER = {
  provide: IconService,
  deps: [
    [new Optional(), new SkipSelf(), IconService],
    MatIconRegistry,
    DomSanitizer,
    UrlTransformerService,
    HttpClient,
    [new Optional(), BACKEND_URL],
    [new Optional(), ASSETS_URL],
    [new Optional(), ICONS_CONFIGURATION],
  ],
  useFactory: iconServiceProviderFactory,
};
