import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {ENVIRONMENT_CONFIG_URL} from '../environment_config_url';
import {ALIST_URL, ALIST_VIEW_URL, ASSETS_URL, BACKEND_URL, PLATFORM_URL} from '../urls';
import {BaseUrlInterceptor} from './base-url.interceptor';
import {EnvironmentService} from './environment.service';

function initialize(environmentService: EnvironmentService): () => Observable<unknown> {
  return () => environmentService._load();
}

export function provideEnvironment(configUrl = './config.env') {
  return [
    {
      provide: BACKEND_URL,
      useValue: new BehaviorSubject(''),
    },
    {
      provide: ASSETS_URL,
      useValue: new BehaviorSubject(''),
    },
    {
      provide: PLATFORM_URL,
      useValue: new BehaviorSubject(''),
    },
    {
      provide: ALIST_URL,
      useValue: new BehaviorSubject(''),
    },
    {
      provide: ALIST_VIEW_URL,
      useValue: new BehaviorSubject(''),
    },
    {
      provide: ENVIRONMENT_CONFIG_URL,
      useValue: configUrl,
    },
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [EnvironmentService],
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true},
  ];
}

@NgModule()
export class EnvironmentModule {
  static forRoot(configUrl = './config.env'): ModuleWithProviders<EnvironmentModule> {
    return {
      ngModule: EnvironmentModule,
      providers: provideEnvironment(configUrl),
    };
  }
}
