export enum ErrorType {
  MISSING_ID = 'MISSING_ID',
  INVALID_OPERATION = 'INVALID_OPERATION',
  INVALID_LOGIN = 'INVALID_LOGIN',
  INVALID_AUTHTOKEN = 'INVALID_AUTHTOKEN',
  SEARCH_QUERY_TOO_SHORT = 'SEARCH_QUERY_TOO_SHORT',
  LOGIN_ALREADY_REGISTERED = 'LOGIN_ALREADY_REGISTERED',
  LOGIN_NOT_REGISTERED = 'LOGIN_NOT_REGISTERED',
  LOGIN_NOT_VERIFIED = 'LOGIN_NOT_VERIFIED',
  LOGIN_INVALID_PASSWORD = 'LOGIN_INVALID_PASSWORD',
  INVALID_TOKEN = 'INVALID_TOKEN',
  NONE = 'NONE',
  FORBIDDEN = 'FORBIDDEN',
  DEFAULT = 'DEFAULT',
  TOO_MANY_CONNECTIONS = 'TOO_MANY_CONNECTIONS',
  TOO_MANY_CONTACTS = 'TOO_MANY_CONTACTS',
  TOO_MANY_PROPOSALS = 'TOO_MANY_PROPOSALS',
  INVITATION_NOT_FOUND = 'INVITATION_NOT_FOUND',
  INVITATION_CONSUMED = 'INVITATION_CONSUMED',
  REGISTRATION_DISABLED = 'REGISTRATION_DISABLED',
  GROUP_NAME_ALREADY_EXISTS = 'GROUP_NAME_ALREADY_EXISTS',
  AGREEMENT_NOT_ACTIVE = 'AGREEMENT_NOT_ACTIVE',
  USERNAME_NOT_AVAILABLE = 'USERNAME_NOT_AVAILABLE',
  PROPERTY_NOT_VALID = 'PROPERTY_NOT_VALID',
  LINK_EXPIRED = 'LINK_EXPIRED',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  PAGE_LANGUAGE_NOT_FOUND = 'PAGE_LANGUAGE_NOT_FOUND',
  PAGE_LANGUAGE_ALREADY_EXISTS = 'PAGE_LANGUAGE_ALREADY_EXISTS',
  PAGE_FIELD_VALUE_ID_DOES_NOT_EXIST = 'PAGE_FIELD_VALUE_ID_DOES_NOT_EXIST',
  PAGE_TEMPLATE_NOT_FOUND = 'PAGE_TEMPLATE_NOT_FOUND',
  PAGE_LANGUAGE_URL_ALREADY_EXISTS = 'PAGE_LANGUAGE_URL_ALREADY_EXISTS',
  PAGE_LANGUAGE_URL_INVALID = 'PAGE_LANGUAGE_URL_INVALID',
  PAGE_LANGUAGE_DOMAIN_INVALID = 'PAGE_LANGUAGE_DOMAIN_INVALID',
  AGREEMENT_NOT_FOUND = 'AGREEMENT_NOT_FOUND',
  AGREEMENT_DOCUMENT_NOT_FOUND = 'AGREEMENT_DOCUMENT_NOT_FOUND',
  AGREEMENT_ALREADY_VERIFIED = 'AGREEMENT_ALREADY_VERIFIED',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  NOT_FOUND = 'NOT_FOUND',
  TOO_MANY_VERIFICATION_ATTEMPTS = 'TOO_MANY_VERIFICATION_ATTEMPTS',
}
