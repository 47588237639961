/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {AddNonConfirmedAgreementRepresentation} from '../model/addNonConfirmedAgreementRepresentation';
// @ts-ignore
import {AgreementRepresentation} from '../model/agreementRepresentation';
// @ts-ignore
import {ExternalAgreementRepresentation} from '../model/externalAgreementRepresentation';
// @ts-ignore
import {FormDataContentDisposition} from '../model/formDataContentDisposition';
// @ts-ignore
import {IdListRepresentation} from '../model/idListRepresentation';
// @ts-ignore
import {IntermediateAgreementDataRepresentation} from '../model/intermediateAgreementDataRepresentation';
// @ts-ignore
import {PaymentIntentRepresentation} from '../model/paymentIntentRepresentation';
// @ts-ignore
import {ProposalCountersRepresentation} from '../model/proposalCountersRepresentation';
// @ts-ignore
import {ProposalRepresentation} from '../model/proposalRepresentation';
// @ts-ignore
import {RequestedPropertyRepresentation} from '../model/requestedPropertyRepresentation';
// @ts-ignore
import {SearchAgreementsRepresentation} from '../model/searchAgreementsRepresentation';
// @ts-ignore
import {SearchAgreementsResponseRepresentation} from '../model/searchAgreementsResponseRepresentation';
// @ts-ignore
import {SubscribeRepresentation} from '../model/subscribeRepresentation';
// @ts-ignore
import {UpdateAgreementRepresentation} from '../model/updateAgreementRepresentation';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class ProposalsService {
  protected basePath = '/r';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Add a non confirmed agreement
   * @param proposalId
   * @param addNonConfirmedAgreementRepresentation Agreement info
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addNonConfirmedAgreement(
    proposalId: string,
    addNonConfirmedAgreementRepresentation: AddNonConfirmedAgreementRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<AgreementRepresentation>;
  public addNonConfirmedAgreement(
    proposalId: string,
    addNonConfirmedAgreementRepresentation: AddNonConfirmedAgreementRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<AgreementRepresentation>>;
  public addNonConfirmedAgreement(
    proposalId: string,
    addNonConfirmedAgreementRepresentation: AddNonConfirmedAgreementRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<AgreementRepresentation>>;
  public addNonConfirmedAgreement(
    proposalId: string,
    addNonConfirmedAgreementRepresentation: AddNonConfirmedAgreementRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling addNonConfirmedAgreement.'
      );
    }
    if (
      addNonConfirmedAgreementRepresentation === null ||
      addNonConfirmedAgreementRepresentation === undefined
    ) {
      throw new Error(
        'Required parameter addNonConfirmedAgreementRepresentation was null or undefined when calling addNonConfirmedAgreement.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/addNonConfirmedAgreement`;
    return this.httpClient.request<AgreementRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: addNonConfirmedAgreementRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Add multiple non confirmed agreement
   * @param proposalId
   * @param addNonConfirmedAgreementRepresentation Agreement info
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addNonConfirmedAgreements(
    proposalId: string,
    addNonConfirmedAgreementRepresentation: Array<AddNonConfirmedAgreementRepresentation>,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<AgreementRepresentation>>;
  public addNonConfirmedAgreements(
    proposalId: string,
    addNonConfirmedAgreementRepresentation: Array<AddNonConfirmedAgreementRepresentation>,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<AgreementRepresentation>>>;
  public addNonConfirmedAgreements(
    proposalId: string,
    addNonConfirmedAgreementRepresentation: Array<AddNonConfirmedAgreementRepresentation>,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<AgreementRepresentation>>>;
  public addNonConfirmedAgreements(
    proposalId: string,
    addNonConfirmedAgreementRepresentation: Array<AddNonConfirmedAgreementRepresentation>,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling addNonConfirmedAgreements.'
      );
    }
    if (
      addNonConfirmedAgreementRepresentation === null ||
      addNonConfirmedAgreementRepresentation === undefined
    ) {
      throw new Error(
        'Required parameter addNonConfirmedAgreementRepresentation was null or undefined when calling addNonConfirmedAgreements.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/addNonConfirmedAgreements`;
    return this.httpClient.request<Array<AgreementRepresentation>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: addNonConfirmedAgreementRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Add requested property
   * @param proposalId
   * @param mode
   * @param requestedPropertyRepresentation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addRequestedProperty(
    proposalId: string,
    mode?: 'OPTIONAL' | 'REQUIRED',
    requestedPropertyRepresentation?: RequestedPropertyRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<RequestedPropertyRepresentation>;
  public addRequestedProperty(
    proposalId: string,
    mode?: 'OPTIONAL' | 'REQUIRED',
    requestedPropertyRepresentation?: RequestedPropertyRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<RequestedPropertyRepresentation>>;
  public addRequestedProperty(
    proposalId: string,
    mode?: 'OPTIONAL' | 'REQUIRED',
    requestedPropertyRepresentation?: RequestedPropertyRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<RequestedPropertyRepresentation>>;
  public addRequestedProperty(
    proposalId: string,
    mode?: 'OPTIONAL' | 'REQUIRED',
    requestedPropertyRepresentation?: RequestedPropertyRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling addRequestedProperty.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (mode !== undefined && mode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>mode, 'mode');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/requestedProperties`;
    return this.httpClient.request<RequestedPropertyRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: requestedPropertyRepresentation,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   * Create intermediate Agreement data
   * @param proposalId
   * @param intermediateAgreementDataRepresentation Intermediate agreement data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createIntermediateAgreementData(
    proposalId: string,
    intermediateAgreementDataRepresentation: IntermediateAgreementDataRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<IntermediateAgreementDataRepresentation>;
  public createIntermediateAgreementData(
    proposalId: string,
    intermediateAgreementDataRepresentation: IntermediateAgreementDataRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<IntermediateAgreementDataRepresentation>>;
  public createIntermediateAgreementData(
    proposalId: string,
    intermediateAgreementDataRepresentation: IntermediateAgreementDataRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<IntermediateAgreementDataRepresentation>>;
  public createIntermediateAgreementData(
    proposalId: string,
    intermediateAgreementDataRepresentation: IntermediateAgreementDataRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling createIntermediateAgreementData.'
      );
    }
    if (
      intermediateAgreementDataRepresentation === null ||
      intermediateAgreementDataRepresentation === undefined
    ) {
      throw new Error(
        'Required parameter intermediateAgreementDataRepresentation was null or undefined when calling createIntermediateAgreementData.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/intermediateAgreementData`;
    return this.httpClient.request<IntermediateAgreementDataRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: intermediateAgreementDataRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create a proposal
   * @param proposalRepresentation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createProposal(
    proposalRepresentation?: ProposalRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ProposalRepresentation>;
  public createProposal(
    proposalRepresentation?: ProposalRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ProposalRepresentation>>;
  public createProposal(
    proposalRepresentation?: ProposalRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ProposalRepresentation>>;
  public createProposal(
    proposalRepresentation?: ProposalRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals`;
    return this.httpClient.request<ProposalRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: proposalRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create a signing order to sign a specific agreement document
   * @param proposalId
   * @param intermediateAgreementDataCode
   * @param proposalDocumentId
   * @param redirectUrl
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createSigningOrderForAgreementDocument(
    proposalId: string,
    intermediateAgreementDataCode: string,
    proposalDocumentId: string,
    redirectUrl?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any>;
  public createSigningOrderForAgreementDocument(
    proposalId: string,
    intermediateAgreementDataCode: string,
    proposalDocumentId: string,
    redirectUrl?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpResponse<any>>;
  public createSigningOrderForAgreementDocument(
    proposalId: string,
    intermediateAgreementDataCode: string,
    proposalDocumentId: string,
    redirectUrl?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpEvent<any>>;
  public createSigningOrderForAgreementDocument(
    proposalId: string,
    intermediateAgreementDataCode: string,
    proposalDocumentId: string,
    redirectUrl?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling createSigningOrderForAgreementDocument.'
      );
    }
    if (intermediateAgreementDataCode === null || intermediateAgreementDataCode === undefined) {
      throw new Error(
        'Required parameter intermediateAgreementDataCode was null or undefined when calling createSigningOrderForAgreementDocument.'
      );
    }
    if (proposalDocumentId === null || proposalDocumentId === undefined) {
      throw new Error(
        'Required parameter proposalDocumentId was null or undefined when calling createSigningOrderForAgreementDocument.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (redirectUrl !== undefined && redirectUrl !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>redirectUrl,
        'redirectUrl'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/intermediateAgreementData/${this.configuration.encodeParam({
      name: 'intermediateAgreementDataCode',
      value: intermediateAgreementDataCode,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/documents/${this.configuration.encodeParam({
      name: 'proposalDocumentId',
      value: proposalDocumentId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/sign`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Delete a pending proposal
   * @param proposalId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePendingProposal(
    proposalId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any>;
  public deletePendingProposal(
    proposalId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<any>>;
  public deletePendingProposal(
    proposalId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<any>>;
  public deletePendingProposal(
    proposalId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling deletePendingProposal.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Export the agreement data for a proposal as csv
   * @param proposalId
   * @param body Csv column headers to define the format for the exported csv      Possible headers are:   * agreementId: ignored while importing, in the response this column will be filled with the ids of the created agreements * agreementCode: ignored while importing, in the response this column will be filled with the agreementCodes of the created agreements * active: whether the agreement is active or expired * confirmed: whether the agreementData is confirmed or not * language: the language the agreement was created in * startTimestamp: The start timestamp of the agreement * expirationTimestamp: The expiration timestamp of the agreement * modificationTimestamp: The modification timestamp of the agreement * all requested properties of the proposal requestedPropertyUid--requestedPropertyType--requestedPropertySubType * custom defined metadata key metadata--customDefinedMetadataKey * custom defined subscriberMetadata key subscriberMetadata--customDefinedSubscriberMetadataKey      format: agreementId;agreementCode;active;confirmed;startTimestamp;expirationTimestamp;modificationTimestamp[;(requestedProperty--requestedPropertyUid--requestedPropertyType(?--requestedPropertySubType))]... [;(metadata--customDefinedMetadataKey]... [;(subscriberMetadata--customDefinedSubscriberMetadataKey]...
   * @param active
   * @param confirmed
   * @param verified
   * @param startModificationTimestamp
   * @param endModificationTimestamp
   * @param skip Skips an amount of results before returning values. Limit + skip should not exceed 10000. Use cursor instead if deep paging is needed.
   * @param limit Maximum number of results. Max value is 10000. Limit + skip should not exceed 10000.
   * @param cursor Cursor returned in previous call to get the next batch of results
   * @param sortDirection
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportAgreementDataForProposal(
    proposalId: string,
    body: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<string>;
  public exportAgreementDataForProposal(
    proposalId: string,
    body: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpResponse<string>>;
  public exportAgreementDataForProposal(
    proposalId: string,
    body: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpEvent<string>>;
  public exportAgreementDataForProposal(
    proposalId: string,
    body: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling exportAgreementDataForProposal.'
      );
    }
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling exportAgreementDataForProposal.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (active !== undefined && active !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>active,
        'active'
      );
    }
    if (confirmed !== undefined && confirmed !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>confirmed,
        'confirmed'
      );
    }
    if (verified !== undefined && verified !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>verified,
        'verified'
      );
    }
    if (startModificationTimestamp !== undefined && startModificationTimestamp !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>startModificationTimestamp,
        'startModificationTimestamp'
      );
    }
    if (endModificationTimestamp !== undefined && endModificationTimestamp !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>endModificationTimestamp,
        'endModificationTimestamp'
      );
    }
    if (skip !== undefined && skip !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>skip, 'skip');
    }
    if (limit !== undefined && limit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>limit, 'limit');
    }
    if (cursor !== undefined && cursor !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>cursor,
        'cursor'
      );
    }
    if (sortDirection !== undefined && sortDirection !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortDirection,
        'sortDirection'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['text/plain'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/export`;
    return this.httpClient.request<string>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: body,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Export the agreement data for a proposal as csv
   * @param proposalId
   * @param active
   * @param confirmed
   * @param verified
   * @param startModificationTimestamp
   * @param endModificationTimestamp
   * @param skip Skips an amount of results before returning values. Limit + skip should not exceed 10000. Use cursor instead if deep paging is needed.
   * @param limit Maximum number of results. Max value is 10000. Limit + skip should not exceed 10000.
   * @param cursor Cursor returned in previous call to get the next batch of results
   * @param sortDirection
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportAgreementDataForProposalWithDefaultColumnHeaders(
    proposalId: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<string>;
  public exportAgreementDataForProposalWithDefaultColumnHeaders(
    proposalId: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpResponse<string>>;
  public exportAgreementDataForProposalWithDefaultColumnHeaders(
    proposalId: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpEvent<string>>;
  public exportAgreementDataForProposalWithDefaultColumnHeaders(
    proposalId: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling exportAgreementDataForProposalWithDefaultColumnHeaders.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (active !== undefined && active !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>active,
        'active'
      );
    }
    if (confirmed !== undefined && confirmed !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>confirmed,
        'confirmed'
      );
    }
    if (verified !== undefined && verified !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>verified,
        'verified'
      );
    }
    if (startModificationTimestamp !== undefined && startModificationTimestamp !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>startModificationTimestamp,
        'startModificationTimestamp'
      );
    }
    if (endModificationTimestamp !== undefined && endModificationTimestamp !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>endModificationTimestamp,
        'endModificationTimestamp'
      );
    }
    if (skip !== undefined && skip !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>skip, 'skip');
    }
    if (limit !== undefined && limit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>limit, 'limit');
    }
    if (cursor !== undefined && cursor !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>cursor,
        'cursor'
      );
    }
    if (sortDirection !== undefined && sortDirection !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortDirection,
        'sortDirection'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/export`;
    return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get intermediate agreement data with the code
   * @param proposalId
   * @param code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getIntermediateAgreementData(
    proposalId: string,
    code: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<IntermediateAgreementDataRepresentation>;
  public getIntermediateAgreementData(
    proposalId: string,
    code: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<IntermediateAgreementDataRepresentation>>;
  public getIntermediateAgreementData(
    proposalId: string,
    code: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<IntermediateAgreementDataRepresentation>>;
  public getIntermediateAgreementData(
    proposalId: string,
    code: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling getIntermediateAgreementData.'
      );
    }
    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling getIntermediateAgreementData.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/intermediateAgreementData/${this.configuration.encodeParam({
      name: 'code',
      value: code,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<IntermediateAgreementDataRepresentation>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Creates a paymentIntent for the intermediate agreement data and returns it\&#39;s client secret
   * @param proposalId
   * @param code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getIntermediateAgreementDataPaymentIntent(
    proposalId: string,
    code: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<PaymentIntentRepresentation>;
  public getIntermediateAgreementDataPaymentIntent(
    proposalId: string,
    code: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<PaymentIntentRepresentation>>;
  public getIntermediateAgreementDataPaymentIntent(
    proposalId: string,
    code: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<PaymentIntentRepresentation>>;
  public getIntermediateAgreementDataPaymentIntent(
    proposalId: string,
    code: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling getIntermediateAgreementDataPaymentIntent.'
      );
    }
    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling getIntermediateAgreementDataPaymentIntent.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/intermediateAgreementData/${this.configuration.encodeParam({
      name: 'code',
      value: code,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/paymentIntent`;
    return this.httpClient.request<PaymentIntentRepresentation>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get the agreements for a proposal as the proposal owner sorted by modification timestamp
   * @param proposalId
   * @param active
   * @param confirmed
   * @param verified
   * @param startModificationTimestamp
   * @param endModificationTimestamp
   * @param skip Skips an amount of results before returning values. Limit + skip should not exceed 10000. Use cursor instead if deep paging is needed.
   * @param limit Maximum number of results. Max value is 10000. Limit + skip should not exceed 10000.
   * @param cursor Cursor returned in previous call to get the next batch of results
   * @param sortDirection
   * @param format
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getModifiedAgreementsForProposal(
    proposalId: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<AgreementRepresentation>>;
  public getModifiedAgreementsForProposal(
    proposalId: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<AgreementRepresentation>>>;
  public getModifiedAgreementsForProposal(
    proposalId: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<AgreementRepresentation>>>;
  public getModifiedAgreementsForProposal(
    proposalId: string,
    active?: boolean,
    confirmed?: boolean,
    verified?: boolean,
    startModificationTimestamp?: number,
    endModificationTimestamp?: number,
    skip?: number,
    limit?: number,
    cursor?: string,
    sortDirection?: 'ASCENDING' | 'DESCENDING',
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling getModifiedAgreementsForProposal.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (active !== undefined && active !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>active,
        'active'
      );
    }
    if (confirmed !== undefined && confirmed !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>confirmed,
        'confirmed'
      );
    }
    if (verified !== undefined && verified !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>verified,
        'verified'
      );
    }
    if (startModificationTimestamp !== undefined && startModificationTimestamp !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>startModificationTimestamp,
        'startModificationTimestamp'
      );
    }
    if (endModificationTimestamp !== undefined && endModificationTimestamp !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>endModificationTimestamp,
        'endModificationTimestamp'
      );
    }
    if (skip !== undefined && skip !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>skip, 'skip');
    }
    if (limit !== undefined && limit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>limit, 'limit');
    }
    if (cursor !== undefined && cursor !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>cursor,
        'cursor'
      );
    }
    if (sortDirection !== undefined && sortDirection !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortDirection,
        'sortDirection'
      );
    }
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/modifiedAgreements`;
    return this.httpClient.request<Array<AgreementRepresentation>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get multiple proposals by id
   * @param format
   * @param idListRepresentation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMultipleProposalsById(
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    idListRepresentation?: IdListRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<ProposalRepresentation>>;
  public getMultipleProposalsById(
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    idListRepresentation?: IdListRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<ProposalRepresentation>>>;
  public getMultipleProposalsById(
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    idListRepresentation?: IdListRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<ProposalRepresentation>>>;
  public getMultipleProposalsById(
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    idListRepresentation?: IdListRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/multiple`;
    return this.httpClient.request<Array<ProposalRepresentation>>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: idListRepresentation,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get a proposal by id
   * @param proposalId
   * @param format
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProposalById(
    proposalId: string,
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ProposalRepresentation>;
  public getProposalById(
    proposalId: string,
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ProposalRepresentation>>;
  public getProposalById(
    proposalId: string,
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ProposalRepresentation>>;
  public getProposalById(
    proposalId: string,
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling getProposalById.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ProposalRepresentation>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get counters for a proposal
   * @param proposalId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProposalCounters(
    proposalId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ProposalCountersRepresentation>;
  public getProposalCounters(
    proposalId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ProposalCountersRepresentation>>;
  public getProposalCounters(
    proposalId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ProposalCountersRepresentation>>;
  public getProposalCounters(
    proposalId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling getProposalCounters.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/counters`;
    return this.httpClient.request<ProposalCountersRepresentation>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get a list of your proposals
   * @param format
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProposals(
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<Array<ProposalRepresentation>>;
  public getProposals(
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<Array<ProposalRepresentation>>>;
  public getProposals(
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<Array<ProposalRepresentation>>>;
  public getProposals(
    format?: 'MINI' | 'SUMMARY' | 'FULL' | 'ID',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals`;
    return this.httpClient.request<Array<ProposalRepresentation>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Import csv agreement data for a proposal
   * Returns an export of the imported agreements with the supplied headers
   * @param proposalId
   * @param body Agreements csv with \&#39;;\&#39; as a separator   The format of the csv is defined by the provided headers on the first row.   Possible headers are:   * agreementId: ignored while importing, in the response this column will be filled with the ids of the created agreements * agreementCode: ignored while importing, in the response this column will be filled with the agreementCodes of the created agreements * startTimestamp: The start date for the agreement, must be in the future * all requested properties of the proposal requestedPropertyUid--requestedPropertyType--requestedPropertySubType * custom defined metadata key metadata--customDefinedMetadataKey format: agreementId;agreementCode;startTimestamp[;(requestedProperty--requestedPropertyUid--requestedPropertyType(?--requestedPropertySubType))]... [;(metadata--customDefinedMetadataKey]...
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public importAgreementDataForProposal(
    proposalId: string,
    body: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<string>;
  public importAgreementDataForProposal(
    proposalId: string,
    body: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpResponse<string>>;
  public importAgreementDataForProposal(
    proposalId: string,
    body: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpEvent<string>>;
  public importAgreementDataForProposal(
    proposalId: string,
    body: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling importAgreementDataForProposal.'
      );
    }
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling importAgreementDataForProposal.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['text/plain'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/import`;
    return this.httpClient.request<string>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: body,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get headers for importing csv agreement data for a proposal
   * @param proposalId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public importAgreementHeadersForProposal(
    proposalId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any>;
  public importAgreementHeadersForProposal(
    proposalId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpResponse<any>>;
  public importAgreementHeadersForProposal(
    proposalId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpEvent<any>>;
  public importAgreementHeadersForProposal(
    proposalId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: 'text/plain'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling importAgreementHeadersForProposal.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/import/headers`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Publish a pending proposal with a default language
   * @param proposalId
   * @param languageId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public publishProposal(
    proposalId: string,
    languageId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ProposalRepresentation>;
  public publishProposal(
    proposalId: string,
    languageId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ProposalRepresentation>>;
  public publishProposal(
    proposalId: string,
    languageId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ProposalRepresentation>>;
  public publishProposal(
    proposalId: string,
    languageId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling publishProposal.'
      );
    }
    if (languageId === null || languageId === undefined) {
      throw new Error(
        'Required parameter languageId was null or undefined when calling publishProposal.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/publish/${this.configuration.encodeParam({
      name: 'languageId',
      value: languageId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ProposalRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Search agreements of all proposals
   * @param searchAgreementsRepresentation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchAgreements(
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<SearchAgreementsResponseRepresentation>;
  public searchAgreements(
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<SearchAgreementsResponseRepresentation>>;
  public searchAgreements(
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<SearchAgreementsResponseRepresentation>>;
  public searchAgreements(
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (searchAgreementsRepresentation === null || searchAgreementsRepresentation === undefined) {
      throw new Error(
        'Required parameter searchAgreementsRepresentation was null or undefined when calling searchAgreements.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/agreements/search`;
    return this.httpClient.request<SearchAgreementsResponseRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: searchAgreementsRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Search agreements of proposal
   * @param proposalId
   * @param searchAgreementsRepresentation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchAgreementsOfProposal(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<SearchAgreementsResponseRepresentation>;
  public searchAgreementsOfProposal(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<SearchAgreementsResponseRepresentation>>;
  public searchAgreementsOfProposal(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<SearchAgreementsResponseRepresentation>>;
  public searchAgreementsOfProposal(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling searchAgreementsOfProposal.'
      );
    }
    if (searchAgreementsRepresentation === null || searchAgreementsRepresentation === undefined) {
      throw new Error(
        'Required parameter searchAgreementsRepresentation was null or undefined when calling searchAgreementsOfProposal.'
      );
    }

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/search`;
    return this.httpClient.request<SearchAgreementsResponseRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: searchAgreementsRepresentation,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Search agreements of proposal and delete results
   * @param proposalId
   * @param searchAgreementsRepresentation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchAndDeleteAgreements(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any>;
  public searchAndDeleteAgreements(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<any>>;
  public searchAndDeleteAgreements(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<any>>;
  public searchAndDeleteAgreements(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling searchAndDeleteAgreements.'
      );
    }
    if (searchAgreementsRepresentation === null || searchAgreementsRepresentation === undefined) {
      throw new Error(
        'Required parameter searchAgreementsRepresentation was null or undefined when calling searchAndDeleteAgreements.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/searchAndDelete`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchAgreementsRepresentation,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Search agreements of proposal and expire results
   * @param proposalId
   * @param searchAgreementsRepresentation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchAndExpireAgreements(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any>;
  public searchAndExpireAgreements(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<any>>;
  public searchAndExpireAgreements(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<any>>;
  public searchAndExpireAgreements(
    proposalId: string,
    searchAgreementsRepresentation: SearchAgreementsRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling searchAndExpireAgreements.'
      );
    }
    if (searchAgreementsRepresentation === null || searchAgreementsRepresentation === undefined) {
      throw new Error(
        'Required parameter searchAgreementsRepresentation was null or undefined when calling searchAndExpireAgreements.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/agreements/searchAndExpire`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchAgreementsRepresentation,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }

  /**
   * Set the proposal access
   * @param proposalId
   * @param access
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setProposalAccess(
    proposalId: string,
    access: 'OPEN' | 'CLOSED',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ProposalRepresentation>;
  public setProposalAccess(
    proposalId: string,
    access: 'OPEN' | 'CLOSED',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ProposalRepresentation>>;
  public setProposalAccess(
    proposalId: string,
    access: 'OPEN' | 'CLOSED',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ProposalRepresentation>>;
  public setProposalAccess(
    proposalId: string,
    access: 'OPEN' | 'CLOSED',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling setProposalAccess.'
      );
    }
    if (access === null || access === undefined) {
      throw new Error(
        'Required parameter access was null or undefined when calling setProposalAccess.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/setAccess/${this.configuration.encodeParam({
      name: 'access',
      value: access,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'OPEN' | 'CLOSED'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ProposalRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Change proposal visibility
   * @param proposalId
   * @param visibility
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setProposalVisibility(
    proposalId: string,
    visibility: 'PUBLIC' | 'PRIVATE',
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ProposalRepresentation>;
  public setProposalVisibility(
    proposalId: string,
    visibility: 'PUBLIC' | 'PRIVATE',
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ProposalRepresentation>>;
  public setProposalVisibility(
    proposalId: string,
    visibility: 'PUBLIC' | 'PRIVATE',
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ProposalRepresentation>>;
  public setProposalVisibility(
    proposalId: string,
    visibility: 'PUBLIC' | 'PRIVATE',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling setProposalVisibility.'
      );
    }
    if (visibility === null || visibility === undefined) {
      throw new Error(
        'Required parameter visibility was null or undefined when calling setProposalVisibility.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/visibility/${this.configuration.encodeParam({
      name: 'visibility',
      value: visibility,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: "'PUBLIC' | 'PRIVATE'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ProposalRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Stop a proposal
   * @param proposalId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public stopProposal(
    proposalId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ProposalRepresentation>;
  public stopProposal(
    proposalId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ProposalRepresentation>>;
  public stopProposal(
    proposalId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ProposalRepresentation>>;
  public stopProposal(
    proposalId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling stopProposal.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/stop`;
    return this.httpClient.request<ProposalRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Subscribe to a proposal internally
   * @param proposalId
   * @param subscribeRepresentation Properties to subscribe with
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public subscribe(
    proposalId: string,
    subscribeRepresentation: SubscribeRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<AgreementRepresentation>;
  public subscribe(
    proposalId: string,
    subscribeRepresentation: SubscribeRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<AgreementRepresentation>>;
  public subscribe(
    proposalId: string,
    subscribeRepresentation: SubscribeRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<AgreementRepresentation>>;
  public subscribe(
    proposalId: string,
    subscribeRepresentation: SubscribeRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling subscribe.'
      );
    }
    if (subscribeRepresentation === null || subscribeRepresentation === undefined) {
      throw new Error(
        'Required parameter subscribeRepresentation was null or undefined when calling subscribe.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/subscribe`;
    return this.httpClient.request<AgreementRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: subscribeRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Subscribe to a proposal externally
   * @param proposalId
   * @param subscribeRepresentation Agreement info
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public subscribeExternally(
    proposalId: string,
    subscribeRepresentation: SubscribeRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ExternalAgreementRepresentation>;
  public subscribeExternally(
    proposalId: string,
    subscribeRepresentation: SubscribeRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ExternalAgreementRepresentation>>;
  public subscribeExternally(
    proposalId: string,
    subscribeRepresentation: SubscribeRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ExternalAgreementRepresentation>>;
  public subscribeExternally(
    proposalId: string,
    subscribeRepresentation: SubscribeRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling subscribeExternally.'
      );
    }
    if (subscribeRepresentation === null || subscribeRepresentation === undefined) {
      throw new Error(
        'Required parameter subscribeRepresentation was null or undefined when calling subscribeExternally.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/subscribeExternally`;
    return this.httpClient.request<ExternalAgreementRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: subscribeRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Subscribe to a proposal externally
   * @param proposalId
   * @param agreementCode
   * @param updateAgreementRepresentation Agreement info
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public subscribeExternallyWithAgreementCode(
    proposalId: string,
    agreementCode: string,
    updateAgreementRepresentation: UpdateAgreementRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<ExternalAgreementRepresentation>;
  public subscribeExternallyWithAgreementCode(
    proposalId: string,
    agreementCode: string,
    updateAgreementRepresentation: UpdateAgreementRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<ExternalAgreementRepresentation>>;
  public subscribeExternallyWithAgreementCode(
    proposalId: string,
    agreementCode: string,
    updateAgreementRepresentation: UpdateAgreementRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<ExternalAgreementRepresentation>>;
  public subscribeExternallyWithAgreementCode(
    proposalId: string,
    agreementCode: string,
    updateAgreementRepresentation: UpdateAgreementRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling subscribeExternallyWithAgreementCode.'
      );
    }
    if (agreementCode === null || agreementCode === undefined) {
      throw new Error(
        'Required parameter agreementCode was null or undefined when calling subscribeExternallyWithAgreementCode.'
      );
    }
    if (updateAgreementRepresentation === null || updateAgreementRepresentation === undefined) {
      throw new Error(
        'Required parameter updateAgreementRepresentation was null or undefined when calling subscribeExternallyWithAgreementCode.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/subscribeExternallyWithAgreementCode/${this.configuration.encodeParam({
      name: 'agreementCode',
      value: agreementCode,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ExternalAgreementRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: updateAgreementRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Subscribe to a proposal internally
   * @param proposalId
   * @param agreementCode
   * @param updateAgreementRepresentation Agreement info
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public subscribeWithAgreementCode(
    proposalId: string,
    agreementCode: string,
    updateAgreementRepresentation: UpdateAgreementRepresentation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<AgreementRepresentation>;
  public subscribeWithAgreementCode(
    proposalId: string,
    agreementCode: string,
    updateAgreementRepresentation: UpdateAgreementRepresentation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpResponse<AgreementRepresentation>>;
  public subscribeWithAgreementCode(
    proposalId: string,
    agreementCode: string,
    updateAgreementRepresentation: UpdateAgreementRepresentation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<HttpEvent<AgreementRepresentation>>;
  public subscribeWithAgreementCode(
    proposalId: string,
    agreementCode: string,
    updateAgreementRepresentation: UpdateAgreementRepresentation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    }
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling subscribeWithAgreementCode.'
      );
    }
    if (agreementCode === null || agreementCode === undefined) {
      throw new Error(
        'Required parameter agreementCode was null or undefined when calling subscribeWithAgreementCode.'
      );
    }
    if (updateAgreementRepresentation === null || updateAgreementRepresentation === undefined) {
      throw new Error(
        'Required parameter updateAgreementRepresentation was null or undefined when calling subscribeWithAgreementCode.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/subscribeWithAgreementCode/${this.configuration.encodeParam({
      name: 'agreementCode',
      value: agreementCode,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<AgreementRepresentation>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: updateAgreementRepresentation,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * @param proposalId
   * @param requestedPropertyId
   * @param propertyType
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public uploadFile(
    proposalId: string,
    requestedPropertyId: string,
    propertyType:
      | 'USERNAME'
      | 'FIRST_NAME'
      | 'MIDDLE_NAME'
      | 'LAST_NAME'
      | 'NAME'
      | 'AVATAR'
      | 'GENDER'
      | 'BIRTHDAY'
      | 'FOUNDED'
      | 'DISSOLVED'
      | 'DAY'
      | 'MONTH'
      | 'YEAR'
      | 'EMAIL'
      | 'MOBILE_PHONE'
      | 'PHONE'
      | 'FAX'
      | 'WEBSITE'
      | 'BIC'
      | 'JOB_TITLE'
      | 'INDUSTRY'
      | 'GROUP'
      | 'REGION'
      | 'ADDRESS'
      | 'STREET'
      | 'COUNTRY'
      | 'BUILDING'
      | 'BOX'
      | 'LOCALITY'
      | 'NUMBER'
      | 'ZIP'
      | 'DISTRICT'
      | 'PROVINCE'
      | 'SUBURB'
      | 'STATE'
      | 'COUNTY'
      | 'LAT_LONG'
      | 'FACEBOOK'
      | 'YOUTUBE'
      | 'INSTAGRAM'
      | 'WHATSAPP'
      | 'WECHAT'
      | 'TIKTOK'
      | 'FACEBOOK_MESSENGER'
      | 'TELEGRAM'
      | 'SNAPCHAT'
      | 'PINTEREST'
      | 'TWITTER'
      | 'REDDIT'
      | 'LINKEDIN'
      | 'VIMEO'
      | 'DISCORD'
      | 'TWITCH'
      | 'TUMBLR'
      | 'MASTODON'
      | 'PATREON'
      | 'KICKSTARTER'
      | 'INDIEGOGO'
      | 'GOFUNDME'
      | 'TAKEAWAY'
      | 'UBEREATS'
      | 'DELIVEROO'
      | 'SHIPTO'
      | 'WEIBO'
      | 'QQ'
      | 'KUAISHOU'
      | 'QZONE'
      | 'SKYPE'
      | 'NOTE'
      | 'ORGANISATION_NAME'
      | 'LEGAL_NAME'
      | 'LEGAL_FORM'
      | 'LEGAL_ID'
      | 'COMPANY_NUMBER'
      | 'JURISDICTION'
      | 'IMPORT_SOURCE'
      | 'PROPOSAL'
      | 'CATEGORY'
      | 'OPENING_HOURS'
      | 'CUSTOM'
      | 'FILE'
      | 'IMAGE'
      | 'FILE_KEY'
      | 'FILE_NAME'
      | 'TERMS_AND_CONDITIONS'
      | 'PRIVACY_POLICY'
      | 'DOCUMENT'
      | 'DOCUMENT_NAME'
      | 'ORGANISATION_TYPE',
    file?: FormDataContentDisposition,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: '*/*'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any>;
  public uploadFile(
    proposalId: string,
    requestedPropertyId: string,
    propertyType:
      | 'USERNAME'
      | 'FIRST_NAME'
      | 'MIDDLE_NAME'
      | 'LAST_NAME'
      | 'NAME'
      | 'AVATAR'
      | 'GENDER'
      | 'BIRTHDAY'
      | 'FOUNDED'
      | 'DISSOLVED'
      | 'DAY'
      | 'MONTH'
      | 'YEAR'
      | 'EMAIL'
      | 'MOBILE_PHONE'
      | 'PHONE'
      | 'FAX'
      | 'WEBSITE'
      | 'BIC'
      | 'JOB_TITLE'
      | 'INDUSTRY'
      | 'GROUP'
      | 'REGION'
      | 'ADDRESS'
      | 'STREET'
      | 'COUNTRY'
      | 'BUILDING'
      | 'BOX'
      | 'LOCALITY'
      | 'NUMBER'
      | 'ZIP'
      | 'DISTRICT'
      | 'PROVINCE'
      | 'SUBURB'
      | 'STATE'
      | 'COUNTY'
      | 'LAT_LONG'
      | 'FACEBOOK'
      | 'YOUTUBE'
      | 'INSTAGRAM'
      | 'WHATSAPP'
      | 'WECHAT'
      | 'TIKTOK'
      | 'FACEBOOK_MESSENGER'
      | 'TELEGRAM'
      | 'SNAPCHAT'
      | 'PINTEREST'
      | 'TWITTER'
      | 'REDDIT'
      | 'LINKEDIN'
      | 'VIMEO'
      | 'DISCORD'
      | 'TWITCH'
      | 'TUMBLR'
      | 'MASTODON'
      | 'PATREON'
      | 'KICKSTARTER'
      | 'INDIEGOGO'
      | 'GOFUNDME'
      | 'TAKEAWAY'
      | 'UBEREATS'
      | 'DELIVEROO'
      | 'SHIPTO'
      | 'WEIBO'
      | 'QQ'
      | 'KUAISHOU'
      | 'QZONE'
      | 'SKYPE'
      | 'NOTE'
      | 'ORGANISATION_NAME'
      | 'LEGAL_NAME'
      | 'LEGAL_FORM'
      | 'LEGAL_ID'
      | 'COMPANY_NUMBER'
      | 'JURISDICTION'
      | 'IMPORT_SOURCE'
      | 'PROPOSAL'
      | 'CATEGORY'
      | 'OPENING_HOURS'
      | 'CUSTOM'
      | 'FILE'
      | 'IMAGE'
      | 'FILE_KEY'
      | 'FILE_NAME'
      | 'TERMS_AND_CONDITIONS'
      | 'PRIVACY_POLICY'
      | 'DOCUMENT'
      | 'DOCUMENT_NAME'
      | 'ORGANISATION_TYPE',
    file?: FormDataContentDisposition,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: '*/*'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpResponse<any>>;
  public uploadFile(
    proposalId: string,
    requestedPropertyId: string,
    propertyType:
      | 'USERNAME'
      | 'FIRST_NAME'
      | 'MIDDLE_NAME'
      | 'LAST_NAME'
      | 'NAME'
      | 'AVATAR'
      | 'GENDER'
      | 'BIRTHDAY'
      | 'FOUNDED'
      | 'DISSOLVED'
      | 'DAY'
      | 'MONTH'
      | 'YEAR'
      | 'EMAIL'
      | 'MOBILE_PHONE'
      | 'PHONE'
      | 'FAX'
      | 'WEBSITE'
      | 'BIC'
      | 'JOB_TITLE'
      | 'INDUSTRY'
      | 'GROUP'
      | 'REGION'
      | 'ADDRESS'
      | 'STREET'
      | 'COUNTRY'
      | 'BUILDING'
      | 'BOX'
      | 'LOCALITY'
      | 'NUMBER'
      | 'ZIP'
      | 'DISTRICT'
      | 'PROVINCE'
      | 'SUBURB'
      | 'STATE'
      | 'COUNTY'
      | 'LAT_LONG'
      | 'FACEBOOK'
      | 'YOUTUBE'
      | 'INSTAGRAM'
      | 'WHATSAPP'
      | 'WECHAT'
      | 'TIKTOK'
      | 'FACEBOOK_MESSENGER'
      | 'TELEGRAM'
      | 'SNAPCHAT'
      | 'PINTEREST'
      | 'TWITTER'
      | 'REDDIT'
      | 'LINKEDIN'
      | 'VIMEO'
      | 'DISCORD'
      | 'TWITCH'
      | 'TUMBLR'
      | 'MASTODON'
      | 'PATREON'
      | 'KICKSTARTER'
      | 'INDIEGOGO'
      | 'GOFUNDME'
      | 'TAKEAWAY'
      | 'UBEREATS'
      | 'DELIVEROO'
      | 'SHIPTO'
      | 'WEIBO'
      | 'QQ'
      | 'KUAISHOU'
      | 'QZONE'
      | 'SKYPE'
      | 'NOTE'
      | 'ORGANISATION_NAME'
      | 'LEGAL_NAME'
      | 'LEGAL_FORM'
      | 'LEGAL_ID'
      | 'COMPANY_NUMBER'
      | 'JURISDICTION'
      | 'IMPORT_SOURCE'
      | 'PROPOSAL'
      | 'CATEGORY'
      | 'OPENING_HOURS'
      | 'CUSTOM'
      | 'FILE'
      | 'IMAGE'
      | 'FILE_KEY'
      | 'FILE_NAME'
      | 'TERMS_AND_CONDITIONS'
      | 'PRIVACY_POLICY'
      | 'DOCUMENT'
      | 'DOCUMENT_NAME'
      | 'ORGANISATION_TYPE',
    file?: FormDataContentDisposition,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {httpHeaderAccept?: '*/*'; context?: HttpContext; transferCache?: boolean}
  ): Observable<HttpEvent<any>>;
  public uploadFile(
    proposalId: string,
    requestedPropertyId: string,
    propertyType:
      | 'USERNAME'
      | 'FIRST_NAME'
      | 'MIDDLE_NAME'
      | 'LAST_NAME'
      | 'NAME'
      | 'AVATAR'
      | 'GENDER'
      | 'BIRTHDAY'
      | 'FOUNDED'
      | 'DISSOLVED'
      | 'DAY'
      | 'MONTH'
      | 'YEAR'
      | 'EMAIL'
      | 'MOBILE_PHONE'
      | 'PHONE'
      | 'FAX'
      | 'WEBSITE'
      | 'BIC'
      | 'JOB_TITLE'
      | 'INDUSTRY'
      | 'GROUP'
      | 'REGION'
      | 'ADDRESS'
      | 'STREET'
      | 'COUNTRY'
      | 'BUILDING'
      | 'BOX'
      | 'LOCALITY'
      | 'NUMBER'
      | 'ZIP'
      | 'DISTRICT'
      | 'PROVINCE'
      | 'SUBURB'
      | 'STATE'
      | 'COUNTY'
      | 'LAT_LONG'
      | 'FACEBOOK'
      | 'YOUTUBE'
      | 'INSTAGRAM'
      | 'WHATSAPP'
      | 'WECHAT'
      | 'TIKTOK'
      | 'FACEBOOK_MESSENGER'
      | 'TELEGRAM'
      | 'SNAPCHAT'
      | 'PINTEREST'
      | 'TWITTER'
      | 'REDDIT'
      | 'LINKEDIN'
      | 'VIMEO'
      | 'DISCORD'
      | 'TWITCH'
      | 'TUMBLR'
      | 'MASTODON'
      | 'PATREON'
      | 'KICKSTARTER'
      | 'INDIEGOGO'
      | 'GOFUNDME'
      | 'TAKEAWAY'
      | 'UBEREATS'
      | 'DELIVEROO'
      | 'SHIPTO'
      | 'WEIBO'
      | 'QQ'
      | 'KUAISHOU'
      | 'QZONE'
      | 'SKYPE'
      | 'NOTE'
      | 'ORGANISATION_NAME'
      | 'LEGAL_NAME'
      | 'LEGAL_FORM'
      | 'LEGAL_ID'
      | 'COMPANY_NUMBER'
      | 'JURISDICTION'
      | 'IMPORT_SOURCE'
      | 'PROPOSAL'
      | 'CATEGORY'
      | 'OPENING_HOURS'
      | 'CUSTOM'
      | 'FILE'
      | 'IMAGE'
      | 'FILE_KEY'
      | 'FILE_NAME'
      | 'TERMS_AND_CONDITIONS'
      | 'PRIVACY_POLICY'
      | 'DOCUMENT'
      | 'DOCUMENT_NAME'
      | 'ORGANISATION_TYPE',
    file?: FormDataContentDisposition,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {httpHeaderAccept?: '*/*'; context?: HttpContext; transferCache?: boolean}
  ): Observable<any> {
    if (proposalId === null || proposalId === undefined) {
      throw new Error(
        'Required parameter proposalId was null or undefined when calling uploadFile.'
      );
    }
    if (requestedPropertyId === null || requestedPropertyId === undefined) {
      throw new Error(
        'Required parameter requestedPropertyId was null or undefined when calling uploadFile.'
      );
    }
    if (propertyType === null || propertyType === undefined) {
      throw new Error(
        'Required parameter propertyType was null or undefined when calling uploadFile.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (authToken) required
    localVarCredential = this.configuration.lookupCredential('authToken');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('AuthToken', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['*/*'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: {append(param: string, value: any): any};
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({encoder: this.encoder});
    }

    if (file !== undefined) {
      localVarFormParams =
        (localVarFormParams.append(
          'file',
          localVarUseForm ? new Blob([JSON.stringify(file)], {type: 'application/json'}) : <any>file
        ) as any) || localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/proposals/${this.configuration.encodeParam({
      name: 'proposalId',
      value: proposalId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/uploadFileForAgreement/${this.configuration.encodeParam({
      name: 'requestedPropertyId',
      value: requestedPropertyId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/${this.configuration.encodeParam({
      name: 'propertyType',
      value: propertyType,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType:
        "'USERNAME' | 'FIRST_NAME' | 'MIDDLE_NAME' | 'LAST_NAME' | 'NAME' | 'AVATAR' | 'GENDER' | 'BIRTHDAY' | 'FOUNDED' | 'DISSOLVED' | 'DAY' | 'MONTH' | 'YEAR' | 'EMAIL' | 'MOBILE_PHONE' | 'PHONE' | 'FAX' | 'WEBSITE' | 'BIC' | 'JOB_TITLE' | 'INDUSTRY' | 'GROUP' | 'REGION' | 'ADDRESS' | 'STREET' | 'COUNTRY' | 'BUILDING' | 'BOX' | 'LOCALITY' | 'NUMBER' | 'ZIP' | 'DISTRICT' | 'PROVINCE' | 'SUBURB' | 'STATE' | 'COUNTY' | 'LAT_LONG' | 'FACEBOOK' | 'YOUTUBE' | 'INSTAGRAM' | 'WHATSAPP' | 'WECHAT' | 'TIKTOK' | 'FACEBOOK_MESSENGER' | 'TELEGRAM' | 'SNAPCHAT' | 'PINTEREST' | 'TWITTER' | 'REDDIT' | 'LINKEDIN' | 'VIMEO' | 'DISCORD' | 'TWITCH' | 'TUMBLR' | 'MASTODON' | 'PATREON' | 'KICKSTARTER' | 'INDIEGOGO' | 'GOFUNDME' | 'TAKEAWAY' | 'UBEREATS' | 'DELIVEROO' | 'SHIPTO' | 'WEIBO' | 'QQ' | 'KUAISHOU' | 'QZONE' | 'SKYPE' | 'NOTE' | 'ORGANISATION_NAME' | 'LEGAL_NAME' | 'LEGAL_FORM' | 'LEGAL_ID' | 'COMPANY_NUMBER' | 'JURISDICTION' | 'IMPORT_SOURCE' | 'PROPOSAL' | 'CATEGORY' | 'OPENING_HOURS' | 'CUSTOM' | 'FILE' | 'IMAGE' | 'FILE_KEY' | 'FILE_NAME' | 'TERMS_AND_CONDITIONS' | 'PRIVACY_POLICY' | 'DOCUMENT' | 'DOCUMENT_NAME' | 'ORGANISATION_TYPE'",
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    });
  }
}
