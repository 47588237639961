export enum ImportProvider {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  YAHOO = 'YAHOO',
  MICROSOFT = 'MICROSOFT',
  GOOGLE = 'GOOGLE',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  VCARD = 'VCARD',
}

export enum ImportStatus {
  AUTH = 'AUTH',
  IMPORTING = 'IMPORTING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
