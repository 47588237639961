import {Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {PropertyType, ProposalPropertyTypeDescription} from '@aztrix/models';
import {
  RequestedPropertyTypeDescriptionRepresentation,
  RequestedPropertyTypeRepresentation,
} from '@aztrix/sdk';
import {LANGUAGE_OVERRIDE, TranslateService} from '@aztrix/translate';
import {BehaviorSubject, Observable, of, Subject, Subscription, switchMap} from 'rxjs';

@Pipe({name: 'proposalPropertyTypeLabel', pure: false})
export class ProposalPropertyTypeLabelPipe implements PipeTransform, OnDestroy {
  changes$ = new Subject<{
    type?: PropertyType | RequestedPropertyTypeDescriptionRepresentation.TypeEnum;
    description?: ProposalPropertyTypeDescription | RequestedPropertyTypeDescriptionRepresentation;
  }>();
  currentValue = '';

  subscription = new Subscription();

  constructor(
    private translate: TranslateService,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    languageOverride$?: BehaviorSubject<string | undefined>
  ) {
    this.subscription = this.changes$
      .pipe(
        switchMap(({type, description}) =>
          ProposalPropertyTypeLabelPipe.proposalPropertyTypeLabel$(
            type,
            description,
            this.translate,
            languageOverride$?.value
          )
        )
      )
      .subscribe((value) => {
        this.currentValue = value;
      });
  }

  static proposalPropertyTypeLabel$(
    type: PropertyType | RequestedPropertyTypeRepresentation.TypeEnum | undefined,
    description:
      | ProposalPropertyTypeDescription
      | RequestedPropertyTypeDescriptionRepresentation
      | undefined,
    translate: TranslateService,
    lang?: string
  ): Observable<string> {
    if (!type) {
      return of('');
    }

    if (type === PropertyType.CUSTOM) {
      return of(description?.customFieldDescription?.label ?? '');
    } else {
      return translate.get(`property.${type}.label`, {}, lang);
    }
  }

  transform(
    type: PropertyType | RequestedPropertyTypeDescriptionRepresentation.TypeEnum | undefined,
    description:
      | ProposalPropertyTypeDescription
      | RequestedPropertyTypeDescriptionRepresentation
      | undefined
  ): string {
    this.changes$.next({type, description});
    return this.currentValue;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
