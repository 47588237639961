import {GroupType, ProfileContext, Visibility} from '@aztrix/models';

import {METADATA} from './metadata';

const {groupTypes} = METADATA;

export interface GroupTypeRepresentation {
  name: GroupType;
  default: boolean;
  limit?: number;
  editable: boolean;
  shareable: boolean;
  visibilityDefault: Visibility;
  canChangeMembershipVisibility: boolean;
  canAddMembers: boolean;
  ownerCanBeMember: boolean;
  followable: boolean;
  sharedInfo: boolean;
  deletable: boolean;
  accessPossibilities: string[];
  accessDefault: string;
  canHaveRequiredProperties: boolean;
  membersVisiblePossibilities: boolean[];
  membersVisibleDefault: boolean;
}

export function groupTypesForProfileContext(
  profileContext: ProfileContext
): GroupTypeRepresentation[] {
  return <GroupTypeRepresentation[]>groupTypes[profileContext];
}

export function groupTypeRep(
  type: GroupType,
  profileContext: ProfileContext
): GroupTypeRepresentation | undefined {
  return groupTypesForProfileContext(profileContext).find(
    (rep: GroupTypeRepresentation) => rep.name === type
  );
}
