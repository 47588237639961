import {HttpBackend, HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, first, map, shareReplay, tap} from 'rxjs';

import {ENVIRONMENT_CONFIG_URL} from '../environment_config_url';
import {ALIST_URL, ALIST_VIEW_URL, ASSETS_URL, BACKEND_URL, PLATFORM_URL} from '../urls';
import {EnvFileParser} from './env-file-parser';

@Injectable()
export class EnvironmentService {
  private _ready$ = new ReplaySubject(1);
  private _http: HttpClient;
  private _configuration$ = new ReplaySubject<{[name: string]: string}>(1);

  ready$ = this._ready$.pipe(shareReplay(1));

  constructor(
    httpBackend: HttpBackend,
    @Inject(ENVIRONMENT_CONFIG_URL) private _environmentConfigUrl: string,
    @Optional() @Inject(ASSETS_URL) private _assetsUrl: BehaviorSubject<string>,
    @Optional() @Inject(BACKEND_URL) private _backendUrl: BehaviorSubject<string>,
    @Optional() @Inject(PLATFORM_URL) private _platformUrl: BehaviorSubject<string>,
    @Optional() @Inject(ALIST_URL) private _alistUrl: BehaviorSubject<string>,
    @Optional() @Inject(ALIST_VIEW_URL) private _alistViewUrl: BehaviorSubject<string>
  ) {
    this._http = new HttpClient(httpBackend);
  }

  _load(): Observable<unknown> {
    return this._http.get(this._environmentConfigUrl, {responseType: 'text'}).pipe(
      first(),
      tap({
        next: (configuration: string) => {
          const parsedConfiguration = EnvFileParser.parse(configuration);
          this._configuration$.next(parsedConfiguration);

          this.setToken(this._assetsUrl, parsedConfiguration, 'ASSETS_URI');
          this.setToken(this._backendUrl, parsedConfiguration, 'BACKEND_URI');
          this.setToken(this._platformUrl, parsedConfiguration, 'PLATFORM_URI');
          this.setToken(this._alistUrl, parsedConfiguration, 'ALIST_URI');
          this.setToken(this._alistViewUrl, parsedConfiguration, 'ALIST_VIEW_URI');

          this._ready$.next(true);
        },
        error: (error) => {
          console.error('error loading configuration');
          console.error(error);
          this._ready$.next(true);
        },
      })
    );
  }

  toggle$(name: string): Observable<boolean> {
    return this._configuration$.pipe(
      map((configuration) => {
        if (!configuration[name]) {
          return true;
        }
        return configuration[name] === 'true' ? true : false;
      })
    );
  }

  number$(name: string, defaultValue = 2): Observable<number | undefined> {
    return this._configuration$.pipe(
      map((configuration) => {
        const result = configuration[name];
        if (result) {
          const number = parseInt(<string>result, 10);
          return isNaN(number) ? undefined : number;
        } else {
          return defaultValue;
        }
      })
    );
  }

  string$(name: string): Observable<string | undefined> {
    return this._configuration$.pipe(map((configuration) => configuration[name]));
  }

  private setToken(
    token: BehaviorSubject<string>,
    configuration: {[name: string]: string},
    key: string
  ) {
    const value = configuration[key];
    if (token && value) {
      token.next(this._urlify(value));
    }
  }

  private _urlify(uri: string): string {
    if (!uri) {
      return '';
    }
    return 'https://' + uri;
  }
}
