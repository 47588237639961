import {Pipe, PipeTransform} from '@angular/core';

import {colorOrDefault} from '../helpers/color-functions';

@Pipe({name: 'colorOrDefault'})
export class ColorOrDefaultPipe implements PipeTransform {
  transform(color: unknown, defaultColor: string): string {
    return colorOrDefault(color, defaultColor);
  }
}
