import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

/**
 * Transforms a property into the property visibility of that property.
 **/

@Pipe({name: 'propertyVisibility'})
export class PropertyVisibilityPipe implements PipeTransform {
  /**
   * @param value the property to get the property visibility from.
   */
  transform(value: Property): any {
    return value && value.visibility;
  }
}
