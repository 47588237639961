export interface TomTomApiResponse {
  summary: TomTomSummary;
  results: any[];
}

export interface TomTomSummary {
  query: string;
  queryType: string;
  queryTime: number;
  numResults: number;
  offset: number;
  totalResults: 4;
  fuzzyLevel: 1;
}

export interface TomTomResult {
  type: TomTomResultType;
  id: string;
  score: number;
  dist: number;
  info: string;
  entityType: string;
  poi: any;
  address: TomTomAddress;
  position: TomTomPosition;
  viewport: any;
  entrypoints: any;
  addressRanges: any;
}

export enum TomTomResultType {
  GEOGRAPHY = 'Geography',
  POINT_ADDRESS = 'Point Address',
  RANGE_ADDRESS = 'Address Range',
  STREET = 'Street',
  INTERSECTION = 'Cross Street',
  POI = 'POI',
}

export enum TomTomEntityType {
  COUNTRY = 'Country',
  COUNTRY_SUBDIVISION = 'CountrySubdivision',
  COUNTRY_SECONDARY_SUBDIVISION = 'CountrySecondarySubdivision',
  COUNTRY_TERTIARY_SUBDIVISION = 'CountryTertiarySubdivision',
  MUNICIPALITY = 'Municipality',
  MUNICIPALITY_SUBDIVISION = 'MunicipalitySubdivision',
  NEIGHBOURHOOD = 'Neighbourhood',
  POSTAL_CODE_AREA = 'PostalCodeArea',
}

export enum TomTomIdxType {
  GEOGRAPHY = 'Geo',
  POINT_ADDRESS = 'PAD',
  RANGE_ADDRESS = 'Addr',
  STREET = 'Str',
  INTERSECTION = 'XStr',
  POI = 'POI',
}

export interface TomTomAddress {
  streetNumber: string;
  streetName: string;
  postalCode: string;
  countryCode: string;
  country: string;
  freeformAddress: string;
  municipality: string;
  municipalitySubdivision: string;
  countrySubdivision: string;
  countrySubdivisionName: string;
  countrySecondarySubdivision: string;
  countryTertiarySubdivision: string;
  extendedPostalCode?: string;
}

export interface TomTomPosition {
  lat: number;
  lon: number;
}
