import {Inject, Pipe, PipeTransform} from '@angular/core';
import {Locale} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LOCALE} from '@aztrix/translate';
import {formatDateTimeFromNow} from '../util/date.util';

/**
 * Transforms a Date object into a formatted string indicating the time difference
 * between now and the given time ('About 2 hours ago', '10/23/2020, 4:54 PM', ...).
 **/
@Pipe({name: 'formatDateTimeFromNow', pure: false})
export class FormatDateTimeFromNowPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>
  ) {}

  transform(value: string | number | Date | undefined | null): string {
    return formatDateTimeFromNow(value, this._locale$.value);
  }
}
