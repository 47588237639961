import {Pipe, PipeTransform} from '@angular/core';
import {Profile} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';

import {isOrganisation} from '../../helpers/profile-functions';

@Pipe({name: 'isOrganisation'})
export class IsOrganisationPipe implements PipeTransform {
  transform(profile: Profile | ProfileRepresentation): boolean {
    return profile && isOrganisation(<ProfileRepresentation>profile);
  }
}
