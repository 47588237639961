import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {Context, ContextType} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'contextDisplayLabel',
  pure: false,
})
export class ContextDisplayLabelPipe implements PipeTransform {
  private translatePipe = new TranslatePipe(
    this._translate,
    this._changeDetector,
    this._languageOverride$
  );

  constructor(
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  transform(context: ContextType | Context): string {
    if ((<Context>context).label) {
      return (<Context>context).label;
    }
    return this.translatePipe.transform(
      'context.' + (typeof context === 'string' ? context : context.type) + '.label'
    );
  }
}
