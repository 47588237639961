import {AgreementVerificationType} from '../agreement/agreement';
import {Document} from '../document/document';
import {ProfileContext} from '../profile/profile';
import {PropertyType} from '../property/property-type';
import {SubjectFormat} from '../subject/subject-format';
import {Visibility} from '../visibility/visibility';

export enum ProposalStatus {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  ACTIVE = 'ACTIVE',
  STOPPED = 'STOPPED',
}

export enum ProposalType {
  NEWSLETTER = 'NEWSLETTER',
  REGISTRATION = 'REGISTRATION',
  INVOICE = 'INVOICE',
  CONTRACT = 'CONTRACT',
  REVIEW = 'REVIEW',
  NONE = 'NONE',
}

export enum LegalGround {
  CONSENT = 'CONSENT',
  CONTRACT = 'CONTRACT',
  LEGITIMATE_INTEREST = 'LEGITIMATE_INTEREST',
  LEGAL_OBLIGATION = 'LEGAL_OBLIGATION',
  PUBLIC_INTEREST = 'PUBLIC_INTEREST',
}

export enum ProposalAccess {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface ProposalLegacy {
  id?: string;
  format?: SubjectFormat;
  ownerId?: string;
  type?: ProposalType;
  legalGround?: LegalGround;
  startTimestamp?: number;
  endTimestamp?: number;
  subscribed?: boolean;
  visibility?: Visibility;
  profileContext?: ProfileContext;
  access?: ProposalAccess;
  verification?: AgreementVerificationType;
  pending?: boolean;
  stopped?: boolean;
  retentionDuration?: number;
  product?: string;
  requiredProperties?: Array<ProposalProperty>;
  optionalProperties?: Array<ProposalProperty>;
  languages?: Array<ProposalLanguage>;
  agreementCount?: number;
}

export interface Proposal {
  id?: string;
  format?: SubjectFormat;
  ownerId?: string;
  type: ProposalType;
  legalGround: LegalGround;
  startTimestamp?: number;
  endTimestamp?: number;
  subscribed?: boolean;
  visibility?: Visibility;
  profileContext?: ProfileContext;
  access: ProposalAccess;
  verification?: AgreementVerificationType;
  pending?: boolean;
  stopped?: boolean;
  retentionDuration?: number;
  product?: string;
  properties: ProposalProperty[];
  languages: ProposalLanguage[];
}

export interface ProposalProperty {
  required?: boolean;
  requestedPropertyId: string;
  requestedPropertyOrderIndex: number;
  readonly?: boolean;
  requestedPropertyTypes: ProposalPropertyType[];
}

export interface ProposalPropertyType {
  type: PropertyType;
  orderIndex: number;
  customFieldInfo?: CustomFieldInfo;
  fileInfo?: FileInfo;
}

export interface CustomFieldInfo {
  type: ProposalCustomType;
  values: string[];
  maxAmountOfValues?: number;
}

export interface FileInfo {
  types?: string[];
  maxSize?: number;
}

export interface ProposalStep {
  id?: string;
  title?: string;
  description?: string;
  skipTo?: string;
}

export interface ProposalGroup {
  title: string;
  description: string;
  orderIndex: number;
  requestedPropertyIds: string[];
}

export interface ProposalLanguage {
  id?: string;
  languageCode: string;
  isDefault: boolean;
  pending: boolean;
  avatar?: string;
  backgroundColor?: string;
  name: string;
  description: string;
  creationTimestamp?: number;
  requestedPropertyDescriptions: ProposalPropertyDescription[];
  purpose: string;
  groups?: ProposalGroup[];
  documents?: Document[];
  askToSendEmail?: boolean;
  askToSendEmailDescription?: string;
  whiteLabel?: boolean;
  pdfDownloadEnabled?: boolean;
  fromEmailAddress?: string;
  sendVerificationReminder?: boolean;
  sendOwnerExpiredEmail?: boolean;
  textTemplates?: Array<any>;
  items?: Array<any>;
  steps?: ProposalStep[];
}

export interface ProposalPropertyDescription {
  requestedPropertyId: string;
  requestedPropertyTypeDescriptions: ProposalPropertyTypeDescription[];
}

export interface ProposalPropertyTypeDescription {
  type: PropertyType;
  description: string;
  customFieldDescription?: CustomFieldDescription;
}

export interface CustomFieldDescription {
  label?: string;
  icon?: string;
  valueLabels?: CustomFieldValueLabel[];
}

export interface CustomFieldValueLabel {
  value: string;
  label: string;
  orderIndex?: number;
  skipTo?: string;
}

export enum ProposalCustomType {
  TEXT = 'TEXT',
  CHECK = 'CHECK',
  RADIO = 'RADIO',
  DROPDOWN = 'DROPDOWN',
  CHIPS = 'CHIPS',
  DATE = 'DATE',
  RATING = 'RATING',
  RICH_TEXT = 'RICH_TEXT',
  TOGGLE = 'TOGGLE',
  NUMBER = 'NUMBER',
  URL = 'URL',
}

export interface ProposalCollapsibleGroup {
  label: string;
  requestedPropertyIds: string[];
}
