import {ElementRef} from '@angular/core';

export function isOverflowing(element: ElementRef<HTMLElement>) {
  if (!element) {
    return false;
  }
  const offsetHeight = element.nativeElement.offsetHeight;
  const scrollHeight = element.nativeElement.scrollHeight;
  const offsetWidth = element.nativeElement.offsetWidth;
  const scrollWidth = element.nativeElement.scrollWidth;

  return offsetHeight < scrollHeight || offsetWidth < scrollWidth;
}
