import {Pipe, PipeTransform} from '@angular/core';

import {profileUsername} from '../../helpers/profile-functions';

@Pipe({
  name: 'hasUsername',
})
export class HasUsernamePipe implements PipeTransform {
  transform(profile: any): boolean {
    return profileUsername(profile) ? true : false;
  }
}
