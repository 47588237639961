import {Context} from '../context/context';
import {PropertyType} from '../property/property-type';
import {SubjectFormat} from '../subject/subject-format';

export interface Profile {
  readonly id: string;
  readonly targetId?: string;
  readonly openCorporatesId?: string;
  readonly type: ProfileType;
  readonly profileContext?: ProfileContext;
  readonly propertyContexts?: ReadonlyArray<Context>;
  readonly isManaged?: boolean;
  readonly format?: SubjectFormat;
  readonly status?: ProfileStatus;
  readonly hidden?: boolean;
  readonly invited?: boolean;
  readonly visibility?: ProfileVisibility;
  readonly forwardCode?: string;
  readonly paymentVerified?: boolean;
}

export enum ProfileStatus {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export enum ProfileType {
  ME_PROFILE = 'ME_PROFILE',
  CONTACT = 'CONTACT',
  PUBLIC_PROFILE = 'PUBLIC_PROFILE',
}

export enum ProfileContext {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANISATION = 'ORGANISATION',
}

export enum ProfileVisibility {
  PUBLIC = 'PUBLIC',
  WITHIN_AZTRIX = 'WITHIN_AZTRIX',
  PRIVATE = 'PRIVATE',
}

export interface ProfileTask {
  name: string;
  type: string;
  completed: boolean;
  propertyType?: PropertyType;
}
