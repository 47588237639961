/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {MetadataRepresentation} from './metadataRepresentation';
import {AgreementDataRepresentation} from './agreementDataRepresentation';

export interface AgreementRepresentation {
  id?: string;
  proposalId?: string;
  proposalOwnerId?: string;
  subscriberId?: string;
  format?: AgreementRepresentation.FormatEnum;
  startTimestamp?: number;
  expirationTimestamp?: number;
  modificationTimestamp?: number;
  active?: boolean;
  deletedByOwner?: boolean;
  agreementCode?: string;
  verified?: boolean;
  verificationTimestamp?: number;
  verificationValue?: string;
  agreementData?: AgreementDataRepresentation;
  agreementMetadata?: Array<MetadataRepresentation>;
  subscriberMetadata?: Array<MetadataRepresentation>;
}
export namespace AgreementRepresentation {
  export type FormatEnum = 'MINI' | 'SUMMARY' | 'FULL' | 'ID';
  export const FormatEnum = {
    MINI: 'MINI' as FormatEnum,
    SUMMARY: 'SUMMARY' as FormatEnum,
    FULL: 'FULL' as FormatEnum,
    ID: 'ID' as FormatEnum,
  };
}
