/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {PropertyRepresentation} from './propertyRepresentation';

export interface AcceptPropertyRequestRepresentationExternal {
  requestId?: string;
  requestStatus?: AcceptPropertyRequestRepresentationExternal.RequestStatusEnum;
  property?: PropertyRepresentation;
}
export namespace AcceptPropertyRequestRepresentationExternal {
  export type RequestStatusEnum = 'ACCEPTED' | 'REJECTED';
  export const RequestStatusEnum = {
    ACCEPTED: 'ACCEPTED' as RequestStatusEnum,
    REJECTED: 'REJECTED' as RequestStatusEnum,
  };
}
