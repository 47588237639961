import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {propertyIsDynamic} from '../../helpers/property-functions';

@Pipe({name: 'propertyIsDynamic'})
export class PropertyIsDynamicPipe implements PipeTransform {
  transform(property: Property): any {
    return propertyIsDynamic(property);
  }
}
