import {Pipe, PipeTransform} from '@angular/core';

import {getLink} from '../metadata/links';
import {ROUTER_LINKS} from '../metadata/metadata';

/**
 * Transforms a key of routerLinks.json into a router link.
 *
 * Usage:
 * <a [routerLink]="'search' | routerLink"></a>
 * <a [routerLink]="'group' | routerLink: {id: '123'}"></a>
 **/

@Pipe({
  name: 'routerLink',
})
export class RouterLinkPipe implements PipeTransform {
  transform(routerLinkKey: string, params?: any): string {
    const link = ROUTER_LINKS[routerLinkKey as keyof typeof ROUTER_LINKS];
    return getLink(link, params);
  }
}
