/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {Address} from './address';
import {TaxId} from './taxId';

export interface StripeCustomerInfoRepresentation {
  taxId?: TaxId;
  name?: string;
  address?: Address;
  email?: string;
  customerType?: StripeCustomerInfoRepresentation.CustomerTypeEnum;
}
export namespace StripeCustomerInfoRepresentation {
  export type CustomerTypeEnum = 'INDIVIDUAL' | 'ORGANISATION';
  export const CustomerTypeEnum = {
    INDIVIDUAL: 'INDIVIDUAL' as CustomerTypeEnum,
    ORGANISATION: 'ORGANISATION' as CustomerTypeEnum,
  };
}
