import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {isPhoneRelated} from '../../helpers/property-functions';

@Pipe({name: 'propertyIsPhoneRelated'})
export class PropertyIsPhoneRelatedPipe implements PipeTransform {
  transform(property: Property): any {
    if (!property || !property.type) {
      return null;
    }
    return isPhoneRelated(property.type);
  }
}
