import {PhonePrefixRepresentation, PropertyType} from '@aztrix/models';
import {CountryCode, parsePhoneNumberFromString, PhoneNumber} from 'libphonenumber-js';

import {COUNTRIES, CountryCodeRepresentation} from '../metadata/country-info';
import {isOfType} from './filter.util';

export function countryCodeForNumber(number: string): string {
  if (typeof number !== 'string') {
    return '';
  }
  const parsedNumber = parseNumber(number);
  if (parsedNumber && parsedNumber.country) {
    return parsedNumber.country.toString();
  } else {
    const prefix = number.split(' ')[0].trim();
    const countryRep = prefixForNumber(prefix);
    if (countryRep) {
      return countryRep.countryCode;
    }
  }
  return '';
}

/**
 * parses a string phonenumber to a phone representation
 * !important this is a wrapper around libphonenumber-js,
 *  which is a dependency of @aztrix/helpers
 *  so this allows you to just import @aztrix/helpers
 *  instead of having to bundle libphonenumber-js every library
 *
 * @param text the phonenumber to parse
 * @param defaultCountry when no country is provided in the phonenumber this country is used
 */
export function parseNumber(
  text: string,
  defaultCountry?: CountryCode | {defaultCountry?: CountryCode; defaultCallingCode?: string}
): PhoneNumber | undefined {
  return parsePhoneNumberFromString(text, defaultCountry);
}

export function prefixForNumber(number?: string): PhonePrefixRepresentation {
  const prefixes = prefixesForNumber(number);
  // get the longest possible prefix out of all the matches
  prefixes.sort((p1, p2) =>
    p1.phonePrefix.length === p2.phonePrefix.length
      ? p1.countryCode > p2.countryCode
        ? -1
        : 1
      : p1.phonePrefix.length > p2.phonePrefix.length
        ? -1
        : 1
  );
  return prefixes[0];
}

export function prefixesForNumber(number?: string): PhonePrefixRepresentation[] {
  if (!number || number === '+') {
    return [];
  }
  return COUNTRIES.flatMap((rep: CountryCodeRepresentation) =>
    rep.phonePrefix
      .filter((prefix) => number.startsWith(prefix))
      .map(
        (prefix) =>
          <PhonePrefixRepresentation>{
            countryCode: rep.countryCode,
            phonePrefix: prefix,
          }
      )
  );
}

export function formattedValueForNumber(number?: string): string | undefined {
  if (!number) {
    return undefined;
  }
  const parsed = parsePhoneNumberFromString(number);
  return parsed?.formatInternational() ?? number;
}

export function isValidPhoneNumber(number: string, type?: PropertyType): boolean {
  if (typeof number !== 'string') {
    return false;
  }
  const phoneNumber = parsePhoneNumberFromString(number);
  if (!phoneNumber || !phoneNumber.isValid()) {
    return false;
  }
  if (type) {
    const phoneType = phoneNumber.getType();
    switch (phoneType) {
      case 'FIXED_LINE':
        return isOfType({type}, PropertyType.PHONE, PropertyType.FAX);
      case 'MOBILE':
        return type === PropertyType.MOBILE_PHONE;
      default:
        return true;
    }
  }
  return true;
}
