import {BreakpointObserver} from '@angular/cdk/layout';
import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  ViewChild,
} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ModalSize} from './overlay.service';
import {OverlayContentDirective} from './overlay-content.directive';
import {modalSizeToPixels} from './overlay-functions';

const FOCUSABLE_ELEMENTS_SELECTOR = [
  'a[href]',
  'button:not([disabled]):not(.c-modal__close)',
  'input:not([disabled]):not([type="hidden"])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  '[contenteditable]',
  '[tabindex]:not([tabindex="-1"])',
].join(', ');

@Component({
  selector: 'ax-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OverlayContentDirective, CommonModule, IconsModule, ButtonModule, TranslatePipe],
})
export class OverlayComponent<T> implements AfterViewInit {
  @ViewChild(OverlayContentDirective) modalContent: OverlayContentDirective;
  title?: string;
  titleOpts: any;
  hasTitleBar: boolean;
  backgroundColor = '#fff';
  modalSize: ModalSize;
  componentRef: ComponentRef<T>;
  didClickClose = new EventEmitter<void>();
  didResize = new EventEmitter<void>();
  viewContainerInserted = new EventEmitter<void>();

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private _changeDetector: ChangeDetectorRef,
    private _breakpointObserver: BreakpointObserver
  ) {}

  ngAfterViewInit() {
    this._loadComponent();
    this._focusFirstElement();
    this._changeDetector.detectChanges();
  }

  init(
    title: string | undefined,
    titleOpts: any,
    hasTitleBar = true,
    backgroundColor = '#fff',
    modalSize: ModalSize,
    componentRef: ComponentRef<T>
  ): void {
    this.title = title;
    this.titleOpts = titleOpts;
    this.hasTitleBar = hasTitleBar;
    this.modalSize = modalSize;
    this.backgroundColor = backgroundColor;

    this.componentRef = componentRef;
  }

  private _loadComponent(): void {
    const viewContainerRef = this.modalContent.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.insert(this.componentRef.hostView);
    this.viewContainerInserted.next();
  }

  private _focusFirstElement(): void {
    if (!this.elementRef.nativeElement.contains(document.activeElement)) {
      const autoFocusable = <HTMLElement>this.elementRef.nativeElement.querySelector(`[autofocus]`);
      const firstFocusable = <HTMLElement>(
        this.elementRef.nativeElement.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR)[0]
      );
      const elementToFocus = autoFocusable || firstFocusable || this.elementRef.nativeElement;
      elementToFocus.focus();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyboardEventHanlder(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.didClickClose.emit();
    }
  }

  @HostListener('window:resize')
  didResizeWindow(): void {
    this.didResize.emit();
  }

  get fullScreenModal() {
    return (
      this.modalSize !== ModalSize.DYNAMIC &&
      (window.innerWidth <= modalSizeToPixels(this.modalSize) || this._breakpointMatched())
    );
  }

  private _breakpointMatched(): boolean {
    return this._breakpointObserver.isMatched('(max-width: 424px)');
  }
}
