import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvironmentService} from '@aztrix/environment';
import {TomTomApiResponse, TomTomIdxType, TomTomResult} from '@aztrix/models';
import {NEVER, Observable} from 'rxjs';
import {first, map, mergeMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TomtomApiService {
  private http: HttpClient;

  constructor(httpBackend: HttpBackend, private _environment: EnvironmentService) {
    this.http = new HttpClient(httpBackend);
  }

  searchTomTom(
    query: string,
    countryCode: string,
    types: TomTomIdxType[],
    limit = 100
  ): Observable<TomTomResult[]> {
    return this._environment.string$('TOM_TOM_API_KEY').pipe(
      first(),
      mergeMap((tomTomApiKey) => {
        if (!tomTomApiKey || !query || typeof query !== 'string') {
          return NEVER;
        }
        return this.http
          .get<TomTomApiResponse>(
            `https://api.tomtom.com/search/2/search/${query.toLowerCase()}.json?key=${tomTomApiKey}&typeahead=true&countrySet=${countryCode}&limit=${limit}&idxSet=${types.join(
              ','
            )}`
          )
          .pipe(map((response) => response.results));
      })
    );
  }

  fuzzySearchTomTom(query: string, countries: string[], limit = 100): Observable<TomTomResult[]> {
    return this._environment.string$('TOM_TOM_API_KEY').pipe(
      first(),
      mergeMap((tomTomApiKey) => {
        if (!tomTomApiKey || !query || typeof query !== 'string') {
          return NEVER;
        }

        return this.http
          .get<TomTomApiResponse>(
            `https://api.tomtom.com/search/2/search/${query.toLowerCase()}.json?key=${tomTomApiKey}&typeahead=true&limit=${limit}&minFuzzyLevel=4&maxFuzzyLevel=4&countrySet=${countries.map(
              (c) => c.toUpperCase()
            )}`
          )
          .pipe(map((response) => response.results));
      })
    );
  }
}
