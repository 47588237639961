import {Pipe, PipeTransform} from '@angular/core';
import {Context, Profile, Property} from '@aztrix/models';

import {verifiablePropertyTypes} from '../metadata/context-type-provider';

@Pipe({
  name: 'displayVerificationWarning',
})
export class DisplayVerificationWarningPipe implements PipeTransform {
  transform(property: Property, context: Context, profile: Profile): boolean {
    if (!profile.profileContext) {
      return false;
    }
    return verifiablePropertyTypes(profile.profileContext, context.type).includes(
      <any>property.type
    );
  }
}
