import {Injectable} from '@angular/core';
import {MutableProperty, Property, PropertyType, TomTomIdxType, TomTomResult} from '@aztrix/models';
import {catchError, first, map, Observable, of} from 'rxjs';

import {subPropertyTypes} from '../metadata/property-type-provider';
import {TomtomApiService} from './tomtom-api.service';

@Injectable({providedIn: 'root'})
export class TomtomService {
  constructor(private _tomTomApiService: TomtomApiService) {}

  search(
    query: string,
    countryCode: string,
    types: TomTomIdxType[] = [
      TomTomIdxType.POINT_ADDRESS,
      TomTomIdxType.RANGE_ADDRESS,
      TomTomIdxType.STREET,
    ],
    limit = 100
  ): Observable<Property[]> {
    if (typeof query !== 'string' || !countryCode) {
      return of([]);
    }

    return this._tomTomApiService.searchTomTom(query, countryCode, types, limit).pipe(
      first(),
      map((results) => results.map((result) => this._useExtendedPostalCodes(result))),
      map((results) => results.map((r) => this._addNumberToStreetResults(query, r))),
      map(this._duplicateAddressesWithMultiplePostalCodes),
      map((results) => results.filter((result) => this._hasSomeAddressProperties(result))),
      map((results) => results.map((result) => this._mapTomTomResultToAddressProperty(result))),
      catchError(() => {
        return of([]);
      })
    );
  }

  private _useExtendedPostalCodes(result: TomTomResult): TomTomResult {
    return {
      ...result,
      address: {
        ...result.address,
        postalCode: result.address.extendedPostalCode || result.address.postalCode,
      },
    };
  }

  private _addNumberToStreetResults(query: string, result: TomTomResult): TomTomResult {
    if (!query || result.address.streetNumber) {
      return result;
    }
    const queryWithoutStreet = query
      .toLowerCase()
      .replace(result.address.streetName.toLowerCase(), '')
      .trim();
    const [number] = queryWithoutStreet.split(' ');
    if (number.match(/\d/g)) {
      return {
        ...result,
        address: {
          ...result.address,
          streetNumber: number.replace(/[^a-z0-9]+/g, ''),
        },
      };
    } else {
      return result;
    }
  }

  private _duplicateAddressesWithMultiplePostalCodes(results: TomTomResult[]): TomTomResult[] {
    for (let index = 0; index < results.length; index++) {
      const result = results[index];
      if (!result.address.postalCode) {
        continue;
      }
      const postalCodes = result.address.postalCode.split(', ');
      if (postalCodes.length > 1) {
        result.address.postalCode = <string>postalCodes.shift();
        for (const postalCode of postalCodes) {
          results.push({
            ...result,
            address: {
              ...result.address,
              postalCode,
            },
          });
        }
      }
    }
    return results;
  }

  private _hasSomeAddressProperties(result: TomTomResult) {
    return (
      result.address.streetName &&
      result.address.postalCode &&
      (result.address.municipalitySubdivision || result.address.municipality) &&
      result.address.countryCode
    );
  }

  private _mapTomTomResultToAddressProperty(tomTomResult: TomTomResult): Property {
    const addressProperty = new MutableProperty(undefined, PropertyType.ADDRESS, undefined, [
      this._createSubProperty(PropertyType.COUNTRY, tomTomResult.address.countryCode || ''),
    ]);
    delete addressProperty.value;

    this._addSubPropertyIfTypeRepExists(
      addressProperty,
      tomTomResult.address.streetName || '',
      PropertyType.STREET
    );
    this._addSubPropertyIfTypeRepExists(
      addressProperty,
      tomTomResult.address.streetNumber || '',
      PropertyType.NUMBER
    );
    this._addSubPropertyIfTypeRepExists(
      addressProperty,
      tomTomResult.address.postalCode || '',
      PropertyType.ZIP
    );
    this._addSubPropertyIfTypeRepExists(
      addressProperty,
      tomTomResult.address.municipality || '',
      PropertyType.LOCALITY
    );
    this._addSubPropertyIfTypeRepExists(
      addressProperty,
      tomTomResult.address.municipalitySubdivision || '',
      PropertyType.SUBURB
    );
    this._addSubPropertyIfTypeRepExists(
      addressProperty,
      tomTomResult.address.countrySecondarySubdivision || '',
      PropertyType.COUNTY
    );
    this._addSubPropertyIfTypeRepExists(
      addressProperty,
      tomTomResult.address.countrySubdivisionName || tomTomResult.address.countrySubdivision || '',
      PropertyType.PROVINCE
    );
    this._addSubPropertyIfTypeRepExists(
      addressProperty,
      tomTomResult.address.countrySubdivisionName || tomTomResult.address.countrySubdivision || '',
      PropertyType.STATE
    );
    if (tomTomResult.position?.lat && tomTomResult.position?.lon) {
      this._addSubPropertyIfTypeRepExists(
        addressProperty,
        tomTomResult.position.lat + ', ' + tomTomResult.position.lon,
        PropertyType.LAT_LONG
      );
    }

    return addressProperty;
  }

  private _addSubPropertyIfTypeRepExists(
    property: MutableProperty,
    value: string,
    propertyType: PropertyType
  ) {
    const propertyTypes = <PropertyType[]>subPropertyTypes(property);
    if (property.properties && propertyTypes.includes(propertyType) && value) {
      property.properties.push(this._createSubProperty(propertyType, value));
    }
  }

  private _createSubProperty(propertyType: PropertyType, value: string): MutableProperty {
    const newProperty = new MutableProperty(undefined, propertyType, value);
    delete newProperty.properties;
    return newProperty;
  }
}
