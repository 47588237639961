import {Directive, forwardRef, Inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE} from './language_override';

/**
 * This directive allows you to scope a language
 *
 * <ng-template [axScopedTranslate]="lang">
 *   all in here will get another language than anything out of this scope
 * </ng-template>
 *
 * It will recreate the view when the language input ([axScopedTranslate]="lang") is changed,
 * and update the LANGUAGE_OVERRIDE token, this token is used in TranslatePipe (and some others)
 * as the provider language
 */
@Directive({
  selector: '[axScopedTranslate]',
  providers: [
    forwardRef(() => ({
      provide: LANGUAGE_OVERRIDE,
      useValue: new BehaviorSubject(undefined),
    })),
  ],
  standalone: true,
})
export class ScopedTranslateDirective {
  private _prevLang = '';

  @Input('axScopedTranslate') set lang(lang: string) {
    if (lang !== this._prevLang) {
      this._prevLang = lang;
      this._languageOverride$?.next(lang);
      this._viewContainerRef.clear();
      this._viewContainerRef.createEmbeddedView(this._templateRef);
    }
  }

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<unknown>,
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}
}
