import {Inject, Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {AlistLanguageRepresentation} from '@aztrix/sdk';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject} from 'rxjs';

import {COMMUNICATION_URL_PARAMS} from '../../communication_url_params';

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class CommunicationUrlParamsService {
  constructor(@Inject(COMMUNICATION_URL_PARAMS) private _params$: BehaviorSubject<Params>) {}

  setParams(alistLanguage: AlistLanguageRepresentation | undefined) {
    const google = alistLanguage?.settings?.analytics?.google;
    const params: any = {};
    if (google) {
      if (google.utmSource) {
        params['utm_source'] = google.utmSource;
      }
      if (google.utmMedium) {
        params['utm_medium'] = google.utmMedium;
      }
    }
    this._params$.next(params);
  }
}
