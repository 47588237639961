import {
  DocumentRepresentation,
  SigningOrderRecipientDocumentRepresentation,
  SigningOrderRecipientRepresentation,
  SigningOrderRepresentation,
} from '@aztrix/sdk';

export enum SigningOrderStatus {
  TO_BE_SIGNED = 'TO_BE_SIGNED',
  PARTIALLY_SIGNED = 'PARTIALLY_SIGNED',
  SIGNED = 'SIGNED',
  EXPIRED = 'EXPIRED',
}

export function getSignedDocumentsForOriginalDocument(
  originalDocument: DocumentRepresentation,
  signedDocuments: SigningOrderRecipientDocumentRepresentation[]
): DocumentRepresentation[] {
  return <DocumentRepresentation[]>signedDocuments
    .map((recipientSignedDocument) => {
      if (recipientSignedDocument.signingOrderDocumentId === originalDocument.id) {
        if (recipientSignedDocument.signed) {
          const mutableSigningOrderDocument = {...originalDocument};
          if (recipientSignedDocument.resultDocument) {
            mutableSigningOrderDocument.value = recipientSignedDocument.resultDocument;
            return mutableSigningOrderDocument;
          }
        }
      }

      return null;
    })
    .filter((d) => d);
}

export function getSignedDocumentForOriginal(
  originalDocument: DocumentRepresentation,
  signedDocuments: SigningOrderRecipientDocumentRepresentation[]
): DocumentRepresentation {
  const recipientSignedDocument = signedDocuments.find(
    (doc) => doc.signed && doc.signingOrderDocumentId === originalDocument.id
  );

  const muteableDocument = {...originalDocument};
  muteableDocument.value =
    recipientSignedDocument && recipientSignedDocument.resultDocument
      ? recipientSignedDocument.resultDocument
      : originalDocument.value;

  return muteableDocument;
}

export function getSigningOrderStatus(
  subject: SigningOrderRepresentation | SigningOrderRecipientRepresentation
): SigningOrderStatus {
  if (!subject) {
    return SigningOrderStatus.TO_BE_SIGNED;
  }

  if (signingOrderPassedExpirationTimestamp(subject)) {
    return SigningOrderStatus.EXPIRED;
  }

  if ('email' in subject) {
    return _getRecipientSigningOrderStatus(subject);
  } else {
    return _getSigningOrderStatus(subject);
  }
}

function _getSigningOrderStatus(signingOrder: SigningOrderRepresentation): SigningOrderStatus {
  const statuses = signingOrder.recipients?.map((r) => _getRecipientSigningOrderStatus(r));
  if (!statuses?.length) {
    return SigningOrderStatus.TO_BE_SIGNED;
  }

  if (statuses.filter((s) => s === SigningOrderStatus.SIGNED).length === statuses.length) {
    return SigningOrderStatus.SIGNED;
  }

  if (statuses.filter((s) => s === SigningOrderStatus.TO_BE_SIGNED).length === statuses.length) {
    return SigningOrderStatus.TO_BE_SIGNED;
  }

  return SigningOrderStatus.PARTIALLY_SIGNED;
}

function _getRecipientSigningOrderStatus(
  recipient: SigningOrderRecipientRepresentation
): SigningOrderStatus {
  const signedDocuments = recipient.documents?.filter(
    (recipientDocument) => recipientDocument.signed
  );

  if (signedDocuments?.length === recipient.documents?.length) {
    return SigningOrderStatus.SIGNED;
  }
  if ((signedDocuments?.length || 0) > 0) {
    return SigningOrderStatus.PARTIALLY_SIGNED;
  }

  return SigningOrderStatus.TO_BE_SIGNED;
}

export function isSigningOrderOwner(signingOrder: SigningOrderRepresentation, myProfileId: string) {
  if (!myProfileId) {
    return false;
  }
  return signingOrder.ownerId === myProfileId;
}

export function signingOrderPassedExpirationTimestamp(signingOrder: SigningOrderRepresentation) {
  const currentDate = new Date().toString();
  return (signingOrder.expirationTimestamp || Infinity) < Date.parse(currentDate);
}
