export function not<T>(predicate: (param: T) => boolean): (param: T) => boolean {
  return (any) => !predicate(any);
}

export function and<T>(...predicates: ((param: T) => boolean)[]): (param: T) => boolean {
  return (any) => !predicates.filter((predicate) => predicate).some((predicate) => !predicate(any));
}

export function or<T>(...predicates: ((param: T) => boolean)[]): (param: T) => boolean {
  return (t) => predicates.filter((predicate) => predicate).some((predicate) => predicate(t));
}
