export enum SuggestionType {
  PROFILE_BASED = 'PROFILE_BASED',
  CONTACT_BASED = 'CONTACT_BASED',
  LOCATION_BASED = 'LOCATION_BASED',
}

export interface Cause {
  contactId: string;
  contactPropertyId: string;
}

export interface SuggestionRepresentation {
  id: string;
  targetId: string;
  type: SuggestionType;
  seen: boolean;
  causes: Cause[];
}

export interface SuggestionsSettingsRepresentation {
  subject: {organisations: boolean; individuals: boolean};
  incoming: {
    profile: boolean;
    location: {addresses: {id: string; value: boolean}[]};
    addressBook: boolean;
    connections: boolean;
    family: boolean;
    category: boolean;
  };
  outgoing: {addressBook: boolean};
}

export interface Suggestion {
  readonly id: string;
  readonly targetId: string;
  readonly type: SuggestionType;
  seen: boolean;
  readonly causes: Cause[];
}
