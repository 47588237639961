import {Pipe, PipeTransform} from '@angular/core';
import {FieldValue, PageLanguage} from '@aztrix/models';

import {getFieldValueFieldValues} from '../../helpers/page-functions';

@Pipe({name: 'pageFieldValueFieldValues'})
export class PageFieldValueFieldValuesPipe implements PipeTransform {
  transform(language: PageLanguage | FieldValue, ...ids: unknown[]): FieldValue[] {
    return getFieldValueFieldValues(language, ...ids);
  }
}
