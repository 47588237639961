import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {Observable} from 'rxjs';
import {finalize, first} from 'rxjs/operators';

@Directive({
  selector: '[axAutoDisableButton]',
})
export class AutoDisableButtonDirective {
  @Input('axAutoDisableButton') action: Observable<any> | (() => Observable<any>);
  @Output() axAutoDisableButtonChange = new EventEmitter();
  @Output() axAutoDisableButtonError = new EventEmitter();
  private _pending = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  private _enabled = true;

  @Input()
  set enabled(value: boolean) {
    this._enabled = value;
  }

  @HostBinding('class.disabled')
  get disabledClass() {
    return !this._enabled || this._pending;
  }

  @HostBinding('class.pending')
  get pendingClass() {
    return this._pending;
  }

  @HostListener('click')
  onClick() {
    if (this._enabled && !this._pending) {
      this._pending = true;
      this.changeDetector.markForCheck();
      (this.action instanceof Function ? this.action() : this.action)
        .pipe(
          first(),
          finalize(() => {
            this._pending = false;
            this.changeDetector.markForCheck();
          })
        )
        .subscribe(
          (result) => this.axAutoDisableButtonChange.emit(result),
          (err) => this.axAutoDisableButtonError.next(err)
        );
    }
  }
}
