import {
  Agreement,
  Context,
  CustomFieldDescription,
  CustomFieldValueLabel,
  ExternalAgreement,
  Profile,
  ProfileContext,
  Property,
  Proposal,
  ProposalAccess,
  ProposalLanguage,
  ProposalProperty,
  ProposalPropertyDescription,
  ProposalPropertyTypeDescription,
  ProposalType,
  Visibility,
} from '@aztrix/models';
import {
  AgreementRepresentation,
  ContextRepresentation,
  ExternalAgreementRepresentation,
  ProfileRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {getTime} from 'date-fns';

export function contextServerRepresentation(context: Context | ContextRepresentation): Context {
  return <Context>{
    id: context.id || '',
    type: context.type,
    label: context.label || undefined,
    properties: context.properties
      ? context.properties.map((property) => propertyServerRepresentation(<Property>property))
      : undefined,
    propertyIdsToDelete: context.propertyIdsToDelete || undefined,
  };
}

export function propertyServerRepresentation(property: Property): Property {
  return <Property>{
    id: property.id || '',
    type: property.type,
    value: property.value || undefined,
    visibility: property.visibility || undefined,
    properties: property.properties
      ? property.properties.map((p) => propertyServerRepresentation(p))
      : undefined,
  };
}

export function profileServerRepresentation(profile: Profile | ProfileRepresentation): Profile {
  return <Profile>{
    id: profile.id,
    targetId: profile.targetId,
    type: profile.type,
    profileContext: profile.profileContext,
    propertyContexts: profile.propertyContexts
      ? profile.propertyContexts.map((context) => contextServerRepresentation(context))
      : undefined,
    format: profile.format,
    status: profile.status,
  };
}

export function proposalLegacyToProposal(
  proposal: ProposalRepresentation | undefined
): Proposal | undefined {
  if (!proposal) {
    return undefined;
  }

  return <any>{
    ...(<ProposalRepresentation>proposal),
    properties: [
      ...(proposal.requiredProperties || []).map((property) => {
        return <ProposalProperty>{...property, required: true};
      }),
      ...(proposal.optionalProperties || []).map((property) => {
        return <ProposalProperty>{...property, required: false};
      }),
    ],
  };
}

export function proposalToProposalLegacy(proposal: Proposal | undefined): ProposalRepresentation {
  const requiredProperties = (proposal?.properties || []).filter((p) => p.required);
  const optionalProperties = (proposal?.properties || []).filter((p) => !p.required);

  return <ProposalRepresentation>{
    ...(proposal || {}),
    requiredProperties: requiredProperties || [],
    optionalProperties: optionalProperties || [],
  };
}

export function externalAgreementLegacyToExternalAgreement(
  externalAgreement: ExternalAgreementRepresentation
): ExternalAgreement {
  return {
    agreement: <Agreement>externalAgreement.agreement,
    proposal: <Proposal>proposalLegacyToProposal(externalAgreement.proposal),
  };
}

export function externalAgreementToExternalAgreementLegacy(
  externalAgreement: ExternalAgreement
): ExternalAgreementRepresentation {
  return {
    agreement: <AgreementRepresentation>externalAgreement.agreement,
    proposal: proposalToProposalLegacy(externalAgreement.proposal),
  };
}

export function proposalServerRepresentation(proposal: Proposal): Proposal {
  return {
    id: proposal.id,
    pending: proposal.pending,
    format: undefined,
    ownerId: proposal.ownerId,
    type: proposal.type || ProposalType.NONE,
    legalGround: proposal.legalGround,
    startTimestamp: proposal.startTimestamp ? getTime(proposal.startTimestamp) : undefined,
    endTimestamp: proposal.endTimestamp ? getTime(proposal.endTimestamp) : undefined,
    visibility: proposal.visibility || Visibility.PRIVATE,
    profileContext: proposal.profileContext || ProfileContext.INDIVIDUAL,
    access: proposal.access || ProposalAccess.OPEN,
    properties: proposal.properties || undefined,
    languages: proposal.languages
      ? proposal.languages.map((l) => proposalLanguageServerRepresentation(l))
      : [],
    retentionDuration: proposal.retentionDuration,
    verification: proposal.verification,
    product: proposal.product,
  };
}

export function proposalLanguageServerRepresentation(language: ProposalLanguage): ProposalLanguage {
  return {
    id: undefined,
    pending: language.pending,
    languageCode: language.languageCode,
    isDefault: false,
    avatar: language.avatar || undefined,
    name: language.name,
    description: language.description,
    creationTimestamp: undefined,
    requestedPropertyDescriptions: proposalRequestedPropertyDescriptionsServerRepresentation(
      language.requestedPropertyDescriptions
    ),
    purpose: language.purpose || '',
    documents: language.documents,
    askToSendEmail: language.askToSendEmail,
    askToSendEmailDescription: language.askToSendEmailDescription,
    whiteLabel: language.whiteLabel,
    pdfDownloadEnabled: language.pdfDownloadEnabled,
    sendVerificationReminder: language.sendVerificationReminder,
    sendOwnerExpiredEmail: language.sendOwnerExpiredEmail,
    fromEmailAddress: language.fromEmailAddress || undefined,
    textTemplates: language.textTemplates,
    steps: language.steps,
    items: language.items,
  };
}

function proposalRequestedPropertyDescriptionsServerRepresentation(
  proposalPropertyDescriptions: ProposalPropertyDescription[]
): ProposalPropertyDescription[] {
  return proposalPropertyDescriptions.map((proposalPropertyDescription) =>
    proposalPropertyDescriptionServerRepresentation(proposalPropertyDescription)
  );
}

function proposalPropertyDescriptionServerRepresentation(
  proposalPropertyDescription: ProposalPropertyDescription
): ProposalPropertyDescription {
  return {
    ...proposalPropertyDescription,
    requestedPropertyTypeDescriptions:
      proposalPropertyDescription.requestedPropertyTypeDescriptions.map(
        (requestedPropertyTypeDescription) =>
          requestedPropertyTypeDescriptionServerRepresentation(requestedPropertyTypeDescription)
      ),
  };
}

function requestedPropertyTypeDescriptionServerRepresentation(
  proposalPropertyTypeDescription: ProposalPropertyTypeDescription
): ProposalPropertyTypeDescription {
  return {
    ...proposalPropertyTypeDescription,
    customFieldDescription: proposalPropertyTypeDescription.customFieldDescription
      ? customFieldDescriptionServerRepresentation(
          proposalPropertyTypeDescription.customFieldDescription
        )
      : undefined,
  };
}

function customFieldDescriptionServerRepresentation(
  customFieldDescription: CustomFieldDescription
): CustomFieldDescription {
  return {
    ...customFieldDescription,
    valueLabels: customFieldDescription
      ? customFieldDescription.valueLabels?.map((valueLabel) =>
          valueLabelServerRepresentation(valueLabel)
        )
      : undefined,
  };
}

function valueLabelServerRepresentation(valueLabel: CustomFieldValueLabel): CustomFieldValueLabel {
  if (valueLabel.skipTo && valueLabel.skipTo !== '') {
    return valueLabel;
  } else {
    return {
      value: valueLabel.value,
      label: valueLabel.label,
      orderIndex: valueLabel.orderIndex,
    };
  }
}
