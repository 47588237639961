import {KeyValue} from '@angular/common';
import {Group, Page, PageLanguage, Profile, Proposal} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';
import {toCanvas} from 'qrcode';
import {EMPTY, first, from, mergeMap, Observable, of} from 'rxjs';

import {groupForwardLink, isGroup, isMyGroup} from '../helpers/group-functions';
import {getPageLanguageUrl, isPageLanguage} from '../helpers/page-functions';
import {isProfile, profileForwardLink} from '../helpers/profile-functions';
import {isProposal, proposalForwardLink} from '../helpers/proposal-functions';

export function downloadDataUrl(dataUrl: string | undefined, filename?: string): void {
  if (!dataUrl || !filename) {
    return;
  }
  const link = document.createElement('a');
  link.download = filename;
  link.href = dataUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Generates a QR code and injects it into the passed image element
 *
 * @param content content of QR code
 * @param nativeElement the image element in which the PNG will be injected
 * @param fileName optional. If it exists, the generated QR code will be automatically downloaded
 *     to a file with this filename
 */
export function generateQR$(
  size: number,
  content?: string,
  imageUrl?: string,
  border = false
): Observable<string | undefined> {
  if (!content) {
    return EMPTY;
  } else {
    return from(_generateQR$(size, content, imageUrl, border));
  }
}

async function _generateQR$(
  size: number,
  content: string,
  imageUrl?: string,
  border = false
): Promise<string | undefined> {
  const canvas = await new Promise<HTMLCanvasElement>((resolve) => {
    toCanvas(content, {width: size}, (error, canvas) => {
      if (error) {
        console.error(error);
      } else {
        resolve(canvas);
      }
    });
  });

  if (!canvas) {
    return undefined;
  }

  if (imageUrl) {
    const context = canvas.getContext('2d');

    if (!context) {
      return undefined;
    }

    const canvasImage = await new Promise<HTMLImageElement>((resolve) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => resolve(img);
      img.src = imageUrl;
    });

    const imageSize = Math.ceil(size / 6);
    const position = size / 2 - imageSize / 2;

    if (border) {
      const borderThickness = Math.ceil(size / 75);
      const borderPosition = position - borderThickness;
      const borderSize = imageSize + borderThickness * 2;
      context.fillStyle = 'white';
      context.fillRect(borderPosition, borderPosition, borderSize, borderSize);
    }

    context.drawImage(canvasImage, position, position, imageSize, imageSize);
  }

  return canvas.toDataURL('image/png');
}

export function fetchQR$(
  size: number,
  subject: Profile | Group | Proposal | PageLanguage,
  baseUrl: string,
  myProfileId?: string,
  queryParams?: KeyValue<string, string>[]
): Observable<string | undefined> {
  return forwardLink$(subject, baseUrl, myProfileId, queryParams)?.pipe(
    first(),
    mergeMap((link) =>
      generateQR$(size, link, `${baseUrl}/assets/icons/custom/aztrixlogo_green-border.svg`)
    )
  );
}

export function forwardLink$(
  subject: Profile | ProfileRepresentation | Group | Proposal | PageLanguage | Page,
  baseUrl: string,
  myProfileId?: string,
  queryParams?: KeyValue<string, string>[]
): Observable<string | undefined> {
  let link$: Observable<string | undefined> = of('');
  if (!subject) {
    return link$;
  }
  if (isProfile(subject)) {
    link$ = of(profileForwardLink(<ProfileRepresentation>subject, baseUrl));
  }
  if (isGroup(subject)) {
    link$ = of(
      groupForwardLink(subject, baseUrl, myProfileId ? isMyGroup(subject, myProfileId) : false)
    );
  }
  if (isProposal(subject)) {
    link$ = of(proposalForwardLink(subject, baseUrl));
  }
  if (isPageLanguage(subject)) {
    link$ = of(getPageLanguageUrl(subject, queryParams));
  }
  return link$;
}
