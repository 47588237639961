import {Context, ContextType, Profile, Property, PropertyType} from '@aztrix/models';
import {
  ContextRepresentation,
  ContextTypeRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  PropertyTypeInfo,
} from '@aztrix/sdk';

import {
  contextTypeRep,
  contextTypeReps,
  propertyTypeRepsForContext,
} from '../metadata/context-type-provider';
import {typeFilter} from '../util/filter.util';
import {ContextBuilder} from './context-builder';
import {getContext, getContexts, isMeProfile} from './profile-functions';
import {
  canBeVerified,
  dummyPropertyForType,
  getProperties,
  getProperty,
  isSocialRelated,
} from './property-functions';

/**
 * Returns a dummy non-enriched context for given parameters
 *
 * @param profileType profile type for given context
 * @param profileContext profile context for given context
 * @param contextType context type which will be returned
 */
export function dummyContextForType(
  profileType: ProfileRepresentation.TypeEnum,
  profileContext: ProfileRepresentation.ProfileContextEnum | undefined,
  contextType?: ContextRepresentation.TypeEnum
): Context | ContextRepresentation {
  if (!contextType) {
    return <Context>ContextBuilder.empty();
  }

  const typeRep = contextTypeRep(profileType, profileContext, contextType);
  if (!typeRep || (!typeRep.required && !typeRep.dummy)) {
    return <Context>ContextBuilder.empty();
  }

  return ContextBuilder.empty()
    .setType(<ContextType>typeRep.name)
    .build();
}

/**
 * Returns dummy contexts which the passed profile does not have yet
 *
 * @param profile profile for which the missing contexts will be returned
 */
export function enrichProfile(profile: Profile): Context[] {
  return <Context[]>contextTypeReps(profile.type, profile.profileContext)
    .filter(
      (rep: ContextTypeRepresentation) =>
        // Only keep the types we do not have yet at all
        !getContext(<ProfileRepresentation>profile, typeFilter(rep.name))
    )
    .map((typeRep: ContextTypeRepresentation) =>
      dummyContextForType(profile.type, profile.profileContext, typeRep.name)
    )
    .filter((context) => context !== null);
}

/**
 * Returns all the possible dummy contexts a profile can still have
 *
 * @param profile profile for which the dummy contexts will be created
 */
export function dummyContextsForProfile(profile: Profile): Context[] {
  return <Context[]>contextTypeReps(profile.type, profile.profileContext)
    .filter((rep: ContextTypeRepresentation) => {
      if (!rep.dummy) {
        return false;
      } // False if it is not a dummy type
      if (!rep.limit) {
        return true;
      } // True if it has no limit
      const existingContexts = getContexts(<ProfileRepresentation>profile, typeFilter(rep.name));
      return existingContexts.length < rep.limit;
    })
    .map((typeRep: ContextTypeRepresentation) =>
      dummyContextForType(profile.type, profile.profileContext, typeRep.name)
    );
}
/**
 * Checks whether the given profile can add a context of the given type
 *
 * @param profile profile of which the contexts will be used
 * @param contextType check if profile has a context of this type
 */
export function canProfileAddContextOfType(profile: Profile, contextType: ContextType): boolean {
  if (
    !profile ||
    profile.type === ProfileRepresentation.TypeEnum.PUBLIC_PROFILE ||
    contextType === ContextType.COMMON
  ) {
    return false;
  }
  // Return true if it still can have a dummy context of this type
  return dummyContextsForProfile(profile).some(typeFilter(<string>contextType));
}

export function enrichContext(context: Context, profile: Profile): Property[] {
  const properties = propertyTypeRepsForContext(profile.type, profile.profileContext, context.type)
    .filter(
      (rep) =>
        // Only keep the types we do not have yet at all
        !getProperty(context, typeFilter(<PropertyType>rep.name))
    )
    .filter((rep) => {
      if (!rep.dummy) {
        return false;
      } // False if it is not a dummy type
      if (!rep.limit) {
        return true;
      } // True if it has no limit
      const existingProperties = getProperties(context, typeFilter(<PropertyType>rep.name));
      return existingProperties.length < rep.limit;
    })
    .map((rep) => dummyPropertyForType(rep.name));
  return <Property[]>(
    (isMeProfile(<ProfileRepresentation>profile)
      ? properties.filter((property) =>
          isSocialRelated(property?.type) ? canBeVerified(property, profile, context) : true
        )
      : properties)
  );
}

export function possiblePropertyTypesForContext(
  profile: ProfileRepresentation | Profile,
  context: ContextRepresentation | Context
): PropertyTypeInfo.NameEnum[] {
  return <PropertyTypeInfo.NameEnum[]>propertyTypeRepsForContext(
    profile.type,
    profile.profileContext,
    context.type
  )
    .filter((rep) => {
      if (!rep.limit) {
        return true;
      }
      const existingProperties = getProperties(context, typeFilter(<PropertyType>rep.name));
      return existingProperties.length < rep.limit;
    })
    .map((rep) => rep.name)
    .filter((type) => !!type);
}

export function possibleContextsForMissingPropertyType(
  propertyType: PropertyRepresentation.TypeEnum | PropertyType | undefined,
  profile: Profile | ProfileRepresentation
): Context[] | ContextRepresentation[] {
  // The possible existing context
  const existingContexts = getContexts(<ProfileRepresentation>profile, (context) =>
    possiblePropertyTypesForContext(profile, context).includes(
      <PropertyRepresentation.TypeEnum>propertyType
    )
  );
  // The possible dummy contexts
  const dummyContexts = dummyContextsForProfile(<Profile>profile).filter((context) =>
    possiblePropertyTypesForContext(profile, context).includes(
      <PropertyRepresentation.TypeEnum>propertyType
    )
  );
  return <any>existingContexts.concat(dummyContexts);
}
