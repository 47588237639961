import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {AgreementVerificationType} from '@aztrix/models';
import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';
import {BehaviorSubject} from 'rxjs';

@Pipe({
  name: 'AgreementVerificationTypeLabel',
})
export class AgreementVerificationTypeLabelPipe implements PipeTransform {
  private _translate = new TranslatePipe(
    this._translateService,
    this._changeDetector,
    this._languageOverride$
  );

  constructor(
    private _translateService: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  transform(type: AgreementVerificationType): string {
    return this._translate.transform(`agreement.verification.type.${type}`, undefined);
  }
}
