import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {Group, Page, Profile, Property, Proposal} from '@aztrix/models';
import {ProfileRepresentation, ProposalRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, Subscription} from 'rxjs';

import {displayName$} from '../helpers/display-name';
import {LANGUAGE_OVERRIDE, TranslateService} from '@aztrix/translate';

/**
 * Transforms a `profile`, `group`, `proposal` or `page` into the `display name`.
 **/
@Pipe({name: 'displayName', pure: false})
export class DisplayNamePipe implements PipeTransform {
  private _value?:
    | Profile
    | ProfileRepresentation
    | Group
    | Proposal
    | ProposalRepresentation
    | Page
    | null
    | undefined;
  private _latestValue?: string;
  private _subscription = new Subscription();

  constructor(
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  /**
   * @param value the profile, group, proposal or page to get the display name from.
   * @param noNameLabel the text that should be shown when there is no display name available,
   *     defaults to 'No name'.
   * @param filter property filter.
   */
  transform(
    value:
      | Profile
      | ProfileRepresentation
      | Group
      | Proposal
      | ProposalRepresentation
      | Page
      | null
      | undefined,
    noNameLabel?: string,
    filter: (p: Property) => boolean = () => true
  ): string | undefined {
    if (!this._value) {
      this._value = value;
      this._subscription = displayName$(
        this._translate,
        value,
        noNameLabel,
        this._languageOverride$?.value,
        filter
      ).subscribe((name) => {
        this._latestValue = name;
        this._changeDetector.markForCheck();
      });

      return this._latestValue;
    }

    if (value !== this._value) {
      this._subscription.unsubscribe();
      this._latestValue = undefined;
      this._value = undefined;
      return this.transform(value, noNameLabel, filter);
    }

    return this._latestValue;
  }
}
