import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SpinnerModule} from '@aztrix/components/spinner';
import {IconsModule} from '@aztrix/icons';

import {AutoDisableButtonDirective} from './auto-disable-button.directive';
import {ButtonComponent} from './button.component';
import {ButtonDirective} from './button.directive';

@NgModule({
  declarations: [ButtonDirective, AutoDisableButtonDirective, ButtonComponent],
  imports: [CommonModule, RouterModule, IconsModule, SpinnerModule],
  exports: [ButtonDirective, AutoDisableButtonDirective, ButtonComponent],
})
export class ButtonModule {}
