import {Profile, ProfileContext, ProfileStatus, ProfileType} from '../profile/profile';

export interface SearchSubject {
  title: SearchSubjectType;
  profileContexts: ProfileContext[];
  profileTypes: ProfileType[];
  contactStatuses: ProfileStatus[];
  openCorporates?: boolean;
  resultsFilter?: (profile: Profile) => boolean;
}

export enum SearchSubjectType {
  ALL = 'ALL',
  INDIVIDUALS = 'INDIVIDUALS',
  ORGANISATIONS = 'ORGANISATIONS',
  GROUPS = 'GROUPS',
  SUGGESTIONS = 'SUGGESTIONS',
}

export enum FilterType {
  CATEGORY = 'CATEGROY',
  COUNTRY = 'COUNTRY',
  AGREEMENT_STATE = 'AGREEMENT_STATE',
  PROPOSAL_TYPE = 'PROPOSAL_TYPE',
  AGREEMENT_VARIFIED_STATE = 'AGREEMENT_VARIFIED_STATE',
}
