import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {Locale, Profile, Property, PropertyType} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {getPropertyTuple} from '../../helpers/context-functions';
import {isProfile} from '../../helpers/profile-functions';
import {LANGUAGE_OVERRIDE, TranslateService, LOCALE} from '@aztrix/translate';
import {isOfType, typeFilter} from '../../util/filter.util';
import {PropertyDisplayValuePipe} from './property-display-value.pipe';

@Pipe({
  name: 'regionDisplay',
  pure: false,
})
export class RegionDisplayPipe implements PipeTransform {
  private _propertyDisplayPipe = new PropertyDisplayValuePipe(
    this._locale$,
    this._translateService,
    this._changeDetector,
    this._languageOverride$
  );

  constructor(
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>,
    private _translateService: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  transform(value: Property | Profile | null | undefined): string {
    let locationProperty: Property | undefined | null;
    if (isProfile(value)) {
      const tuple = getPropertyTuple(
        value.propertyContexts || [],
        typeFilter(PropertyType.REGION, PropertyType.ADDRESS)
      );
      if (!tuple || !tuple.context) {
        return '';
      }
      locationProperty = tuple.property;
    } else if (!isOfType(value, PropertyType.REGION, PropertyType.ADDRESS)) {
      return '';
    } else {
      locationProperty = value;
    }

    if (!locationProperty) {
      return '';
    }

    locationProperty = {
      ...locationProperty,
      properties: (locationProperty.properties || []).filter(
        typeFilter(PropertyType.ZIP, PropertyType.LOCALITY, PropertyType.COUNTRY)
      ),
    };

    return this._propertyDisplayPipe.transform(locationProperty);
  }
}
