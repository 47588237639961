import {Group, GroupType, ProfileContext} from '@aztrix/models';

import {
  GroupTypeRepresentation,
  groupTypesForProfileContext,
} from '../metadata/group-type-provider';
import {GROUP_SUBDIVISIONS} from '../metadata/metadata';

export enum GroupSubdivisionType {
  MY_GROUPS = 'MY_GROUPS',
  MEMBER_GROUPS = 'MEMBER_GROUPS',
  FOLLOWER_GROUPS = 'FOLLOWER_GROUPS',
  IMPORTING_GROUPS = 'IMPORTING_GROUPS',
}

export function sortGroups(groups: Group[], profileContext: ProfileContext): Group[] {
  return sort(groups, groupTypesOrder(profileContext));
}

export function sortGroupsForSubdivision(
  groups: Group[],
  subdivision: GroupSubdivisionType
): Group[] {
  const order = groupTypesOrderForSubdivision(subdivision);
  const groupsForSubdivision = groups.filter((group) => order.includes(group.type));
  return sort(groupsForSubdivision, order);
}

function groupTypesOrder(profileContext: ProfileContext): GroupType[] {
  return groupTypesForProfileContext(profileContext).map(
    (rep: GroupTypeRepresentation) => rep.name
  );
}

function groupTypesOrderForSubdivision(subdivision: GroupSubdivisionType): GroupType[] {
  return <GroupType[]>GROUP_SUBDIVISIONS[subdivision];
}

function sort(groups: Group[], order: GroupType[]): Group[] {
  if (!groups || groups.length === 0) {
    return [];
  }
  if (groups.length === 1) {
    return groups;
  }
  return groups.sort((g1: Group, g2: Group) => order.indexOf(g1.type) - order.indexOf(g2.type));
}
