import {Locale} from '@aztrix/models';

import {METADATA} from './metadata';

const {countryCodeSpecifics} = METADATA;

export const COUNTRIES = countryCodeSpecifics;

export interface CountryCodeRepresentation {
  countryCode: string;
  phonePrefix: string[];
  ibanPrefix: string;
}

export function countryCodeRep(countryCode: string | undefined): CountryCodeRepresentation {
  if (!countryCode) {
    return countryCodeSpecifics[0];
  }

  return (
    countryCodeSpecifics.find(
      (rep) => rep.countryCode.toUpperCase() === countryCode.toUpperCase()
    ) || countryCodeSpecifics[0]
  );
}

export function phonePrefixForCountryCode(countryCode: string): string | undefined {
  if (!countryCode) {
    return undefined;
  }
  return countryCodeRep(countryCode)?.phonePrefix[0];
}

export function ibanPrefixForCountryCode(countryCode: string): string | undefined {
  if (!countryCode) {
    return undefined;
  }
  return countryCodeRep(countryCode)?.ibanPrefix;
}

export function phonePrefixes(): Set<string> {
  return new Set(...countryCodeSpecifics.map((rep) => rep.phonePrefix));
}

export function browserLanguageCode(): string {
  const countryCode = navigator.languages
    .map(browserLanguageToCodes)
    .map((codes) => codes.languageCode)
    .filter((lang) => !!lang)[0];

  return countryCode || 'en';
}

export function browserCountryCode(): string {
  const countryCode = navigator.languages
    .map(browserLanguageToCodes)
    .map((codes) => codes.countryCode)
    .filter((country) => !!country)[0];

  return countryCode || 'BE';
}

export function browserLocale(): Locale {
  return {
    languageCode: browserLanguageCode() || 'en',
    countryCode: browserCountryCode() || 'BE',
  };
}

export function browserCountryCodeRep(): CountryCodeRepresentation {
  return countryCodeRep(browserCountryCode());
}

function browserLanguageToCodes(browserLanguage: string): {
  languageCode: string | undefined;
  countryCode: string | undefined;
} {
  const regex =
    /^(?:(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))$|^((?:[a-z]{2,3}(?:(?:-[a-z]{3}){1,3})?)|[a-z]{4}|[a-z]{5,8})(?:-([a-z]{4}))?(?:-([a-z]{2}|\d{3}))?((?:-(?:[\da-z]{5,8}|\d[\da-z]{3}))*)?((?:-[\da-wy-z](?:-[\da-z]{2,8})+)*)?(-x(?:-[\da-z]{1,8})+)?$|^(x(?:-[\da-z]{1,8})+)$/i;

  const split = regex.exec(browserLanguage) || [];
  const languageCode = split[0];
  const countryCode = split[5];

  return {languageCode: languageCode?.toLowerCase(), countryCode: countryCode?.toUpperCase()};
}
