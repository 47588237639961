import {Pipe, PipeTransform} from '@angular/core';
import {PropertyType, ProposalLanguage, ProposalPropertyTypeDescription} from '@aztrix/models';
import {
  ProposalLanguageRepresentation,
  RequestedPropertyTypeDescriptionRepresentation,
} from '@aztrix/sdk';

import {getPropertyTypeDescription} from '../../helpers/proposal-functions';

@Pipe({name: 'proposalPropertyDescription'})
export class ProposalPropertyDescriptionPipe implements PipeTransform {
  transform(
    language: ProposalLanguage | ProposalLanguageRepresentation | undefined,
    requestedId: string | undefined,
    type: PropertyType | undefined
  ): ProposalPropertyTypeDescription | RequestedPropertyTypeDescriptionRepresentation | undefined {
    return getPropertyTypeDescription(language, requestedId, type);
  }
}
