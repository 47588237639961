/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {LatLong} from './latLong';

export interface SearchAgreementsRepresentation {
  agreementStatus?: SearchAgreementsRepresentation.AgreementStatusEnum;
  agreementConfirmation?: SearchAgreementsRepresentation.AgreementConfirmationEnum;
  agreementVerification?: SearchAgreementsRepresentation.AgreementVerificationEnum;
  skip?: number;
  limit?: number;
  verificationValue?: string;
  query?: string;
  cursor?: string;
  location?: LatLong;
  excludedAgreements?: Array<string>;
}
export namespace SearchAgreementsRepresentation {
  export type AgreementStatusEnum =
    | 'AGREEMENTSTATUS_UNDEFINED'
    | 'ACTIVE'
    | 'EXPIRED'
    | 'UNRECOGNIZED';
  export const AgreementStatusEnum = {
    AGREEMENTSTATUS_UNDEFINED: 'AGREEMENTSTATUS_UNDEFINED' as AgreementStatusEnum,
    ACTIVE: 'ACTIVE' as AgreementStatusEnum,
    EXPIRED: 'EXPIRED' as AgreementStatusEnum,
    UNRECOGNIZED: 'UNRECOGNIZED' as AgreementStatusEnum,
  };
  export type AgreementConfirmationEnum =
    | 'AGREEMENTCONFIRMATION_UNDEFINED'
    | 'UNCONFIRMED'
    | 'CONFIRMED'
    | 'UNRECOGNIZED';
  export const AgreementConfirmationEnum = {
    AGREEMENTCONFIRMATION_UNDEFINED: 'AGREEMENTCONFIRMATION_UNDEFINED' as AgreementConfirmationEnum,
    UNCONFIRMED: 'UNCONFIRMED' as AgreementConfirmationEnum,
    CONFIRMED: 'CONFIRMED' as AgreementConfirmationEnum,
    UNRECOGNIZED: 'UNRECOGNIZED' as AgreementConfirmationEnum,
  };
  export type AgreementVerificationEnum =
    | 'AGREEMENTVERIFICATION_UNDEFINED'
    | 'UNVERIFIED'
    | 'VERIFIED'
    | 'UNRECOGNIZED';
  export const AgreementVerificationEnum = {
    AGREEMENTVERIFICATION_UNDEFINED: 'AGREEMENTVERIFICATION_UNDEFINED' as AgreementVerificationEnum,
    UNVERIFIED: 'UNVERIFIED' as AgreementVerificationEnum,
    VERIFIED: 'VERIFIED' as AgreementVerificationEnum,
    UNRECOGNIZED: 'UNRECOGNIZED' as AgreementVerificationEnum,
  };
}
