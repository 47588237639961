import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {isVerified} from '../../helpers/property-functions';

@Pipe({name: 'propertyIsVerified'})
export class PropertyIsVerifiedPipe implements PipeTransform {
  transform(property?: Property): boolean {
    return isVerified(property);
  }
}
