import {Pipe, PipeTransform} from '@angular/core';
import {FieldValue, PageLanguage} from '@aztrix/models';

import {getFieldValue} from '../../helpers/page-functions';

@Pipe({name: 'pageFieldValue'})
export class PageFieldValuePipe implements PipeTransform {
  transform(language: PageLanguage | FieldValue, ...ids: any[]): string | FieldValue[] {
    return getFieldValue(language, ...ids);
  }
}
