import {ForwardType, Group, GroupType} from '@aztrix/models';

import {groupTypeRep} from '../metadata/group-type-provider';
import {getLink} from '../metadata/links';
import {ROUTER_LINKS} from '../metadata/metadata';
import {isOfType} from '../util/filter.util';
import {addQueryParametersToLink} from '../util/http.util';
import {isPrivate, isPublic} from './subject-functions';

export function isGroup(group: any): group is Group {
  return Object.keys(GroupType).includes(group?.type);
}

export function isMyGroup(group: Group, myProfileId: string): boolean {
  return group && myProfileId === group.ownerId;
}

export function isContacts(group: Group): boolean {
  return group && group.type === GroupType.CONTACTS;
}

export function canAddMembers(group: Group, myProfileId: string): boolean {
  return (group && group.canAddMembers) || (isContacts(group) && isMyGroup(group, myProfileId));
}

export function canRemoveMembers(group: Group, myProfileId: string): boolean {
  if (isContacts(group)) {
    return false;
  }
  return canAddMembers(group, myProfileId);
}

export function groupVisibilityCanBeChanged(group: Group): boolean {
  return group && !isOfType(group, GroupType.BLOCKED, GroupType.FAVORITES, GroupType.FAMILY);
}

export function ownerCanBeMember(group: Group): boolean {
  return groupTypeRep(group.type, group.profileContext)?.ownerCanBeMember ?? false;
}

export function isFollowable(group: Group): boolean {
  if (!group) {
    return false;
  } else {
    const typeRep = groupTypeRep(group.type, group.profileContext);
    return typeRep?.followable ?? false;
  }
}

export function canChangeMembershipVisibility(group: Group): boolean {
  return (
    (group && groupTypeRep(group.type, group.profileContext)?.canChangeMembershipVisibility) ??
    false
  );
}

export function canChangeMembersVisibility(group: Group): boolean {
  return canMakeMembersVisible(group) || canMakeMembersNonVisible(group);
}

export function canChangeSettings(group: Group): boolean {
  return canChangeMembersVisibility(group) || canChangeMembershipVisibility(group);
}

export function groupCanBeDeleted(group: Group, myProfileId: string): boolean {
  if (!isMyGroup(group, myProfileId)) {
    return false;
  }
  return groupTypeRep(group.type, group.profileContext)?.deletable ?? false;
}

export function canBeFollowed(group: Group, myProfileId: string): boolean {
  return (
    !isMyGroup(group, myProfileId) &&
    groupVisibilityCanBeChanged(group) &&
    !group.follower &&
    isFollowable(group)
  );
}

export function canBeUnfollowed(group: Group, myProfileId: string): boolean {
  return (
    (!isMyGroup(group, myProfileId) &&
      groupVisibilityCanBeChanged(group) &&
      group.follower &&
      isFollowable(group)) ??
    false
  );
}

export function canBeMadePublic(group: Group): boolean {
  return groupVisibilityCanBeChanged(group) && isPrivate(group);
}

export function canBeMadePrivate(group: Group): boolean {
  return groupVisibilityCanBeChanged(group) && isPublic(group);
}

export function serverRepresentation(group: Group): Group {
  return <Group>{
    id: group.id || '',
    name: group.name,
    type: group.type,
    avatar: group.avatar,
    access: group.access,
    visibility: group.visibility,
  };
}

/* links */

export function groupForwardLink(
  group: Group,
  baseUrl: string,
  isMyGroup: boolean,
  forwardOption?: ForwardType,
  completeLink = true
): string {
  const link = completeLink
    ? `${baseUrl || ''}${groupWithIdLink(group.id)}`
    : groupLink(group, isMyGroup);
  return forwardOption ? addQueryParametersToLink(link, {forwardType: forwardOption}) : link;
}

export function groupLink(group: Group, isMyGroup: boolean): string {
  if (!group) {
    return '';
  }

  if (group.type === GroupType.CONTACTS && isMyGroup) {
    return ROUTER_LINKS.contacts + '/forward';
  } else {
    return groupWithIdLink(group.id) + '/forward';
  }
}

export function groupWithIdLink(groupId: string): string {
  return getLink(ROUTER_LINKS.group, {id: groupId});
}

export function canMakeMyselfVisibleAsMember(group: Group): boolean {
  return (canChangeMembershipVisibility(group) && !group?.visibleAsMember) ?? false;
}

export function canMakeMyselfNonVisibleAsMember(group: Group): boolean {
  return (canChangeMembershipVisibility(group) && group?.visibleAsMember) ?? false;
}

export function canMakeMembersVisible(group: Group): boolean {
  return !group?.membersVisible;
}

export function canMakeMembersNonVisible(group: Group): boolean {
  return group?.membersVisible ?? false;
}

/* filters */
export function isMemberFilter(group: Group): boolean {
  return group?.member ?? false;
}

export function isFollowerFilter(group: Group): boolean {
  return group?.follower ?? false;
}
