/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ProposalLanguageItemRepresentation {
  type?: ProposalLanguageItemRepresentation.TypeEnum;
  orderIndex?: number;
}
export namespace ProposalLanguageItemRepresentation {
  export type TypeEnum = 'TEMPLATE' | 'PROPERTY' | 'STEP';
  export const TypeEnum = {
    TEMPLATE: 'TEMPLATE' as TypeEnum,
    PROPERTY: 'PROPERTY' as TypeEnum,
    STEP: 'STEP' as TypeEnum,
  };
}
