import {Pipe, PipeTransform} from '@angular/core';
import {CommunicationType} from '@aztrix/models';

import {isProfile} from '../../helpers/profile-functions';
import {communicationTypesForProfile} from '../../util/communication.util';

@Pipe({
  name: 'communicationTypesForProfile',
})
export class CommunicationTypesForProfilePipe implements PipeTransform {
  transform(value: any): CommunicationType[] {
    return isProfile(value) ? communicationTypesForProfile(value) : [];
  }
}
