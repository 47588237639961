import {Pipe, PipeTransform} from '@angular/core';

import {COUNTRIES, CountryCodeRepresentation} from '../metadata/country-info';
import {TranslateService} from '@aztrix/translate';
import {CountryCodeLabelPipe} from './country-code-label.pipe';

@Pipe({
  name: 'countrySearch',
  pure: false,
})
export class CountrySearchPipe implements PipeTransform {
  static allCountryCodeRepsSorted(
    translateService: TranslateService,
    all = false
  ): CountryCodeRepresentation[] {
    const countryCodeReps = [
      ...COUNTRIES.sort((rep1, rep2) => {
        const translation1 = translateService.instant(
          CountryCodeLabelPipe.countryCodeLabel(rep1.countryCode)
        );
        const translation2 = translateService.instant(
          CountryCodeLabelPipe.countryCodeLabel(rep2.countryCode)
        );
        return translation1 < translation2 ? -1 : 1;
      }),
    ];

    return all
      ? [<CountryCodeRepresentation>{countryCode: ''}, ...countryCodeReps]
      : countryCodeReps;
  }

  transform(countryCode: string, all = false, countryCodes: string[]): any {
    return CountrySearchPipe.allCountryCodeRepsSorted(this._translate, all)
      .filter((rep) => {
        if (countryCode) {
          return this._translate
            .instant(CountryCodeLabelPipe.countryCodeLabel(rep.countryCode))
            .toLowerCase()
            .includes(countryCode.toLowerCase());
        } else {
          return true;
        }
      })
      .filter((rep) => (countryCodes?.length ? countryCodes.includes(rep.countryCode) : true))
      .filter((rep) => (all ? true : rep.countryCode !== ''));
  }

  constructor(private _translate: TranslateService) {}
}
