import {ChangeDetectorRef, Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {Locale, Property} from '@aztrix/models';
import {ProfilePropertyRepresentation, PropertyRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {displayValue$, hasEqualValues} from '../../helpers/property-functions';
import {LANGUAGE_OVERRIDE, LOCALE, TranslateService} from '@aztrix/translate';

/**
 * Transforms a `property` into its `display value`.
 **/
@Pipe({
  name: 'propertyDisplayValue',
  pure: false,
})
export class PropertyDisplayValuePipe implements PipeTransform, OnDestroy {
  private _value?:
    | Property
    | Property[]
    | PropertyRepresentation
    | PropertyRepresentation[]
    | ProfilePropertyRepresentation
    | ProfilePropertyRepresentation[]
    | null;
  private _latestValue?: string;
  private _latestReturnedValue?: string;

  private _subscription = new Subscription();

  constructor(
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>,
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}
  /**
   * @param value the `property` to get the display value of.
   */
  transform(
    value:
      | Property
      | Property[]
      | PropertyRepresentation
      | PropertyRepresentation[]
      | ProfilePropertyRepresentation
      | ProfilePropertyRepresentation[]
      | undefined
      | null,
    noValuePlaceholder?: string,
    short = false
  ): any {
    if (!this._value) {
      this._value = value;

      let observable$: Observable<string>;
      if (Array.isArray(value)) {
        observable$ = combineLatest(
          value.map((prop) =>
            displayValue$(
              this._translate,
              prop,
              this._locale$.value,
              this._languageOverride$?.value,
              noValuePlaceholder,
              short
            )
          )
        ).pipe(map((values) => values.join(' ')));
      } else {
        observable$ = displayValue$(
          this._translate,
          value,
          this._locale$.value,
          this._languageOverride$?.value,
          noValuePlaceholder,
          short
        );
      }

      this._subscribe(observable$);

      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (!hasEqualValues(value, this._value)) {
      this._dispose();
      return this.transform(value);
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  ngOnDestroy() {
    this._dispose();
  }

  private _subscribe(obs: Observable<any>): void {
    this._subscription.add(
      obs.subscribe((value) => {
        this._latestValue = value;
        this._changeDetector.markForCheck();
      })
    );
  }

  private _dispose() {
    this._subscription.unsubscribe();
    this._value = undefined;
    this._latestValue = undefined;
    this._latestReturnedValue = undefined;
  }
}
