export enum GroupType {
  CONTACTS = 'CONTACTS',
  FOLLOWERS = 'FOLLOWERS',
  FAVORITES = 'FAVORITES',
  FAMILY = 'FAMILY',
  BLOCKED = 'BLOCKED',
  EMERGENCIES = 'EMERGENCIES',
  IMPORTING = 'IMPORTING',
  EXPLICIT = 'EXPLICIT',
}
