import {Pipe, PipeTransform} from '@angular/core';
import {Profile} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';

import {isContact} from '../../helpers/profile-functions';

@Pipe({
  name: 'isContact',
})
export class IsContactPipe implements PipeTransform {
  transform(profile: Profile | ProfileRepresentation): boolean {
    return profile && isContact(<ProfileRepresentation>profile);
  }
}
