/**
 * Aztrix
 * Smart address book for individuals and organisations, respecting privacy.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {ProfilePropertyRepresentation} from './profilePropertyRepresentation';

export interface ContextRepresentation {
  id?: string;
  type?: ContextRepresentation.TypeEnum;
  label?: string;
  properties?: Array<ProfilePropertyRepresentation>;
  propertyIdsToDelete?: Array<string>;
}
export namespace ContextRepresentation {
  export type TypeEnum =
    | 'PROFILE'
    | 'COMMON'
    | 'PERSONAL'
    | 'WORK'
    | 'GENERAL'
    | 'LEGAL'
    | 'GROUP'
    | 'MISC'
    | 'PROPOSAL'
    | 'DOCUMENTS';
  export const TypeEnum = {
    PROFILE: 'PROFILE' as TypeEnum,
    COMMON: 'COMMON' as TypeEnum,
    PERSONAL: 'PERSONAL' as TypeEnum,
    WORK: 'WORK' as TypeEnum,
    GENERAL: 'GENERAL' as TypeEnum,
    LEGAL: 'LEGAL' as TypeEnum,
    GROUP: 'GROUP' as TypeEnum,
    MISC: 'MISC' as TypeEnum,
    PROPOSAL: 'PROPOSAL' as TypeEnum,
    DOCUMENTS: 'DOCUMENTS' as TypeEnum,
  };
}
