export interface AgreementSearch {
  cursor?: string;
  agreementStatus: AgreementStatus;
  agreementConfirmation: AgreementConfirmation;
  agreementVerification: AgreementVerification;
  skip?: number;
  limit?: number;
  query: string;
  location?: {lat: number; lon: number};
  excludedAgreements?: string[];
}

export enum AgreementStatus {
  AGREEMENTSTATUS_UNDEFINED = 'AGREEMENTSTATUS_UNDEFINED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum AgreementConfirmation {
  AGREEMENTCONFIRMATION_UNDEFINED = 'AGREEMENTCONFIRMATION_UNDEFINED',
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum AgreementVerification {
  AGREEMENTVERIFICATION_UNDEFINED = 'AGREEMENTVERIFICATION_UNDEFINED',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
