import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import {IconService} from './icon.service';

@Component({
  selector: 'ax-icon',
  template: `<mat-icon
    *ngIf="(loading$ | async) === false"
    [svgIcon]="(name | lowercase) || 'alert-circle'"
    [inline]="inline"
  ></mat-icon>`,
  styleUrls: ['icon.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class IconComponent {
  @Input() name? = 'alert-circle';
  @Input() inline = false;

  loading$ = this._iconService.loading$;

  constructor(private _iconService: IconService) {}
}
