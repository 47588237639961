export type PropertyType =
  | 'USERNAME'
  | 'FIRST_NAME'
  | 'MIDDLE_NAME'
  | 'LAST_NAME'
  | 'AVATAR'
  | 'GENDER'
  | 'BIRTHDAY'
  | 'FOUNDED'
  | 'DISSOLVED'
  | 'DAY'
  | 'MONTH'
  | 'YEAR'
  | 'EMAIL'
  | 'MOBILE_PHONE'
  | 'PHONE'
  | 'FAX'
  | 'WEBSITE'
  | 'BIC'
  | 'JOB_TITLE'
  | 'INDUSTRY'
  | 'GROUP'
  | 'REGION'
  | 'ADDRESS'
  | 'STREET'
  | 'COUNTRY'
  | 'BUILDING'
  | 'BOX'
  | 'LOCALITY'
  | 'NUMBER'
  | 'ZIP'
  | 'DISTRICT'
  | 'PROVINCE'
  | 'SUBURB'
  | 'STATE'
  | 'COUNTY'
  | 'LAT_LONG'
  | 'FACEBOOK'
  | 'TWITTER'
  | 'TIKTOK'
  | 'WHATSAPP'
  | 'LINKEDIN'
  | 'INSTAGRAM'
  | 'SKYPE'
  | 'YOUTUBE'
  | 'WECHAT'
  | 'FACEBOOK_MESSENGER'
  | 'WEIBO'
  | 'QQ'
  | 'KUAISHOU'
  | 'QZONE'
  | 'SNAPCHAT'
  | 'TELEGRAM'
  | 'PINTEREST'
  | 'REDDIT'
  | 'DISCORD'
  | 'TWITCH'
  | 'TUMBLR'
  | 'MASTODON'
  | 'PATREON'
  | 'KICKSTARTER'
  | 'INDIEGOGO'
  | 'GOFUNDME'
  | 'VIMEO'
  | 'NOTE'
  | 'ORGANISATION_NAME'
  | 'LEGAL_NAME'
  | 'LEGAL_FORM'
  | 'LEGAL_ID'
  | 'COMPANY_NUMBER'
  | 'JURISDICTION'
  | 'IMPORT_SOURCE'
  | 'PROPOSAL'
  | 'CATEGORY'
  | 'TAKEAWAY'
  | 'DELIVEROO'
  | 'SHIPTO'
  | 'UBEREATS'
  | 'OPENING_HOURS'
  | 'CUSTOM'
  | 'FILE'
  | 'IMAGE'
  | 'FILE_KEY'
  | 'FILE_NAME'
  | 'TERMS_AND_CONDITIONS'
  | 'PRIVACY_POLICY'
  | 'DOCUMENT'
  | 'DOCUMENT_NAME'
  | 'ORGANISATION_TYPE'
  // not compliant with PropertyRepresentation.TypeEnum
  | 'NAME'
  | 'BANK_ACCOUNT'
  | 'IBAN'
  | 'PAGE';

export const PropertyType = {
  USERNAME: 'USERNAME' as PropertyType,
  FIRST_NAME: 'FIRST_NAME' as PropertyType,
  MIDDLE_NAME: 'MIDDLE_NAME' as PropertyType,
  LAST_NAME: 'LAST_NAME' as PropertyType,
  AVATAR: 'AVATAR' as PropertyType,
  GENDER: 'GENDER' as PropertyType,
  BIRTHDAY: 'BIRTHDAY' as PropertyType,
  FOUNDED: 'FOUNDED' as PropertyType,
  DISSOLVED: 'DISSOLVED' as PropertyType,
  DAY: 'DAY' as PropertyType,
  MONTH: 'MONTH' as PropertyType,
  YEAR: 'YEAR' as PropertyType,
  EMAIL: 'EMAIL' as PropertyType,
  MOBILE_PHONE: 'MOBILE_PHONE' as PropertyType,
  PHONE: 'PHONE' as PropertyType,
  FAX: 'FAX' as PropertyType,
  WEBSITE: 'WEBSITE' as PropertyType,
  BIC: 'BIC' as PropertyType,
  JOB_TITLE: 'JOB_TITLE' as PropertyType,
  INDUSTRY: 'INDUSTRY' as PropertyType,
  GROUP: 'GROUP' as PropertyType,
  REGION: 'REGION' as PropertyType,
  ADDRESS: 'ADDRESS' as PropertyType,
  STREET: 'STREET' as PropertyType,
  COUNTRY: 'COUNTRY' as PropertyType,
  BUILDING: 'BUILDING' as PropertyType,
  BOX: 'BOX' as PropertyType,
  LOCALITY: 'LOCALITY' as PropertyType,
  NUMBER: 'NUMBER' as PropertyType,
  ZIP: 'ZIP' as PropertyType,
  DISTRICT: 'DISTRICT' as PropertyType,
  PROVINCE: 'PROVINCE' as PropertyType,
  SUBURB: 'SUBURB' as PropertyType,
  STATE: 'STATE' as PropertyType,
  COUNTY: 'COUNTY' as PropertyType,
  LAT_LONG: 'LAT_LONG' as PropertyType,
  FACEBOOK: 'FACEBOOK' as PropertyType,
  TWITTER: 'TWITTER' as PropertyType,
  TIKTOK: 'TIKTOK' as PropertyType,
  WHATSAPP: 'WHATSAPP' as PropertyType,
  LINKEDIN: 'LINKEDIN' as PropertyType,
  INSTAGRAM: 'INSTAGRAM' as PropertyType,
  SKYPE: 'SKYPE' as PropertyType,
  YOUTUBE: 'YOUTUBE' as PropertyType,
  WECHAT: 'WECHAT' as PropertyType,
  FACEBOOK_MESSENGER: 'FACEBOOK_MESSENGER' as PropertyType,
  WEIBO: 'WEIBO' as PropertyType,
  QQ: 'QQ' as PropertyType,
  KUAISHOU: 'KUAISHOU' as PropertyType,
  QZONE: 'QZONE' as PropertyType,
  SNAPCHAT: 'SNAPCHAT' as PropertyType,
  TELEGRAM: 'TELEGRAM' as PropertyType,
  PINTEREST: 'PINTEREST' as PropertyType,
  REDDIT: 'REDDIT' as PropertyType,
  DISCORD: 'DISCORD' as PropertyType,
  TWITCH: 'TWITCH' as PropertyType,
  TUMBLR: 'TUMBLR' as PropertyType,
  MASTODON: 'MASTODON' as PropertyType,
  PATREON: 'PATREON' as PropertyType,
  KICKSTARTER: 'KICKSTARTER' as PropertyType,
  INDIEGOGO: 'INDIEGOGO' as PropertyType,
  GOFUNDME: 'GOFUNDME' as PropertyType,
  VIMEO: 'VIMEO' as PropertyType,
  NOTE: 'NOTE' as PropertyType,
  ORGANISATION_NAME: 'ORGANISATION_NAME' as PropertyType,
  LEGAL_NAME: 'LEGAL_NAME' as PropertyType,
  LEGAL_FORM: 'LEGAL_FORM' as PropertyType,
  LEGAL_ID: 'LEGAL_ID' as PropertyType,
  COMPANY_NUMBER: 'COMPANY_NUMBER' as PropertyType,
  JURISDICTION: 'JURISDICTION' as PropertyType,
  IMPORT_SOURCE: 'IMPORT_SOURCE' as PropertyType,
  PROPOSAL: 'PROPOSAL' as PropertyType,
  CATEGORY: 'CATEGORY' as PropertyType,
  TAKEAWAY: 'TAKEAWAY' as PropertyType,
  DELIVEROO: 'DELIVEROO' as PropertyType,
  SHIPTO: 'SHIPTO' as PropertyType,
  UBEREATS: 'UBEREATS' as PropertyType,
  OPENING_HOURS: 'OPENING_HOURS' as PropertyType,
  CUSTOM: 'CUSTOM' as PropertyType,
  FILE: 'FILE' as PropertyType,
  IMAGE: 'IMAGE' as PropertyType,
  FILE_KEY: 'FILE_KEY' as PropertyType,
  FILE_NAME: 'FILE_NAME' as PropertyType,
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS' as PropertyType,
  PRIVACY_POLICY: 'PRIVACY_POLICY' as PropertyType,
  DOCUMENT: 'DOCUMENT' as PropertyType,
  DOCUMENT_NAME: 'DOCUMENT_NAME' as PropertyType,
  ORGANISATION_TYPE: 'ORGANISATION_TYPE' as PropertyType,
  // not compliant with PropertyRepresentation.TypeEnum
  NAME: 'NAME' as PropertyType,
  BANK_ACCOUNT: 'BANK_ACCOUNT' as PropertyType,
  IBAN: 'IBAN' as PropertyType,
  PAGE: 'PAGE' as PropertyType,
};

export const FULL_NAME = [
  PropertyType.FIRST_NAME,
  PropertyType.MIDDLE_NAME,
  PropertyType.LAST_NAME,
];
