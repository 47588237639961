export interface PaymentInfo {
  subscribed: boolean;
  interval: string;
  currency: string;
  price: number;
}

export enum TierType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
}

export interface Tier {
  tier: TierType;
  interval: string;
  currency: string;
  price: number;
  credits: number;
}

export interface TierSubscriptionInfo {
  currentTier: TierType;
  credits: number;
  renewalDate: number;
  pendingDowngradeTier?: TierType;
  availableTiers: Tier[];
}

export interface BillingInfo {
  taxId: StripeTax;
  name: string;
  address: StripeAddress;
  email: string;
}

export interface StripeAddress {
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
}

export interface StripeTax {
  type: TaxType;
  value: string;
}

export enum TaxType {
  AE_TRN = 'AE_TRN',
  AU_ABN = 'AU_ABN',
  BR_CNPJ = 'BR_CNPJ',
  BR_CPF = 'BR_CPF',
  CA_BN = 'CA_BN',
  CA_QST = 'CA_QST',
  CH_VAT = 'CH_VAT',
  CL_TIN = 'CL_TIN',
  ES_CIF = 'ES_CIF',
  EU_VAT = 'EU_VAT',
  HK_BR = 'HK_BR',
  ID_NPWP = 'ID_NPWP',
  IN_GST = 'IN_GST',
  JP_CN = 'JP_CN',
  KR_BRN = 'KR_BRN',
  LI_UID = 'LI_UID',
  MX_RFC = 'MX_RFC',
  MY_FRP = 'MY_FRP',
  MY_ITN = 'MY_ITN',
  MY_SST = 'MY_SST',
  NO_VAT = 'NO_VAT',
  NZ_GST = 'NZ_GST',
  RU_INN = 'RU_INN',
  SA_VAT = 'SA_VAT',
  SG_GST = 'SG_GST',
  SG_UEN = 'SG_UEN',
  TH_VAT = 'TH_VAT',
  TW_VAT = 'TW_VAT',
  US_EIN = 'US_EIN',
  ZA_VAT = 'ZA_VAT',
}
