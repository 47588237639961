import {Pipe, PipeTransform} from '@angular/core';
import {Property} from '@aztrix/models';

import {isAddressRelated} from '../../helpers/property-functions';

@Pipe({name: 'propertyIsAddressRelated'})
export class PropertyIsAddressRelatedPipe implements PipeTransform {
  transform(property: Property | undefined): any {
    return isAddressRelated(property);
  }
}
