import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {ProfileVisibility, Visibility} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

import {LANGUAGE_OVERRIDE, TranslatePipe, TranslateService} from '@aztrix/translate';

@Pipe({
  name: 'profileVisibilityLabel',
  pure: false,
})
export class ProfileVisibilityLabelPipe implements PipeTransform {
  private _translate = new TranslatePipe(
    this._translateService,
    this._changeDetector,
    this._languageOverride$
  );

  constructor(
    private _translateService: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  transform(visibility: ProfileVisibility | Visibility): string {
    return this._translate.transform(`me.privacy.profile.${visibility.toLowerCase()}.title`);
  }
}
